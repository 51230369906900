import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useStores } from "@/store";
import { Drawer } from "antd";

const BookingQrCode = ({ currentBooking={}, visible, setVisible }) => {

  const { t } = useTranslation();
  const { bookingsStore } = useStores();
  const [scanned, setScanned] = useState(false)
  const timer = useRef(null)

  const { member={}, reservation_time } = currentBooking
  const { name } = currentBooking.restaurant || {};

  let time = moment(reservation_time).format("hh:mm A");
  let full_name
  if (!member.first_name && !member.last_name) {
    full_name = member.nickname
  } else {
    full_name = `${member.first_name} ${member.last_name}`
  }

  useEffect(() => {
    if (visible) {
      timer.current = setInterval(() => {
        getQrcodeStatus()
      }, 3000);
    }

    return () => {
      clearInterval(timer.current)
    }
  }, [visible])

  /* 轮训扫码状态 */
  const getQrcodeStatus = async () => {
    if (currentBooking.qr_code.key) {
      let res = await bookingsStore.getQrcodeStatus(currentBooking.qr_code.key)
      if (res?.is_scanned) {
        setScanned(true)
        // 扫码成功后，轮询订单详情
        clearInterval(timer.current)
        timer.current = setInterval(() => {
          getBookingDetail()
        }, 3000);
      }
    }
  }

  /* 轮训核销状态 */
  const getBookingDetail = async () => {
    if (currentBooking.id && currentBooking.reservation_code) {
      let booking = await bookingsStore.getReservation(currentBooking.id, currentBooking.reservation_code)
      if (booking && booking.status === 'confirmed' && booking.checked_at) {
        let list = bookingsStore.bookings.slice();
        let index = list.findIndex((i) => i.id === currentBooking.id);
        list[index] = booking
        bookingsStore.currentBooking = booking
        bookingsStore.bookings = list;
        setQrcodeVisible(false)
        clearInterval(timer)
      }
    }
  }

  return (
    <Drawer
      className="qrcode-drawer"
      placement="bottom"
      closable={false}
      height="514"
      destroyOnClose
      onClose={() => {
        clearInterval(timer.current);
        setVisible(false);
      }}
      visible={visible}
    >
      <div className="qrcode-drawer-body">
        <h3 className="title">{t("RESERVATION_INFO.QRCodeTitle")}</h3>
        <div className="content">
          <div className="content-inner">
            <div className="res-name">
              <i className="icon icon-rest"></i>
              <div>{name}</div>
            </div>
            <div className="user-info">
              <img src={member.avatar_url} alt="" />
              <div className="right">
                <div className="user-name">{full_name}</div>
                <div className="email">{member.email}</div>
                <div className="phone">+{member.country_code} {member.mobile}</div>
              </div>
            </div>
            <div className="info-bottom">
              <div className="bottom-item">
                <i className="icon icon-date"></i>
                {currentBooking.formated_date}
              </div>
              <div className="bottom-item">
                <i className="icon icon-time"></i>
                {time}
              </div>
              <div className="bottom-item">
                <i className="icon icon-seat"></i>
                {currentBooking.reservation_seats}
              </div>
            </div>
          </div>
          <div className="tip">{t("RESERVATION_INFO.QRCodeTip")}</div>
          <div className="success">
            {
              scanned && (
                <div className="success-text">
                  <i className="icon icon-success"></i>
                  {t("RESERVATION_INFO.ScanSuccessfully")}
                </div>
              )
            }
          </div>
          <div className="qr-code">
            <img src={currentBooking.qr_code?.qrcode_url} mode="scaleToFill" lazy-load="false" />
          </div>
        </div>
      </div>
    </Drawer>
  );
};


export default BookingQrCode;
