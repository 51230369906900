import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

/**时段信息 */
export const Availabilities = ({ availabilities, onClick }) => {
  const { t } = useTranslation();
  const times = useMemo(() => {
    try {
      return availabilities.time_slots || [];
    } catch (error) {
      return [];
    }
  });
  if (!availabilities) return null;
  return (
    <section className="page-restaurant--availabilities hairline-bottom">
      <div className="title flex">
        <h3>{t("WEB.Restaurant_Book_now")}</h3>
        <span className="inline-flex">
          <i className="icon icon-date"></i>
          {availabilities.date}
        </span>
      </div>

      <div className="availabilities-list">
        {times.map((item, index) => {
          return (
            <div
              className="list-item"
              key={index}
              onClick={() => onClick(item)}
              data-discount={item.formated_discount}
            >
              <p>{item.formated_time}</p>
              {item.formated_discount ? (
                <span>{item.formated_discount}</span>
              ) : (
                <span>
                  {item.seats} {t("SEATS")}
                </span>
              )}
            </div>
          );
        })}
      </div>
    </section>
  );
};
