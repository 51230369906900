import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {MenuItem,Icon} from "@/components";
import {Menu} from "antd";
import "./index.less";
import {useStores} from "@/store";
/**All */
const All = ({menus,tap,timeData}) => {
    let temp = [];
    let openKeys = []
    for (let key in menus) {
        let menu = menus[key];
        openKeys.push(key)
        temp.push({
            "key": key,
            "value": menu
        })
    }
    menus = temp;
    if (!menus.length) return null;
    const [state, setState] = useState({
        current: menus[0].key,
        openKeys: menus[0].key
    });
    const [allmenus, setAllmenus] = useState();// Popular
    const {restaurantStore} = useStores();
    const data = [];
    let nowDate = new Date();
    let year = nowDate.getFullYear();
    let month = nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1)
      : nowDate.getMonth() + 1;
    let day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate
      .getDate();
    let dateStr = year + "-" + month + "-" + day;
    useEffect(() => {
        restaurantStore.getAllMenus().then(function (data) {
            let temp = [];
            let openKeys = []
            for (let key in data) {
                let menu = data[key];
                openKeys.push(key)
                temp.push({
                    "key": key,
                    "value": menu
                })
            }
            menus = temp;
            setAllmenus(menus);
        });//All
    }, []);

    useEffect(() => {
        if(timeData.selectValue.length>0){
            let discount = timeData?.selectValue[0].split("-")[1].substring(0, timeData?.selectValue[0].split("-")[1].length - 1)
            restaurantStore.getAllMenusCache(discount).then(function (data) {
                let temp = [];
                let openKeys = []
                for (let key in data) {
                    let menu = data[key];
                    openKeys.push(key)
                    temp.push({
                        "key": key,
                        "value": menu
                    })
                }
                menus = temp;
                setAllmenus(menus)
            });
        }else{
            restaurantStore.getAllMenus().then(function (data) {
                let temp = [];
                let openKeys = []
                for (let key in data) {
                    let menu = data[key];
                    openKeys.push(key)
                    temp.push({
                        "key": key,
                        "value": menu
                    })
                }
                menus = temp;
                setAllmenus(menus)
            })
        }
      }, [timeData]);

    const {t} = useTranslation();

    function handleClick(e) {
        setState({
            current: e.key,
            openKeys: e.keyPath.slice(1)
        });
    };
    return (
        <section className="page-restaurant--ALL">
            <h3>{t("WEB.Full menu")} <span className="title-nums">{menus.length}</span></h3>
           <section>
               <Menu onClick={handleClick}
                     defaultOpenKeys={[state.current]}
                     selectedKeys={[state.current]}
                         mode="inline" >
                   {allmenus?allmenus.map((item) => {
                       return <Menu.SubMenu key={item.key} title={<span className="deal-title-all">{item.key}</span>}>
                           <div className="deals-list-ss ">
                               {item.value.map((item1, index1) => {
                                   return <MenuItem menu={item1} key={item1.id+item.key} odd={index1 % 2 === 0} mode={"all"}/>;
                               })}
                           </div>
                       </Menu.SubMenu>;
                   }):null}
               </Menu>
           </section>
        </section>
    );
};
export default All;
