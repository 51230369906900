import React, { useState, useMemo } from "react";
import Gallery from "react-bnb-gallery";
import "react-bnb-gallery/dist/style.css";
import { slimImage } from '@/service/utils'
import no_dish_picture from "@/images/no_dish_picture.svg";
/**横幅 */
export const Banner = ({ photos, cover }) => {
  const [show, setShow] = useState(false);
  function showPhotos() {
    setShow(true);
  }
  if (photos.length == 0) {
    photos.push({
      image_url: no_dish_picture
    })
  }
  const photoList = photos.map((i) => i.picture_url == undefined ? i.image_url : i.picture_url);
  return (
    <React.Fragment>
      {photos.length ? (
        <section className="page-restaurant--banner">
          <div className="banner-inner" onClick={showPhotos}>
              <div className={"review-pic"}>
                <img src={slimImage(cover || (photos[0].picture_url == undefined ?  photos[0].image_url :  photos[0].picture_url))} className="banner-bg" />
              </div>
            <div className="banner-tag">
              <i className="icon icon-pic"></i>
              <span>{photos.length}</span>
            </div>
          </div>
          <Gallery
            onClose={() => setShow(false)}
            photos={photoList}
            show={show}
            showThumbnails={false}
          ></Gallery>
        </section>
      ) : null}
    </React.Fragment>
  );
};
