import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Skeleton, Divider, Typography, Drawer } from 'antd'
import { RestaurantRate } from './Rate'
import { Header, MapView } from '@/components'

/**餐厅详情 */
export const Profile = ({ restaurant }) => {
  const { t } = useTranslation()
  const [show, setShow] = useState(false)
  const [showMap, setShowMap] = useState(false)
  const [open, setOpen] = useState(false)
  return (
    <article className='page-restaurant--profile'>
      {restaurant ? (
        <React.Fragment>
          <h3>{restaurant.name}</h3>
          <RestaurantRate restaurant={restaurant} showPrice={true} />
          <div className='profile-items'>
            <div className='profile-openhour'>
              <i className='icon icon-time'></i>
              <div className='content'>
                <div style={{ height: open ? 'auto' : '35px' }}>
                  {restaurant.opening_hour}
                </div>
                {restaurant.opening_hour &&
                  restaurant.opening_hour.split('\n').length > 2 && (
                    <i
                      className='icon icon-arrow-down'
                      onClick={() => {
                        setOpen(!open)
                      }}
                      style={{
                        transform: open ? 'rotate(90deg)' : 'rotate(-90deg)',
                      }}
                    ></i>
                  )}
              </div>
            </div>
            <div
              className='profile-openhour-mobile'
              data-show={show}
              onClick={() => setShow(!show)}
            >
              <i className='icon icon-time'></i>
              <div className='content'>{restaurant.opening_hour}</div>
              <i className='icon icon-arrow-down'></i>
            </div>
            <Divider type='vertical' />
            <footer className='profile-footer flex'>
              <div
                className='profile-address inline-flex'
                onClick={() => setShowMap(true)}
              >
                <i className='icon icon-location'></i>
                <span>{restaurant.address}</span>
              </div>
              <Divider type='vertical' />
              <a
                className='icon icon-phone'
                href={`tel:${restaurant.phone}`}
              ></a>
            </footer>
          </div>
        </React.Fragment>
      ) : (
        <Skeleton />
      )}
      <Drawer
        className='restaurant-drawer'
        placement='right'
        closable={false}
        destroyOnClose
        onClose={() => setShowMap(false)}
        visible={showMap}
      >
        <Header title={t('ADDRESS')} onClick={() => setShowMap(false)} />
        {/* 地图 */}
        <div className='restaurant-map'>
          <MapView {...restaurant} showButton={true} />
        </div>
      </Drawer>
    </article>
  )
}
