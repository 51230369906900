import React, { useMemo, useState, useEffect, useRef, useCallback } from 'react'
import { useStores } from '@/store'
import { Carousel } from 'antd'
import { getQuery } from '@/service/http'
import { handleDeepLink } from '@/service/utils'
import './index.less'

const ShortCuts = () => {
  const [shortCuts, setShortCuts] = useState([])
  const { HomeStore } = useStores()
  const [isOverflow, setIsOverflow] = useState(false)
  const [isTopShow, setIsTopShow] = useState(false)
  const [isBottomShow, setIsBottomShow] = useState(true)
  const nodeRef = useRef('')
  const {
    region,
    lang = 'en',
    private_token,
    callback,
    payment,
    login_url,
  } = getQuery()
  useEffect(() => {
    HomeStore.getHomeShortCuts({ region }).then(setShortCuts)
  }, [HomeStore, region])
  const swiper = useMemo(() => {
    if (shortCuts?.length) {
      let tempCuts = [...shortCuts]
      let tempSwiper = []
      const step = 10
      for (let index = 0; index < tempCuts.length; index += step) {
        tempSwiper.push(tempCuts.slice(index, index + step))
      }
      return tempSwiper
    } else {
      return []
    }
  }, [shortCuts])

  const handlerOverflow = useCallback(() => {
    if (shortCuts?.length * 47 > nodeRef.current?.offsetHeight) {
      setIsOverflow(true)
    } else {
      setIsOverflow(false)
    }
  }, [shortCuts])
  const handlerScroll = (e) => {
    if (e.target.scrollTop === 0) {
      setIsTopShow(false)
      setIsBottomShow(true)
    } else if (
      e.target.scrollTop + e.target.offsetHeight >=
      e.target.scrollHeight
    ) {
      setIsTopShow(true)
      setIsBottomShow(false)
    } else {
      setIsTopShow(true)
      setIsBottomShow(true)
    }
  }

  useEffect(() => {
    handlerOverflow()
    // 监听
    window.addEventListener('resize', handlerOverflow)
    // 销毁
    return () => window.removeEventListener('resize', handlerOverflow)
  })
  useEffect(() => {
    nodeRef.current.addEventListener('scroll', handlerScroll)
    return () => nodeRef?.current.addEventListener('scroll', handlerScroll)
  })
  const hanlderDown = () => {
    const itemList = document.querySelectorAll('.shortcuts-pc .shortcut')
    itemList[itemList.length - 1].scrollIntoView({
      behavior: 'smooth',
    })
    setIsTopShow(true)
    setIsBottomShow(false)
  }
  const hanlderUp = () => {
    const item = document.querySelector('.shortcuts-pc .shortcut')
    item.scrollIntoView({
      behavior: 'smooth',
    })
    setIsTopShow(false)
    setIsBottomShow(true)
  }
  return (
    <div className='shortcuts-link'>
      <div className='shortcuts-mobile'>
        <Carousel dots={false} adaptiveHeight={true} infinite={false}>
          {swiper.map((cuts, index) => (
            <div key={index}>
              <div className='shortcuts-item'>
                {cuts.map((item) => (
                  <div
                    key={item.id}
                    className='shortcut'
                    onClick={() => {
                      handleDeepLink(item?.links?.web || item?.links?.ios)
                    }}
                  >
                    <img src={item.icon_url} alt='' />
                    <div className='title'>{item.title}</div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </Carousel>
      </div>
      <div className='shortcuts-pc' ref={nodeRef}>
        {isOverflow && (
          <div className='shortcuts-op-top shortcuts-op' hidden={!isTopShow}>
            <div className='shortcuts-btn' onClick={hanlderUp}>
              <i className='icon icon-arrow-down'></i>
            </div>
          </div>
        )}
        {shortCuts.map((item) => (
          <a
            key={item.id}
            className='shortcut'
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleDeepLink(item?.links?.web || item?.links?.ios)
            }}
            href={item?.links?.web || item?.links?.ios}
          >
            <img src={item.icon_url + '-thumb'} alt='' />
            <div className='title'>{item.title}</div>
          </a>
        ))}
        {isOverflow && (
          <div
            className='shortcuts-op-bottom shortcuts-op'
            hidden={!isBottomShow}
          >
            <div className='shortcuts-btn' onClick={hanlderDown}>
              <i className='icon icon-arrow-down'></i>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ShortCuts
