import React, {useState} from "react";
import {slimImage} from "@/service/utils";
import { useTranslation } from 'react-i18next';
import "./index.less";
import {Icon,MenuDetail} from "@/components";
import no_dish_picture from "@/images/no_dish_picture.svg";
import {useStores} from "@/store";
/**
 * 餐厅信息
 *
 * @returns
 */
const Restaurant = ({menu, odd,index,mode}) => {
    const { t } = useTranslation();
    const [visibleDetail, setVisibleDetail] = useState(false);
    const [menu1, setMenu] = useState(menu);
    const [detail, setDetail] = useState([]);
    const [deals, setMenuDeals] = useState([]);
    const [events, setMenuEvents] = useState([]);
    // const [reviews, setMenuReviews] = useState([]);
    const {restaurantStore} = useStores();
    function goDetail(menu) {
        if (!visibleDetail) {
            restaurantStore.getMenuDetail(menu.id).then(setDetail)
            restaurantStore.getMenuEvents(menu.id).then(setMenuEvents)
            restaurantStore.getMenuDeals(menu.id).then(setMenuDeals)
            // restaurantStore.getMenuReviews(menu.id).then(setMenuReviews)
            setMenu(menu)
            setVisibleDetail(true)
        }
    }
    return (
        <a
            onClick={() => goDetail(menu1)}
            className="deal-menu-item"
        >
            <article>
                <div className="deal-menu-item--image">
                    <img
                        src={slimImage(menu.photos==undefined||menu.photos.length == 0||menu.photos[0]==null ? no_dish_picture : menu.photos[0].image_url+'-thumb', "/w/148/h/100")}
                        alt=""/>
                    <div className="imgs-buttom-info">
                        <Icon icon={"icon icon-pic"}/>
                        {menu.photos_count}{menu.photos_count < 99 ? '' : "+"}
                    </div>
                    {menu.popular_rank != null ?
                        menu.popular_rank<11?
                          menu.popular_rank>0&menu.popular_rank<4?
                      <div className="imgs-top-info opacity04">
                          Top{menu.popular_rank}
                      </div>:<div className="imgs-top-info opacity410">
                                Top{menu.popular_rank}
                            </div>
                          :null
                     : ""}
                </div>
                <div className="deal-menu-item--content">
                    <div className="deal-title">{menu.name}</div>
                    <div className="deal-description ellipsis">
                        {menu.desc}
                    </div>
                    <div className="deal-footer">
                        {menu.comments_count==null||menu.comments_count==""?<span className="deal-location small"></span>:<span className="deal-location small">
                           <Icon icon="icon icon-review-grey" text={menu.comments_count}/>
                            {menu.comments_count < 99 ? '' : "+"}
                          <span></span>
                         </span>}
                         {menu.event_menu? (
                            <div className="eventExclusive">{t("WEB.EventExclusive")}</div>
                            ) : (
                            <span className='deal-price-desc'>
                                {menu.menu_category_name === 'discount' ? (
                                <i>
                                    {menu.currency}
                                    {menu.discountPrice}
                                </i>
                                ) : null}
                                {menu.currency}
                                {Number(menu.price).toLocaleString()}
                            </span>
                        )}
                    </div>
                </div>
            </article>
            {visibleDetail ?
              <MenuDetail menu={menu1} visibleDetail={visibleDetail} setVisibleDetail={setVisibleDetail} detail={detail} deals={deals} events={events}/> : null}
        </a>
    );
};

export default Restaurant;
