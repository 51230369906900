import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useStores } from "@/store";
import "./index.less";
import { Drawer, Modal, message, Spin } from "antd";
import { Header } from "@/components";
import { getQuery } from "@/service/http";

const emailReg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,6}$/
const phoneReg = /(^[1-9]\d*$)/

let timer = null
let gettingCode = false
let timeStep = 180

/**
 * 账号设置
 *
 */
const AccountSettings = ({ member, onUpdate, toggleVisible, userDeleteSuccess }) => {
  const { t } = useTranslation();
  const { memberStore } = useStores();
  const { lang = "en" } = getQuery();
  const [settingVisible, setSettingVisible] = useState(false);
  const [settingType, setSettingType] = useState('')
  const [deleteVisible, setDeleteVisible] = useState(false)
  const [genderVisible, setGenderVisible] = useState(false)
  const [phoneVisible, setPhoneVisible] = useState(false)
  const [codesVisible, setCodesVisible] = useState(false)
  const [destoryData, setDestoryData] = useState({})
  const [nickname, setNickname] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [promotion, setPromotion] = useState(true)
  const [countryCode, setCountryCode] = useState(member.country_code)
  const [phone, setPhone] = useState('')
  const [code, setCode] = useState('')
  const [gender, setGender] = useState('')
  const [codeError, setCodeError] = useState('')
  const [phoneError, setPhoneError] = useState(false)
  const [countryCodes, setCountryCodes] = useState([])
  const [getCodeSuccess, setGetCodeSuccess] = useState(false)
  const [time, setTime] = useState(timeStep)
  const [cursorVisible, setCursorVisible] = useState(false)
  const [phoneCallVisible, setPhoneCallVisible] = useState(false)
  const [helpVisible, setHelpVisible] = useState(false)

  useEffect(() => {
    memberStore.getDestorySummery({ lang }).then(setDestoryData)
    memberStore.getCountryCodes({ lang }).then(setCountryCodes)

    return () => {
      clearInterval(timer)
    }
  }, []);

  const canSave = useMemo(() => {
    if (settingType === 'nickname') {
      return !!(nickname.trim())
    } else if (settingType === 'name') {
      return !!(firstName.trim() && lastName.trim())
    } else if (settingType === 'email') {
      return emailReg.test(email.trim())
    } else if (settingType === 'gender') {
      return !!gender
    } else if (settingType === 'phone') {
      return phoneReg.test(phone.trim()) && code.length === 4
    } else if (settingType === 'delete') {
      return phoneReg.test(phone.trim()) && code.length === 4
    } else {
      return false
    }
  }, [settingType, nickname, firstName, lastName, email, gender, phone, code]);

  const emialError = useMemo(() => {
    return email && !emailReg.test(email.trim())
  }, [email])

  const cursorIndex = useMemo(() => {
    if (cursorVisible) {
      return code.length
    } else {
      return -1
    }
  }, [code, cursorVisible])

  function tapSettingItem(type) {
    if (type === 'phone') {
      setPhone('')
      setCode('')
      gettingCode = false
      setGetCodeSuccess(false)
      setPhoneVisible(true)
    } else if (type === 'delete') {
      setDeleteVisible(true)
    } else {
      setSettingVisible(true)
    }
    setSettingType(type)
  }

  function selectGender(val) {
    setGenderVisible(false)
    setGender(val)
  }

  function saveUpdate() {
    if (!canSave) return
    let params = {}
    switch (settingType) {
      case 'nickname':
        params.nickname = nickname.trim()
        break;
      case 'name':
        params.first_name = firstName.trim()
        params.last_name = lastName.trim()
        break;
      case 'email':
        params.email = email.trim()
        params.promotion = promotion
        break;
      case 'gender':
        params.gender = gender
        break;
      case 'phone':
        params.country_code = countryCode
        params.mobile = phone
        params.mobile_token = code
      default:
        break;
    }
    onUpdate(params).then(() => {
      setSettingVisible()
      setNickname('')
      setFirstName('')
      setLastName('')
      setEmail('')
      setPhone('')
      setCode('')
      setGender('')
    }).catch(err => {
      console.log(err)
      if (settingType === 'phone') {
        setCodeError(err.message)
      }
    })
  }

  function savePhoneNumber() {
    if (settingType === 'delete') {
      deleteAccount({
        country_code: countryCode,
        mobile: phone,
        mobile_token: code
      })
    } else {
      saveUpdate()
    }
  }

  function deleteAccount(params) {
    memberStore.detoryMember(params).then(res => {
      setDeleteVisible(false)
      setPhoneVisible(false)
      toggleVisible(false)
      userDeleteSuccess()
    })
  }

  function selectCountryCode(item) {
    setCodesVisible(false)
    setCountryCode(item.country_code)
  }

  function getSmsCode(byPhone = false) {
    if (!phoneReg.test(phone.trim())) {
      setPhoneError(true)
      return
    }
    if (phoneError || gettingCode) return
    gettingCode = true
    memberStore.sendSmsCode({
      country_code: countryCode,
      mobile: phone.trim(),
      token_sender: byPhone ? 'voice' : 'sms'
    }).then(res => {
      if (res?.status) {
        if (byPhone) {
          setPhoneCallVisible(false)
        }
        clearInterval(timer)
        setGetCodeSuccess(true)
        timeStep = res.expired_seconds
        setTime(timeStep)
        timer = setInterval(() => {
          timeStep -= 1
          setTime(timeStep)
          if (timeStep <= 0) {
            gettingCode = false
            setGetCodeSuccess(false)
          }
        }, 1000);
      } else {
        gettingCode = false
      }
    }).catch(() => {
      gettingCode = false
    })
  }

  function enterCode(e) {
    setCode(e.target.value.trim())
  }

  function tapDeleteAccount() {
    setPhone(member.mobile)
    setCountryCode(member.country_code)
    gettingCode = false
    setGetCodeSuccess(false)
    setPhoneVisible(true)
  }

  return (
    <div className="dc-account-settings">
      <div className="settings">
        <div className="setting-item" onClick={() => tapSettingItem('nickname')}>
          <div className="label">{t("Profile.setting-nickname")}</div>
          <div className="value">{member.nickname || t('Profile.no-set')}</div>
          <i className="icon icon-arrow-right"></i>
        </div>
        <div className="setting-item" onClick={() => tapSettingItem('name')}>
          <div className="label">{t("Profile.setting-name")}</div>
          <div className="value">{member.first_name && member.last_name ? `${member.first_name} ${member.last_name}` : t('Profile.no-set')}</div>
          <i className="icon icon-arrow-right"></i>
        </div>
        <div className="setting-item" onClick={() => tapSettingItem('email')}>
          <div className="label">{t("Profile.setting-email")}</div>
          <div className="value">{member.email || t('Profile.no-set')}</div>
          <i className="icon icon-arrow-right"></i>
        </div>
        <div className="setting-item" onClick={() => tapSettingItem('phone')}>
          <div className="label">{t("Profile.setting-phone")}</div>
          <div className="value">{member.mobile || t('Profile.no-set')}</div>
          <i className="icon icon-arrow-right"></i>
        </div>
        <div className="setting-item" onClick={() => tapSettingItem('gender')}>
          <div className="label">{t("Profile.setting-gender")}</div>
          <div className="value">{member.gender ? t(`Profile.gender-${member.gender}`) : t('Profile.no-set')}</div>
          <i className="icon icon-arrow-right"></i>
        </div>
        <div className="setting-item" onClick={() => tapSettingItem('delete')}>
          <div className="label">{t("Profile.setting-delete")}</div>
          <div className="value"></div>
          <i className="icon icon-arrow-right"></i>
        </div>
      </div>

      <Drawer
        className="booking-drawer setting-form-drawer"
        placement="right"
        closable={false}
        bodyStyle={{ paddingLeft: 0, paddingRight: 0, paddingTop: '44px' }}
        destroyOnClose
        zIndex={9998}
        onClose={() => setSettingVisible(false)}
        visible={settingVisible}
      >
        <Header title={t(`Profile.setting-${settingType}`)} onClick={() => setSettingVisible(false)} />
        <div className="setting-form">
          {
            settingType === 'nickname' ? (
              <div className="input-item">
                <div className="label">{t("Profile.setting-nickname")}</div>
                <input type="text" placeholder={t("Profile.placeholder-nickname")} value={nickname} onChange={e => setNickname(e.target.value)} />
              </div>
            ) : null
          }
          {
            settingType === 'name' ? (
              <React.Fragment>
                <div className="input-item">
                  <div className="label">{t("Profile.setting-first-name")}</div>
                  <input type="text" placeholder={t("Profile.placeholder-first-name")} value={firstName} onChange={e => setFirstName(e.target.value)} />
                </div>
                <div className="input-item">
                  <div className="label">{t("Profile.setting-last-name")}</div>
                  <input type="text" placeholder={t("Profile.placeholder-last-name")} value={lastName} onChange={e => setLastName(e.target.value)} />
                </div>
              </React.Fragment>
            ) : null
          }
          {
            settingType === 'email' ? (
              <div className="email-item">
                <div className="title">{t('Profile.email-title')}</div>
                <div className="tip">{t('Profile.email-tip1')}</div>
                <div className="tip">{t('Profile.email-tip2')}</div>
                <div className={`email-input ${emialError ? 'error' : ''}`}>
                  <input type="text" placeholder={t('Profile.placeholder-email')} value={email} onChange={e => setEmail(e.target.value)} />
                </div>
                {
                  emialError && <div className="error-tip">{t("Profile.email-error")}</div>
                }
                <div className="receive-msg" onClick={() => setPromotion(!promotion)}>
                  <i className={`icon icon-check ${promotion ? 'selected' : ''}`}></i>
                  <div className="receive-tip">{t("Profile.email-receive")}</div>
                </div>
              </div>
            ) : null
          }
          {
            settingType === 'gender' ? (
              <div className="gender-item">
                <div className="title">{t('Profile.setting-gender')}</div>
                <div className="tip">{t('Profile.gender-tip')}</div>
                <div className="gender-select" onClick={() => setGenderVisible(true)}>
                  <div className={`value ${gender ? '' : 'placeholder'}`}>{gender ? t(`Profile.gender-${gender}`) : t("Profile.gender-select")}</div>
                  <i className="icon icon-arrow-down"></i>
                </div>
              </div>
            ) : null
          }
          <div className={`save-button ${canSave ? '' : 'disabled'}`} onClick={() => saveUpdate()}>{t("Profile.setting-save")}</div>
        </div>
      </Drawer>

      <Drawer
        className="booking-drawer setting-form-drawer"
        placement="right"
        closable={false}
        zIndex={9999}
        bodyStyle={{
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: '44px',
          paddingBottom: 0,
          display: 'flex',
          flexDirection: 'column',
        }}
        destroyOnClose
        onClose={() => setDeleteVisible(false)}
        visible={deleteVisible}
      >
        <Header title={t(`Profile.setting-delete`)} onClick={() => setDeleteVisible(false)} />
        <div className="delete-account">
          <div className="warning">
            <i className="icon icon-warning"></i>
          </div>
          <div className="title">{t("Profile.deleting")} +{destoryData.country_code} {destoryData.mobile}</div>
          <div className="tip">
            {destoryData.summary}
            {/* <div>{t("Profile.deleting-tip1")}</div>
            <div style={{ marginBottom: '20px' }}>{t("Profile.deleting-tip2")}</div>
            <div>{t("Profile.deleting-tip3")}</div>
            <div>{t("Profile.deleting-tip4")}</div>
            <div>{t("Profile.deleting-tip5")}</div>
            <div>{t("Profile.deleting-tip6")}</div>
            <div>{t("Profile.deleting-tip7")}</div>
            <div style={{ marginBottom: '20px' }}>{t("Profile.deleting-tip8")}</div>
            <div style={{ marginBottom: '20px' }}>{t("Profile.deleting-tip9")}</div>
            <div>{t("Profile.deleting-tip0")}</div> */}
          </div>
          <div className="bottom">
            <div className="confirm-button" onClick={() => tapDeleteAccount()}>{t("Profile.deleting-confirm")}</div>
            <div className="conditions">
              {t("Profile.deleting-confirm-tip")}
              <a href={destoryData.destroy_conditions_url} target="_blank">{t("Profile.deleting-conditions")}</a>
            </div>
          </div>
        </div>
      </Drawer>

      <Drawer
        className="booking-drawer setting-form-drawer"
        placement="right"
        closable={false}
        bodyStyle={{ paddingLeft: 0, paddingRight: 0, paddingTop: '44px' }}
        destroyOnClose
        zIndex={9999}
        onClose={() => setPhoneVisible(false)}
        visible={phoneVisible}
      >
        <Header title={t(`Profile.setting-${settingType}`)} onClick={() => setPhoneVisible(false)} />
        <div className="setting-form">
          <div className="phone-item">
            <div className="title">{t('Profile.phone-title')}</div>
            <div className="tip">{t('Profile.phone-tip')}</div>
            <div className="small-title">{t('Profile.phone-small-title')}</div>
            <div className={`phone-input ${phoneError ? 'error' : ''}`}>
              <div className={`country-box ${settingType === 'delete' ? 'disabled' : ''}`} onClick={() => { settingType !== 'delete' && setCodesVisible(true) }}>
                <div className="country">+{countryCode}</div>
                <i className="icon icon-arrow-down"></i>
              </div>
              <input type="tel" value={phone} disabled={settingType === 'delete'} onChange={e => { setPhone(e.target.value); setPhoneError(e.target.value && !phoneReg.test(e.target.value.trim())) }} placeholder={t('Profile.placeholder-phone')} />
            </div>
            {
              phoneError ? <div className="phone-error">{t('Profile.phone-error')}</div> : null
            }
            <div className="code-box">
              <div className="code-input">
                <label className="code-nums" htmlFor="code-input">
                  <div className={`code-num ${code[0] || cursorIndex === 0 ? 'focus' : ''}`}>
                    {code[0]}
                    {cursorIndex === 0 ? <div className="cursor"></div> : null}
                  </div>
                  <div className={`code-num ${code[1] || cursorIndex === 1 ? 'focus' : ''}`}>
                    {code[1]}
                    {cursorIndex === 1 ? <div className="cursor"></div> : null}
                  </div>
                  <div className={`code-num ${code[2] || cursorIndex === 2 ? 'focus' : ''}`}>
                    {code[2]}
                    {cursorIndex === 2 ? <div className="cursor"></div> : null}
                  </div>
                  <div className={`code-num ${code[3] || cursorIndex === 3 ? 'focus' : ''}`}>
                    {code[3]}
                    {cursorIndex === 3 ? <div className="cursor"></div> : null}
                  </div>
                  <input
                    type="tel"
                    id="code-input"
                    value={code}
                    autoComplete="off"
                    maxLength="4"
                    onFocus={() => { setCursorVisible(true); setCodeError('') }}
                    onBlur={() => setCursorVisible(false)}
                    onChange={e => enterCode(e)}
                  />
                </label>
                {
                  codeError ? <div className="code-error">{codeError}</div> : null
                }
              </div>
              <div className={`get-code ${getCodeSuccess ? 'getted' : ''}`} onClick={() => getSmsCode()}>
                {getCodeSuccess ? `${t('Profile.get-again')} (${time})` : t('Profile.phone-sms-code')}
              </div>
            </div>
            <div className="code-help">
              <div className="help" onClick={() => setHelpVisible(true)}>{t('Profile.phone-cannot-get')}</div>
              <div className="phone-call" onClick={() => setPhoneCallVisible(true)}>{t('Profile.phone-phone-call')}</div>
            </div>
          </div>
          <div className={`save-button ${canSave ? '' : 'disabled'}`} onClick={() => savePhoneNumber()}>{t("Profile.setting-save")}</div>
        </div>
      </Drawer>

      <Modal
        title={t("Profile.select-gender")}
        visible={genderVisible}
        footer={null}
        zIndex={9999}
        onCancel={() => setGenderVisible(false)}
      >
        <div className="gender-select-item" onClick={() => selectGender('m')}>{t("Profile.gender-m")}</div>
        <div className="gender-select-item" onClick={() => selectGender('f')}>{t("Profile.gender-f")}</div>
      </Modal>

      <Modal
        title={t("Profile.select-country-code")}
        visible={codesVisible}
        footer={null}
        zIndex={1002}
        onCancel={() => setCodesVisible(false)}
      >
        <div className="country-codes">
          {
            countryCodes.map((item, index) => (
              <div className="code-item" key={`${item.country_code}-${index}`} onClick={() => selectCountryCode(item)}>
                {item.name}
              </div>
            ))
          }
        </div>
      </Modal>

      <Modal
        visible={phoneCallVisible}
        footer={null}
        closable={false}
        zIndex={1003}
        onCancel={() => setPhoneCallVisible(false)}
      >
        <div className="phone-call">
          <div className="title">
            <i className="icon icon-phone"></i>
            {t("Profile.phone-call-title")}
          </div>
          <div className="content">
            <div className="tip">{t("Profile.phone-call-tip1")}</div>
            <div className="tip" style={{ marginTop: '20px' }}>{t("Profile.phone-call-tip2")}</div>
          </div>
          <div className="footer-buttons">
            <div className="back" onClick={() => setPhoneCallVisible(false)}>{t("Profile.phone-call-back")}</div>
            <div className={`confirm ${getCodeSuccess ? 'getted' : ''}`} onClick={() => getSmsCode(true)}>
              {getCodeSuccess ? `${t('Profile.get-again')} (${time})` : t('Profile.phone-call-me')}
            </div>
          </div>
        </div>
      </Modal>

      <SettingHelp visible={helpVisible} toggleVisible={setHelpVisible}></SettingHelp>
    </div>
  );
};

const SettingHelp = ({ visible, toggleVisible }) => {
  const { t } = useTranslation();
  const { memberStore } = useStores();
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [submitLoading, setSubmitLoading] = useState(false)

  useEffect(() => {
    if (!visible) {
      setFirstName('')
      setLastName('')
      setEmail('')
    }
  }, [visible])

  const canSave = useMemo(() => {
    return firstName.trim() && lastName.trim() && emailReg.test(email.trim())
  }, [firstName, lastName, email])

  function sendHelp() {
    if (canSave) {
      setSubmitLoading(true)
      memberStore.sendHelp({
        first_name: firstName,
        last_name: lastName,
        email: email
      }).then(res => {
        if (res && res.status) {
          message.success(t("Profile.setting-submit-success"));
          toggleVisible(false)
        }
      }).finally(() => {
        setTimeout(() => {
          setSubmitLoading(false)
        }, 500)
      })
    }
  }

  return (
    <Modal
      visible={visible}
      footer={null}
      bodyStyle={{ padding: 0 }}
      zIndex={1004}
      closable={false}
      onCancel={() => toggleVisible(false)}
    >
      <div className="setting-help">
        <div className="title">
          {t("Profile.setting-help")}
          <i className="icon icon-close" onClick={() => toggleVisible(false)}></i>
        </div>
        <div className="content">
          <div className="tip">{t("Profile.setting-help-tip")}</div>
          <div className="name-input">
            <input type="text" placeholder={t('Profile.setting-first-name')} value={firstName} onChange={(e) => setFirstName(e.target.value)} />
            <input type="text" placeholder={t('Profile.setting-last-name')} value={lastName} onChange={(e) => setLastName(e.target.value)} />
          </div>
          <div className="email-input">
            <input type="email" placeholder={t('Profile.placeholder-email')} value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>
        </div>
        <div className="footer-buttons">
          <Spin spinning={submitLoading}>
            <div className={`confirm ${canSave ? '' : 'disabled'}`} onClick={() => sendHelp()}>{t("Profile.setting-submit")}</div>
          </Spin>
        </div>
      </div>
    </Modal>
  )
}

export default AccountSettings;
