import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Drawer, message, Modal } from 'antd'
import { getQuery } from '@/service/http'
import { useStores } from '@/store'
import * as Common from '@/components/common'
import './index.less'
import { Button } from 'antd'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Terms } from '../../../../components/dc-voucher'
import QRCode from 'qrcode.react'
import qs from 'qs'
const ButtonGroup = Button.Group
let timer = null
function sleep(time = 300) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), time)
  })
}

// 进出场，回调
async function animate(dir, callback) {
  if (dir === 'PUSH') {
    callback()
    await sleep(30)
    document.body.setAttribute('animate', 'push')
  } else {
    document.body.setAttribute('animate', 'pop')
    await sleep()
    callback()
    document.body.setAttribute('animate', '')
  }
}

/**
 * GetDeal
 *
 * @param {*} props
 */
const GetDeal = ({
  dealId,
  restaurant_id,
  getDealVisible,
  setGetDealVisible,
  deal,
  checkRedeem,
}) => {
  const { t } = useTranslation()
  let { paymentStore, dealStore, restaurantStore, memberStore } = useStores()
  let [visible, setVisible] = useState(false)
  let [loading, setLoading] = useState('')
  let [termsVisible, setTermsVisible] = useState(false)
  let [paymentVisible, setPaymentVisible] = useState(false)
  let [payment, setPayment] = useState('')
  let [dealsVoucherBatch, setDealsVoucherBatch] = useState([])
  let [max, setMax] = useState(0)
  let [price, setPrice] = useState(0)
  let [acceptTerms, setAcceptTerms] = useState(false)
  let [declineDisabled, setDeclineDisabled] = useState(true)
  let [increaseDisabled, setIncreaseDisabled] = useState(false)
  let [payState, setPayState] = useState(false)
  let [payFailed, setPayFailed] = useState(false)
  let [payProcessing, setPayProcessing] = useState(false)
  let [state, setState] = useState({
    count: 1,
    show: true,
  })
  let [paymentLoadingVisible, setPaymentLoadingVisible] = useState(false)
  let [showQrocde, setShowQrocde] = useState(false)
  let [terms, setTerms] = useState('') // 条款
  let [termsShow, setTermsShow] = useState(false)
  let [showUseNow, setShowUseNow] = useState('desktop')
  let isSubmit = false
  console.log(document.referrer)
  /**查看条款 */
  function showTerm(data) {
    setTerms(data)
    setTermsShow(true)
  }
  function onPaymentPopupBack(data) {
    setPaymentVisible(false)
    setPayment(data)
  }
  console.log(deal)
  /**当成功动画播放结束 */
  function onAnimateComplete(createVoucherResult) {
    setVisible(false)
    isSubmit = false
    if (createVoucherResult) {
      onPay(createVoucherResult)
    }
  }
  const { payment: payState1, lang, region } = getQuery()
  const baseURL =
    process.env.REACT_APP_MODE === 'PRODUTION'
      ? 'https://website.diningcity.asia'
      : 'https://staging-website.diningcity.asia'
  //  : "https://staging-website.diningcity.asia"
  const private_token = window.private_token
  let query = qs.stringify({
    payment: true,
    payment_method: payment,
    private_token: private_token,
    price: price,
    count: state.count,
  })
  query = query.replace(new RegExp('&', 'g'), '-')
  // console.log(window.top.location,window.top.location.href)
  // const success_url = `${baseURL}/#/deals/${dealId}?` + query
  const success_url =
    `${document.referrer}${lang}/${region}/deals/${dealId}/reservation?` + query
  let queryFail = qs.stringify({
    payment: false,
    payment_method: payment,
    private_token: private_token,
    price: price,
    count: state.count,
  })
  queryFail = queryFail.replace(new RegExp('&', 'g'), '-')
  const fail_url = `${baseURL}/#/deals/${dealId}?` + queryFail

  /**唤起支付 */
  function onPay(createVoucherResult) {
    paymentStore
      .prePayment({
        ...createVoucherResult,
        payment_method: payment,
        success_url: success_url,
      })
      .then((data) => {
        setPayState(true)
        setPayFailed(false)
        setPayProcessing(true)
        if (['wx_pub_qr', 'alipay_qr'].includes(data.channel)) {
          setShowQrocde(true)
          checkPayStatus()
        } else {
          // ping++支付，出现支付等待弹窗
          // setPaymentLoadingVisible(true);
          paymentStore.callPayment().then((status) => {
            setPaymentLoadingVisible(false)
            console.log('---------')
            console.log(status)
          })
          setPayState(true)
          setPayFailed(false)
        }
      })
      .catch((error) => {
        setPayState(true)
        setPayFailed(true)
        setPaymentLoadingVisible(false)
        console.error(error)
      })
  }

  /**获取到支付结果 */
  function onPaymentComplete() {
    setPaymentLoadingVisible(false)
    debugger
    sessionStorage.setItem('pay', '123')
    console.log(document.referrer)
    // 支付完成，去回调页面
    window.location.href = success_url
    // history.goBack()
  }

  async function onConfirm() {
    if (isSubmit) return
    isSubmit = true
    try {
      setVisible(true)
      setLoading('loading')
      let data = {
        order: {
          items: [
            {
              product_type: 'VoucherBatch',
              product_id: dealsVoucherBatch.id,
              quantity: state.count,
            },
          ],
        },
      }
      let createVoucherResult = await dealStore.createVoucher(
        data,
        restaurant_id
      )
      // 如果需要需要支付，不出现动画
      if (createVoucherResult) {
        onAnimateComplete(createVoucherResult)
      } else {
        setPayFailed(true)
        setTimeout(() => {
          setLoading('')
          setVisible(false)
          isSubmit = false
        }, 3300)
      }
    } catch (error) {
      message.error(error.data.full_messages, 5)
      setPayState(true)
      setPayFailed(true)
      setPaymentLoadingVisible(false)
      setVisible(false)
      setLoading(false)
    }
  }

  // pay now
  async function onSubmit() {
    try {
      setVisible(true)
      onConfirm()
    } catch (error) {
      console.log(error)
    }
  }

  async function useLater() {
    setAcceptTerms(false)
    setGetDealVisible(false)
    setPayState(false)
    setPayFailed(false)
    setTermsVisible(false)
    setPayment('')
  }

  async function useNow() {
    // memberStore.private_token = window.private_token
    // window.location.href = dealStore.webform
    setAcceptTerms(false)
    setGetDealVisible(false)
    setPayState(false)
    setPayFailed(false)
    setTermsVisible(false)
    setPayment('')
    checkRedeem(true)
  }
  async function canceWechatPay() {
    clearInterval(timer)
    setPaymentLoadingVisible(false)
    setShowQrocde(false)
    paymentStore.getPayment().then(({ data }) => {
      if (data.status === 'pending') {
        setPayFailed(true)
        setPayProcessing(false)
        setPayState(true)
      } else {
        setPayState(true)
        setPayFailed(false)
        setPayProcessing(false)
        checkRedeem()
      }
    })
  }

  async function checkPayStatus() {
    clearInterval(timer)
    console.log('checkPayStatus')
    timer = setInterval(() => {
      paymentStore.getPayment().then(({ data }) => {
        if (data.status == 'pending') {
          setPayProcessing(true)
          setPayFailed(false)
        } else if (data.status == 'paid') {
          console.log('paid')
          setPayProcessing(false)
          setPayFailed(false)
          setPayState(true)
          setShowQrocde(false)
          clearInterval(timer)
          checkRedeem()
        }
      })
    }, 2000)
  }

  const onChange = async (data = {}) => {
    setAcceptTerms(data.acceptTerms)
  }
  useEffect(() => {
    paymentStore.getMethods()
    if (payState1 && payState1.includes('-')) {
      let arrayParam = payState1.split('-')
      window.private_token = arrayParam[2].split('=')[1]
    }
    dealStore.getDealsVoucherBatch(dealId).then(function (data) {
      if (null != data) {
        setDealsVoucherBatch(data)
        setMax(data.amount)
        setPrice(data.price)
        if (1 == data.amount) {
          setIncreaseDisabled(true)
        }
      }
    })

    restaurantStore.setUrl(restaurant_id)
    restaurantStore.getRestaurant().then((data) => {
      restaurantStore.restaurant = data
    })
    if (payState1) {
      setTimeout(() => {
        let arrayParam = payState1.split('-')
        setPayFailed(arrayParam[0] === 'false' ? true : false)
        setPayState(true)
        setPayment(arrayParam[1].split('=')[1])
        window.private_token = arrayParam[2].split('=')[1]
        setPrice = arrayParam[3].split('=')[1]
        setState({ count: arrayParam[4].split('=')[1] })
      }, 1000)
    }
    setShowUseNow(paymentStore.deviceDetect())
  }, [])
  let disabled = !acceptTerms || !payment || dealsVoucherBatch.can_buy_voucher
  const increase = () => {
    const count = state.count + 1
    setState({ count })
    if (count > 1) {
      setDeclineDisabled(false)
    }
    if (count == max) {
      setIncreaseDisabled(true)
    }
    setPrice((dealsVoucherBatch.price * count).toFixed(2))
  }

  const decline = () => {
    let count = state.count - 1
    if (count < 0) {
      count = 0
    }
    setState({ count })
    if (count == 1) {
      let count = 1
      setDeclineDisabled(true)
      setState({ count })
    }
    if (count < max) {
      setIncreaseDisabled(false)
    }
    setPrice((dealsVoucherBatch.price * count).toFixed(2))
  }
  return (
    <Container
      className='booking-drawer deal-drawer max-width-none deal-prepay-action'
      placement='bottom'
      onClose={useLater}
      onCancel={useLater}
      visible={getDealVisible}
      height={showUseNow == 'desktop' ? '88%' : '97%'}
      showUseNow={showUseNow}
      footer={
        <PrepayAction
          currency={dealsVoucherBatch.currency}
          price={price}
          disabled={disabled}
          onSubmit={onSubmit}
          payState={payState}
          useLater={useLater}
          onCancel={useLater}
          useNow={useNow}
          getDealVisible={getDealVisible}
          payFailed={payFailed}
          showUseNow={showUseNow}
          payProcessing={payProcessing}
        />
      }
    >
      <React.Fragment>
        <Container
          visible={termsVisible}
          closable={false}
          placement='bottom'
          height={showUseNow === 'desktop' ? '88%' : '87%'}
          showUseNow={showUseNow}
          footer={
            <div style={{ textAlign: 'center' }}>
              <Common.Button
                type='primary'
                onClick={() => setTermsVisible(false)}
              >
                {t('VOUCHERS.Close')}
              </Common.Button>
            </div>
          }
          title={<h4 style={{ textAlign: 'center' }}>{t('TERMS')}</h4>}
        >
          <p
            style={{
              height: showUseNow === 'desktop' ? '310px' : '95%',
              overflow: 'auto',
            }}
            dangerouslySetInnerHTML={{ __html: dealsVoucherBatch.desc }}
          ></p>
        </Container>
        <Container
          className='booking-drawer deal-drawer max-width-none deal-prepay-action'
          placement='bottom'
          closable={false}
          onClose={() => canceWechatPay()}
          visible={showQrocde}
          height={showUseNow == 'desktop' ? '88%' : '97%'}
          showUseNow={showUseNow}
          footer={
            <Common.Button type='primary' onClick={() => canceWechatPay()}>
              {t('CANCEL')}
            </Common.Button>
          }
        >
          {showQrocde ? <QrcodePage onCancel={() => canceWechatPay()} /> : null}
        </Container>

        {/* 兑换条款 */}
        <Terms visible={termsShow} setVisible={setTermsShow} text={terms} />
        {/* 等待支付 */}
        {paymentLoadingVisible ? (
          <Common.PaymentModal
            visible={paymentLoadingVisible}
            onComplete={onPaymentComplete}
          />
        ) : null}
        <Common.LoadingModal
          visible={visible}
          loading={loading}
          setVisible={setVisible}
          onSubmit={onConfirm}
          onComplete={onAnimateComplete}
          term={false}
        ></Common.LoadingModal>
        {/* {termsVisible ? (
          <Common.TermsPopup
            visible={termsVisible}
            deal_id={dealId}
            deal={deal}
            onBack={() => animate('POP', () => setTermsVisible(false))}
          ></Common.TermsPopup>
        ) : null} */}

        {/* 支付方式 */}
        {paymentVisible ? (
          <Common.PaymentPopup
            visible={paymentVisible}
            data={payment}
            onBack={(data) => animate('POP', () => onPaymentPopupBack(data))}
          ></Common.PaymentPopup>
        ) : null}
        <h3 className='H3Class'>
          {payState && !payFailed && !payProcessing
            ? t('PAYMENT.Payment successful!')
            : payFailed
            ? t('PAYMENT.Payment failed')
            : payProcessing
            ? t('PAYMENT.Processing payment')
            : t('WEB.Buy voucher')}
        </h3>
        <div className='voucher-info1'>
          <div className='voucher-info1--body' data-price='true'>
            <div className='info-price'>
              <strong className='fuhao'>{dealsVoucherBatch.currency}</strong>
              <strong>{dealsVoucherBatch.price}</strong>
            </div>
            <div className='voucher-right-all-content'>
              <div className='right-all-contents'>
                <div className='voucher-info1--title ellipsis-2'>
                  {dealsVoucherBatch.title}
                </div>
              </div>
              <div className='info-item'>
                <label>{t('VOUCHERS.Expires on')}: </label>
                <span>{dealsVoucherBatch.end_on}</span>
              </div>
              <div className='info-item'>
                <label>{t('VOUCHERS.Redeems')}: </label>
                <span>
                  {dealsVoucherBatch.points_type === 'unlimited'
                    ? t('VOUCHERS.Unlimited')
                    : dealsVoucherBatch.points}
                </span>
              </div>
            </div>
          </div>
          <div>
            <span
              className='terms-button'
              onClick={() => showTerm(dealsVoucherBatch.desc)}
            >
              {t('VOUCHERS.Terms of use')}
              <i className='icon icon-info terms-icon'></i>
            </span>
          </div>
        </div>
        <Common.ListItem
          title={
            <Common.Icon
              text={t('VOUCHERS.TITLE')}
              icon='icon icon-voucher'
            ></Common.Icon>
          }
          value={
            <React.Fragment>
              {!payState ? (
                <div className='sub-title1'>
                  <ButtonGroup>
                    <Button onClick={decline} disabled={declineDisabled}>
                      <MinusOutlined />
                    </Button>
                    <input value={state.count} readOnly={true} />
                    <Button onClick={increase} disabled={increaseDisabled}>
                      <PlusOutlined />
                    </Button>
                  </ButtonGroup>
                </div>
              ) : (
                <span>{state.count}</span>
              )}
            </React.Fragment>
          }
        ></Common.ListItem>
        {payState && !payFailed ? (
          <Common.ListItem
            title={
              <Common.Icon
                text={t('PAYMENT.Payment method')}
                icon='icon icon-payment-method'
              ></Common.Icon>
            }
            value={
              <React.Fragment>
                <span>
                  {payment ? (
                    <span className='selectPayment'>
                      {' '}
                      {t(`PAYMENT.${payment}`)}
                    </span>
                  ) : (
                    <span className='select'>{t('PAYMENT.Select')}</span>
                  )}
                </span>
              </React.Fragment>
            }
          ></Common.ListItem>
        ) : (
          <Common.ListItem
            onClick={() => {
              animate('PUSH', () => setPaymentVisible(true))
            }}
            title={
              <Common.Icon
                text={t('PAYMENT.Payment method')}
                icon='icon icon-payment-method'
              ></Common.Icon>
            }
            value={
              <React.Fragment>
                <span>
                  {payment ? (
                    <span className='selectPayment'>
                      {' '}
                      {t(`PAYMENT.${payment}`)}
                    </span>
                  ) : (
                    <span className='select'>{t('PAYMENT.Select')}</span>
                  )}
                </span>
                {!payState || payFailed ? (
                  <i
                    className='icon icon-arrow-right'
                    style={{ width: 12, height: 12, backgroundSize: 12 }}
                  ></i>
                ) : null}
              </React.Fragment>
            }
          ></Common.ListItem>
        )}
        {payState && !payFailed ? (
          <Common.ListItem
            title={
              <Common.Icon
                text={t('PAYMENT.Total paid amount')}
                icon='icon icon-payment-method'
              ></Common.Icon>
            }
            value={
              <React.Fragment>
                <span>
                  {dealsVoucherBatch.currency}
                  {price}
                </span>
              </React.Fragment>
            }
          ></Common.ListItem>
        ) : null}
        {!payState && (
          <Common.Terms
            checked={acceptTerms}
            key={Math.random()}
            onChange={(val) => onChange({ acceptTerms: val })}
            onShow={() => animate('PUSH', () => setTermsVisible(true))}
          ></Common.Terms>
        )}
      </React.Fragment>
    </Container>
  )
}
let PrepayAction = ({
  currency,
  price,
  disabled,
  onSubmit,
  payState,
  useLater,
  useNow,
  getDealVisible,
  payFailed,
  payProcessing,
  showUseNow,
}) => {
  const { t } = useTranslation()
  if (!getDealVisible) {
    return null
  }
  return (
    <footer align='right'>
      {payState ? (
        <div>
          {!payFailed ? (
            <div>
              {!payProcessing && (
                <Common.Button onClick={useLater}>
                  {t('PAYMENT.Use later')}
                </Common.Button>
              )}

              {showUseNow !== 'desktop' && !payProcessing ? (
                <Common.Button type='primary' onClick={useNow}>
                  {t('PAYMENT.Use now')}
                </Common.Button>
              ) : null}
            </div>
          ) : (
            <div>
              <Common.Button onClick={useLater}>
                {t('VOUCHERS.Close')}
              </Common.Button>
              <Common.Button type='primary' onClick={onSubmit}>
                {t('VOUCHERS.Try again')}
              </Common.Button>
            </div>
          )}
        </div>
      ) : (
        <div>
          <strong>
            {currency}
            {price}
          </strong>
          <Common.Button disabled={disabled} type='primary' onClick={onSubmit}>
            {t('PAYMENT.Pay now')}
          </Common.Button>
        </div>
      )}
    </footer>
  )
}
const Container = (props) => {
  return props?.showUseNow === 'desktop' ? (
    <Modal {...props}>{props.children}</Modal>
  ) : (
    <Drawer {...props}>{props.children}</Drawer>
  )
}
/**二维码支付 */
const QrcodePage = (props) => {
  const { paymentStore, restaurantStore } = useStores()
  const { t } = useTranslation()
  const [qrcode, setQrcode] = useState('')
  useEffect(() => {
    const { channel, credential } = paymentStore.payment
    switch (channel) {
      case 'alipay_qr':
        setQrcode(credential.alipay_qr)
        break
      case 'wx_pub_qr':
        setQrcode(credential.wx_pub_qr)
        break
    }
  }, [])
  return (
    <div className='step-payment'>
      <h2>{t('PAYMENT.PAY WITH WECHAT')}</h2>
      <div className='tip'>{t('PAYMENT.qrcode tip')}</div>

      <div className='qrcode'>
        {qrcode ? <QRCode size={145} value={qrcode} /> : null}
      </div>
    </div>
  )
}

export default GetDeal
