import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { BookingQRCode, Appeal, SvgPage } from "./pages";
import "./index.less"

const Main = () => {
  return (
    <React.Fragment>
      {/* <Redirect from="/h5" to="/h5/booking-qrcode" /> */}
      <Route path="/h5/booking-qrcode" component={BookingQRCode}></Route>
      <Route path="/h5/svg" component={SvgPage}></Route>
      <Route path="/h5/appeal" component={Appeal}></Route>
    </React.Fragment>
  );
}

export default Main