import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useStores } from '@/store'
import * as Common from '@/components/common'
import { Button, ListItem, Icon } from '@/components'
import { Drawer, Modal, Typography, message } from 'antd'
import { Terms } from '../../../../components/dc-voucher'
import './index.less'
import { useMemo } from 'react'

let timer = null
function sleep(time = 300) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), time)
  })
}
// 进出场，回调
async function animate(dir, callback) {
  if (dir === 'PUSH') {
    callback()
    await sleep(30)
    document.body.setAttribute('animate', 'push')
  } else {
    document.body.setAttribute('animate', 'pop')
    await sleep()
    callback()
    document.body.setAttribute('animate', '')
  }
}

const Redeem = ({
  dealId,
  deal,
  redeemVisible,
  setRedeemVisible,
  voucherAvailableList,
  checkRedeem,
}) => {
  const { t } = useTranslation()
  let { paymentStore, dealStore } = useStores()
  const [showUseNow, setShowUseNow] = useState('desktop')
  const [terms, setTerms] = useState('') // 条款
  const [termsShow, setTermsShow] = useState(false)
  // 1券码列表  2兑换确认  3扫码  4兑换结果
  const [status, setStatus] = useState(1)
  const [qrcode, setQrcode] = useState({})
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [termsVisible, setTermsVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [currentVoucher, setCurrentVoucher] = useState({})
  const [redeemResult, setRedeemResult] = useState({})
  const [messageApi, contextHolder] = message.useMessage()
  const handlerClose = () => {
    setStatus(1)
    setRedeemVisible(false)
    clearInterval(timer)
    setAcceptTerms(false)
    checkRedeem()
  }
  const error = () => {
    messageApi.open({
      type: 'error',
      content: t('VOUCHERS.VoucherInvalid'),
    })
  }
  const title = useMemo(() => {
    switch (status) {
      case 1:
        return 'VOUCHERS.SelectVoucher'
      case 2:
        return 'VOUCHERS.ConfirmVoucher'
      case 3:
        return 'VOUCHERS.RedeemVoucher'
      case 4:
        return 'VOUCHERS.RedeemSucess'

      default:
        return
    }
  }, [status])
  const confirmRedeem = (item) => {
    setCurrentVoucher(item)
    setStatus(2)
  }
  const openQrcode = () => {
    setLoading(true)
    dealStore
      .getRedeemQrcode(currentVoucher.code)
      .then((result) => {
        setQrcode(result)
        setStatus(3)
        setLoading(false)
        // 开始轮询
        timer = setInterval(() => {
          dealStore.queryRedeemResult(result?.id).then((result) => {
            if (result?.checked_at) {
              // 兑换完成
              setStatus(4)
              setRedeemResult(result)
              clearInterval(timer)
            }
          })
        }, 2000)
      })
      .catch(() => {
        setLoading(false)
        error()
        // setLoading(true)
        // 兑换失败
      })
  }
  useEffect(() => {
    setShowUseNow(paymentStore.deviceDetect())
  }, [paymentStore])
  /**查看条款 */
  function showTerm(data) {
    setTerms(data)
    setTermsShow(true)
  }
  const onChange = async (data = {}) => {
    setAcceptTerms(data.acceptTerms)
  }
  const backPrev = () => {
    if (status === 1) {
      setRedeemVisible(false)
    } else {
      setStatus(status - 1)
    }
  }
  return (
    <>
      {contextHolder}
      <Container
        className='booking-drawer deal-drawer deal-prepay-action'
        placement='bottom'
        onClose={handlerClose}
        onCancel={handlerClose}
        visible={redeemVisible}
        height={showUseNow === 'desktop' ? '88%' : '97%'}
        showUseNow={showUseNow}
        footer={null}
      >
        <div className='deal-redeem'>
          <div className='header-left' onClick={backPrev}>
            <i className='icon icon-arrow-left'></i>
          </div>
          <h3 className='H3Class'>{t(title)}</h3>
          {[1, 2].includes(status) && (
            <div className='vouchers'>
              {voucherAvailableList.map((item) => (
                <div
                  className='voucher-info1'
                  hidden={status === 2 && item?.code !== currentVoucher?.code}
                >
                  <div
                    className='voucher-info1--body'
                    data-price='true'
                    onClick={() => {
                      confirmRedeem(item)
                    }}
                  >
                    <div className='info-price'>
                      <strong className='fuhao'>{item?.detail.currency}</strong>
                      <strong>
                        {Number(item?.detail.price).toLocaleString()}
                      </strong>
                    </div>
                    <div className='voucher-right-all-content'>
                      <div className='right-all-contents'>
                        <div className='voucher-info1--title ellipsis-2'>
                          {item?.detail.title}
                        </div>
                      </div>
                      <div className='info-item'>
                        <label>{t('VOUCHERS.Expires on')}: </label>
                        <span>{item?.detail?.end_on}</span>
                      </div>
                      <div className='info-item'>
                        <label>{t('VOUCHERS.Redeems')}: </label>
                        <span>
                          {item?.detail.points_type === 'unlimited'
                            ? t('VOUCHERS.Unlimited')
                            : item?.points}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <span
                      className='terms-button'
                      onClick={() => showTerm(item?.detail.desc)}
                    >
                      {t('VOUCHERS.Terms of use')}
                      <i className='icon icon-info terms-icon'></i>
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* {termsVisible ? (
            <Common.TermsPopup
              visible={termsVisible}
              deal_id={dealId}
              deal={deal}
              onBack={() => animate('POP', () => setTermsVisible(false))}
            ></Common.TermsPopup>
          ) : null} */}
          <Container
            visible={termsVisible}
            closable={false}
            placement='bottom'
            height={showUseNow === 'desktop' ? '88%' : '87%'}
            showUseNow={showUseNow}
            footer={
              <div style={{ textAlign: 'center' }}>
                <Common.Button
                  type='primary'
                  onClick={() => setTermsVisible(false)}
                >
                  {t('VOUCHERS.Close')}
                </Common.Button>
              </div>
            }
            title={<h4 style={{ textAlign: 'center' }}>{t("TERMS")}</h4>}
          >
            <p
              style={{
                height: showUseNow === 'desktop' ? '310px' : '95%',
                overflow: 'auto',
              }}
              dangerouslySetInnerHTML={{ __html: currentVoucher?.detail?.desc }}
            ></p>
          </Container>
          {status === 2 && (
            <div>
              <Common.Terms
                checked={acceptTerms}
                onChange={(val) => onChange({ acceptTerms: val })}
                onShow={() => animate('PUSH', () => setTermsVisible(true))}
              ></Common.Terms>
              <div style={{ textAlign: 'right', margin: '20px 20px 0 0' }}>
                <Button
                  type='primary'
                  onClick={openQrcode}
                  className={'getDealClass'}
                  disabled={!acceptTerms}
                  loading={loading}
                >
                  {t('VOUCHERS.Redeem')}
                </Button>
              </div>
            </div>
          )}
          {status === 3 && (
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <p>{t('VOUCHERS.ToRedeem')}</p>
              <img src={qrcode?.qr_code?.qrcode_url} alt='' />
            </div>
          )}
          {status === 4 && (
            <div className='reedem-history-details'>
              <div className='reedem-detail'>
                <ListItem
                  title={
                    <Icon
                      text={t('VOUCHERS.The offer') + ':'}
                      icon={'icon icon-voucher'}
                    />
                  }
                />
                <div className='reedem-detail--content strong'>
                  <Typography.Paragraph ellipsis={{ rows: 2 }}>
                    {redeemResult?.voucher?.detail?.title}
                  </Typography.Paragraph>
                </div>
              </div>
              <div className='reedem-detail'>
                <ListItem
                  title={
                    <Icon
                      text={t('VOUCHERS.Date and time') + ':'}
                      icon={'icon icon-time'}
                    />
                  }
                />
                <div className='reedem-detail--content'>
                  {redeemResult.formated_datetime}
                </div>
              </div>
              <div className='reedem-detail'>
                <ListItem
                  title={
                    <Icon
                      text={t('VOUCHERS.Venue') + ':'}
                      icon={'icon icon-shop'}
                    />
                  }
                />
                <div className='reedem-detail--content'>
                  {redeemResult?.restaurant_basic_info?.localized_name}
                </div>
              </div>
              <div className='reedem-detail'>
                <ListItem
                  title={
                    <Icon
                      text={t('VOUCHERS.Items') + ':'}
                      icon={'icon icon-info'}
                    />
                  }
                  value={
                    <strong style={{ fontSize: 22, lineHeight: 1 }}>
                      x{redeemResult?.voucher?.detail?.points}
                    </strong>
                  }
                />
              </div>
              <div style={{ textAlign: 'right', margin: '20px 20px 0 0' }}>
                <Button type='primary' onClick={handlerClose}>
                  {t('VOUCHERS.Close')}
                </Button>
              </div>
            </div>
          )}
          {/* 兑换条款 */}
          <Terms visible={termsShow} setVisible={setTermsShow} text={terms} />
        </div>
      </Container>
    </>
  )
}

const Container = (props) => {
  return props?.showUseNow === 'desktop' ? (
    <Modal {...props}>{props.children}</Modal>
  ) : (
    <Drawer {...props}>{props.children}</Drawer>
  )
}

export default Redeem
