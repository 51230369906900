import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

/**优惠详情 */
export const Profile = ({
  name,
  description,
  restaurant_name,
  category_name,
  recommends_count,
  price_desc = [],
  deal_price,
  category_keyword,
  original_price,
}) => {
  const { t } = useTranslation();
  const showOriginalPrice = category_keyword === 'discount';
  return (
    <article className="page-deal--profile">
      <h2>{name}</h2>
      <div className="profile-restaurant-name">{restaurant_name}</div>
      <div className="profile-category">
        <span className="profile-category-tag">{category_name}</span>
        <span className="profile-price">{price_desc[0]} <i hidden={!showOriginalPrice}>{price_desc[1]}</i></span>
      </div>
      <span className="profile-recommends" hidden={recommends_count < 1}>
        {t("WEB.Recommended", { count: recommends_count })}
      </span>
      <div className="profile-description" dangerouslySetInnerHTML={{
        __html: description
      }}></div>
    </article>
  );
};
