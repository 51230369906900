import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

/**菜单 */
export const Menu = ({ menus, deal_price }) => {
  const { t } = useTranslation();
  return (
    <section className="page-deal--menu">
      <h3>{t("WEB.Menu")}</h3>
      {menus.map((item,index) => {
        return (
          <div className="menu-slot" key={item.id}>
            <div className="menu-courses">
              <Category {...item} />
              <SubMenus menus={item.menus} deal_price={deal_price} />
              {menus.length === (index + 1) ? null : <div className="hairline-bottom-new"></div>}
            </div>
          </div>
        );
      })}
    </section>
  );
};

const Category = ({ optional_desc, menu_category }) => {
  if (!menu_category) return null;
  return (
    <div className="menu-items">
      <div className="menu-item--title">
        {menu_category.name}
        <div className="course-item-menu--desc">{optional_desc}</div>
      </div>
    </div>
  );
};

const SubMenus = ({ menus }) => {
  return (
    <div className="menu-items">
      <div className="menu-item--subs">
        {menus.map((course) => {
          return (
            <div className="course-item" key={course.id}>
              <div className="course-item--title">
                {course.name}
                {/* 如果有两个价格,第二个价格划线表示 */}
                <span
                  className="course-item--price"
                  hidden={!course.price_desc[0]}
                >
                  <span>{course.price_desc[0] || course.price}</span>
                  {course.price_desc[1] ? (
                    <span className="old-price">{course.price_desc[1]}</span>
                  ) : null}
                </span>
              </div>
              <div className="course-item--desc">{course.desc}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
