import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { slimImage } from "@/service/utils";
import { Link } from "react-router-dom";
import ArrowIcon from "@/images/forward_normal_grey.svg";
import { getQuery } from "@/service/http";

/**
 * 我的优惠券-卡片
 *
 */
export const Item = ({ voucher, status, onSelect, showTerm, drinks }) => {
  const { t } = useTranslation();
  const { currency, price, title, points_type } = voucher.detail || {};
  const priceStyle = useMemo(() => {
    let size = String(currency + price).length;
    if (size <= 5) {
      return { fontSize: "34px" };
    } else {
      return { fontSize: "26px" };
    }
  }, [price]);

  const deal = useMemo(() => {
    try {
      let deal = voucher.detail.deal;
      return {
        ...deal,
        picture_url: slimImage(deal.picture_url, "/w/128"),
        link: {
          pathname: `/deals/${deal.id}`,
          search: `?lang=${getQuery().lang}`,
        },
      };
    } catch (error) {
      return null;
    }
  }, [voucher]);

  return (
    <div className="voucher-info" data-expired={"available" !== status}>
      {deal ? <Deal deal={deal} /> : null}
      <div
        className="voucher-info--body"
        data-price={!!price}
        onClick={onSelect}
      >
        <div className="info-price" hidden={!price}>
          <i>{currency}</i>
          <strong style={priceStyle}>{price}</strong>
        </div>
        <div className="voucher-info--title ellipsis-2">{title}</div>
        <div className="info-item">
          <label>{t("VOUCHERS.Expires on")}: </label>
          <span>{moment(voucher.end_at).format("YYYY-MM-DD")}</span>
        </div>
        <div className="info-item">
          <label>{t("VOUCHERS.Redeems left")}: </label>
          <span>
            {points_type === "unlimited"
              ? t("VOUCHERS.Unlimited")
              : voucher.points}
          </span>
        </div>
      </div>
      <div className="voucher-info--footer">
        <span className="terms-button" onClick={showTerm}>
          {t("VOUCHERS.Terms of use")}
          <i className="icon icon-info terms-icon"></i>
        </span>
        {drinks ? drinks : null}
        <span className="expired-button" hidden={"expired" !== status}>
          {t("VOUCHERS.expired")}
        </span>
      </div>
    </div>
  );
};

const Deal = ({ deal }) => {
  return (
    <Link to={deal.link} target="_blank" className="info-deal">
      <div className="info-deal--image">
        <img src={deal.picture_url} alt="" />
      </div>
      <div className="info-deal--title">{deal.name}</div>
      <div className="info-deal--action">
        <img src={ArrowIcon} alt="" />
      </div>
    </Link>
  );
};