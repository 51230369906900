import React, { useState, useEffect, useMemo } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Drawer, Typography, message, Modal } from "antd";
import { useStores } from "@/store";
import { Button, Checkbox, ListItem, Icon, Header } from "@/components";
import { Terms, Item, VoucherDetail } from "../dc-voucher";
import { useObserver } from "mobx-react-lite";
import { getQuery } from "@/service/http";
import "./index.less";

export default ({ restaurant }) => {
  const { t } = useTranslation();
  const { restaurantStore, voucherStore } = useStores();
  const [list, setList] = useState([]); // 列表
  const [listShow, setListShow] = useState(false);
  const [detail, setDetail] = useState({ detail: {} }); // 详情
  const [detailShow, setDetailShow] = useState(false);
  const [redeemDetail, setRedeemDetail] = useState({}); // 兑换详情
  const [redeemDetailShow, setRedeemDetailShow] = useState(false);
  const [terms, setTerms] = useState(""); // 条款
  const [termsShow, setTermsShow] = useState("");
  const [redeemShow, setRedeemShow] = useState(false); // 确认兑换
  const [emptyShow, setEmptyShow] = useState(false); // 空数据提示

  const offerSize = useMemo(() => {
    const size = list.length;
    return size > 99 ? "99+" : size;
  }, [list]);

  useEffect(() => {
    getList();
  }, []);

  function getList() {
    voucherStore.available().then((data) => {
      setList(data);
      if (!data.length) {
        onCancel();
      }
    });
  }

  /**点击列表按钮 */
  function onButtonClick() {
    if (offerSize === 0) {
      setEmptyShow(true);
    } else {
      setListShow(true);
    }
  }

  /**退出兑换 */
  function onCancel() {
    setListShow(false);
    setRedeemShow(false);
  }

  /**查看条款 */
  function showTerm(data) {
    setTerms(data);
    setTermsShow(true);
  }

  /**查看餐厅条款 */
  function showTos(text) {
    showTerm(text);
  }

  /**查看详情并兑换 */
  function showDetail(data) {
    setDetail(data);
    setRedeemShow(true);
  }

  /**查看兑换详情 */
  function showRedeemDetail(data) {
    setRedeemDetail(data);
    setRedeemDetailShow(true);
  }

  /**再次兑换 */
  function onRedeemAgain() {
    setRedeemDetailShow(false);
    setRedeemShow(false);
    getList();
  }

  /**查看优惠券详情 */
  function onShowDetail() {
    setDetailShow(true);
  }

  return (
    <div className="dc-redeem">
      <Button
        type="primary"
        onClick={onButtonClick}
        style={{ opacity: offerSize > 0 ? 1 : 0.5 }}
      >
        {offerSize > 0 ? <i className="badge small">{offerSize}</i> : null}
        {t("VOUCHERS.Get offer")}
      </Button>

      {/* 兑换条款 */}
      <Terms visible={termsShow} setVisible={setTermsShow} text={terms} />

      {/* 空数据提示 */}
      <EmptyNotice visible={emptyShow} setVisible={setEmptyShow} />

      {/* 优惠券详情 */}
      <Drawer
        className="booking-drawer"
        placement="right"
        closable={false}
        destroyOnClose
        push={{ distance: 0 }}
        onClose={() => setDetailShow(false)}
        visible={detailShow}
      >
        <Header
          title={t("VOUCHERS.Voucher details")}
          onClick={() => setDetailShow(false)}
        />
        <VoucherDetail group={t("VOUCHERS.available")} id={detail.id} />
      </Drawer>

      {/* 兑换详情 */}
      <RedeemDetails
        visible={redeemDetailShow}
        setVisible={setRedeemDetailShow}
        redeem={redeemDetail}
        title={detail.detail.title}
        onRedeemAgain={onRedeemAgain}
        onShowDetail={onShowDetail}
      />

      {/* 确认兑换 */}
      <Confirm
        visible={redeemShow}
        setVisible={setRedeemShow}
        voucher={detail}
        restaurant={restaurant}
        showTos={showTos}
        showTerm={(data) => showTerm(data.detail.desc)}
        onCancel={onCancel}
        onRedeem={showRedeemDetail}
      />

      {/* 优惠券列表 */}
      <List
        visible={listShow}
        setVisible={setListShow}
        list={list}
        showDetail={showDetail}
        showTerm={(data) => showTerm(data.detail.desc)}
      />
    </div>
  );
};

/**列表 */
const List = ({ visible, setVisible, list, showDetail, showTerm }) => {
  const { t } = useTranslation();
  return (
    <Drawer
      className="dc-redeem--drawer"
      placement="bottom"
      closable={false}
      height={305}
      destroyOnClose
      onClose={() => setVisible(false)}
      visible={visible}
    >
      <div className="dc-redeem--drawer-content">
        <header>
          <span></span>
          <h3>{t("VOUCHERS.Select voucher")}</h3>
          <span></span>
        </header>
        <div
          className="scroll-view"
          style={{
            height: 210,
            overflowX: "hidden",
            overflowY: "auto",
          }}
        >
          {list.map((item, index) => {
            return (
              <Item
                voucher={item}
                key={index}
                status="available"
                onSelect={() => showDetail(item)}
                showTerm={() => showTerm(item)}
              />
            );
          })}
        </div>
      </div>
    </Drawer>
  );
};

/**确认兑换 */
const Confirm = ({
  restaurant,
  voucher,
  visible,
  setVisible,
  showTerm,
  showTos,
  onRedeem,
  onCancel,
}) => {
  const { t } = useTranslation();
  const { restaurantStore, voucherStore } = useStores();
  const [accpet, setAccept] = useState(false);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(1);
  const { acc_code, acc_pwd, source, lang } = getQuery();
  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  /**返回 */
  function onBack() {
    setCount(1);
    setLoading(false);
    setVisible(false);
  }

  /**确认兑换 */
  function onConfirm() {
    setLoading(true);
    const { code, project } = voucher;
    voucherStore
      .redeem(restaurant.id, {
        code,
        project,
        points: count,
        acc_code,
        acc_pwd,
        source,
      })
      .then((data) => {
        onRedeem(data);
        onBack();
      })
      .catch((error) => {
        message.error(error.message);
        setLoading(false);
      });
  }

  return useObserver(() => {
    return (
      <Drawer
        className="dc-redeem--drawer"
        placement="bottom"
        closable={false}
        height={350}
        destroyOnClose
        maskClosable={false}
        onClose={() => setVisible(false)}
        visible={visible}
      >
        <div className="dc-redeem--drawer-content">
          <header>
            <span className="icon icon-arrow-left" onClick={onBack}></span>
            <h3>{t("VOUCHERS.Confirm redeem")}</h3>
            <span></span>
          </header>
          <Item
            voucher={voucher}
            status="available"
            showTerm={() => showTerm(voucher)}
            drinks={
              <Drinks
                points={voucher.points}
                type={voucher.detail.points_type}
                onChange={setCount}
              />
            }
          />
          <Checkbox onChange={setAccept}>
            {restaurant.tos ? (
              <React.Fragment>
                <span
                  dangerouslySetInnerHTML={{
                    __html: t("VOUCHERS_AGREE_PRIVACY_POLICY", {
                      privacyUrl: restaurantStore.privacyUrl,
                    }),
                  }}
                ></span>
                <a onClick={() => showTos(restaurant.tos)}>{t("TERMS")}</a>
                {lang === "en" ? "." : "。"}
              </React.Fragment>
            ) : (
              <span
                dangerouslySetInnerHTML={{
                  __html: t("VOUCHERS_AGREE_PRIVACY_POLICY_ONLY", {
                    privacyUrl: restaurantStore.privacyUrl,
                  }),
                }}
              ></span>
            )}
          </Checkbox>
          <footer>
            <Button onClick={onCancel}>{t("CANCEL")}</Button>
            <Button
              type="primary"
              onClick={onConfirm}
              disabled={!accpet}
              loading={loading}
            >
              {t("CONFIRM")}
            </Button>
          </footer>
        </div>
      </Drawer>
    );
  });
};

/**展示兑换结果 */
export const RedeemDetails = ({
  redeem,
  title,
  setVisible,
  visible,
  onRedeemAgain,
  onShowDetail,
}) => {
  const { t } = useTranslation();
  const info = {
    restaurant_basic_info: {},
    ...redeem,
  };
  return (
    <Drawer
      placement="bottom"
      visible={visible}
      closable={false}
      onClose={() => setVisible(false)}
      height={540}
      destroyOnClose
      className="dc-redeem--drawer"
    >
      <div className="dc-redeem--drawer-content">
        <header>
          <span></span>
          <h3>{t("VOUCHERS.Redeem successful!")}</h3>
          <span></span>
        </header>
        <div className="reedem-detail">
          <ListItem
            title={
              <Icon
                text={t("VOUCHERS.The offer") + ":"}
                icon={"icon icon-voucher"}
              />
            }
          />
          <div className="reedem-detail--content strong">
            <Typography.Paragraph ellipsis={{ rows: 2 }}>
              {title}
            </Typography.Paragraph>
          </div>
        </div>
        <div className="reedem-detail">
          <ListItem
            title={
              <Icon
                text={t("VOUCHERS.Date and time") + ":"}
                icon={"icon icon-time"}
              />
            }
          />
          <div className="reedem-detail--content">{info.formated_datetime}</div>
        </div>
        <div className="reedem-detail">
          <ListItem
            title={
              <Icon text={t("VOUCHERS.Venue") + ":"} icon={"icon icon-shop"} />
            }
          />
          <div className="reedem-detail--content">
            {info.restaurant_basic_info.localized_name}
          </div>
        </div>
        <div className="reedem-detail">
          <ListItem
            title={
              <Icon text={t("VOUCHERS.Items") + ":"} icon={"icon icon-info"} />
            }
            value={
              <strong style={{ fontSize: 22, lineHeight: 1 }}>
                x{redeem.points}
              </strong>
            }
          />
        </div>
        <footer>
          <Button onClick={onRedeemAgain}>{t("VOUCHERS.Redeem again")}</Button>
          <Button type="primary" onClick={onShowDetail}>
            {t("VOUCHERS.VIEW")}
          </Button>
        </footer>
      </div>
    </Drawer>
  );
};

/**选择饮品数量 */
const Drinks = ({ type, points, onChange }) => {
  const { t } = useTranslation();
  const [count, setCount] = useState(1);
  const max = useMemo(() => {
    return type === "unlimited" ? 9 : Math.min(points, 99);
  }, [type, points]);
  useEffect(() => {
    onChange(count);
  }, [count]);
  function onBlur(ev) {
    const value = ev.target.value;
    if (!/^[0-9]{1,2}$/.test(value) || value < 1) {
      setCount(1);
    } else if (value > max) {
      setCount(max);
    } else {
      setCount(value);
    }
  }
  return (
    <div className="voucher-drinks-input">
      <label>{t("VOUCHERS.Items")}:</label>
      <span onClick={() => setCount(count - 1)} data-disabled={count < 2}>
        -
      </span>
      <input
        type="tel"
        onChange={(ev) => setCount(ev.target.value)}
        onBlur={onBlur}
        value={count}
      />
      <span onClick={() => setCount(count + 1)} data-disabled={count >= max}>
        +
      </span>
    </div>
  );
};

/**空数据 */
const EmptyNotice = ({ visible, setVisible }) => {
  const { t } = useTranslation();
  return (
    <Modal
      visible={visible}
      wrapClassName="dc-modal voucher-modal"
      width={300}
      footer={null}
      closable={false}
      centered
      destroyOnClose
    >
      <div className="voucher-modal--content">
        <header></header>
        <div
          dangerouslySetInnerHTML={{
            __html: t(
              "VOUCHERS.You have no voucher available to use in this venue"
            ),
          }}
        ></div>
        <footer>
          <span></span>
          <strong onClick={() => setVisible(false)}>
            {t("VOUCHERS.Understood")}
          </strong>
        </footer>
      </div>
    </Modal>
  );
};
