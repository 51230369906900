import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

/**时段信息 */
export const Detail = ({ availabilities = [], restaurant = {} }) => {
  const { t } = useTranslation();
  return (
    <section className="page-deal--detail">
      <h3>{t("WEB.Details")}</h3>
      <div className="detail-list">
        <div className="deail-list--title">
          <i className="icon icon-time"></i>
          {t("WEB.Available to book on")}:
        </div>
        {availabilities.map((item) => {
          return (
            <div className="deail-list--item" key={item.weekday}>
              <label>{item.weekday}</label>
              <span>
                {item.times.map((day) => {
                  return <div key={day}>{day}</div>;
                })}
              </span>
            </div>
          );
        })}
        <div className="hairline-bottom-new" ></div>
        <div className="deail-list--title" style={{paddingTop:'10px'}} >
          <i className="icon icon-location"></i>
          {t("WEB.Address")}
        </div>
        <div className="deail-list--item">{restaurant.address}</div>
      </div>
      <div className="hairline-bottom-new" ></div>
    </section>
  );
};
