import React from "react";

const Main = () => {
  return (
    <div>
      <div>
        <i className="icon icon-arrow-left"></i>
        icon-arrow-left
      </div>
      <div>
        <i className="icon icon-arrow-right"></i>
        icon-arrow-right
      </div>
      <div>
        <i className="icon icon-arrow-down"></i>
        icon-arrow-down
      </div>
      <div>
        <i className="icon icon-date"></i>
        icon-date
      </div>
      <div>
        <i className="icon icon-time"></i>
        icon-time
      </div>
      <div>
        <i className="icon icon-seat"></i>
        icon-seat
      </div>
      <div>
        <i className="icon icon-profile"></i>
        icon-profile
      </div>
      <div>
        <i className="icon icon-info"></i>
        icon-info
      </div>
      <div>
        <i className="icon icon-check"></i>
        icon-check
      </div>
      <div>
        <i className="icon icon-save"></i>
        icon-save
      </div>
      <div>
        <i className="icon icon-booking"></i>
        icon-booking
      </div>
      <div>
        <i className="icon icon-phone"></i>
        icon-phone
      </div>
      <div>
        <i className="icon icon-booking-white"></i>
        icon-booking-white
      </div>
      <div>
        <i className="icon icon-reviewed"></i>
        icon-reviewed
      </div>
      <div>
        <i className="icon icon-vip"></i>
        icon-vip
      </div>
      <div>
        <i className="icon icon-vip-grey"></i>
        icon-vip-grey
      </div>
      <div>
        <i className="icon icon-event"></i>
        icon-event
      </div>
      <div>
        <i className="icon icon-deal"></i>
        icon-deal
      </div>
      <div>
        <i className="icon icon-location"></i>
        icon-location
      </div>
      <div>
        <i className="icon icon-map"></i>
        icon-map
      </div>
      <div>
        <i className="icon icon-copy"></i>
        icon-copy
      </div>
      <div>
        <i className="icon icon-pic"></i>
        icon-pic
      </div>
      <div>
        <i className="icon icon-review"></i>
        icon-review
      </div>
      <div>
        <i className="icon icon-menu"></i>
        icon-menu
      </div>
      <div>
        <i className="icon icon-trash"></i>
        icon-trash
      </div>
      <div>
        <i className="icon icon-program"></i>
        icon-program
      </div>
      <div>
        <i className="icon icon-diamond"></i>
        icon-diamond
      </div>
      <div>
        <i className="icon icon-gold"></i>
        icon-gold
      </div>
      <div>
        <i className="icon icon-jade"></i>
        icon-jade
      </div>
      <div>
        <i className="icon icon-basic"></i>
        icon-basic
      </div>
      <div>
        <i className="icon icon-verified"></i>
        icon-verified
      </div>
      <div>
        <i className="icon icon-payment-method"></i>
        icon-payment-method
      </div>
      <div>
        <i className="icon icon-crown-fill"></i>
        icon-crown-fill
      </div>
      <div>
        <i className="icon icon-heart"></i>
        icon-heart
      </div>
      <div>
        <i className="icon icon-small-heart"></i>
        icon-small-heart
      </div>
      <div>
        <i className="icon icon-small-heart selected"></i>
        icon-small-heart selected
      </div>
      <div>
        <i className="icon icon-eye"></i>
        icon-eye
      </div>
      <div>
        <i className="icon icon-close-res"></i>
        icon-close-res
      </div>
      <div>
        <i className="icon icon-shop"></i>
        icon-shop
      </div>
      <div>
        <i className="icon icon-voucher"></i>
        icon-voucher
      </div>
      <div>
        <i className="icon icon-cancel"></i>
        icon-cancel
      </div>
      <div>
        <i className="icon icon-book_and_save_normal_colored"></i>
        icon-book_and_save_normal_colored
      </div>
      <div>
        <i className="icon icon-qrcode"></i>
        icon-qrcode
      </div>
      <div>
        <i className="icon icon-rest"></i>
        icon-rest
      </div>
      <div>
        <i className="icon icon-success"></i>
        icon-success
      </div>
      <div>
        <i className="icon icon-review-circle"></i>
        icon-review-circle
      </div>
      <div>
        <i className="icon a_la_carte"></i>
        a_la_carte
      </div>
      <div>
        <i className="icon icon-date selected"></i>
        icon-date selected
      </div>
      <div>
        <i className="icon icon-time selected"></i>
        icon-time selected
      </div>
      <div>
        <i className="icon icon-seat selected"></i>
        icon-seat selected
      </div>
      <div>
        <i className="icon icon-info selected"></i>
        icon-info selected
      </div>
      <div>
        <i className="icon icon-check selected"></i>
        icon-check selected
      </div>
      <div>
        <i className="icon icon-date current"></i>
        icon-date current
      </div>
      <div>
        <i className="icon icon-time current"></i>
        icon-time current
      </div>
      <div>
        <i className="icon icon-seat current"></i>
        icon-seat current
      </div>
      <div>
        <i className="icon icon-info current"></i>
        icon-info current
      </div>
      <div>
        <i className="icon icon-check current"></i>
        icon-check current
      </div>
      <div>
        <i className="icon icon-star"></i>
        icon-star
      </div>
      <div>
        <i className="icon icon-star selected"></i>
        icon-star selected
      </div>
    </div>
  );
};

export default Main;
