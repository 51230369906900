import React from "react";
import { useTranslation } from "react-i18next";
import { Rate, Divider } from "antd";
import { Template } from "@/components";
import { useObserver } from "mobx-react-lite";
import { useStores } from "@/store";

function padRight(str) {
  return Number(str).toFixed(1);
}

/**评分 */
export const RestaurantRate = ({ restaurant, showPrice }) => {
  const { t } = useTranslation();
  const { restaurantStore } = useStores();
  const {
    recommends_count,
    reviews_ratings,
    format_avg_price,
    ratings_avg = 0,
  } = restaurant;
  const {
    // ratings_avg = 0,
    service_avg = 0,
    cuisine_avg = 0,
    atmosphere_avg = 0,
  } = reviews_ratings || {};
  return useObserver(() => {
    const { currency } = restaurantStore;
    return (
      <Template show={!!reviews_ratings}>
        <div className="restaurant-rate">
          <div className="restaurant-rate--title">
            <span className="inline-flex">
              {parseInt(ratings_avg) !== 0 && (
                <Rate count={5} value={ratings_avg / 2} disabled />
              )}
              {parseInt(ratings_avg) === 0 ? (
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: 700,
                    marginLeft: "5px",
                  }}
                >
                  {t("WEB.No rating yet")}
                </span>
              ) : (
                <span className="restaurant-ratings">
                  {padRight(ratings_avg)}
                </span>
              )}
            </span>
            <Divider type="vertical" hidden={!showPrice} />
            <span className="restaurant-price" hidden={!showPrice}>
              {t("WEB.avg price", {
                price: format_avg_price,
              })}
            </span>
          </div>
          <div className="restaurant-rate--desc">
            <span>
              {t("WEB.Food")}: {padRight(cuisine_avg)}
            </span>
            <span>
              {t("WEB.Service")}: {padRight(service_avg)}
            </span>
            <span>
              {t("WEB.Decoration")}: {padRight(atmosphere_avg)}
            </span>
          </div>
          <div>
            <span className="recommends" hidden={recommends_count < 1}>
              {t("WEB.Recommended", { count: recommends_count })}
            </span>
          </div>
        </div>
      </Template>
    );
  });
};
