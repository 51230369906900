import React from "react";
import { useHistory } from "react-router-dom";
import { BackIcon } from "../dc-icon";
import "./index.less";

/**
 * 通用头部
 *
 * @param {*} props
 */
const Header = (props) => {
  let history = useHistory();
  function goBack() {
    history.goBack();
  }
  return (
    <header className={`page-header ${props.className || ""}`}>
      {
        !props.hideLeft && (
          <div className="page-header--left" onClick={props.onClick || goBack}>
            <BackIcon style={{ fontSize: 20 }} />
          </div>
        )
      }
      <div className="page-header--middle">{props.title}</div>
      <div className="page-header--right">{props.children}</div>
    </header>
  );
};

export default Header;
