import { action, computed, observable } from "mobx";
import http from "../service/http";

const mock = {
  available: [1, 2, 3, 4, 5].map((item) => {
    return {
        id: 869536,
        code: "2xvyme",
        start_at: "2020-11-12T00:00:00.000+08:00",
        end_at: "2020-11-22T23:59:59.000+08:00",
        project: "rwcn_winter_2020_drinkfest",
        points: 1,
        used: false,
        expired: false,
        project_config: { title: "饮迷中国鸡尾酒节", project_type: "event" },
        detail: {
          title: "饮迷中国鸡尾酒节超值酒饮券(50元/杯)",
          desc:
            "饮迷鸡尾酒节超值酒饮券仅限活动期间(11月12日-11月22日)使用，可在活动门店兑换50元/杯的指定酒饮，随兑随用，不限门店，不限次数。\n扫描店内活动二维码或向门店工作人员直接出示“兑换成功”页面，即可以50元/杯的优惠价格购买活动酒饮。",
          id: 164,
          name: "drinkfest1",
          code_length: 6,
          points_type: "unlimited",
          price: "50.0",
          currency: "￥",
          points: 1,
          deal: null,
        },
      }
  }),
};

class Store {
  /**优惠券列表 */
  @observable vouchers = [];
  @observable total = 1;
  /**当前优惠券列表 */
  @observable current = {};

  /**可用列表 */
  @action
  async available() {
    // return mock.available
    return http
      .get(`/member/vouchers/available`, {
        params: {
          project: 'Diningcity',
          per_page: 999,
        },
      })
      .then((data) => data.data);
  }
    @action
    async getVoucherAvailableList(deal_id) {
        return http
            .get(`/member/vouchers/available?deal_id=${deal_id}`,{
                params: {
                    per_page: 9999,
                },
            })
            .then((data) => data.data);
    }

  /**兑换优惠券 */
  redeem(restaurant_id, redeem) {
    return new Promise((resolve, reject) => {
      http
        .post(`/public/restaurants/${restaurant_id}/redeems`, {
          redeem,
        })
        .then((data) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(this.formatError(error));
        });
    });
  }
  formatError({ errMsg }) {
    try {
      return errMsg.full_messages[0];
    } catch (error) {
      return errMsg;
    }
  }
  /**列表 */
  @action
  list() {
    return new Promise((resolve, reject) => {
      const list = ["available", "consumed", "expired"];
      Promise.all(
        list.map((item) => {
          return http
            .get(`/member/vouchers/${item}`, {
              params: {
                per_page: 999,
              },
            })
            .then((data) => data.data);
        })
      )
        .then((data) => {
          let total = 0;
          this.vouchers = list.map((item, index) => {
            total += data[index].length;
            return {
              name: item,
              data: data[index],
            };
          });
          this.total = total;
          resolve(this.vouchers);
        })
        .catch(() => {
          this.total = 0;
          resolve([]);
        });
    });
  }

  /**
   * 详情
   */
  @action
  show(id) {
    return http.get(`/member/vouchers/${id}`).then((data) => data.data);
  }

  /**
   * 获取兑换历史
   *
   * @memberof Service
   */
  @action
  getRedeemHistory(id) {
    return http
      .get(`/member/vouchers/${id}/redeems`, {
        params: {
          per_page: 999,
        },
      })
      .then((data) => data.data);
  }

  /**
   * putVoucherRefund
   * @param id
   * @returns {Promise<AxiosResponse<T> | never>}
   */
  @action
  putVoucherRefund(id) {
    return http
      .put(`/member/vouchers/${id}/refund`)
      .then((data) => data.data);
  }

  /**
   *
   * @param id
   * @returns {Promise<AxiosResponse<T> | never>}
   */
  @action
  getRefundDetail(id) {
    return http
      .get(`/member/vouchers/${id}/refund_detail`)
      .then((data) => data.data);
  }
  @action
  addVoucher(id) {
    return http
      .post(`/member/vouchers/${id}/entry`)
      .then((data) => data.data);
  }
  
  // 获取二维码
  @action
  getQrcode(code){
    return http.post(`/member/redeems`,{
      "redeem": {
        "code": code
      }
    }).then((data) => data.data)
  }
  // 查询兑换情况
  queryRedeem(id){
    return http.get(`/member/redeems/${id}`).then((data) => data.data)
  }

}

export default new Store();
