import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';
import { Input, Modal, Col, Row, Tabs, Drawer } from 'antd';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ShortCuts } from '@/components';
import { MenuOutlined, UserOutlined, LeftOutlined } from '@ant-design/icons';
import { useStores } from '@/store';
import { getQuery } from '@/service/http';
import { goLogin } from '@/service/utils';
import './index.less';

/**
 * @description: 网站header
 *
 */
function debounce(fn, wait) {
  let callback = fn;
  let timerId = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timerId);
    timerId = setTimeout(function () {
      callback.apply(context, args);
    }, wait);
  };
}

const HeaderBar = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const {
    region_id,
    lang = 'zh',
    private_token,
    region,
    gid,
    callback,
    payment,
    regionId,
  } = getQuery();
  const { CommonStore } = useStores();
  const [isShow, setIsShow] = useState(false);
  const [regionList, setRegionList] = useState({});
  const [currentCity, setCurrentCity] = useState(null);
  const [pos, setPos] = useState({});
  const [isShowUserOptions, setIsShowUserOptions] = useState(false);
  const [footerDeatil, setFooterDeatil] = useState(null);
  const [pageCity, setPageCity] = useState({});

  const isShowShortCuts = useMemo(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return false;
    }
    return /\/home|\/collection|\/deal\/|\/restaurants\/|\/deals\//.test(
      location.pathname
    );
  }, [location]);
  useEffect(() => {
    console.log(document?.contentWindow?.parent);
    if (document?.contentWindow?.parent) {
      document.contentWindow.parent.document.querySelector(
        '#frontend-guides-restaurants'
      ).style.height = '100vh';
    }
    if (region || region_id) {
      CommonStore.getCityByRegion(region || region_id).then((data) => {
        setPageCity(data);
      });
    }
    CommonStore.getFooterDetail(region || region_id || regionId).then(
      (data) => {
        setFooterDeatil(data);
      }
    );

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude: lat, longitude: lng } = position.coords;
        setPos({ lat, lng });
        CommonStore.getCityByLocation(lat, lng).then((res) => {
          setCurrentCity(res);
        });
      },
      () => { },
      {
        timeout: 30000,
        enableHighAccuracy: true,
        maximumAge: 75000,
      }
    );
    CommonStore.getAllRegions().then((res) => {
      // const allCountry = Array.from(new Set(res.map((_) => _.country_name)));
      // let mapRegins = {};
      // allCountry.forEach((_) => (mapRegins[_] = {}));

      res.forEach((item) => {
        let mapRegins = {};
        item.regions.forEach((region) => {
          const char = region?.key_word[0].toLocaleUpperCase();
          if (!mapRegins?.[char]) {
            mapRegins[char] = [];
          }
          mapRegins[char].push(region);
        });
        item.regions = mapRegins;
      });
      setRegionList(res);
    });
  }, [CommonStore, region, regionId, region_id]);

  function reLogin() {
    let inIframe = window.top !== window.self;
    if (inIframe) {
      window.top.postMessage(
        {
          type: 'logout',
          params: {},
        },
        '*'
      );
    }
  }
  return (
    <React.Fragment>
      {isShowShortCuts && <ShortCuts />}
      <header className='header-bar'>
        <div className='header-bar-left'>
          <div
            className='header-bar-left-logo'
            onClick={() => {
              window.location.href = `/#/home?gid=${gid}&region=${region || region_id || regionId || 'shanghai'
                }&lang=${lang}&private_token=${private_token}&callback=${callback}&payment=${payment}`;
              window.top.postMessage(
                {
                  type: 'other',
                  params: {
                    url: `${region && lang
                        ? `${pageCity?.domain
                          ? 'https://www.' + pageCity?.domain + '/'
                          : document?.referrer
                        }${lang}/${region}`
                        : pageCity?.domain
                          ? 'https://www.' + pageCity?.domain + '/'
                          : document?.referrer
                      }`,
                    region,
                    lang,
                  },
                },
                '*'
              );
            }}
          >
            <img
              src={
                Array.from(document.body.classList).includes('is-mobile')
                  ? footerDeatil?.mobile_logo
                  : footerDeatil?.web_logo
              }
              alt=''
            />
          </div>
          <div
            className='header-bar-left-changeCity headerBtn'
            onClick={() => {
              setIsShow(true);
            }}
          >
            <i className='icon icon-location'></i>
            <span style={{ textTransform: 'capitalize' }}>
              {pageCity?.name || region || region_id || regionId || 'shanghai'}
            </span>
          </div>
        </div>
        <div className='header-bar-center'>
          <div className='header-bar-center-search'>
            <SearchInput pos={pos} pageCity={pageCity} />
          </div>
        </div>
        <div className='header-bar-right'>
          <div className='header-bar-right-btns'>
            <div
              className='content btn'
              onClick={() => {
                window.open(`${pageCity?.domain
                    ? 'https://www.' + pageCity?.domain + '/'
                    : document?.referrer
                  }${lang}/${region}/search_restaurants?utf8=1&keyword=&search_restaurant_id=`, '_blank')
              }}
            >
              {t('Header.Browse')}
            </div>
            <div
              className='content btn'
              onClick={() => {
                window.open('https://assistant.diningcity.asia', '_blank');
              }}
            >
              {t('Header.RestaurantLog')}
            </div>

            <div
              className='content btn'
              onClick={() => {
                window.open('https://partnerwithus.diningcity.asia', '_blank');
              }}
            >
              {t('Header.PartnerWithUs')}
            </div>
          </div>
          <div className='header-bar-right-user'>
            <div
              className='userInfo headerBtn'
              onClick={() => {
                // setIsShowUserOptions(!isShowUserOptions);
                if (private_token) {
                  window.top.location.href = `${pageCity?.domain
                      ? 'https://www.' + pageCity?.domain + '/'
                      : document?.referrer
                    }${lang}/members/bookings`;
                  // window.location.href = `/#/profile?lang=${lang}&private_token=${private_token}&callback=${callback}&payment=${payment}&region_id=${region}`;
                  window.top.postMessage(
                    {
                      type: 'other',
                      params: {
                        url: `${pageCity?.domain
                            ? 'https://www.' + pageCity?.domain + '/'
                            : document?.referrer
                          }${lang}/members/bookings`,
                        region,
                        lang,
                      },
                    },
                    '*'
                  );
                } else {
                  goLogin()
                }
              }}
            >
              <MenuOutlined />
              <UserOutlined />
            </div>
          </div>
        </div>
        <SelectRegion
          isShow={isShow}
          setIsShow={setIsShow}
          regionList={regionList}
          currentCity={currentCity}
        />
      </header>
      <div className='userOptions' hidden={!isShowUserOptions}>
        {!private_token ? (
          <React.Fragment>
            <div
              onClick={() => {
                goLogin()
              }}
            >
              {t('Header.SignIn')}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div
              onClick={() => {
                window.top.location.href = `${pageCity?.domain
                    ? 'https://www.' + pageCity?.domain + '/'
                    : document?.referrer
                  }${lang}/members/bookings`;
                // window.location.href = `/#/profile?lang=${lang}&private_token=${private_token}&callback=${callback}&payment=${payment}&region_id=${region}`;
                window.top.postMessage(
                  {
                    type: 'other',
                    params: {
                      url: `${pageCity?.domain
                          ? 'https://www.' + pageCity?.domain + '/'
                          : document?.referrer
                        }${lang}/members/bookings`,
                      region,
                      lang,
                    },
                  },
                  '*'
                );
              }}
            >
              {t('Header.Bookings')}
            </div>
            <div onClick={reLogin}>{t('Header.SignOut')}</div>
          </React.Fragment>
        )}
      </div>
      {isShowUserOptions && (
        <div
          className='header-mask'
          onClick={() => {
            setIsShowUserOptions(false);
          }}
          onWheel={() => {
            setIsShowUserOptions(false);
          }}
          onTouchMove={() => {
            setIsShowUserOptions(false);
          }}
        ></div>
      )}
    </React.Fragment>
  );
};

const SearchInput = (props) => {
  const { t } = useTranslation();
  const { pos, pageCity } = props;
  const { CommonStore } = useStores();
  const location = useLocation();
  const {
    lang = 'zh',
    private_token,
    region = 'shanghai',
    callback,
    payment,
    login_url,
    source,
    q,
  } = getQuery();
  let str = useRef(q || '');
  let inp = useRef();
  useEffect(() => {
    if (/\/home/.test(location.pathname)) {
      str.current = '';
      document.querySelector('.searchInput input').value = '';
      inp.current.state.value = '';
    }
  }, [location]);
  const [currentRestaurant, setCurrentRestaurant] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchBoxVisible, setSearchBoxVisible] = useState(false);
  const [searchInfoVisible, setSearchInfoVisible] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const query = (isMore = false) => {
    CommonStore.getBrowseRestaurants(
      {
        api_key: 'cgecegcegcc',
        lang,
        q: str.current,
        lat: pos?.lat || 31.199083,
        lng: pos?.lng || 121.440144,
        per_page: 4,
        page: isMore ? currentPage : 1,
      },
      region
    ).then((res) => {
      const { data, headers } = res;
      const { next_page, prev_page } = headers;
      if (next_page > prev_page) {
        setCurrentPage(next_page);
        setHasMore(true);
      } else {
        setHasMore(false);
      }
      if (isMore) {
        setCurrentRestaurant([...currentRestaurant, ...data]);
      } else {
        setCurrentRestaurant(data);
      }
    });
  };

  const handleChange = debounce(function (e) {
    const { value } = e.target;
    str.current = value;
    query();
  }, 150);
  const goRestaurant = (item) => {
    window.location.href = `/#/restaurants/${item.id}?lang=${lang}&region=${region}&private_token=${private_token}&callback=${callback}&payment=${payment}&login_url=${login_url}&source=${source}`;
    window.top.postMessage(
      {
        type: 'other',
        params: {
          url: item.website_detail_url,
          region,
          lang,
        },
      },
      '*'
    );
  };
  return (
    <div className='searchInput'>
      <Input
        ref={inp}
        suffix={
          // <i className="icon icon-search"></i>
          <i
            className='icon icon-htSearch'
            onClick={() => {
              window.open(`${pageCity?.domain
                  ? 'https://www.' + pageCity?.domain + '/'
                  : document?.referrer
                }${lang}/${region}/search_restaurants?utf8=1&keyword=${str.current
                }&search_restaurant_id=`, '_blank')
            }}
          />
        }
        onFocus={() => {
          setSearchBoxVisible(true);
        }}
        onBlur={() => {
          setSearchBoxVisible(false);
        }}
        placeholder={t('Header.SearchRestaurants')}
        defaultValue={str.current}
        onChange={(e) => {
          e.persist();
          setSearchInfoVisible(true);
          handleChange(e);
        }}
        onPressEnter={(e) => {
          e.persist();
          const { value } = e.target;
          window.top.location.href = `${pageCity?.domain
              ? 'https://www.' + pageCity?.domain + '/'
              : document?.referrer
            }${lang}/${region}/search_restaurants?utf8=1&keyword=${value
            }&search_restaurant_id=`;
          // window.location.href = `/#/browse?q=${str.current}&region=${region}&lang=${lang}&private_token=${private_token}&callback=${callback}&payment=${payment}`;
          // window.top.postMessage(
          //   {
          //     type: 'other',
          //     params: {
          //       url: `${document?.referrer}/${lang}/${region}/search_restaurants`,
          //       region,
          //       lang,
          //     },
          //   },
          //   '*'
          // );
        }}
      />
      {searchBoxVisible && (
        <div className='searchResult'>
          {searchInfoVisible && (
            <div className='searchInfo'>
              <span>
                {t('Header.SearchFor')} "{str.current}"
              </span>
            </div>
          )}
          {currentRestaurant.map((item) => {
            return (
              <div
                className='restaurantBox'
                key={item.id}
                onMouseDown={(e) => {
                  e.preventDefault();
                  goRestaurant(item);
                }}
              >
                <Row>
                  <Col span={4}>
                    <img src={item.cover + '-thumb'} alt='' />
                  </Col>
                  <Col span={16}>
                    <div className='restaurantBox-detail'>
                      <div className='name'>{item.name}</div>
                      <div className='location'>
                        <i className='icon icon-location'></i>
                        <span>{item?.location?.name}</span>
                      </div>
                    </div>
                  </Col>
                  <Col span={4}>
                    <div className='rating'>
                      {item.ratings_avg && item.ratings_avg.toFixed(1)}
                    </div>
                    <div className='distance'>
                      {item?.distance && (
                        <span>{(item.distance / 1000).toFixed(1)}km</span>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            );
          })}
          {hasMore && (
            <div
              className='load-more'
              onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
                query(true);
              }}
            >
              <span>{t('Header.More')}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const SelectRegion = (props) => {
  const { t } = useTranslation();
  const { region = 'shanghai', lang = 'zh' } = getQuery();
  const { isShow, setIsShow, regionList = [], currentCity } = props;
  const { CommonStore } = useStores();
  const [searchResult, setSearchResult] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [currentTab, setCurrentTab] = useState('0');
  const keyword = useRef('');
  const inpRef = useRef();
  const query = () => {
    if (!keyword?.current) return;
    CommonStore.searchRegion(keyword.current).then((data) => {
      if (Array.isArray(data)) {
        setSearchResult(data);
        setIsSearch(true);
      }
    });
  };
  const handleChange = debounce(function (e) {
    const { value } = e.target;
    keyword.current = value;
    query();
  }, 500);
  const Regions = useCallback(
    () => (
      <div className='regionGroup'>
        {isSearch ? (
          <div className='searchRegions'>
            <h3>{t('Header.SearchResults')}</h3>
            {searchResult && searchResult.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{ cursor: 'pointer', margin: '5px 0' }}
                  onClick={() => {
                    // window.top.location.href = `https://www.diningcity.local/${item.country_name}/${item.key_word}`;
                    // window.top.location.href = `${item.domain}/${lang}/${item.key_word}`;
                    window.top.postMessage(
                      {
                        type: 'switch',
                        params: {
                          url: `https://www.${item.domain}/${lang}/${item.key_word}`,
                          region,
                          lang,
                        },
                      },
                      '*'
                    );
                  }}
                >
                  {item.name},{item.country_title}
                </div>
              );
            })}
          </div>
        ) : (
          <div>
            <h3>{t('Header.Popular')}</h3>
            <Tabs
              // defaultActiveKey='0'
              activeKey={currentTab}
              onTabClick={(key) => {
                setCurrentTab(key);
              }}
              tabBarExtraContent={
                !Array.from(document.body.classList).includes('is-mobile')
                  ? null
                  : {
                    left:
                      Number(currentTab) === 0 ? null : (
                        <div
                          className='arrowBtn'
                          onClick={() => {
                            const key = Number(currentTab) - 1;
                            setCurrentTab(String(key));
                          }}
                        >
                          <i className='icon icon-arrow-left'></i>
                        </div>
                      ),
                    right:
                      Number(currentTab) === regionList?.length - 1 ? null : (
                        <div
                          className='arrowBtn'
                          onClick={() => {
                            const key = Number(currentTab) + 1;
                            setCurrentTab(String(key));
                          }}
                        >
                          <i className='icon icon-arrow-right'></i>
                        </div>
                      ),
                  }
              }
            >
              {regionList?.map((country, i) => {
                const id = String(i);
                return (
                  <Tabs.TabPane
                    tab={country.title}
                    key={id}
                    style={{ height: 320, overflow: 'auto' }}
                  >
                    {Object.keys(country.regions)
                      .sort()
                      .map((char) => {
                        return (
                          <div key={char}>
                            <h3>{char}</h3>
                            {country.regions[char].map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className='region'
                                  style={{ margin: '5px 0', cursor: 'poniter' }}
                                  onClick={() => {
                                    // window.top.location.href = `https://www.diningcity.local/${item.country_name}/${item.key_word}`;
                                    // window.top.location.href = `${item.domain}/${lang}/${item.key_word}`;
                                    window.top.postMessage(
                                      {
                                        type: 'switch',
                                        params: {
                                          url: `https://www.${item.domain}/${lang}/${item.key_word}`,
                                          region,
                                          lang,
                                        },
                                      },
                                      '*'
                                    );
                                  }}
                                >
                                  {item.name}
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                  </Tabs.TabPane>
                );
              })}
            </Tabs>
          </div>
        )}
      </div>
    ),
    [isSearch, t, searchResult, currentTab, regionList, region, lang]
  );
  return !Array.from(document.body.classList).includes('is-mobile') ? (
    <Modal
      visible={isShow}
      closeIcon={
        <i
          className='icon icon-close'
          onClick={() => {
            setIsShow(false);
          }}
        ></i>
      }
      footer={null}
      title={t('Header.SelectYourRegion')}
      className='selectRegionModal'
      centered={true}
      onCancel={() => {
        keyword.current = '';
        setIsSearch(false);
        inpRef.current.state.value = '';
      }}
    >
      <section>
        <Input
          className='searchRegionInp'
          ref={inpRef}
          onChange={(e) => {
            e.persist();
            handleChange(e);
          }}
          style={{ paddingLeft: '20px' }}
          placeholder={isSearch ? 'Enter search keywords' : ''}
          prefix={
            !isSearch ? (
              <div className='detected'>
                <i className='icon icon-location-colored'></i>
                <span>
                  {t('Header.Detected')}:{currentCity?.name}
                  {currentCity?.name && ','}
                  {currentCity?.country_title}
                </span>
              </div>
            ) : null
          }
          suffix={
            isSearch ? (
              <div
                className='serachSuffix'
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  keyword.current = '';
                  setIsSearch(false);
                  inpRef.current.state.value = '';
                }}
              >
                <LeftOutlined />
              </div>
            ) : (
              <div
                className='serachSuffix'
                onClick={() => {
                  query();
                }}
              >
                <i
                  className='icon icon-htSearch'
                  style={{ borderRadius: '50%' }}
                ></i>
              </div>
            )
          }
        ></Input>
        <Regions />
      </section>
    </Modal>
  ) : (
    <Drawer
      visible={isShow}
      closeIcon={
        <i className='icon icon-close' onClick={() => setIsShow(false)}></i>
      }
      footer={null}
      title={
        <p style={{ textAlign: 'center' }}>{t('Header.SelectYourRegion')}</p>
      }
      className='selectRegionModal'
      centered={true}
      placement='bottom'
      onClose={() => {
        keyword.current = '';
        setIsSearch(false);
        inpRef.current.state.value = '';
      }}
    >
      <section>
        <Input
          // onFocus={() => {
          //   setIsShowPrefix(false);
          // }}
          ref={inpRef}
          onChange={(e) => {
            e.persist();
            handleChange(e);
          }}
          prefix={
            !isSearch ? (
              <div className='detected'>
                <i className='icon icon-location-colored'></i>
                <span>
                  {t('Header.Detected')}:{currentCity?.name}
                  {currentCity?.name && ','}
                  {currentCity?.country_title}
                </span>
              </div>
            ) : null
          }
          suffix={
            isSearch ? (
              <div
                className='serachSuffix'
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  keyword.current = '';
                  setIsSearch(false);
                  inpRef.current.state.value = '';
                }}
              >
                <LeftOutlined />
              </div>
            ) : (
              <div
                className='serachSuffix'
                onClick={() => {
                  query();
                }}
              >
                <i
                  className='icon icon-htSearch'
                  style={{ borderRadius: '50%' }}
                ></i>
              </div>
            )
          }
        ></Input>
        <Regions />
      </section>
    </Drawer>
  );
};
export default HeaderBar;
