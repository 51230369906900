import React, { useState, useEffect, useCallback, useRef } from "react";
import { useObserver } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Spin } from 'antd';
import { useStores } from "@/store";
import { Loading, Restaurant, AdsItem, Footer } from "@/components";
import CollectionMap from "./components/collection-map";
import { getQuery,getUrlofLang } from "../../service/http"
import InfiniteScroll from "react-infinite-scroller";
import { Modal } from 'antd'
import "./index.less";

let loading = false;
let beforeParams = {}
let extraParams = {
  region: '',
  page: 1,
  per_page: 20,
  q: ''
}
let tagParams = null;

/**
 * 我的优惠券-列表
 *
 */
const Browse = () => {
  const { t } = useTranslation();
  const { BrowseStore } = useStores();
  const [mapVisible, setMapVisible] = useState(true);
  const [restaurants, setRestaurants] = useState([])
  const [restaurantAds, setRestaurantAds] = useState([])
  const [mapRestaurants, setMapRestaurants] = useState([])
  const [regionData, setRegionData] = useState({})
  const [hasMore, setHasMore] = useState(true);
  const [filterParams, setFilterParams] = useState({})
  const { region, lang = "en", q } = getQuery();
  const [infiniteKey, setInfiniteKey] = useState(0)
  const [markerIndex, setMarkerIndex] = useState([])
  const [mapSelectedRestaurants, setMapSelectedRestaurants] = useState([])
  const [keyword, setKeyword] = useState(q)
  const [spainLoading, setSpainLading] = useState(false)
  const [sort, setSort] = useState('')
  const restaurantsScrollRef = useRef(null)

  const getGeoSuccess = (position) => {
    const { latitude, longitude } = position.coords
    BrowseStore.getCityByLocation(latitude, longitude).then(res => {
      extraParams = {
        ...extraParams,
        lang,
        q: q || '',
        lat: res.lat,
        lng: res.lng,
        region: res.key_word
      }
      getRestaurants()
      getMapRestaurants()
      setRegionData(res)
      BrowseStore.getBrowseParams({
        lang: lang,
        region_id: res.key_word
      }).then(setFilterParams)
    })
  }
  console.log({...extraParams,lang:getUrlofLang()})
  useEffect(() => {
    if (window.screen.width <= 768) {
      setMapVisible(false)
    }
    let params = {};
    
    if(localStorage.getItem('search_type')){
      tagParams = JSON.parse(localStorage.getItem('search_type'))
      Object.keys(tagParams).forEach(key => {
        params[key==='locations'?'location.id':key+'.id'] = tagParams[key][0]?.id
      })
      localStorage.removeItem('search_type')
    }
    if (region) {
      extraParams = {
        ...extraParams,
        lang,
        q: q || '',
        region,
        ...params
      }
      getRestaurants()
      getMapRestaurants()
      BrowseStore.getCityByRegion(region).then(setRegionData)
      BrowseStore.getBrowseParams({
        lang: lang,
        region_id: region
      }).then(setFilterParams)
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(getGeoSuccess, undefined, {
        timeout: 1000
      })
    }

    window.addEventListener('message', getMessageFromParent)
    return () => {
      window.removeEventListener('message', getMessageFromParent)
    }
  }, []);

  useEffect(() => {
    let ids = markerIndex.map(mIndex => mapRestaurants[mIndex].id)
    console.log(ids)
    if (ids.length) {
      BrowseStore.getBrowseRestaurants({
        extraParams: extraParams.lang,
        region: extraParams.region,
        "api-key": "cgecegcegcc",
        ids: ids.join(',')
      }).then(setMapSelectedRestaurants)
    } else {
      setMapSelectedRestaurants([])
    }
  }, [markerIndex])

  function getMessageFromParent(event) {
    const data = event.data
    switch (data.cmd) {
      case 'search':
        const keyword = data.params.q
        setKeyword(keyword)
        applyFilter({
          ...extraParams,
          q: keyword
        })
        break
    }
  }

  function sendMessageToParent(key) {
    window.parent.postMessage({
      cmd: key,
      params: {}
    }, '*')
  }

  function clearSearch() {
    setKeyword('')
    sendMessageToParent('clearSearch')
    applyFilter({
      ...extraParams,
      q: ''
    })
  }

  async function getRestaurants() {
    if (extraParams.region) {
      if (loading) return;
      setSpainLading(true)
      loading = true;
      console.log('----',extraParams);
      try {
        let [res, ads, resAds] = await Promise.all([
          BrowseStore.getBrowseRestaurants({...extraParams,lang:getUrlofLang()}),
          BrowseStore.getAds(extraParams.q || undefined, extraParams.region),
          BrowseStore.getResAds({
            region: extraParams.region,
            collection_id: '',
            type: 'restaurant',
            search_word: extraParams.q || '',
            lang:getUrlofLang()
          })
        ])
        setRestaurantAds(resAds)
        if (res) {
          let totalRes = []
          if (extraParams.page === 1) {
            totalRes = res
          } else {
            totalRes = restaurants.concat(res)
          }
          if (ads && ads.length) {
            let adsLen = ads.length
            totalRes.forEach((item, index) => {
              if ((index + 1) % 10 === 0) {
                let adIndex = ((index + 1) / 10 - 1) % adsLen
                item.ad = ads[adIndex]
              }
            })
          }
          ads.forEach((ad, index) => {
            if (totalRes[10 * (index + 1) - 1]) {
              totalRes[10 * (index + 1) - 1].ad = ad
            }
          })
          setRestaurants(totalRes)
          if (res.length < 10) {
            setHasMore(false);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        loading = false;
        setSpainLading(false)
      }
    }
  }

  async function getMapRestaurants() {
    if (extraParams.region) {
      try {
        let res = await BrowseStore.getBrowseMapRestaurants({
          ...extraParams,
          page: 1,
          per_page: 2000
        })
        setMapRestaurants(res || [])
      } catch (error) {
        console.error(error);
      }
    }
  }

  function loadMore(page) {
    if (loading) return false
    extraParams = {
      ...extraParams,
      page
    }
    getRestaurants()
  }

  function applyFilter(params) {
    setInfiniteKey(Math.ceil(Math.random() * 100000))
    restaurantsScrollRef.current.scrollTop = 0
    extraParams = {
      lang: lang,
      region: extraParams.region,
      page: 1,
      per_page: 20,
      q: '',
      ...params
    }
    getRestaurants()
    getMapRestaurants()
  }

  function applySort(sortKey) {
    setSort(sortKey)
    if (sortKey) {
      extraParams.order_by = sortKey
    } else {
      delete extraParams.order_by
    }
    extraParams.page = 1
    getRestaurants()
  }

  function tapMarkersHandler(indexs) {
    setMarkerIndex(indexs)
  }

  function toggleMap() {
    setMapVisible(!mapVisible)
  }

  return useObserver(() => {
    return (
      <Spin spinning={spainLoading}>
        <div className="page-browse">
          <Filter 
            params={filterParams} 
            onApply={(params) => applyFilter(params)} 
            mapVisible={mapVisible} 
            keyword={keyword}
            curSort={sort}
            setSort={sortKey => applySort(sortKey)}
            toggleMap={() => toggleMap()} 
            clearSearch={() => clearSearch()}
          />
          <div className="content">
            <div className="restaurants" ref={restaurantsScrollRef}>
              <InfiniteScroll
                initialLoad={false}
                pageStart={1}
                loadMore={loadMore}
                hasMore={hasMore}
                useWindow={false}
                key={infiniteKey}
              >
                {
                  restaurantAds.map(rest => <Restaurant restaurant={rest} isAd={true} region={region} />)
                }
                {
                  restaurants.map(rest => {
                    return (
                      <React.Fragment key={rest.id}>
                        <Restaurant restaurant={rest} region={region} />
                        {
                          rest.ad && <AdsItem ad={rest.ad} />
                        }
                      </React.Fragment>
                    )
                  })
                }
                <div className="load-more">
                  {hasMore && <Loading red></Loading>}
                </div>
              </InfiniteScroll>
            </div>

            {
              mapVisible && <div className="map">
                <CollectionMap restaurants={mapRestaurants} region={regionData} tapMarkers={(indexs) => tapMarkersHandler(indexs)} />
                {
                  mapSelectedRestaurants.length ? (
                    <div className="map-restaurant">
                      {
                        mapSelectedRestaurants.map(res => (
                          <Restaurant restaurant={res} key={res.id} only={mapSelectedRestaurants.length === 1} region={region} />
                        ))
                      }
                    </div>
                  ) : null
                }
              </div>
            }
          </div>
        </div>
        <Footer isFixed={true}/>
      </Spin>
    );
  });
};

const Filter = ({ params = {}, onApply, toggleMap, mapVisible, clearSearch, keyword, setSort, curSort }) => {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false)
  const [curFilterKey, setFilterKey] = useState('')
  const [filterParams, setFilterParams] =  useState(params)
  const [canApply, setCanApply] = useState(false)
  const [canClear, setCanClear] = useState(false)
  const [selectedParams, setSelectedParams] = useState({})
  const [moreSelectedVisible, setMoreSelectedVisible] = useState(false)
  const [moreSelectedActive, setMoreSelectedActive] = useState(false)
  const [lastFilterCount, setLastFilterCount] = useState(0)
  const [sortItems, setSortItems] = useState([{name: "relevance", key: ''}, {name: "nearest", key: 'distance_asc'}, {name: "rating", key: 'ratings_avg_desc'}, {name: "booksCount", key: 'books_count_desc'}, {name: "lowestPrice", key: 'avg_price_asc'}, {name: "highestPrice", key: 'avg_price_desc'}])
  const [filterTypes, setFilterTypes] = useState({
    location: false,
    cuisines: false,
    filters: false,
    sort: false
  })
  const selectedRef = useRef(null)
  const selectedBoxRef = useRef(null)

  useEffect(() => {
    if (params.distance && typeof params.distance[0] === 'number') {
      params.distance = params.distance.map(item => ({
        id: item,
        name: item
      }))
      params.price_range = params.price_range.map(item => {
        item.id = item.start_price + '|' + item.end_price
        item.name = item.view_price
        return item
      })
      params.ratings = params.ratings.map(item => ({
        id: item,
        name: item
      }))
    }
    beforeParams = JSON.parse(JSON.stringify(params))
    if(tagParams && Object.keys(params).length>0){
      const key = Object.keys(tagParams)[0];
      const tag = params[key].find(_=>_.id===tagParams[key][0]?.id)
      if(tag){
        tag.checked = true
      }
      applyFilter(params)
    }
    setFilterParams(params)
  }, [params,tagParams])

  useEffect(() => {
    setFilterTypes({
      ...filterTypes,
      sort: !!curSort
    })
  }, [curSort])

  useEffect(() => {
    let hasChecked = false
    for (const key in filterParams) {
      if (Object.hasOwnProperty.call(filterParams, key)) {
        const element = filterParams[key];
        if (element.filter(item => item.checked).length) {
          hasChecked = true
          break
        }
      }
    }
    setCanClear(hasChecked)
    let hasChanged = false
    for (const key in filterParams) {
      if (Object.hasOwnProperty.call(filterParams, key)) {
        const before = filterParams[key] || [];
        const after = beforeParams[key] || [];
        if (before.filter(item => item.checked).map(item => item.id).join(',') !== after.filter(item => item.checked).map(item => item.id).join(',')) {
          hasChanged = true
          break
        }
      }
    }
    setCanApply(hasChanged)
  }, [filterParams, modalVisible])

  useEffect(() => {
    const selectedDom = selectedRef.current
    const selectedBoxDom = selectedBoxRef.current
    const maxWidth = selectedBoxDom.offsetWidth
    const selectedItems = Array.from(selectedDom.children)
    let curWidth = 100
    for (let i = 0; i < selectedItems.length; i++) {
      curWidth += selectedItems[i].offsetWidth + 8
      if (curWidth > maxWidth) {
        setMoreSelectedVisible(true)
        setLastFilterCount(selectedItems.length - i)
        break
      }
    }
    if (curWidth <= maxWidth) {
      setMoreSelectedVisible(false)
    }
  }, [selectedParams, keyword])

  function showFilterDialog(item) {
    setFilterKey(item)
    setModalVisible(true)
  }

  function hideFilterDialog() {
    setFilterParams(JSON.parse(JSON.stringify(beforeParams)))
    setModalVisible(false)
  }

  function changeFilterParams(key, index, type = 'checkbox') {
    let params = { ...filterParams }
    if (type === 'radio') {
      params[key].forEach(item => {
        item.checked = false
      })
    }
    params[key][index].checked = !params[key][index].checked
    setFilterParams(params)
  }

  function applyFilter(params) {
    beforeParams = JSON.parse(JSON.stringify(params || filterParams))
    let obj = {}
    let selected = {}
    let typesActive = {
      location: false,
      cuisines: false,
      filters: false,
      sort: false
    }
    Object.keys(beforeParams).forEach(key => {
      switch (key) {
        case 'cuisines':
          let cuisines = beforeParams[key].filter(item => item.checked)
          if (cuisines.length) {
            selected.cuisines = cuisines
            typesActive.cuisines = true
            obj['cuisines.id'] = cuisines.map(item => item.id).join(',')
          }
          break
        case 'locations':
          let locations = beforeParams[key].filter(item => item.checked)
          if (locations.length) {
            selected.locations = locations
            typesActive.location = true
            obj['location.id'] = locations.map(item => item.id).join(',')
          }
          break
        case 'landmarks':
          let landmarks = beforeParams[key].filter(item => item.checked)
          if (landmarks.length) {
            selected.landmarks = landmarks
            typesActive.location = true
            obj['landmarks.id'] = landmarks.map(item => item.id).join(',')
          }
          break
        case 'tags':
          let tags = beforeParams[key].filter(item => item.checked)
          if (tags.length) {
            selected.tags = tags
            typesActive.filters = true
            obj['tags.id'] = tags.map(item => item.id).join(',')
          }
          break
        case 'extras':
          let extras = beforeParams[key].filter(item => item.checked)
          if (extras.length) {
            selected.extras = extras
            typesActive.filters = true
            obj['extras.id'] = extras.map(item => item.id).join(',')
          }
          break
        case 'distance':
          let distance = beforeParams[key].find(item => item.checked)
          if (distance) {
            typesActive.filters = true
            selected.distance = [distance]
            obj['distance'] = distance.id + 'm'
          }
          break
        case 'price_range':
          let price = beforeParams[key].find(item => item.checked)
          if (price) {
            typesActive.filters = true
            selected.price = [price]
            obj['avg_price'] = price.id.replace('|', ',')
          }
          break
      }
    })
    setFilterTypes(typesActive)
    setSelectedParams(selected)
    setModalVisible(false)
    onApply(obj)
  }

  function removeParams(key, removeId) {
    // 更新弹窗筛选
    let beforeFilterParams = JSON.parse(JSON.stringify(filterParams))
    let index = beforeFilterParams[key].findIndex(item => item.id === removeId)
    beforeFilterParams[key][index].checked = false
    setFilterParams(beforeFilterParams)
    applyFilter(beforeFilterParams)
  }

  function clearFilter() {
    if (!canClear) return
    let params = { ...filterParams }
    if (curFilterKey === 'filters') {
      params.tags.forEach(item => {
        item.checked = false
        return item
      })
      params.price_range.forEach(item => {
        item.checked = false
        return item
      })
      params.distance.forEach(item => {
        item.checked = false
        return item
      })
      params.extras.forEach(item => {
        item.checked = false
        return item
      })
    } else if (curFilterKey === 'cuisines') {
      params.cuisines.forEach(item => {
        item.checked = false
        return item
      })
    } else if (curFilterKey === 'location') {
      params.locations.forEach(item => {
        item.checked = false
        return item
      })
      params.landmarks.forEach(item => {
        item.checked = false
        return item
      })
    } 
    setFilterParams(params)
  }

  function selectSort(sort) {
    setSort(sort.key)
    setModalVisible(false)
  }

  return (
    <div className="filters">
      <div className="filter-buttons">
        {
          Object.keys(filterTypes).map(key => (
            <span className={`filter-button ${filterTypes[key] ? 'active' : ''}`} key={key} onClick={() => showFilterDialog(key)}>
              { t(`Browse.${key}`) }
            </span>
          ))
        }
        <span className={`icon ${mapVisible ? 'icon-list' : 'icon-map'}`} onClick={toggleMap}></span>
      </div>
      {
        <div className={`selected-box ${moreSelectedVisible && !moreSelectedActive ? 'hide' : ''}`} ref={selectedBoxRef}>
          <div className="selected" ref={selectedRef}>
            {
              keyword ? <div className="selected-item" onClick={clearSearch}>&times; { keyword }</div> : null
            }
            {
              Object.keys(selectedParams).map(key => (
                selectedParams[key].map(item => (
                  <div className="selected-item" key={item.id} onClick={() => removeParams(key, item.id)}>&times; { item.name }</div>
                ))
              ))
            }
          </div>
          {
            moreSelectedVisible && (
              <div className={`show-more ${moreSelectedActive ? 'active' : ''}`} onClick={() => setMoreSelectedActive(!moreSelectedActive)}>
                { moreSelectedActive ? t('Browse.hide') : `${t('Browse.showMore')} · ${lastFilterCount}`}
                <span className="arrow"></span>
              </div>
            )
          }
        </div>
      }
      {/* <span className="button map-icon">
              
      </span> */}
      <Modal 
        className="browse-filter-modal" 
        visible={modalVisible} 
        onCancel={hideFilterDialog} 
        title={null} 
        footer={null} 
        closable={false}
      >
        <header>
          { t(`Browse.${curFilterKey}`) }
          {
            curFilterKey !== 'sort' && (
              <div className={`clear ${canClear ? 'active' : ''}`} onClick={() => clearFilter()}>{ t('Browse.clear') }</div>
            )
          }
        </header>
        <section>
          {
            curFilterKey === 'location' ? (
              <React.Fragment>
                <div className="filter-title">{ t('Browse.district') }</div>
                <div className="filter-items">
                  {
                    filterParams.locations?.map((item, index) => (
                      <label key={item.id} className={item.checked ? 'active' : ''} onClick={() => changeFilterParams('locations', index)}>
                        <span>{ item.name }</span>
                      </label>
                    ))
                  }
                </div>
                <div className="filter-title">{ t('Browse.landmarks') }</div>
                <div className="filter-items">
                  {
                    filterParams.landmarks?.map((item, index) => (
                      <label key={item.id} className={item.checked ? 'active' : ''} onClick={() => changeFilterParams('landmarks', index)}>
                        <span>{ item.name }</span>
                      </label>
                    ))
                  }
                </div>
              </React.Fragment>
            ) : null
          }
          {
            curFilterKey === 'cuisines' ? (
              <React.Fragment>
                <div className="filter-items">
                  {
                    filterParams.cuisines?.map((item, index) => (
                      <label key={item.id} className={item.checked ? 'active' : ''} onClick={() => changeFilterParams('cuisines', index)}>
                        <span>{ item.name }</span>
                      </label>
                    ))
                  }
                </div>
              </React.Fragment>
            ) : null
          }
          {
            curFilterKey === 'filters' ? (
              <React.Fragment>
                {filterParams.tags?.length > 0&&(<React.Fragment>
                  <div className="filter-title">{ t('Browse.tags') }</div>
                  <div className="filter-items">
                    {
                      filterParams.tags?.map((item, index) => (
                        <label key={item.id} className={item.checked ? 'active' : ''} onClick={() => changeFilterParams('tags', index)}>
                          <img src={item.icon_url} alt="" />
                          <span>{ item.name }</span>
                        </label>
                      ))
                    }
                  </div>
                </React.Fragment>)}
                {filterParams.price_range?.length > 0&&(<React.Fragment>
                  <div className="filter-title">{ t('Browse.price_range') }</div>
                  <div className="filter-items">
                    {
                      filterParams.price_range?.map((item, index) => (
                        <label key={item.id} className={item.checked ? 'active' : ''} onClick={() => changeFilterParams('price_range', index, 'radio')}>
                          <span>{ item.name }</span>
                        </label>
                      ))
                    }
                  </div>
                </React.Fragment>)}
                {filterParams.distance?.length > 0&&(<React.Fragment>
                  <div className="filter-title">{ t('Browse.distance') }</div>
                  <div className="filter-items">
                    {
                      filterParams.distance?.map((item, index) => (
                        <label key={item.id} className={item.checked ? 'active' : ''} onClick={() => changeFilterParams('distance', index, 'radio')}>
                          <span>&lt; { item.name } m</span>
                        </label>
                      ))
                    }
                  </div>
                </React.Fragment>)}
                {filterParams.extras?.length > 0 && (
                  <React.Fragment>
                    <div className="filter-title">{t("Browse.others")}</div>
                    <div className="filter-items">
                      {filterParams.extras?.map((item, index) => (
                        <label
                          key={item.id}
                          className={item.checked ? "active" : ""}
                          onClick={() => changeFilterParams("extras", index)}
                        >
                          <span>{item.name}</span>
                        </label>
                      ))}
                    </div>
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : null
          }
          {
            curFilterKey === 'sort' ? (
              <React.Fragment>
                {
                  sortItems.map(item => (
                    <div className={`sort-item ${curSort === item.key ? 'active' : ''}`} onClick={() => selectSort(item)}>{ t(`Browse.${item.name}`) }</div>
                  ))
                }
              </React.Fragment>
            ) : null
          }
        </section>
        {
          curFilterKey !== 'sort' && (
            <footer className={[canApply ? 'active' : '']} onClick={() => applyFilter()}>
              { t('Browse.apply') }
            </footer>
          )
        }
      </Modal>
    </div>
  )
}

export default Browse;
