import React, { useState, useEffect, useMemo } from "react";
import Gallery from "react-bnb-gallery";
import { useTranslation } from "react-i18next";
import { slimImage } from "@/service/utils";
import { useStores } from '@/store';
import { getQuery } from '@/service/http';

const Template = (props) => {
  return props.show ? <React.Fragment>{props.children}</React.Fragment> : null;
};

/**详情 */
export const Details = ({ restaurant, tags, extras }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false)
  let {
    basic_info,
    book_config,
    location,
    landmarks = [],
    cuisines = [],
    tags: restaurantTags = [],
    licenses
  } = restaurant;
  basic_info = basic_info || {};
  location = location || {};
  const { CommonStore } = useStores();
  const [pageCity, setPageCity] = useState({});
  const {
    region_id,
    region,
    lang
  } = getQuery();
  if (region || region_id) {
    CommonStore.getCityByRegion(region || region_id).then((data) => {
      setPageCity(data);
    });
  }
  licenses = licenses.map(item => item.picture_url)

  const policy = useMemo(() => {
    try {
      return book_config.cancel.policy;
    } catch (error) {
      return ''
    }
  })
  const toSearchPage = (item,type) => {
    localStorage.setItem('search_type', `{"${type}":${JSON.stringify([{...item,checked:true}])}}`)
    window.open(`${
      pageCity?.domain
        ? 'https://www.' + pageCity?.domain + '/'
        : document?.referrer
    }${lang}/${region}/search_restaurants?utf8=1&keyword=&search_restaurant_id=`)
  }
  return (
    <section className="page-restaurant--details">
      <h3>{t("WEB.Details")}</h3>
      <Tags tags={tags} />
      {/* 餐厅其他信息 */}
      <div className="page-restaurant--others">
        <h4>{t("WEB.Introduction")}</h4>
        <div
          lang="en"
          className="restaurant-description"
          dangerouslySetInnerHTML={{
            __html: basic_info.description,
          }}
        ></div>
        <Template show={policy}>
          <h4>{t("WEB.Cancellation policy")}</h4>
          <div
            className="restaurant-cancellation"
            dangerouslySetInnerHTML={{
              __html: policy,
            }}
          ></div>
        </Template>
        <Template show={location.name}>
          <h4>{t("WEB.District")}</h4>
          <div className="restaurant-tags">
            <div className="tag-button" onClick={()=>{toSearchPage(location,'locations')}}>{location.name}</div>
          </div>
        </Template>
        <h4>{t("WEB.Landmarks")}</h4>
        <div className="restaurant-tags">
          {landmarks.map((item) => {
            return (
              <div className="tag-button" key={item.id} onClick={()=>{toSearchPage(item,'landmarks')}}>
                {item.name}
              </div>
            );
          })}
        </div>
        <h4>{t("WEB.Cuisines")}</h4>
        <div className="restaurant-tags">
          {cuisines.map((item) => {
            return (
              <div className="tag-button" key={item.id} onClick={()=>{toSearchPage(item,'cuisines')}}>
                {item.name}
              </div>
            );
          })}
        </div>
        <h4 hidden={!extras.length}>{t("WEB.Tags")}</h4>
        <div className="restaurant-tags" hidden={!extras.length}>
          {extras.slice(0,5).map((item) => {
            return (
              <div className="tag-button" key={item.id} onClick={()=>{toSearchPage(item,'extras')}}>
                {item.name}
              </div>
            );
          })}
        </div>
        {
          !!licenses.length && (
            <React.Fragment>
              <h4>{t("WEB.Licenses")}</h4>
              <div className="restaurant-tags">
                {licenses.map((item, index) => {
                  let photo = `${item}?imageView2/1/w/24/h/24/interlace/1`.replace('??', '?')
                  return (
                    <div className="license-button" key={index} onClick={() => setShow(true)}>
                      <img src={photo} alt="" />
                      <i className="icon icon-eye"></i>
                    </div>
                  );
                })}
              </div>
            </React.Fragment>
          )
        }
      </div>
      <Gallery
        onClose={() => setShow(false)}
        photos={licenses}
        show={show}
        showThumbnails={false}
      ></Gallery>
    </section>
  );
};

/* 餐厅标签 */
const Tags = ({ tags }) => {
  return (
    <div className="page-restaurant--tags">
      {tags.map((item, index) => {
        return (
          <div className="tag-item" key={index}>
            <img
              className="tag-icon"
              src={slimImage(item.icon_url, "/w/64/h/64")}
            />
            <div className="tag-content">
              <div className="tag-name">{item.name}</div>
              <div
                className="tag-description"
                dangerouslySetInnerHTML={{
                  __html: item.description,
                }}
              ></div>
              <a className="tag-link" target="_blank" href={item.url}>{item.link.text}</a>
            </div>
          </div>
        );
      })}
    </div>
  );
};
