import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { RestaurantRate } from "../../../../pages/restaurant/components/Rate";
import { ReviewItem, ReviewDetail } from "@/components";
import { useStores } from "@/store";

/**评分&评论 */
export const Reviews = ({ menu }) => {
  const { t } = useTranslation();
  // const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [currentReview, setCurrentReview] = useState({});
  const { restaurantStore } = useStores();
  const [restaurant, setRestaurant] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState({});
  useEffect(() => {
    setLoading(true);
    restaurantStore.getMenuReviews(menu.id, currentPage).then((res) => {
      const { data, info } = res;
      setRestaurant([...restaurant, ...data]);
      setPageInfo(info);
      setLoading(false);
    });
  }, [currentPage]);
  const onLoadMore = () => {
    console.log(restaurant);
    if (
      pageInfo?.total_pages &&
      currentPage < parseInt(pageInfo?.total_pages)
    ) {
      setCurrentPage(parseInt(pageInfo.current_page) + 1);
    }
  };

  /**查看详情 */
  function onShow(item) {
    setCurrentReview(item);
    setVisible2(true);
  }
  /**查看大图 */
  function onPreview() {}

  return (
    <section
      className="page-restaurant--reviews"
      hidden={restaurant.length === 0}>
      <h3>
        {pageInfo?.total_count}
        {t("WEB.Reviews")}
      </h3>
      <RestaurantRate restaurant={restaurant} />
      <div
        className="hairline-bottom"
        style={{ height: 10, marginBottom: 10, marginTop: -10 }}></div>
      {/* 详情 */}
      <ReviewDetail
        review={currentReview}
        onPreview={onPreview}
        visible={visible2}
        setVisible={setVisible2}
      />
      {restaurant.map((item) => {
        return (
          <ReviewItem
            key={item.id}
            review={item}
            mobile={true}
            onPreview={onPreview}
            hideMore={true}
            onClick={() => onShow(item)}
          />
        );
      })}
      {currentPage < parseInt(pageInfo.total_pages) && (
        <div style={{ textAlign: "center" }}>
          <Button
            className="dc-button"
            type="primary"
            style={{
              width: "240px",
              margin: "0 auto",
              backgroundImage: "linear-gradient(320deg, #d92029, #d9315d)",
              color: "#fff",
            }}
            block
            onClick={onLoadMore}
            loading={loading}>
            {t("WEB.Show more reviews")}
          </Button>
        </div>
      )}
    </section>
  );
};
