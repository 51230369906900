import React, { useState } from "react";
import { BackIcon } from "@/components";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";

/**VIP待遇 */
export const Benfits = ({ benfits }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  function onCancel() {
    setVisible(false);
  }
  if (!benfits.length) return null;
  return (
    <React.Fragment>
      <section
        className="page-restaurant--benfits"
        onClick={() => setVisible(true)}
      >
        <h3>{t("WEB.VIP Benefits")}</h3>
        <div className="flex">
          <div className="title flex">
            <i className="icon icon-crown-fill"></i>
            <span>
              {t("WEB.Get a welcome drink and more", {
                title: benfits[0]?.title,
              })}
            </span>
          </div>
          <div className="action">
            <em>{benfits.length}</em>
            <BackIcon style={{ fontSize: 12 }} />
          </div>
        </div>
      </section>
      <Modal
        title={
          <div>
            <h3>{t("WEB.VIP Benefits")}</h3>{" "}
            <p>{t("WEB.VIP Benefits Description")}</p>
          </div>
        }
        bodyStyle={{ paddingTop: "0" }}
        visible={visible}
        destroyOnClose
        footer={null}
        onCancel={onCancel}
      >
        {benfits.map((item) => {
          return <p key={item.id}>{item.title}</p>;
        })}
      </Modal>
    </React.Fragment>
  );
};
