import React, { useState, useEffect, useMemo, memo } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Drawer, Affix, Modal, message } from "antd";
import { useStores } from "@/store";
import { useHistory } from "react-router-dom";
import { getQuery } from "@/service/http";
import qs from "qs";
import {
  Button,
  Icon,
  Header,
  Locate,
  SpecialEvent,
  Template,
  Redeem,
  WriteReview,
  Footer
} from "@/components";
import {
  Banner,
  Profile,
  Reviews,
  Aside,
  Menus,
  Details,
  Grid,
  Availabilities,
  Benfits,
  Deals,
  Vouchers,
  DraftReview,
  Similar,
} from "./components";
import "./index.less";
import BookNow from "../restaurant/components/bookNow";
import { useObserver } from "mobx-react-lite";
import { goLogin } from '@/service/utils';
const BASE_URL =
  process.env.REACT_APP_MODE === "PRODUTION"
    ? "https://book.diningcity.asia"
    : "https://staging-book.diningcity.asia";

/**
 * 餐厅详情
 *
 * PC和移动端使用不同的布局方式
 */
const Main = () => {
  const { t } = useTranslation();
  const { restaurantStore, memberStore, voucherStore, reviewStore } = useStores();
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [restaurant, setRestaurant] = useState(null);
  const [deals, setDeals] = useState([]);
  const [events, setEvents] = useState([]);
  const [menus, setMenus] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [benfits, setBenfits] = useState([]);
  const [availabilities, setAvailability] = useState(null);
  const [tags, setTags] = useState([]);
  const [blockJustifications, setBlockJustifications] = useState([]);
  const [visible, setVisible] = useState(false);
  const [currentDate, setCurrentDate] = useState({});
  const [visibleClose, setVisibleeClose] = useState(false);
  const [bookNowVisible, setBookNowVisible] = useState(false);
  const [reviewVisible, setReviewVisible] = useState(false)
  const [currency, setCurrency] = useState('¥')
  const [loading,setLoading] = useState(false);
  const [successVisible, setSuccessVisible] = useState({
    visible: false,
    tip: ''
  })
  const [recommenders,setRecommenders] = useState([])
  const [extras,setExtras] = useState([])
  const [submitLoading,setSubmitLoading] = useState(false)
  const { CommonStore } = useStores();
  const { gid, region, lang, payment, callback,private_token } = getQuery();
  // const { region } = getQuery();
  const weburl = useMemo(() => {
    if (!restaurant) return "";
    const { id } = restaurant;
    const { lang,source } = getQuery();
    const path = `${BASE_URL}/#/restaurants/${id}/reservation?`;
    const private_token = memberStore.private_token;
    const query = qs.stringify({
      lang,
      private_token,
      date: currentDate.date,
      time: currentDate.formated_time,
      source: source,
      show_group_booking: true
    });
    return path + query;
  }, [restaurant, memberStore.private_token, currentDate.date, currentDate.formated_time]);


  useEffect(() => {
    setLoading(true)
    restaurantStore.setUrl(id);
    restaurantStore.getRestaurant().then((data) => {
      setExtras(data?.extras)
      setRestaurant(data);
      setCurrency(data?.region?.currency || currency(data?.region?.key_word));
      restaurantStore.getPhotos().then(setPhotos);
      restaurantStore.getDeals().then(setDeals);
      restaurantStore.getExtrasEvents().then(setEvents);
      restaurantStore.getMenus().then(setMenus);
      restaurantStore.getBenfits().then(setBenfits);
      restaurantStore.getAvailability().then(setAvailability);
      restaurantStore.getTags().then(setTags);
      restaurantStore.getblockJustifications().then(setBlockJustifications);
      restaurantStore.getRecommenders().then(setRecommenders).finally(()=>{
        setLoading(false)
      })
    });
  }, [id]);

  const times = useMemo(() => {
    try {
      return availabilities.time_slots || [];
    } catch (error) {
      return [];
    }
  });

  const showRedeem = useMemo(() => {
    return restaurant && restaurant.reservation_type === 4;
  }, [restaurant]);

  const showLaCarte = useMemo(() => {
    return restaurant && restaurant.booking_enabled && restaurant.reservation_type !== 4 && times.length
  }, [restaurant, times])

  const showBookNow = useMemo(() => {
    return showLaCarte || deals.length || events.length
  }, [showLaCarte, deals, events])

  /**选择预定时间 */
  function onBook(item) {
    if (deals.length == 0 && events.length == 0) {
      setCurrentDate(item);
      setVisible(true);
    } else {
      setBookNowVisible(true);
    }
  }
  function showCLose() {
    if (blockJustifications.notification) {
      setVisibleeClose(true)
    }
  }
  const goReview = () => {
    setReviewVisible(true)
  }
  const onSubmitReview = (type, rid, options) => {
    setSubmitLoading(true)
    reviewStore.submitReview(type, rid, options).then(res => {
      setSubmitLoading(false)
      if (res && res.status) {
        setReviewVisible(false)
        try {
          let reviews = JSON.parse(localStorage.getItem('draft-reviews'))
          let index = reviews.findIndex(item => item.rid == restaurant.id)
          if (index >= 0) {
            reviews.splice(index, 1)
            localStorage.setItem('draft-reviews', JSON.stringify(reviews))
          }
        } catch (err) {
          console.log(err)
        } finally {
          setSuccessVisible({
            visible: true,
            tip: res.message.split('\n').map(item => `<p>${item}</p>`).join('')
          })
          reviewStore.reviewInit()
          setRestaurant({...restaurant})
        }
        message.success(t("REVIEW.Saved Successfully"));
      }
    })
  }
  const chenckToken = ()=>{
    // const private_token = memberStore.private_token;
    const { private_token } = getQuery();
    if(private_token){
      setReviewVisible(true)
    }else{
      // 没有token时跳转到登录页面
      goLogin();
    }
  }
  const { domain } = CommonStore.cityDetail;
  const origin = process.env.REACT_APP_MODE === "PRODUTION" ? "https://www.diningcity.cn/" : "https://www.diningcitydeals.com/"

  return (
    <div className="hasFooterContent">
      <div className="page-restaurant" data-aside={true} key={id}>
        <section className="page-restaurant--main">
          {restaurant&&!loading ? (
            <React.Fragment>
            {<div className="navigation-title" style={{marginTop:'-5px'}}>
                <div onClick={() => {
                  window.location.href = `/#/home?gid=${gid}&region=${
                    region ||'shanghai'
                  }&lang=${lang}&private_token=${private_token}&callback=${callback}&payment=${payment}`;
                  window.top.postMessage(
                    {
                      type: 'other',
                      params: {
                        url: `${
                          domain
                              ? 'https://www.' + domain + '/'
                              : origin
                          }${lang}/${region}`,
                        region,
                        lang,
                      },
                    },
                    '*'
                  );
                }}>{t('Header.Home')}</div>
                <span>&gt;</span>
                <div className="navigation-title-selected">{restaurant?.name}</div>
              </div>}
              <Banner photos={photos} cover={restaurant.cover} />
              <Grid photos={photos} />
              <Profile restaurant={restaurant} />
              <SpecialEvent events={events} />
              <DraftReview restaurant={restaurant} goReview={goReview} />
              <Availabilities availabilities={availabilities} onClick={onBook} />
              <Benfits benfits={benfits} />
              <Deals deals={deals} />
              <Affix>
                <Navigate restaurant={restaurant} menus={menus} recommenders={recommenders}/>
              </Affix>
              <Menus menus={menus} />
              <Reviews restaurant={restaurant} />
              <Details restaurant={restaurant} tags={tags} extras={extras}/>
              {recommenders.length>0&&<Similar recommenders={recommenders}/>}
              <footer className="page-footer-pc">
                <div className="page-footer-inner" align="right">
                  <div className="left-btns">
                    <div className="left-btn" onClick={chenckToken}>
                      <i className="icon icon-review-normal"></i>
                      {t("REVIEW.Review")}
                    </div>
                  </div>
                </div>
              </footer>
              <footer className="page-footer" align="right">
                <div className="left-btns">
                  <div onClick={chenckToken}><i className="icon icon-review-circle"></i></div>
                </div>
                {
                  showRedeem ? <Redeem restaurant={restaurant} /> :
                  showBookNow ? (
                    <Button type="primary" onClick={() => onBook({})}>
                      {t("WEB.Book now")}
                    </Button>
                  ) : null
                }
              </footer>
              {
                showBookNow ? <div className="interimBtn">
                    <Button type="primary" onClick={() => onBook({})}>
                      {t("WEB.Book now")}
                    </Button>
                </div> : null
              }
            </React.Fragment>
          ) : null}

         {restaurant&&!loading&&<React.Fragment>
          <Drawer
            className="booking-drawer deal-drawer"
            placement="bottom"
            closable={false}
            destroyOnClose
            onClose={() => setVisible(false)}
            visible={visible}
          >
            {/* <Header title={t("WEB.Book now")} onClick={() => setVisible(false)} /> */}
            <div className="booking-form--close">
              <span>{t("WEB.Book now")}</span>
              <i className="icon icon-close" onClick={()=>setVisible(false)}></i>
            </div>
            <Aside weburl={weburl} />
          </Drawer>

          <Drawer
            className="booking-drawer restaurant-review-drawer"
            placement="right"
            closable={false}
            destroyOnClose
            onClose={() => setReviewVisible(false)}
            visible={reviewVisible}
          >
            <WriteReview restaurant={restaurant} onClose={setReviewVisible} onSubmitReview={onSubmitReview} submitLoading={submitLoading}/>
          </Drawer>

          <Drawer
            className="closed-drawer"
            placement="bottom"
            closable={false}
            destroyOnClose
            onClose={() => setVisibleeClose(false)}
            visible={visibleClose}
            height={'90%'}
          >
            <React.Fragment>
              <div className="closed">
                <h4 className="H4Class">
                  {t("WEB.No seats available")}
                </h4>
                <div className="content">
                  <p className="seatsContent">{t("WEB.No seats content")}</p>
                  {blockJustifications.notification ? (<div><h4 className="justification">
                    {t("WEB.Provided justification")}
                  </h4>
                    <div className="justificationContent-phone">
                      {blockJustifications.notification}
                    </div>
                  </div>) : null}
                </div>
              </div>
            </React.Fragment>

          </Drawer>
         <BookNow
            restaurant_id={id}
            bookNowVisible={bookNowVisible}
            setBookNowVisible={setBookNowVisible}
            setCurrentDate={setCurrentDate}
            setVisible={setVisible}
            showLaCarte={showLaCarte}
          />

          <Modal
            visible={successVisible.visible}
            wrapClassName="dc-modal"
            width={300}
            footer={null}
            closable={false}
            keyboard={false}
            maskClosable={false}
            centered
            destroyOnClose
          >
            <p
              style={{ padding: "40px 40px 0 40px", fontSize: 13 }}
              dangerouslySetInnerHTML={{
                __html: successVisible.tip
              }}
            ></p>
            <footer>
              <Button onClick={() => setSuccessVisible({visible: false, tip: ''})} type="normal">
                {t("REVIEW.Thanks")}
              </Button>
            </footer>
          </Modal>
          <Uploading t={ t } />
          </React.Fragment>}
        </section>
        {
          restaurant&&!loading &&(showBookNow ? (
            <div className="pc-aside">
              <Aside weburl={weburl}></Aside>
            </div>
          ) : restaurant?.phone?.trim() ? (
            <div className="pc-aside">
              <React.Fragment>
                <div className="asideClosed">
                  <div className="closed">
                    <h4 className="H4Class">
                      {t("WEB.Call")}
                    </h4>
                    <div className="content">
                      <p className="call-tip">{t("WEB.Call tip")}</p>
                      <a className="phone" href={`tel:${restaurant.phone}`}>
                        +{restaurant.region.phone_code}
                        &nbsp;
                        {
                        String(restaurant.region.phone_code).includes('86')
                        ? String(restaurant.phone).replace(/^0+/, '')
                        : restaurant.phone
                        }
                      </a>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            </div>
          ) : (
            <div className="pc-aside">
              <React.Fragment>
                <div className="asideClosed">
                  <div className="closed">
                    <h4 className="H4Class">
                      {t("WEB.Call")}
                    </h4>
                    <div className="content">
                      <p className="call-tip">{t("WEB.Call tip without phone")}</p>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            </div>
          ))
        }
      </div>
      {restaurant && <Footer hasMore={/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)}/>}
    </div>
  );
};

/* 中部导航栏 */
const Navigate = ({ restaurant, menus ,recommenders}) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState("");
  const navigates = [
    { label: t("WEB.Menu"), value: "menu" },
    restaurant.reviews_count
      ? { label: t("WEB.ReviewsTitle"), value: "reviews" }
      : null,
    { label: t("WEB.Details"), value: "details" },
    recommenders.length > 0
      ? { label: t('WEB.Similar'), value: 'similar' }
      : null,
  ].filter((i) => i !== null);
  function scrollTo(name) {
    setSelected(name);
    try {
      let $el = document.querySelector(`.page-restaurant--${name}`);
      var headerOffset = 35;
      const bodyRect = document.body.getBoundingClientRect().top;
      var elementPosition = $el.getBoundingClientRect().top;
      var offsetPosition = elementPosition - headerOffset;
      if (window.top === window) {
        let scrollDom = document.querySelector('.hasFooterContent')
        scrollDom.scrollTo(0, elementPosition + scrollDom.scrollTop - 34) // 减去navigate的高度
        window.scrollTo({
          top: offsetPosition - bodyRect + 20,
          behavior: "smooth",
        });
      } else {
        // 在iframe的情况下，iframe被设置为scrolling: no，是为了解决iframe滚动闪烁问题
        // 但利用dom节点的滚动条，会导致window.scroll失效
        let scrollDom = document.querySelector('.hasFooterContent')
        scrollDom.scrollTo(0, elementPosition + scrollDom.scrollTop - 34) // 减去navigate的高度
      }
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <div className="page-restaurant--navigate">
      {navigates.map((item, index) => {
        return (
          <span
            key={index}
            data-selected={selected === item.value}
            onClick={() => scrollTo(item.value)}
          >
            {item.label}
          </span>
        );
      })}
    </div>
  );
};

const Uploading = memo(({ t }) => {
  const { reviewStore } = useStores()
  return useObserver(() => {
    return (
      <div className={`uploading ${reviewStore.uploadStep ? 'active' : ''}`}>
        {t("REVIEW.Uploading tip", { current: reviewStore.uploadStep, total: reviewStore.photos.length })}
        {/* <div className="cancel">{t("CANCEL")}</div> */}
        { !!reviewStore.photos.length && <div className="progress" style={{width: `${Math.ceil(reviewStore.uploadStep / reviewStore.photos.length * 100)}%`}}></div> }
      </div>
    )
  })
})

export default Main;
