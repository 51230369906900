import React, { useEffect, useState } from "react";
import { slimImage } from "@/service/utils";
import { useStores } from "@/store";
import { getQuery } from "@/service/http";
import "./index.less";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

/**
 * 餐厅信息
 *
 * @returns
 */
const Restaurant = ({ deal, odd, bookNowDeals, showGet = true, tapTimeslot, isAd = false}) => {
  const history = useHistory()
  const { t } = useTranslation();
  const { memberStore } = useStores();
  const [distance, setDistance] = useState("");
  const location = useLocation();
  const { lang = 'en', region = 'shanghai' } = getQuery();
  const { area_range } = deal;
  useEffect(() => {
    if (undefined != deal.restaurant) {
      const { lat, lng } = deal.restaurant;
      setDistance(memberStore.getDistance(lat, lng));
    }
  }, []);

  let dealDistance = area_range && (area_range > 1000
    ? `${(area_range / 1000).toFixed(1)}km`
    : `${Math.ceil(area_range)}m`);
  const goWebsiteDetailUrl = (e) => {
    history.push(`/deals/${deal.id}${location.search}`)
    // 窗口url要定位为website_detail_url
    if (process.env.REACT_APP_MODE !== "DEV" && window.parent && deal.website_detail_url) {
      e.preventDefault();
      // window.parent.location.href = deal.website_detail_url;
      window.location.href = `/#/deals/${deal.id}${location.search}`;
      window.top.postMessage(
        {
          type: 'other',
          params: {
            url: deal.website_detail_url,
            region,
            lang,
          },
        },
        '*'
      )
    }
  }

  const onTapTimeslot = (e, item) => {
    e.stopPropagation()
    tapTimeslot && tapTimeslot(item, deal)
  }

  return (
    <div
      className={`deal-item ${bookNowDeals ? 'booknow-deals' : ''}`}
      data-odd={odd}
      onClick={goWebsiteDetailUrl}
    >
      <article>
        <div className="deal-item--image">
          <img src={deal.picture_url.split('?')[0] + '-thumb'} alt="" />
        </div>
        <div className="deal-item--content">
          <div className="deal-title">{deal.name}</div>
          <div className="deal-description ellipsis">
            {deal.restaurant_name}
          </div>
          <div className="deal-tags">{deal.category_name}</div>
          <div className="deal-location">
            <i className="icon icon-location" data-size="mini"></i>
            {Array.isArray(deal?.locations) ? deal?.locations[0]?.name : deal?.locations?.name}
            {/* <span style={{ marginLeft: 10 }}>{distance}</span> */}
            <span className="distance">{distance || dealDistance}</span>
          </div>
          <div className="deal-right">
            {
              isAd && <span className="dc-restaurant--ad" style={{marginRight:'0px'}}>
                <span>{t('Browse.ad')}</span>
              </span>
            }
            <span className="deal-price-desc">
              {/* {deal.price_desc[0]} */}
              {deal.format_deal_price}
              {/* {deal.category_keyword === "discount" ? (
                <i>￥{deal.original_price}</i>
              ) : null} */}
              {
                deal.price_desc?.length > 1 ? (
                  <i>{deal.price_desc[1]}</i>
                ) : null
              }
            </span>
            {
             !isAd && showGet && <div className="get-button">{t("WEB.Get")}</div>
            }
          </div>
        </div>
      </article>
      {
        deal?.current_avaliable_time_slots?.length ? (
          <div className="timeslots" onClick={e => e.stopPropagation()}>
            {
              deal.current_avaliable_time_slots.map((item, index) => (
                <div key={index} className={`time-item ${item.formated_discount ? 'discount' : ''}`} onClick={(e) => onTapTimeslot(e, item)}>
                  <div className="time">{item.formated_time}</div>
                  <div className="value">{item.formated_discount || ''}</div>
                </div>
              ))
            }
          </div>
        ) : null
      }
    </div>
  );
};

export default Restaurant;
