import { action, computed, observable } from "mobx";
import http, { getQuery } from "../service/http";
import Promise from 'bluebird'
import { message } from "antd";
import moment from 'moment'
import memberStore from "./member";

const md5 = require('js-md5')

class Store {
  @observable photos = [];
  @observable curPhotoIndex = 0;
  @observable popularDishes = [];
  @observable specialDishes = [];
  @observable fullDishes = [];
  @observable fullDishesKeys = [];
  @observable uploadStep = 0

  @computed
  get hasDishes() {
    return this.photos.some(item => item.comment || item.dish_id)
  }

  @action
  reviewInit() {
    this.photos = [];
    this.curPhotoIndex = 0;
    this.uploadStep = 0;
    this.popularDishes = [];
    this.specialDishes = [];
    this.fullDishes = [];
    this.fullDishesKeys = [];
  }

  /**获取点评tip */
  @action
  async getReviewsTips(restaurantId) {
    try {
      let { data } = await http.get(`/public/reviews/tips`, {
        params: {
          restaurant_id: restaurantId,
          platform: 'web'
        },
        headers: {
          "accept-version": 2
        }
      });
      return data.tips || ''
    } catch (error) {
      return '';
    }
  }
  /**选中图片 */
  @action
  async setPhotos() {
    this.uploadStep = 0
    await Promise.each(this.photos, async (item, index) => {
      if (!item.id) {
        this.uploadStep = index + 1
        let photo = await this.uploadPhoto(item, index)
        this.photos[index].id = photo.photo.id
        this.photos[index].filePath = photo.photo.image_url
        delete this.photos[index].file
      }
    })
  }
  /**上传图片 */
  @action
  uploadPhoto(file, index) {
    return new Promise(async (resolve, reject) => {
      try {
        const hexHash = md5(file.filePath)
        const suffix = file.fileType.split('/')[1]
        let options = {
          blob: {
            filename: `${new Date().getTime()}.${suffix}`,
            content_type: file.fileType,
            byte_size: file.size,
            checksum: hexHash
          }
        }
        let res1 = await http.post('/direct_uploads', options)

        // 上传到七牛云
        const formData = new FormData()
        formData.append('file', file.file);
        formData.append('token', res1.data?.direct_upload.headers['x-token']);
        formData.append('key', res1.data?.key);
        await http.post(res1.data?.direct_upload.url, formData)

        let options2 = {
          storage_image: res1.data?.signed_id,
          imageable_type: 'Member',
          imageable_id: memberStore.member?.id
        }
        let res2 = await http.post('/public/photos', options2, {
          headers: {
            'accept-version': '2'
          }
        })
        console.log(res2)
        if (res2 && res2.data && res2.data.status) {
          resolve(res2.data)
        } else {
          message.error(res2.data.message);
          reject()
        }
      } catch (error) {
        reject(error)
      }
    })
  }
  /**获取点评菜单 */
  @action
  async getReviewDishes(restaurantId) {
    try {
      if (this.specialDishes.length || this.popularDishes.length || this.fullDishes.length) return this.fullDishesKeys
      let res = await Promise.all([
        http.get(`/public/restaurants/${restaurantId}/menus/online_meals`),
        http.get(`/public/restaurants/${restaurantId}/menus/popular`),
        http.get(`/public/restaurants/${restaurantId}/menus`),
      ])
      let specialRes = res[0].data
      let popularRes = res[1].data
      let fullRes = res[2].data

      // --- 处理specialDishes
      this.specialDishes = specialRes.map(sItem => {
        sItem.courses = sItem.courses.map(item => {
          item.coverImg = item.photos && item.photos.length ? 
            `${item.photos[0].image_url}?imageView2/1/w/60/h/46/q/60|imageslim`.replace('??', '?') :
            'https://library.diningcity.cn/images/mini_program/review/no-dish-icon.png'
          return item
        })
        return sItem
      })

      // --- 处理popularDishes
      this.popularDishes = popularRes.map(item => {
        item.coverImg = item.photos && item.photos.length ? 
          `${item.photos[0].image_url}?imageView2/1/w/60/h/46/q/60|imageslim`.replace('??', '?') :
          'https://library.diningcity.cn/images/mini_program/review/no-dish-icon.png'
        return item
      })

      // --- 处理full menu
      for (const key in fullRes) {
        fullRes[key] = fullRes[key].map(item => {
          item.coverImg = item.photos && item.photos.length ? 
            `${item.photos[0].image_url}?imageView2/1/w/60/h/46/q/60|imageslim`.replace('??', '?') :
            'https://library.diningcity.cn/images/mini_program/review/no-dish-icon.png'
          return item
        })
      }
      this.fullDishes = fullRes
      this.fullDishesKeys = Object.keys(fullRes).map(item => {
        return {
          key: item,
          active: false
        }
      })
      return this.fullDishesKeys
    } catch (error) {
      console.log(error)
      return [];
    }
  }
  /**获取点评菜单 */
  @action
  async filterDishes(restaurantId, keyword) {
    try {
      let res = await http.get(`/public/restaurants/${restaurantId}/menus/search?q=${keyword}`)
      console.log(res)
      return res.data.slice(0, 10).map(item => {
        item.coverImg = item.photos && item.photos.length ? 
          `${item.photos[0].image_url}?imageView2/1/w/60/h/46/q/60|imageslim`.replace('??', '?') :
          'https://library.diningcity.cn/images/mini_program/review/no-dish-icon.png'
        return item
      })
    } catch (err) {
      return []
    }
  }
  /**保存点评 */
  @action
  async submitReview(type, rid, options) {
    try {
      options['source_type'] = type
      options['source_id'] = rid

      await this.setPhotos()
      options['photos'] = this.photos
      options['platform'] = 'web'

      let res = await http.post('/public/reviews', options, {
        headers: {
          'accept-version': '2',
        }
      })
      return res.data
    } catch (err) {
      console.log(err)
      message.error(err.data.message, 5)
      return {}
    }
  }
  /**修改点评 */
  @action
  async submitEditReview(type, rid, sid, options) {
    try {
      options['source_type'] = type
      options['source_id'] = rid

      await this.setPhotos()
      options['photos'] = this.photos
      options['platform'] = 'web'

      let res = await http.put(`/member/reviews/${sid}`, options, {
        headers: {
          'accept-version': '2',
        }
      })
      return res.data
    } catch (err) {
      console.log(err)
      message.error(err.data.attributes[0].messages[0].message, 5)
      return {}
    }
  }
  /**保存草稿 */
  @action
  async saveDraft(rid, review) {
    try {
      await this.setPhotos()
      review.photos = this.photos
      review.rid = rid
      review.date = moment().format('YYYY-MM-DD')
      let draftReviews = localStorage.getItem('draft-reviews')
      if (draftReviews && typeof draftReviews === 'string' && draftReviews[0] === '[') {
        draftReviews = JSON.parse(draftReviews)
        let index = draftReviews.findIndex(item => item.rid === rid)
        if (index >= 0) {
          draftReviews[index] = review
        } else {
          draftReviews.push(review)
        }
        localStorage.setItem('draft-reviews', JSON.stringify(draftReviews))
      } else {
        localStorage.setItem('draft-reviews', JSON.stringify([review]))
      }
    } catch (err) {
      console.log(err)
    }
  }

  /** 我的评论 */
  @action
  getMyReviews(page = 1, per_page = 10) {
    return new Promise((resolve, reject) => {
      http
        .get(`/member/reviews`, {
          params: {
            page,
            per_page
          },
          headers: {
            'accept-version': '2',
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          resolve(null);
        });
    });
  }

  /** 设置评论点赞 */
  @action
  setReviewLike(id) {
    return new Promise((resolve, reject) => {
      http
        .post(`/public/reviews/${id}/like`, {
          id
        }, {
          headers: {
            'accept-version': '2',
          },
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /** 删除评论 */
  @action
  deleteReview(id) {
    return new Promise((resolve, reject) => {
      http
        .delete(`/member/reviews/${id}`, {
          params: {
            id
          },
          headers: {
            'accept-version': '2',
          },
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default new Store();
