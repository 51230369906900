import { action, observable } from "mobx";
import http from "../service/http";

class Store {
  /**当前缓存的广告 */
  @observable ads = []
  @observable resAds = []
  @observable dealAds = []

  /** 获取餐厅列表 */
  @action
  getBrowseRestaurants(options = {}) {
    return new Promise((resolve, reject) => {
      http
        .get(`/public/regions/${options.region}/restaurants/search`, {
          params: options
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          resolve(null);
        });
    });
  }

  /** 获取地图餐厅列表 */
  @action
  getBrowseMapRestaurants(options = {}) {
    return new Promise((resolve, reject) => {
      http
        .get(`/public/regions/${options.region}/restaurants/search_for_map`, {
          params: {
            ...options,
            "api-key": "cgecegcegcc"
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          resolve(null);
        });
    });
  }

  /** 获取餐厅列表 */
  @action
  getBrowseParams(options = {}) {
    return new Promise((resolve, reject) => {
      http
        .get(`/public/regions/${options.region_id}/restaurants/query_params`, {
          params: {
            lang: options.lang,
            price_class: true, // #4624 used to show the price range
            'api-key': 'cgecegcegcc'
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          resolve(null);
        });
    });
  }

  /** 通过经纬度获取城市信息 */
  @action
  getCityByLocation(lat, lng) {
    return new Promise((resolve, reject) => {
      http
        .get(`/public/regions/geo`, {
          params: {
            lat,
            lng
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          resolve(null);
        });
    });
  }

  /** 通过名称获取城市信息 */
  @action
  getCityByRegion(region) {
    return new Promise((resolve, reject) => {
      http
        .get(`/public/regions/${region}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          resolve(null);
        });
    });
  }

  /** 获取广告 */
  @action
  getAds(keyword = 'search', region) {
    return new Promise((resolve, reject) => {
      if (this.ads?.length) {
        resolve(this.ads);
      } else {
        http.get(`/public/ads`, {
          params: {
            'api-key': 'cgecegcegcc',
            keyword,
            region
          },
          headers: {
            'accept-version': '2'
          }
        })
          .then(({ data }) => {
            this.ads = data;
            resolve(data);
          })
          .catch((error) => {
            resolve(null);
          });
      }
    });
  }

  /** 获取餐厅广告 */
  @action
  getResAds(options) {
    return new Promise((resolve, reject) => {
      if (this.resAds?.length) {
        return this.resAds
      } else {
        http.get(`/public/ads/placement`, {
          params: options,
          headers: {
            'accept-version': '2'
          }
        }).then(({ data }) => {
          resolve(data);
        }).catch((error) => {
          resolve(null);
        });
      }
    });
  }

 /** 获取优惠广告 */
  @action
  getDealAds(options) {
    return new Promise((resolve, reject) => {
      if (this.dealAds?.length) {
        return this.dealAds
      } else {
        http.get(`/public/ads/deals_placement`, {
          params: options,
          headers: {
            'accept-version': '2'
          }
        }).then(({ data }) => {
          resolve(data);
        }).catch((error) => {
          resolve(null);
        });
      }
    });
  }




  /** 获取普通广告 */
  @action
  getNormalAds(options) {
    return new Promise((resolve, reject) => {
      http.get(`/public/ads`, {
        params: {
          'api-key': 'cgecegcegcc',
          ...options
        },
        headers: {
          'accept-version': '2'
        }
      }).then(({ data }) => {
        resolve(data);
      }).catch((error) => {
        resolve(null);
      });
    });
  }
}

export default new Store();
