import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Drawer, Badge } from 'antd'
import { useStores } from '@/store'
import {
  MapView,
  Restaurant,
  Button,
  Header,
  Locate,
  Footer,
} from '@/components'
import { getQuery } from '@/service/http'
import {
  Banner,
  Profile,
  Review,
  Aside,
  Menu,
  Detail,
  Grid,
} from './components'
import { Similar } from '../restaurant/components'
import './index.less'
import GetDeal from './components/getDeal'
import Redeem from './components/redeem'
import { goLogin } from '@/service/utils';

/**
 * 优惠预订页面
 *
 * PC和移动端使用不同的布局方式
 */
const Main = () => {
  const { t } = useTranslation()
  const { dealStore, voucherStore } = useStores()
  const { dealId } = useParams()
  const [info, setInfo] = useState(null)
  const [visible, setVisible] = useState(false)
  const [getDealVisible, setGetDealVisible] = useState(false)
  const [voucherAvailableList, setVoucherAvailableList] = useState([])
  const [recommenders, setRecommenders] = useState([])
  const [redeemVisible, setRedeemVisible] = useState(false)
  const {
    payment: payState1,
    lang = 'en',
    region = 'shanghai',
    private_token,
  } = getQuery()
  document.body.classList.add('is-deal')
  useEffect(() => {
    dealStore.show(dealId).then((res) => {
      console.log(res, private_token)
      if (res?.deal?.is_need_login && !private_token) {
        goLogin();
      }
      setInfo(res)
    })
    if (payState1 && payState1.includes('-')) {
      try {
        let arrayParam = payState1.split('-')
        if(arrayParam[2]){
          window.private_token = arrayParam[2].split('=')[1]
        }
      } catch {}
    }
    dealStore.getRecommenders(dealId).then(setRecommenders)
    voucherStore.getVoucherAvailableList(dealId).then((res) => {
      const filterData = res.filter((_) => _?.applicable)
      setVoucherAvailableList(filterData)
      if (payState1 && payState1.includes('-')) {
        setGetDealVisible(payState1)
      }
      sessionStorage.setItem(`v-${dealId}`, filterData.length)
    })
  }, [dealId, dealStore, payState1, voucherStore])

  // 为防止出现在其他端已经兑换的情况 再次查询列表进行校验
  const checkRedeem = (flag = false) => {
    voucherStore.getVoucherAvailableList(dealId).then((res) => {
      const filterData = res.filter((_) => _?.applicable)
      setVoucherAvailableList(filterData)
      if (flag) {
        setRedeemVisible(true)
      }
    })
  }

  function bookNow() {
    window.location.href = dealStore.webform
  }

  function getDeal() {
    if (private_token) {
      setGetDealVisible(true)
    } else {
      goLogin();
    }
  }

  function getUrl(name) {
    try {
      let reg = new RegExp('[^?&]?' + encodeURI(name) + '=[^&]+')
      // let arr = parent.document.getElementById("reservation_webform").contentWindow.location.href.match(reg);
      let parentUrl = getParentUrl()
      let arr = parentUrl.toString().match(reg)
      //let arr =  "https://staging-website.diningcity.asia/#/deal/8531?lang=en&login_url=http%3A%2F%2Flogin.diningcitydeals.com%2Fmembers%2Fsign_in%3Flocale%3Den&private_token=&callback=http%3A%2F%2Fwww.diningcitydeals.com%2Fen%2Fdiningcity%2Fdeals%2F8531%2Freservation&payment=".match(reg);
      if (arr != null) {
        return decodeURIComponent(arr[0].substring(arr[0].search('=') + 1))
      } else {
        return ''
      }
    } catch (e) {
      console.log(e)
      return ''
    }
  }

  function getParentUrl() {
    let url = ''
    if (parent !== window) {
      try {
        url = parent.location.href
      } catch (e) {
        url = document.URL
      }
    }
    return url
  }

  // return <Grid  />;
  return (
    <div className='hasFooterContent'>
      <div className='page-deal'>
        <section className='page-deal--main'>
          {!info ? null : (
            <React.Fragment>
              {info.photos.length ? (
                <Banner {...info.deal} photos={info.photos} />
              ) : null}
              {info.photos.length ? <Grid photos={info.photos} /> : null}
              <Profile {...info.deal} />
              {info.menus.length ? (
                <Menu menus={info.menus} deal_price={info.deal.deal_price} />
              ) : null}
              {info.reviews.length ? (
                <Review
                  {...info.deal}
                  reviews={info.reviews}
                  deal={info.deal}
                />
              ) : null}
              <Detail {...info.deal} availabilities={info.availabilities} />
              {info.deal.term_condition && info.deal.term_condition != '' ? (
                <h3 style={{ fontSize: 15, marginBottom: 10, marginTop: 0 }}>
                  {t('WEB.Terms and conditions')}
                </h3>
              ) : null}
              {info.deal.term_condition && info.deal.term_condition != '' ? (
                <div
                  style={{
                    fontSize: 13,
                    textAlign: 'left',
                    marginBottom: '10px',
                    wordBreak: 'break-word',
                    whiteSpace: 'pre-line',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: info.deal.term_condition.replace('\n\n', '<br/>'),
                  }}
                ></div>
              ) : null}
              {info.deal.term_condition && info.deal.term_condition != '' ? (
                <div className='hairline-bottom-new'></div>
              ) : null}
              <h3 style={{ paddingTop: '10px' }}>
                {t('WEB.Restaurant introduction')}
              </h3>
              <div className='restaurant-info'>
                <MapView {...info.deal.restaurant} />
                <Restaurant restaurant={info.deal.restaurant} only={true} />
              </div>
              {recommenders.length > 0 && (
                <div style={{ paddingBottom: '50px' }}>
                  <Similar recommenders={recommenders} type='deal' />
                </div>
              )}
              <div className='page-footer' align='right'>
                {!!info.deal.can_buy_voucher && (
                  <Button
                    type='primary'
                    onClick={() => getDeal()}
                    className={'getDealClass'}
                  >
                    {t('WEB.Get deal1')}
                  </Button>
                )}
                &nbsp; &nbsp;
                <div className={'bookNowClass'}>
                  {info?.deal?.can_buy_voucher &&
                    info?.deal?.restaurant?.reservation_type === 4 &&
                    private_token && (
                      <div className='page-deal-voucher'>
                        <Badge
                          count={voucherAvailableList.length}
                          offset={[-10, 5]}
                        >
                          <Button
                            type='primary'
                            onClick={() => setRedeemVisible(true)}
                            className={'getDealClass'}
                            disabled={voucherAvailableList.length === 0}
                          >
                            {t('VOUCHERS.Redeem')}
                          </Button>
                        </Badge>
                      </div>
                    )}
                  {info.deal.can_buy_voucher &&
                  info?.deal?.restaurant?.reservation_type !== 4 &&
                  private_token ? (
                    <Button
                      type='primary'
                      onClick={bookNow}
                      disabled={
                        !info.deal.can_buy_voucher ||
                        !voucherAvailableList ||
                        voucherAvailableList.length == 0
                      }
                    >
                      {voucherAvailableList.length > 0 ? (
                        <i className='badge small'>
                          {voucherAvailableList.length}
                        </i>
                      ) : null}
                      {t('WEB.Book now')}
                    </Button>
                  ) : info.deal?.restaurant?.booking_enabled ? (
                    <Button type='primary' onClick={bookNow}>
                      {t('WEB.Book now')}
                    </Button>
                  ) : null}
                </div>
              </div>
              {!info?.deal?.can_buy_voucher &&
                info?.deal?.restaurant?.reservation_type !== 4 && (
                  <div className='interimBtn'>
                    <Button type='primary' onClick={bookNow}>
                      {t('WEB.Book now')}
                    </Button>
                  </div>
                )}
            </React.Fragment>
          )}
          <Drawer
            className='booking-drawer deal-drawer'
            placement='right'
            closable={false}
            destroyOnClose
            onClose={() => setVisible(false)}
            visible={visible}
          >
            <Header
              title={t('WEB.Book now')}
              onClick={() => setVisible(false)}
            />
            <Aside />
          </Drawer>
          {!info ? null : (
            <GetDeal
              dealId={dealId}
              restaurant_id={info.deal.restaurant.id}
              getDealVisible={getDealVisible}
              setGetDealVisible={setGetDealVisible}
              setRedeemVisible={setRedeemVisible}
              deal={info.deal}
              checkRedeem={checkRedeem}
            />
          )}
          {!info ? null : (
            <Redeem
              dealId={dealId}
              deal={info.deal}
              redeemVisible={redeemVisible}
              setRedeemVisible={setRedeemVisible}
              voucherAvailableList={voucherAvailableList}
              checkRedeem={checkRedeem}
            />
          )}
        </section>
        {info?.deal?.can_buy_voucher &&
          info?.deal?.restaurant?.reservation_type === 4 && (
            <div className='page-deal-voucher'>
              <Button
                type='primary'
                onClick={() => getDeal()}
                className={'getDealClass'}
              >
                {t('WEB.Get deal1')}
              </Button>
              <p></p>
              <Badge count={voucherAvailableList.length} offset={[-10, 5]}>
                <Button
                  type='primary'
                  // onClick={checkRedeem}
                  onClick={() => setRedeemVisible(true)}
                  className={'getDealClass'}
                  disabled={voucherAvailableList.length === 0}
                >
                  {t('VOUCHERS.Redeem')}
                </Button>
              </Badge>
            </div>
          )}
        {!info?.deal?.can_buy_voucher &&
          info?.deal?.restaurant?.reservation_type !== 4 && (
            <div className='pc-aside'>
              <Aside />
            </div>
          )}
        <Locate />
      </div>
      <Footer
        hasMore={/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )}
      />
    </div>
  )
}

export default Main
