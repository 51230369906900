import React, { useEffect } from "react";
import MapPin from "@/images/map_pin.png";
import "./index.less";

/**
 * 地图
 *
 * 自动判定选择谷歌还是高德地图
 *
 * @param {any} locations
 * @returns
 */
const MapView = ({ region, lat, lng, showButton }) => {
  if (!region || !lat || !lng || Object.keys(region).length === 0) {
    return null;
  }
  console.log(L)
  useEffect(() => {
    let map = {};
    setTimeout(() => {
      var inChina = (region || {}).country_name === "china";

      // 高德地图
      var normal = L.layerGroup([
        L.tileLayer(
          "http://webrd0{s}.is.autonavi.com/appmaptile?lang=zh_en&size=1&scale=1&style=8&x={x}&y={y}&z={z}",
          {
            maxZoom: 18,
            minZoom: 5,
            subdomains: "1234",
          }
        ),
      ]);
      let googleMap = null;
      // 谷歌地图
      if (region && (region.country_name.toLowerCase() === "hongkong")) {
        // 谷歌地图
        googleMap = L.tileLayer.chinaProvider("Google.Normal.Map", {
          maxZoom: 18,
          minZoom: 5,
        });
      }
      else {
        googleMap = L.tileLayer(`http://{s}.google.com/vt?lyrs=m&x={x}&y={y}&z={z}&hl=${region.country_name}`, {
          maxZoom: 18,
          minZoom: 5,
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
        });
      }

      var google = L.layerGroup([googleMap]);

      map = L.map("leaflet-map", {
        center: [lat, lng],
        zoom: 15,
        layers: [inChina ? normal : google],
        zoomControl: false,
        attributionControl: false,
      });
      const position = new L.LatLng(lat, lng);
      const marker = L.marker(position, {
        icon: L.icon({
          iconSize: [28, 32],
          iconUrl: MapPin,
        }),
      });
      map.addLayer(marker);
      if (showButton) {
        L.control.zoom({
          position: 'topleft'
        }).addTo(map)
      }
    }, 10);

    return () => {
      map?.remove && map.remove();
    };
  }, []);

  return <div className="dc-map-view" id="leaflet-map"></div>;
};

export default MapView;
