import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useStores } from "@/store";
import "./index.less";
import { Loading, Restaurant } from "@/components";
import InfiniteScroll from "react-infinite-scroller";

let loading = false;
/**
 * 单个收藏下餐厅列表
 *
 */
const CollectionRestaurants = ({ collect }) => {
  const { t } = useTranslation();
  const { memberStore } = useStores();
  const [resList, setResList] = useState([])
  const [curDelIndex, setCurDelIndex] = useState(-1)
  const [showManage, setShowManage] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    loadMore()
  }, []);

  async function loadMore(page = 1) {
    if (loading) return;
    loading = true;
    try {
      let res = await memberStore.getCollectionRestaurants(collect.id, {
        page: page,
        per_page: 10,
        sort: 'featured'
      });
      if (res) {
        if (page === 1) {
          setResList(res)
        } else {
          setResList(reviews.concat(res))
        }
        if (res.length < 10) {
          setHasMore(false);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading = false;
      setIsLoaded(true)
    }
  }

  return (
    <div className="dc-collection-res">
      <div className="top">
        <img className="top-bg" src={collect.cover_url} alt="" />
        <div className="title">{collect.name}</div>
      </div>
      <InfiniteScroll
        initialLoad={false}
        pageStart={1}
        loadMore={loadMore}
        hasMore={hasMore}
        useWindow={false}
      >
        <div className="res-list">
          {
            resList.map((item, index) => (
              <div style={{ overflow: 'hidden' }} key={item.id}>
                <div className={`res-item ${curDelIndex === index ? 'show-delete' : ''}`}>
                  <div className={`res-inner ${item.hide ? 'hide' : ''}`}>
                    <div className={`select-box ${showManage ? 'active' : ''}`}>
                      <div className="select">
                        <div className="select-inner {{item.selected ? 'checked' : ''}}"></div>
                      </div>
                    </div>
                    <div className="res-box">
                      <Restaurant restaurant={item.target} />
                    </div>
                  </div>
                  <div className="res-delete">
                    <img src="https://library.diningcity.cn/images/mini_program/collection-trash-icon.png" />
                    <div>{t("Profile.collection-del")}</div>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
        <div className="load-more" style={{ textAlign: 'center' }}>
          {hasMore && <Loading red></Loading>}
        </div>
      </InfiniteScroll>

      {
        !resList.length && isLoaded ? (
          <div className="no-res">
            <img className="no-res-img" src="https://library.diningcity.cn/images/mini_program/collection-nores.png" mode="scaleToFill" lazy-load="false" />
            <div className="text">{t("Profile.collection-notip-1")}</div>
            <div className="text">{t("Profile.collection-notip-2")}</div>
          </div>
        ) : null
      }
      {/* <view class="manage-box" wx:if="{{ resList.length }}">
        <button wx:if="{{ !!colDetail.sort && colDetail.sort.length > 0 }}" class="sort" size="mini" plain="true" @tap.stop="toggleSorting">{{ sort.name }}</button>
        <view class="manage" @tap.stop="traggleManage">
          <image wx:if="{{ !showManage }}" class="icon" src="https://library.diningcity.cn/images/mini_program/collection-manage.png" mode="scaleToFill" lazy-load="false"></image>
          <view wx:else>{{ lang.done }}</view>
        </view>
      </view>
      <view class="no-res" wx:else>
        <image class="no-res-img" src="https://library.diningcity.cn/images/mini_program/collection-nores.png" mode="scaleToFill" lazy-load="false" />
        <view class="text">{{ lang.noTip1 }}</view>
        <view class="text">{{ lang.noTip2 }}</view>
      </view> */}
    </div >
  );
};

export default CollectionRestaurants;
