import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { hot } from "react-hot-loader/root";
import { ConfigProvider } from "antd";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { Platform, Auth, HeaderBar} from "./components";
import "./index.less";
import { getQuery } from '@/service/http';
import { useStores } from "./store";
import {
  Booking,
  Deal,
  Offers,
  Restaurant,
  Vouchers,
  H5,
  Points,
  Browse,
  MyReviews,
  Profile,
  Home,
  ResCollection,
  Ctrip,
  DynamicPage
} from "./pages";
import "@/service/resize";
import qs from "qs";
import matomoinit from "./service/matoma";

function App() {
  const [isFinished, setIsFinished] = useState(false)
  const { region_id, region, regionId } = getQuery()
  const { memberStore, CommonStore } = useStores();
  const locale = memberStore.getLocale();
  useEffect(()=>{
    // 初始化matomo
    matomoinit()
  },[])
  CommonStore.getFooterDetail(region || region_id || regionId).then(()=>{
    setIsFinished(true)
    document.body.classList.add("loaded")
  })
  return (
    <Auth>
      <Router>
        <ConfigProvider locale={locale}>
          {isFinished?<div className="page-main">
          <HeaderBar />
            <Switch>
              <Route path="/points" component={Points}></Route>
              <Route path="/profile" component={Profile}></Route>
              <Route path="/home" component={Home}></Route>
              <Route path="/collection" component={ResCollection}></Route>
              <Route path="/dynamicPage" component={DynamicPage}></Route>
            </Switch>
            <main>
              <Switch>
                <Route path="/Invite-friends" component={Ctrip}></Route>
                <Route path="/ctrip-restaurant-collection" component={Ctrip}></Route>
                <Route path="/my-bookings" component={Booking}></Route>
                <Route path="/browse" component={Browse}></Route>
                <Route path="/deal/:dealId" component={Deal}></Route>
                <Route path="/deals/:dealId" component={Deal}></Route>
                <Route path="/restaurants/:id/offers" component={Offers}></Route>
                <Route path="/restaurants/:id" component={Restaurant}></Route>
                <Route path="/vouchers" component={Vouchers}></Route>
                <Route path="/my-reviews" component={MyReviews}></Route>
                <Route path="/h5/" component={H5}></Route>
              </Switch>
            </main>
            <Platform></Platform>
          </div>:<div></div>}
        </ConfigProvider>
      </Router>
    </Auth>
  );
}

async function bootstrap() {
  const WithHotReload = process.env.NODE_ENV === "production" ? App : hot(App);
  // 是否是正确的url
  let hasHash = location.href.indexOf("/#/") > -1;
  if (!hasHash) {
    let url = [
      location.protocol,
      "//",
      location.href.split("/")[2], // host
      "/#/",
      location.href.split("#/")[1], // path and query
    ].join("");
    console.log("payment redirect", url);
    if (url.indexOf("payment=") > -1) {
      location.replace(url);
    }
  }
  if (window.matchMedia) {
    setMode(window.matchMedia("(prefers-color-scheme: dark)"));
    // window
    //   .matchMedia("(prefers-color-scheme: dark)")
    //   .addEventListener("change", setMode);
  }
  document.body.classList.add(window.top === window ? "is-top" : "is-iframe");
  ReactDOM.render(<WithHotReload />, document.getElementById("root"));
}

function setMode({ matches }) {
  document.body.classList.add(matches ? "dark-mode" : "light-mode");
}

bootstrap();
