import { action, computed, observable } from 'mobx'
import http from '../service/http'
import * as mock from '../service/mock'
import { message } from 'antd'
import { reject } from 'bluebird'

class Store {
  /**预定列表 */
  @observable bookings = []
  @observable bookingsOfType = {
    'all':[],
    'upcoming':[],
    'need_pay':[],
    'past':[]
  }
  /**当前展示的预订 */
  @observable currentBooking = {}
  /** 当前展示的预定的操作按钮 */
  @observable currentBookingOperational = {}
  /**获取预订数据 */
  @action
  getBookings(query, isChange) {
    return new Promise((resolve, reject) => {
      http
        .get('/member/bookings/list', {
          params: {
            page: 1,
            per_page: 10,
            ...query
          }
        })
        .then(data => {
          if (isChange) {
            // 如果切换了booking类型，则清空数据
            this.bookingsOfType[query.type] = data.data
          } else {
            this.bookingsOfType[query.type] = this.bookingsOfType[query.type].concat(data.data)
          }
          this.bookings = Object.values(this.bookingsOfType).reduce((a, b) => a.concat(b), [])
          resolve(data)
        })
        .catch(reject)
    })
  }

  /**
   * 取消预订
   *
   * @param {*} reservationCode 预订码
   * @param {*} reservationId 订单id
   */
  @action
  cancelBooking(reservationCode, reservationId) {
    return new Promise((resolve, reject) => {
      http
        .delete(`/public/books/${reservationId}`, {
          params: {
            reservation_code: reservationCode
          }
        })
        .then(({ data }) => {
          if (data.status) {
            this.updateById(reservationId)
            resolve(data)
          } else {
            reject(data)
          }
        })
        .catch(error => {
          console.error('取消预订 失败, ', error)
          reject(error)
        })
    })
  }

  /**
   * 获取预订
   *
   * @param {*} reservationCode 预订码
   * @param {*} reservationId 订单id
   * @returns
   * @memberof Service
   */
  @action
  getBookingById(reservationCode, reservationId) {
    return new Promise((resolve, reject) => {
      http
        .get(`/public/books/${reservationId}`, {
          params: {
            reservation_code: reservationCode
          }
        })
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          console.error('获取预订信息 失败, ', error)
          reject(error)
        })
    })
  }

  /**
   * 获取预定操作按钮配置信息
   * @param {*} id
   * @returns
   */
  @action
  getOperationalById(id) {
    return new Promise((resolve, reject) => {
      http
        .get(`/public/books/${id}/operational`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(err => {
          console.error('获取预定操作配置失败', err)
          reject(err)
        })
    })
  }
  /**
   * 设置当前booking操作按钮
   * @param {*} id
   */
  @action
  setBookingOperational(id) {
    this.getOperationalById(id).then(res => {
      this.currentBookingOperational = res
    })
  }

  /**根据预订id同时修改预订列表和当前预订 */
  @action
  updateById(bookId) {
    let { reservation_code } = this.bookings.find(i => i.id === bookId)
    this.getBookingById(reservation_code, bookId)
      .then(booking => {
        let list = this.bookings.slice()
        let index = list.findIndex(i => i.id === bookId)
        list.splice(index, 1, booking)
        this.bookings = list
        this.currentBooking = booking
        return booking
      })
      .then(booking => {
        this.setBookingOperational(booking.id)
      })
  }

  /**提交申诉 */
  @action
  createAppeal(bookId, form, directly) {
    return new Promise((resolve, reject) => {
      let formdata = new FormData()
      formdata.append('description', form.description)
      for (let i = 0; i < form.fileList.length; i++) {
        let file = form.fileList[i]
        formdata.append('image', file, file.name)
      }
      http
        .post(`/public/books/${bookId}/appeal`, formdata, {
          headers: {
            'Content-Type': 'multipart/form-data;charset=UTF-8'
          }
        })
        .then(({ data }) => {
          !directly && this.updateById(bookId)
          resolve(data)
        })
        .catch(reject)
    })
  }

  /**获取申诉状态 */
  @action
  getAppeal(bookId) {
    return new Promise((resolve, reject) => {
      http
        .get(`/public/books/${bookId}/appeal`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          message.error(error.message)
          reject(error)
        })
    })
  }

  /**获取project信息 */
  @action
  getProject(project) {
    return new Promise((resolve, reject) => {
      http
        .get(`/public/project_configs/${project}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          console.error('获取project信息 失败, ', error)
          resolve(null)
        })
    })
  }
  /**删除空字符 */
  dropEmpty(data) {
    let form = {}
    Object.keys(data).forEach(key => {
      let val = data[key]
      if (val !== '' && (val !== undefined) & (val !== null)) {
        form[key] = val
      }
    })
    return form
  }

  /**生成订单核销二维码 */
  @action
  createBookingQrcode(bookingId) {
    return new Promise((resolve, reject) => {
      http
        .post(`/public/qr_codes`, {
          qr_code: {
            args: {
              book_id: bookingId
            }
          }
        })
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          resolve(null)
        })
    })
  }

  /**获取扫码状态 */
  @action
  getQrcodeStatus(key) {
    return new Promise((resolve, reject) => {
      http
        .get(`/public/qr_codes/${key}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          resolve(null)
        })
    })
  }

  /**获取核销状态 */
  @action
  getReservation(id, code) {
    return new Promise((resolve, reject) => {
      http
        .get(`/public/books/${id}?reservation_code=${code}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          resolve(null)
        })
    })
  }
  /**获取儿童隐私政策 */
  @action
  getPrivacyPolicy(restaurantId) {
    return new Promise((resolve, reject) => {
      http
        .get(`/public/restaurants/${restaurantId}/booking_conditions`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(error => {
          resolve(null)
        })
    })
  }
}

export default new Store()
