import React, { useState, useEffect, useMemo } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useStores } from "@/store";
import { getQuery } from "../../service/http"
import { Carousel, Drawer } from "antd";
import InfiniteScroll from "react-infinite-scroller";
import { Header, Loading, Footer, ShortCuts } from "@/components";
import BookingDetail from "../bookings/components/detail"
import BookingQrCode from "../bookings/components/qrCode";
import { handleDeepLink } from '@/service/utils'
import "./index.less";
let extraParams = {
  region: '',
  q: '',
  page: 1,
  size: 6
}

/**
 * 首页
 *
 */
const Home = () => {
  const { t } = useTranslation();
  const { HomeStore, BrowseStore } = useStores();
  const { region, lang = "en", q, private_token, region_id } = getQuery();
  const [curRegion, setCurRegion] = useState('');
  const [banners, setBanners] = useState([])
  const [collections, setCollections] = useState([])
  const [popUpDatas, setPopUpDatas] = useState([])
  const [todayBookings, setTodayBookings] = useState([])
  const [hasMore, setHasMore] = useState(true);
  const getGeoSuccess = (position) => {
    const { latitude, longitude } = position.coords
    BrowseStore.getCityByLocation(latitude, longitude).then(res => {
      extraParams = {
        ...extraParams,
        lang,
        q: q || '',
        region: res.key_word
      }
      setCurRegion(res.key_word)
      initData(extraParams)
    })
  }
  const isMobile = useMemo(() => {
    var userAgent = navigator.userAgent;
    var platform = {
      trident: userAgent.indexOf("Trident") > -1,
      presto: userAgent.indexOf("Presto") > -1,
      webKit: userAgent.indexOf("AppleWebKit") > -1,
      gecko: userAgent.indexOf("Gecko") > -1 && userAgent.indexOf("KHTML") == -1,
      mobile: !!userAgent.match(/AppleWebKit.*Mobile.*/),
      android:
        userAgent.indexOf("Android") > -1 || userAgent.indexOf("Linux") > -1, //android终端或者uc浏览器
      iPhone: userAgent.indexOf("iPhone") > -1, //是否为iPhone或者QQHD浏览器
      iPad: userAgent.indexOf("iPad") > -1, //是否iPad
      webApp: userAgent.indexOf("Safari") == -1, //是否web应该程序，没有头部与底部
    };
    extraParams = {
      region: '',
      q: '',
      page: 1,
      size: 6
    }
    return platform.mobile || platform.android || platform.iPhone || platform.iPad;
  }, [])
  useEffect(() => {
    if (region) {
      extraParams = {
        ...extraParams,
        lang,
        q: q || '',
        region
      }
      initData(extraParams)
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(getGeoSuccess)
    }
  }, []);

  const initData = (params) => {
    HomeStore.getHomeBanners(params, isMobile).then(setBanners)
    if (private_token) {
      HomeStore.getTodayBookings().then(res => {
        setTodayBookings(res.map(item => {
          item.formated_time_ap = `${item.formated_time}${item.formated_time.split(':')[0] >= 12 ? 'PM' : 'AM'}`
          item.isChecked = !!(item.status === 'confirmed' && item.checked_at)
          return item
        }))
      })
    }
    HomeStore.getHomeAdvPopup(params).then(res => {
      if (private_token) {
        setPopUpDatas(res.filter(item => item.view_types === 'logged_in'))
      } else {
        setPopUpDatas(res.filter(item => item.view_types === 'logged_out'))
      }
    })
    getHomeCollections(params)
  }

  const getHomeCollections = function (params) {
    HomeStore.getHomeCollections(params).then(res => {
      let totalRes = []
      if (params.page === 1) {
        totalRes = res
      } else {
        totalRes = collections.concat(res)
      }
      setCollections(totalRes)
      if (res.length < params.size) {
        setHasMore(false);
      }
    })
  }

  function loadMore(page) {
    extraParams = {
      ...extraParams,
      page
    }
    getHomeCollections(extraParams)
  }

  return (
    <div className="home-page">
      <InfiniteScroll
        className="home-page-scroll"
        initialLoad={false}
        pageStart={1}
        loadMore={loadMore}
        hasMore={hasMore}
        useWindow={false}
      >
        <section className="home-page-content">
          <Banner banners={banners} />
          <TodayBookings bookings={todayBookings} t={t} />
          {/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          ) && <ShortCuts />}
          <Guides collections={collections} t={t} curRegion={curRegion} />
          <VoucherPopUp popUpDatas={popUpDatas} />
          <BookingQrcode />
          <div className="load-more">{hasMore && <Loading red></Loading>}</div>
        </section>
      </InfiniteScroll>
      <Footer isFixed={true} />
    </div>
  )
};

const Banner = ({ banners, t }) => {
  const { region, lang = "en", private_token, callback, payment, login_url } = getQuery();
  const { HomeStore } = useStores();
  return (
    !!banners.length && <div className="banners">
      <Carousel autoplay>
        {
          banners.map(item => (
            <div key={item.id}>
              <div className="banner-item" style={{
                backgroundImage: `url(${item.image_url})`
              }} onClick={() => {
                if (item.link.includes("diningcity.allapp.link")) {
                  handleDeepLink(item?.link)
                } else {
                  window.top.location.href = item.link
                  window.top.postMessage(
                    {
                      type: 'other',
                      params: {
                        url: item.link,
                        region,
                        lang,
                      },
                    },
                    '*'
                  )
                }
              }}></div>
            </div>
          ))
        }
      </Carousel>
    </div>
  )
}

const TodayBookings = ({ bookings, t }) => {
  const { bookingsStore } = useStores();
  const [visible, setVisible] = useState(false)
  const [currentBooking, setCurrentBooking] = useState({})
  const [qrcodeVisible, setQrcodeVisible] = useState(false)

  const tapTodayBooking = (item) => {
    bookingsStore.currentBooking = item;
    setVisible(true)
  }

  const showQrcode = async (e, item) => {
    e.stopPropagation()
    if (!(item.qr_code?.qrcode_url)) {
      const qrCodeData = await bookingsStore.createBookingQrcode(item.id)
      item.qr_code = Object.assign({}, qrCodeData)
    }
    bookingsStore.currentBooking = item;
    setCurrentBooking(item)
    setQrcodeVisible(true)
  }

  return (
    bookings?.length ? <div className="today-bookings">
      {
        bookings.map(item => (
          <div
            key={item.id}
            className={`booking-item ${item.isChecked ? 'checked-in' : ''}`}
            onClick={() => tapTodayBooking(item)}
          >
            <div className="top">
              <div className="left">
                <div className="title">
                  <i className="icon icon-me-bookings black"></i>
                  {item.isChecked ? t("RESERVATION_INFO.CheckedIn") : t("RESERVATION_INFO.UnComing")}
                </div>
                <div className="tip">{item.isChecked ? t("RESERVATION_INFO.CheckedInTip") : t("RESERVATION_INFO.UnComingTip")}</div>
              </div>
              {
                item.isChecked &&
                <div className="go-detail">
                  {t("WEB.Details")}
                  <i className="icon icon-me-privacy black"></i>
                </div>
              }
              <i className="qr-code icon icon-qrcode black" onClick={(e) => showQrcode(e, item)}></i>
            </div>
            <div className="bottom">
              <div>{item.restaurant.name}</div>
              <div>{item.formated_time_ap}</div>
            </div>
          </div>
        ))
      }
      <BookingQrCode currentBooking={currentBooking} visible={qrcodeVisible} setVisible={setQrcodeVisible} />
      <Drawer
        className="booking-drawer today-drawer"
        placement="right"
        closable={false}
        destroyOnClose
        onClose={() => setVisible(false)}
        visible={visible}
      >
        <Header
          title={t("WEB.Booking details")}
          onClick={() => setVisible(false)}
        />
        <BookingDetail />
      </Drawer>
    </div > : null
  )
}

// const ShortCuts = ({ shortCuts }) => {
//   const { region, lang = "en" } = getQuery();
//   const swiper = useMemo(() => {
//     if (shortCuts?.length) {
//       let tempCuts = [...shortCuts]
//       let tempSwiper = []
//       const step = 10
//       for (let index = 0; index < tempCuts.length; index+= step) {
//         tempSwiper.push(tempCuts.slice(index, index + step))
//       }
//       return tempSwiper
//     } else {
//       return []
//     }
//   }, [shortCuts]);

//   return (
//     <div className="shortcuts">
//       <div className="shortcuts-mobile">
//         <Carousel adaptiveHeight={true} infinite={false}>
//           {
//             swiper.map((cuts, index) => (
//               <div key={index}>
//                 <div className="shortcuts-item">
//                   {
//                     cuts.map(item => (
//                       <div key={item.id} className="shortcut" onClick={() => {
//                         window.top.location.href = item.links?.web || item.links.ios;
//                         window.top.postMessage(
//                           {
//                             type: 'other',
//                             params: {
//                               url: item.links?.web || item.links.ios,
//                               region,
//                               lang,
//                             },
//                           },
//                           '*'
//                         )
//                       }}>
//                         <img src={item.icon_url} alt="" />
//                         <div className="title">{item.title}</div>
//                       </div>
//                     ))
//                   }
//                 </div>
//               </div>
//             ))
//           }
//         </Carousel>
//       </div>
//       <div className="shortcuts-pc sdsds">
//         {
//           shortCuts.map(item => (
//             <div key={item.id} className="shortcut" onClick={() => {
//               window.top.location.href = item.links?.web || item.links.ios;
//               window.top.postMessage(
//                 {
//                   type: 'other',
//                   params: {
//                     url: item.links?.web || item.links.ios,
//                     region,
//                     lang,
//                   },
//                 },
//                 '*'
//               )
//             }}>
//               <img src={item.icon_url} alt="" />
//               <div className="title">{item.title}</div>
//             </div>
//           ))
//         }
//       </div>
//     </div>
//   )
// }

const Guides = ({ collections, t, curRegion }) => {
  const { region, lang = "en", private_token, } = getQuery();
  const history = useHistory();
  const { CommonStore } = useStores();
  const { domain } = CommonStore.cityDetail;
  const clickShowMore = (item) => {
    // const { dc_website_url } = getQuery()
    if (window.top === window) {
      window.location.href = `/#/collection?gid=${item.id}&region=${curRegion || region}&lang=${lang}&private_token=${private_token}&collectionName=${item?.name}`
      history.push(`collection?gid=${item.id}&region=${curRegion || region}&lang=${lang}&private_token=${private_token}&collectionName=${item?.name}`)
    } else {
      // window.open(`${dc_website_url}/${lang}/${region}/guides/${item.id}/${item.item_type === 'deal' ? 'deals' : 'restaurants'}`, '_blank')
      // window.open(`${process.env.REACT_APP_MODE === "PRODUTION" ? "https://www.diningcity.cn" : "https://www.diningcitydeals.com"}/${lang}/${region}/guides/${item.id}/${item.item_type === 'deal' ? 'deals' : 'restaurants'}`, '_blank')
      const origin = process.env.REACT_APP_MODE === "PRODUTION" ? "https://www.diningcity.cn/" : "https://www.diningcitydeals.com/"
      window.location.href = `/#/collection?gid=${item.id}&region=${curRegion || region}&lang=${lang}&private_token=${private_token}&collectionName=${item?.name}`
      window.top.postMessage(
        {
          type: 'other',
          params: {
            url: `${domain
              ? 'https://www.' + domain + '/'
              : origin
              }${lang}/${region}/guides/${item.id}/${item.item_type === 'deal' ? 'deals' : 'restaurants'}`,
            region,
            lang,
          },
        },
        '*'
      )

    }
  }
  return (
    <div className="guides">
      {
        collections.map(item => (
          <div key={item.id} className="collections-item">
            <div className="title">
              <div className="title-text">{item.name}</div>
              {item.item_type !== 'section'
                && <div className="show-more" onClick={() => clickShowMore(item)}>
                  {t("Profile.collection-more")}
                </div>}
            </div>
            <div className="collections-list">
              {
                item.items.map(collection =>
                  <CollectionItem
                    key={collection.id}
                    collection={collection}
                    itemType={item.item_type}
                    privateToken={private_token}
                    t={t}
                  />
                )
              }
            </div>
          </div>
        ))
      }
    </div>
  )
}

const VoucherPopUp = ({ popUpDatas }) => {
  const [popUpData, setPopUpData] = useState([])

  useEffect(() => {
    setPopUpData(popUpDatas)
  }, [popUpDatas])

  const closePopup = function () {
    let data = [...popUpData]
    data.pop()
    setPopUpData(data)
  }

  return (
    popUpData?.length ?
      <div className="voucher-pop-up">
        <div className="voucher-pop-up-inner">
          <i className="icon icon-close" onClick={closePopup}></i>
          <img src={popUpData[0].image} alt="" />
        </div>
      </div> : null
  )
}

const BookingQrcode = () => {
  return (
    <div></div>
  )
}

const CollectionItem = ({ collection, itemType, t, privateToken }) => {
  const { region, lang = "en", private_token, callback, payment, login_url, source } = getQuery();
  const { CommonStore } = useStores();
  const { domain } = CommonStore.cityDetail;
  if (itemType === 'restaurant') {
    return (
      <div
        className="collection-item"
        onClick={() => {
          // window.open(collection.website_detail_url, '_blank')
          // const origin = process.env.REACT_APP_MODE === "PRODUTION" ? "https://www.diningcity.cn" : "https://www.diningcitydeals.com"
          // window.open(`${origin}/${lang}/${region}/${collection.dirname}`, '_blank')
          window.location.href = `/#/restaurants/${collection.id}?lang=${lang}&region=${region}&private_token=${private_token}&callback=${callback}&payment=${payment}&login_url=${login_url}&source=${source}`
          window.top.postMessage(
            {
              type: 'other',
              params: {
                url: collection.website_detail_url,
                region,
                lang,
              },
            },
            '*'
          )
        }}
      >
        {
          parseInt(collection.ratings_avg) == 0
            ? <div className="rating-score"><span>{t("WEB.No rating")}</span></div>
            : <div className="rating-score">
              <div>{collection.ratings_avg.toFixed(1)}</div>
              <span>Rating</span>
            </div>
        }
        <div
          className="restaurant-picture"
          style={{ backgroundImage: `url(${collection.cover.replace(/\?|$/, '-thumb?timestamp=12334')})` }}
        ></div>
        <div className="restaurant-cuisines">
          {collection.cuisines.map((cuisine) => cuisine.name).join(' · ')}
        </div>
        <div className="restaurant-name">{collection.name}</div>
        <div className="bottom">
          {!!collection.avg_price && (
            <div className="restaurant-price" title={collection.format_avg_price}>
              {t('WEB.avg price simple', {
                price: collection.format_avg_price,
              })}
            </div>
          )}
          <div className="location">
            <i className="icon icon-location"></i>
            <div className="location-name">{collection?.location?.name}</div>
          </div>
        </div>
      </div>
    )
  } else if (itemType === 'deal') {
    return (
      <div
        className="collection-item"
        onClick={() => {
          // window.open(collection.website_detail_url, '_blank')
          // const origin = process.env.REACT_APP_MODE === "PRODUTION" ? "https://www.diningcity.cn" : "https://www.diningcitydeals.com"
          // window.open(`${origin}/${lang}/${region}/deals/${collection.id}/reservation`, '_blank')
          const origin = process.env.REACT_APP_MODE === "PRODUTION" ? "https://www.diningcity.cn" : "https://www.diningcitydeals.com"
          // window.open(`${origin}/${lang}/${region}/deals/${collection.id}/reservation`, '_blank')
          window.location.href = `/#/deal/${collection.id}?lang=${lang}&region=${region}&private_token=${private_token}&callback=${callback}&payment=${payment}&login_url=${login_url}`
          window.top.postMessage(
            {
              type: 'other',
              params: {
                url: collection.website_detail_url,
                region,
                lang,
              },
            },
            '*'
          )
        }}
      >
        <div
          className="restaurant-picture"
          style={{ backgroundImage: `url(${collection.picture_url.replace(/\?|$/, '-thumb?timestamp=1234')})` }}
        ></div>
        <div className="deal-name">{collection.name}</div>
        <div className="deal-res-name">{collection.restaurant_name}</div>
        <div className="bottom">
          {!!collection.category_name && (
            <div className="deal-type">
              <div className="deal-type-inner">{collection.category_name}</div>
            </div>
          )}
          {!!collection.price_desc?.length && (
            <div className="deal-price">
              {collection.price_desc[0]}
              {collection.price_desc.length > 1 && (
                <div className="origin-price">{collection.price_desc[1]}</div>
              )}
            </div>
          )}
        </div>
      </div>
    )
  } else if (itemType === 'section') {
    return (
      <div
        className="collection-item"
        onClick={() => {
          const { region, lang = "en" } = getQuery();
          if (collection.item_type === 'deal') {
            // window.open(`${origin}/${lang}/${region}/guides/${collection.id}/deals`, '_blank')
            window.location.href = `/#/collection?gid=${collection.id}&region=${region}&lang=${lang}&private_token=${private_token}&collectionName=${collection?.name}`
            window.top.postMessage(
              {
                type: 'other',
                params: {
                  url: `${domain
                    ? 'https://www.' + domain + '/'
                    : document.referrer
                    }${lang}/${region}/guides/${collection.id}/deals`,
                  region,
                  lang,
                },
              },
              '*'
            )
          } else {
            //  when item_type is restaurant
            // window.open(`${origin}/${lang}/${region}/guides/${collection.id}/restaurants`, '_blank')
            window.location.href = `/#/collection?gid=${collection.id}&region=${region}&lang=${lang}&private_token=${private_token}&collectionName=${collection?.name}`
            window.top.postMessage(
              {
                type: 'other',
                params: {
                  url: `${domain
                    ? 'https://www.' + domain + '/'
                    : document.referrer
                    }${lang}/${region}/guides/${collection.id}/restaurants`,
                  region,
                  lang,
                },
              },
              '*'
            )
          }

        }}
      >
        <div
          className="restaurant-picture"
          style={{ backgroundImage: `url(${collection.cover_url.replace(/\?|$/, '-thumb?timestamp=1234')})` }}
        ></div>
        <div className="deal-name">{collection.name}</div>
        <div className="deal-res-name">{collection.restaurant_name}</div>
        <div className="bottom">
          {!!collection.category_name && (
            <div className="deal-type">
              <div className="deal-type-inner">{collection.category_name}</div>
            </div>
          )}
          {!!collection.price_desc?.length && (
            <div className="deal-price">
              {collection.price_desc[0]}
              {collection.price_desc.length > 1 && (
                <div className="origin-price">{collection.price_desc[1]}</div>
              )}
            </div>
          )}
        </div>
      </div>
    )
  } else {
    return (
      <div
        className="collection-item"
        onClick={() =>
        // window.open(`/#/deal/${collection.id}?private_token=${privateToken}`)
        {
          window.location.href = `/#/deal/${collection.id}?private_token=${privateToken}&region=${region}`
          window.top.postMessage(
            {
              type: 'other',
              params: {
                url: collection.website_detail_url,
                region,
                lang,
              },
            },
            '*'
          )
        }
        }
      >
        <div
          className="restaurant-picture"
          style={{ backgroundImage: `url(${collection.picture_url})` }}
        ></div>
        <div className="deal-name">{collection.name}</div>
        <div className="deal-res-name">{collection.restaurant_name}</div>
        <div className="bottom">
          {!!collection.category_name && (
            <div className="deal-type">
              <div className="deal-type-inner">{collection.category_name}</div>
            </div>
          )}
          {!!collection.price_desc?.length && (
            <div className="deal-price">
              {collection.price_desc[0]}
              {collection.price_desc.length > 1 && (
                <div className="origin-price">{collection.price_desc[1]}</div>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default Home;
