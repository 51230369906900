import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en.json";
import zhCN from "./zh-CN.json";
import zhTW from "./zh-TW.json";
import vi from "./vi.json"
import http from "../service/http";

// 获取语言环境
let { lang = "en" } = http.getQuery();

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    zh: { translation: zhCN },
    "zh-HK": { translation: zhTW },
    vi: { translation: vi },
  },
  lng: lang,
  fallbackLng: "en",
});

export default i18n;
