import React, { useState, useEffect, useMemo } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import * as Common from '@/components/common'
import { Header, Icon, Button, ListItem } from '@/components'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useStores } from '@/store'
import { Drawer, Modal, Typography,message } from 'antd'
import { useHistory, Link } from 'react-router-dom'
import { getQuery } from '@/service/http'
import { handleDeepLink } from '@/service/utils'
import Refund from '../../bookings/components/refund'

const { lang } = getQuery()

function sleep(time = 300) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), time)
  })
}

// 进出场，回调
async function animate(dir, callback) {
  if (dir === 'PUSH') {
    callback()
    await sleep(30)
    document.body.setAttribute('animate', 'push')
  } else {
    document.body.setAttribute('animate', 'pop')
    await sleep()
    callback()
    document.body.setAttribute('animate', '')
  }
}
let timer = null
/**
 * 详情
 *
 * @param {*} props
 */
const BookInfo = ({ id, group }) => {
  const { t } = useTranslation()
  const [voucher, setVoucher] = useState({})
  const [visible, setVisible] = useState(false)
  const [redeems, setRedeems] = useState([])
  const [redeemHistory, setRedeemHistory] = useState({})
  const { voucherStore, paymentStore, CommonStore } = useStores()
  const deal = useMemo(() => {
    try {
      if (voucher.detail.deal) {
        return {
          title: t('VOUCHERS.Deal'),
          value: voucher.detail.deal.name,
          icon: 'icon icon-deal',
        }
      }
      if (voucher.project_config.project_type == 'event') {
        return {
          title: t('VOUCHERS.Event'),
          value: voucher.project_config.title,
          icon: 'icon icon-event',
        }
      }
      return false
    } catch (error) {
      return false
    }
  }, [t, voucher])
  const { start_at, end_at, code, points, detail = {} } = voucher
  useEffect(() => {
    voucherStore.show(id).then((data) => {
      setVoucher(data)
    })
    voucherStore.getRedeemHistory(id).then((data) => {
      setRedeems(data)
    })
  }, [id, voucherStore])

  const [refundDetailsDrawerVisible, setRefundDetailsDrawerVisible] =
    useState(false)
  const [refundModalVisible, setRefundModalVisible] = useState(false)
  const [refundDetailsButtonVisible, setRefundDetailsButtonVisible] =
    useState(false)
  const [refundSuccessfulVisible, setRefundSuccessfulVisible] = useState(false)
  const [refundDetail, setRefundDetail] = useState({})
  const [refundResult, setRefundResult] = useState({})
  const [isShowQrcode, setIsShowQrcode] = useState(false)
  const [qrCodeDetail, serQrCodeDetail] = useState({})
  const [currentRedeemDeatil, setCurrentRedeemDeatil] = useState({})
  const [showUseNow, setShowUseNow] = useState('desktop')
  const [redeemSuccessfulVisible, setRedeemSuccessfulVisible] = useState(false)
  const [termsVisible, setTermsVisible] = useState(false)
  useEffect(() => {
    setShowUseNow(paymentStore.deviceDetect())
  }, [paymentStore])

  /**
   * Refund
   */
  function putVoucherRefund() {
    try {
      //关闭确认框
      setRefundModalVisible(false)
      //展示Details按钮
      setRefundDetailsButtonVisible(true)
      setRefundSuccessfulVisible(true)
      //隐藏Refund按钮
      voucher.can_refund = false
      //Refund
      voucherStore.putVoucherRefund(id).then((data) => {
        setRefundResult(data)
      })
      //重新刷新数据
      voucherStore.show(id).then((data) => {
        setVoucher(data)
      })
    } catch (e) {
      setRefundModalVisible(false)
    }
  }
  function onClick(data) {
    setRedeemHistory({
      redeem: data,
      offer: detail.title,
    })
    setVisible(true)
  }

  function showRefundDetailsDrawer() {
    //refund_detail
    voucherStore.getRefundDetail(id).then((data) => {
      setRefundDetail(data)
    })
    setRefundDetailsDrawerVisible(true)
  }
  const getQrcode = () => {
    voucherStore.getQrcode(voucher.code).then((data) => {
      serQrCodeDetail(data)
      setIsShowQrcode(true)
      timer = setInterval(() => {
        voucherStore.queryRedeem(data?.id).then((res) => {
          if (res?.checked_at) {
            clearInterval(timer)
            setCurrentRedeemDeatil(res)
            setRedeemSuccessfulVisible(true)
          }
        })
      }, 2000)
    })
  }
  const handlerCancel = () => {
    setIsShowQrcode(false)
    clearInterval(timer)
  }
  return (
    <div className='page-voucher-detail'>
      <HistoryDetails
        {...redeemHistory}
        visible={visible}
        onClick={setVisible}
      />
      {voucher?.can_redeem && (
        <div className='qrcode'>
          <h2>
            {redeemSuccessfulVisible ? t('VOUCHERS.Redeem successful') : group}
          </h2>
          {!redeemSuccessfulVisible && (
            <i className='icon icon-qrcode' onClick={getQrcode}></i>
          )}
        </div>
      )}
      <h3>{t('VOUCHERS.Details')}</h3>
      <ListItem title={t('VOUCHERS.Offer')} value={detail.title} />
      <ListItem
        title={t('VOUCHERS.Valid from')}
        value={moment(start_at).format('YYYY-MM-DD HH:mm:ss')}
      />
      <ListItem
        title={t('VOUCHERS.Expires on')}
        value={moment(end_at).format('YYYY-MM-DD HH:mm:ss')}
      />
      {/*<ListItem
        hidden={!detail.price}
        title={t("VOUCHERS.Price")}
        value={detail.currency + " " + detail.price}
      />*/}
      <ListItem
        hidden={!detail.value}
        title={t('VOUCHERS.Value')}
        value={detail.value}
      />
      <ListItem
        hidden={!voucher.amount_paid}
        title={t('WEB.Amount paid')}
        value={detail.currency + ' ' + detail.price}
      />
      <ListItem
        hidden={!refundResult.refund_amount}
        title={t('WEB.Refund amount')}
        value={detail.currency + ' ' + detail.price}
      />
      <ListItem
        title={t('VOUCHERS.Code')}
        value={
          <CopyToClipboard text={code} onCopy={() => message.success(t('Copied code'))}>
            <span>
              <span
                style={{
                  textTransform: 'uppercase',
                  marginRight: 10,
                }}
              >
                {code}
              </span>
              <span className='icon icon-copy'></span>
            </span>
          </CopyToClipboard>
        }
      />
      <ListItem
        title={t('VOUCHERS.Total redeems')}
        value={
          detail.points_type == 'unlimited'
            ? t('VOUCHERS.Unlimited')
            : detail.points
        }
      />
      <ListItem
        title={t('VOUCHERS.Redeems left')}
        value={
          detail.points_type == 'unlimited' ? t('VOUCHERS.Unlimited') : points
        }
      />
      {deal && (
        <ListItem
          hidden={false}
          title={<Icon text={deal.title} icon={deal.icon} />}
          value={deal.value}
        />
      )}

      <ListItem
        hidden={!detail.desc}
        title={
          <Icon
            text={t('VOUCHERS.Terms of use')}
            icon='icon icon-info selected'
          />
        }
      />
      <article className='requests-text' hidden={!detail.desc}>
        {detail.desc}
      </article>
      {detail?.brief_owners?.length > 0 && (
        <div className='eligible'>
          <h3>{t('VOUCHERS.Eligible at')}</h3>
          {detail?.brief_owners.map((item) => {
            return (
              <div className='eligible-item'>
                <div className='eligible-left'>
                  <div className='eligible-cover'>
                    <img src={item.cover+'-thumb'} alt='' />
                  </div>
                  <div className='eligible-info'>
                    <span>{item.name}</span>
                    <span>{item.sub_title}</span>
                  </div>
                </div>
                <div className='eligible-btn'>
                  <Button
                    type='primary'
                    size='small'
                    onClick={() => handleDeepLink(item?.deeplink)}
                  >
                    {t('VOUCHERS.Browse')}
                  </Button>
                </div>
              </div>
            )
          })}
        </div>
      )}
      <History list={redeems} onClick={(redeem) => onClick(redeem)}></History>
      <footer className='page-footer' align='right' style={{ bottom: '52px' }}>
        {voucher && voucher.can_refund && !refundDetailsButtonVisible ? (
          <Button onClick={() => setRefundModalVisible(true)}>
            {t('WEB.Refund')}
          </Button>
        ) : null}
        {refundDetailsButtonVisible ? (
          <Button onClick={() => showRefundDetailsDrawer()}>
            {t('WEB.Details')}
          </Button>
        ) : null}
      </footer>
      <Drawer
        className='booking-drawer'
        placement='right'
        closable={false}
        destroyOnClose
        onClose={() => setRefundDetailsDrawerVisible(false)}
        visible={refundDetailsDrawerVisible}
      >
        <Header
          title={t('WEB.CancelRefund')}
          onClick={() => setRefundDetailsDrawerVisible(false)}
        />
        <Refund payment={refundDetail || {}} />
      </Drawer>

      <RefundModal
        visible={refundModalVisible}
        showRefund={true}
        onSubmit={() => putVoucherRefund()}
        setVisible={setRefundModalVisible}
      />
      <Container
        visible={isShowQrcode}
        className='booking-drawer deal-drawer deal-prepay-action redeemVouncherModal'
        placement='bottom'
        footer={null}
        onClose={handlerCancel}
        onCancel={handlerCancel}
        height={showUseNow === 'desktop' ? '88%' : '97%'}
        showUseNow={showUseNow}
        closable={false}
      >
        {redeemSuccessfulVisible ? (
          <div className='reedem-history-details'>
            <div className='reedem-detail'>
              <ListItem
                title={
                  <Icon
                    text={t('VOUCHERS.The offer') + ':'}
                    icon={'icon icon-voucher'}
                  />
                }
              />
              <div className='reedem-detail--content strong'>
                <Typography.Paragraph ellipsis={{ rows: 2 }}>
                  {currentRedeemDeatil?.voucher?.detail?.title}
                </Typography.Paragraph>
              </div>
            </div>
            <div className='reedem-detail'>
              <ListItem
                title={
                  <Icon
                    text={t('VOUCHERS.Date and time') + ':'}
                    icon={'icon icon-time'}
                  />
                }
              />
              <div className='reedem-detail--content'>
                {currentRedeemDeatil.formated_datetime}
              </div>
            </div>
            <div className='reedem-detail'>
              <ListItem
                title={
                  <Icon
                    text={t('VOUCHERS.Venue') + ':'}
                    icon={'icon icon-shop'}
                  />
                }
              />
              <div className='reedem-detail--content'>
                {currentRedeemDeatil?.restaurant_basic_info?.localized_name}
              </div>
            </div>
            <div className='reedem-detail'>
              <ListItem
                title={
                  <Icon
                    text={t('VOUCHERS.Items') + ':'}
                    icon={'icon icon-info'}
                  />
                }
                value={
                  <strong style={{ fontSize: 22, lineHeight: 1 }}>
                    x{currentRedeemDeatil?.voucher?.detail?.points}
                  </strong>
                }
              />
            </div>
            <div style={{ textAlign: 'right', margin: '20px 20px 0 0' }}>
              <Button type='primary' onClick={handlerCancel}>
                {t('VOUCHERS.Close')}
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <header>
              <div className='header-left' onClick={handlerCancel}>
                <i className='icon icon-arrow-left'></i>
              </div>
              <h3 className='H3Class'>{t('VOUCHERS.RedeemVoucher')}</h3>
            </header>
            <p>{t('VOUCHERS.To Redeem')}</p>
            <div className='qrcode-image'>
              <img src={qrCodeDetail?.qr_code?.qrcode_url} alt='' />
            </div>
            {/* <p>
              By redeeming this voucher, you agree to the{' '}
              <a href='/'>Privacy policy</a> and the venue{' '}
              <a href='/'>Terms and Conditions</a>.
            </p> */}
            <div>
              <span
                dangerouslySetInnerHTML={{
                  __html: t('AGREE_PRIVACY_POLICY_AND_TERMS', {
                    privacyUrl: CommonStore.privacyUrl,
                  }),
                }}
              ></span>
              {/* {' '}
              <a
                href='https://www.diningcity.cn/en/pages/Redeem_T_C?embed_mode=true'
                rel='noopener noreferrer'
                target='_blank'
              >
                {t('TERMS')}
              </a> */}
              <span
                style={{ color: '#dc2224', cursor: 'pointer' }}
                onClick={() => setTermsVisible(true)}
              >
                {t('TERMS')}
              </span>
              .
            </div>
          </div>
        )}
        <Container
          visible={termsVisible}
          closable={false}
          placement='bottom'
          height={showUseNow === 'desktop' ? '88%' : '87%'}
          showUseNow={showUseNow}
          footer={
            <div style={{ textAlign: 'center' }}>
              <Common.Button
                type='primary'
                onClick={() => setTermsVisible(false)}
              >
                {t('VOUCHERS.Close')}
              </Common.Button>
            </div>
          }
          title={
            <h4 style={{ textAlign: 'center' }}>{t("TERMS")}</h4>
          }
        >
          <p
            style={{
              height: showUseNow === 'desktop' ? '310px' : '95%',
              overflow: 'auto',
            }}
            dangerouslySetInnerHTML={{
              __html: qrCodeDetail?.voucher?.detail?.desc,
            }}
          ></p>
        </Container>
        {/* {termsVisible ? (
          <Common.TermsPopup
            visible={termsVisible}
            deal_id={deal?.id}
            deal={deal}
            onBack={() => animate('POP', () => setTermsVisible(false))}
          ></Common.TermsPopup>
        ) : null} */}
      </Container>
    </div>
  )
}

/**兑换历史 */
export const History = ({ list, onClick }) => {
  const { t } = useTranslation()
  function showRestaurant(id) {
    return {
      pathname: `/restaurants/${id}`,
      search: `?lang=${lang}`,
    }
  }
  return (
    <div className='reedem-history' hidden={!list.length}>
      <h3>
        <span>{t('VOUCHERS.Redemption history')}</span>
        <span>{list.length}</span>
      </h3>
      {list.map((redeem, index) => {
        return (
          <section className='reedem-history-item' key={index}>
            <header>
              <Link to={showRestaurant(redeem.restaurant_id)} target='_blank'>
                <ListItem
                  title={
                    <Icon
                      text={
                        <span
                          style={{
                            fontSize: 13,
                            fontWeight: 'normal',
                          }}
                        >
                          {redeem.restaurant_basic_info.localized_name}
                        </span>
                      }
                      icon={'icon icon-shop'}
                    />
                  }
                  value={<Icon icon='icon icon-arrow-right' />}
                />
              </Link>
            </header>
            <div className='reedem-content'>
              <ListItem
                title={
                  <span>
                    <strong>{t('VOUCHERS.Date & time')}</strong>
                    <span
                      style={{
                        fontWeight: 'normal',
                        marginLeft: 40,
                        fontSize: 13,
                      }}
                    >
                      {redeem.formated_datetime}
                    </span>
                  </span>
                }
                value={
                  <Icon onClick={() => onClick(redeem)} icon='icon icon-eye' />
                }
              />
            </div>
            <footer hidden={!redeem.billing}>
              <ListItem
                title={
                  <Icon
                    text={t('VOUCHERS.Points')}
                    icon={'icon icon-vip-grey'}
                  />
                }
                value={redeem.billing ? '+' + redeem.billing.debit : ''}
              />
            </footer>
          </section>
        )
      })}
    </div>
  )
}

/**兑换历史详情 */
export const HistoryDetails = ({ redeem, offer, onClick, visible }) => {
  const { t } = useTranslation()
  const { paymentStore } = useStores()
  const [showUseNow, setShowUseNow] = useState('desktop')
  useEffect(() => {
    setShowUseNow(paymentStore.deviceDetect())
  }, [paymentStore])
  return (
    <Container
      placement='bottom'
      visible={visible}
      closable={false}
      onClose={() => onClick(false)}
      onCancel={() => onClick(false)}
      // height={500}
      height={showUseNow === 'desktop' ? '88%' : '87%'}
      showUseNow={showUseNow}
      footer={null}
      destroyOnClose
      className='reedem-history-drawer'
    >
      {redeem ? (
        <div className='reedem-history-details'>
          <header>
            <span
              className='icon icon-arrow-left'
              onClick={() => onClick(false)}
            ></span>
            <h3>{t('VOUCHERS.Redeem confirmation')}</h3>
            <span></span>
          </header>
          <div className='reedem-detail'>
            <ListItem
              title={
                <Icon
                  text={t('VOUCHERS.The offer') + ':'}
                  icon={'icon icon-voucher'}
                />
              }
            />
            <div className='reedem-detail--content strong'>
              <Typography.Paragraph ellipsis={{ rows: 2 }}>
                {offer}
              </Typography.Paragraph>
            </div>
          </div>
          <div className='reedem-detail'>
            <ListItem
              title={
                <Icon
                  text={t('VOUCHERS.Date and time') + ':'}
                  icon={'icon icon-time'}
                />
              }
            />
            <div className='reedem-detail--content'>
              {redeem.formated_datetime}
            </div>
          </div>
          <div className='reedem-detail'>
            <ListItem
              title={
                <Icon
                  text={t('VOUCHERS.Venue') + ':'}
                  icon={'icon icon-shop'}
                />
              }
            />
            <div className='reedem-detail--content'>
              {redeem.restaurant_basic_info.localized_name}
            </div>
          </div>
          <div className='reedem-detail'>
            <ListItem
              title={
                <Icon
                  text={t('VOUCHERS.Items') + ':'}
                  icon={'icon icon-info'}
                />
              }
              value={
                <strong style={{ fontSize: 22, lineHeight: 1 }}>
                  x{redeem.points}
                </strong>
              }
            />
          </div>
        </div>
      ) : null}
    </Container>
  )
}

const Container = (props) => {
  return props?.showUseNow === 'desktop' ? (
    <Modal {...props}>{props.children}</Modal>
  ) : (
    <Drawer {...props}>{props.children}</Drawer>
  )
}

export default BookInfo
export const RefundModal = (props) => {
  let { visible, setVisible, onSubmit, showRefund } = props
  const { t } = useTranslation()
  return (
    <Modal
      visible={visible}
      wrapClassName='dc-modal'
      width={300}
      footer={null}
      closable={false}
      keyboard={false}
      maskClosable={false}
      centered
      destroyOnClose
    >
      <p
        style={{ padding: '40px 40px 0 40px', fontSize: 18 }}
        dangerouslySetInnerHTML={{
          __html: showRefund
            ? t('WEB.SURE_TO_REFUND')
            : t('WEB.SURE_TO_REFUND'),
        }}
      ></p>
      <footer>
        <Button
          onClick={() => {
            setVisible(false)
          }}
        >
          {t('WEB.Nevermind')}
        </Button>
        <Button onClick={onSubmit} type='primary'>
          {t('WEB.Refund')}
        </Button>
      </footer>
    </Modal>
  )
}
