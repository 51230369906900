import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Drawer} from "antd";
import {useStores} from "@/store";
import * as Common from "@/components/common";
import "./index.less";
import { Deals } from "../Deals";
import {SpecialEvent} from "@/components";

function sleep(time = 300) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), time);
  });
}

// 进出场，回调
async function animate(dir, callback) {
  if (dir === "PUSH") {
    callback();
    await sleep(30);
    document.body.setAttribute("animate", "push");
  } else {
    document.body.setAttribute("animate", "pop");
    await sleep();
    callback();
    document.body.setAttribute("animate", "");
  }
}

/**
 * bookNow
 *
 * @param {*} props
 */
const BookNow = ({restaurant_id, bookNowVisible, setBookNowVisible, setCurrentDate, setVisible, showLaCarte = false}) => {
  let {restaurantStore,dealStore} = useStores();
  const {t} = useTranslation();
  const [deals, setDeals] = useState([]);
  const [events, setMenuEvents] = useState([]);
  const [benfits, setBenfits] = useState([]);
  useEffect(() => {
    restaurantStore.setUrl(restaurant_id);
    restaurantStore.getRestaurant().then((data) => {
      restaurantStore.restaurant = data;
      restaurantStore.getDeals().then(setDeals);
      restaurantStore.getExtrasEvents().then(setMenuEvents)
      restaurantStore.getBenfits().then(setBenfits);

    })
  }, []);
  if (bookNowVisible) {
    setTimeout(() => {
      let $scrollClassOrder = document.querySelector(`.scrollClassOrder`);
      if ($scrollClassOrder) {
        $scrollClassOrder.scrollTo({
          left: 0,
          top: 10000,
          behavior: "smooth",
        });
      }
    }, 500)
  }
  function bookNow() {
    setCurrentDate({});
    setVisible(true)
    // window.location.href = dealStore.webform;
  }
  return (<Drawer
      className="booking-drawer book-now-offer-drawer"
      placement="bottom"
      closable={false}
      onClose={() => setBookNowVisible(false)}
      visible={bookNowVisible}
      height={"75%"}
    >
      <React.Fragment>
        <div className="book-now-offer">
          <h3 className="H3Class">
            {t("WEB.Select your offer")}
          </h3>
          <div className="content scrollClassOrder">
            {
              !!showLaCarte && (
                <div onClick={() => bookNow()} style={{ marginBottom: '5px' }}>
                  <h3 className="carteClass">{t("WEB.A la carte")}</h3>
                  <div className="label-box">
                    <div className="label-inner">
                      <div className={`label-item ${restaurantStore.restaurant.discount_humanize && !(benfits && benfits.length > 0) ? 'only-one' : ''} ${!restaurantStore.restaurant.discount_humanize || !(benfits && benfits.length > 0) ? 'only-two' : ''}`}>
                        <i className="icon a_la_carte"></i>
                        {t("WEB.Any dish on menu")}
                      </div>
                      {
                        !!restaurantStore.restaurant.discount_humanize && (
                          <div className="label-item">
                            <i className="icon icon-save"></i>
                            {restaurantStore.restaurant.discount_humanize}
                          </div>
                        )
                      }
                      {
                        !!(benfits && benfits.length > 0 && restaurantStore.restaurant.discount_humanize) && (
                          <div className="label-item">
                            <i className="icon icon-vip"></i>
                            {t("WEB.VIP Benefits")}
                          </div>
                        )
                      }
                      <div className={`get-button active`}>{t("WEB.Book")}</div>
                    </div>
                  </div>
                </div>
              )
            }
            <Deals deals={deals} bookNowDeals={true} />
            <SpecialEvent events={events} bookNowEvent={true} />
          </div>
        </div>
      </React.Fragment></Drawer>
  );
};

export default BookNow;
