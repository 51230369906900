import http, { getQuery } from '@/service/http'
const regions = {
  hk: 'HK$',
  sg: 'S$',
  hongkong: 'HK$',
  singapore: 'S$',
  macau: 'MOP$',
  macao: 'MOP$',
  bangkok: '฿',
  kohsamui: '฿',
  kualalumpur: 'RM',
}

/**CDN图片压缩 */
export function slimImage(src, option = '') {
  if (!src) return ''
  let image = src.replace(/\?/g, '')
  return image + '?imageView2/0' + option + '/q/75'
}

/**不同的国家返回不同的货币单位 */
export function currency(region) {
  return regions[region] || '￥'
}

function getFront(mainStr, searchStr) {
  let foundOffset = mainStr.indexOf(searchStr);
  if (foundOffset == -1) {
    return null;
  }
  return mainStr.substring(0, foundOffset);
}

//提取查找字符串后面的所有字符
function getEnd(mainStr, searchStr) {
  let foundOffset = mainStr.indexOf(searchStr);
  if (foundOffset == -1) {
    return null;
  }
  return mainStr.substring(foundOffset + searchStr.length, mainStr.length);
}


//在字符串 searchStr 前面插入字符串 insertStr
export function insertString(mainStr, searchStr, insertStr) {
  var front = getFront(mainStr, searchStr);
  var end = getEnd(mainStr, searchStr);
  if (front != null && end != null) {
    return front + searchStr + insertStr + end;
  }
  return null;
}

// 获取特定的url

export function getUrl(name) {
  try {
    let reg = new RegExp("[^\?&]?" + encodeURI(name) + "=[^&]+");
    // let arr = parent.document.getElementById("reservation_webform").contentWindow.location.href.match(reg);
    let parentUrl = getParentUrl();
    let arr = parentUrl.toString().match(reg);
    //let arr =  "https://staging-website.diningcity.asia/#/deal/8531?lang=en&login_url=http%3A%2F%2Flogin.diningcitydeals.com%2Fmembers%2Fsign_in%3Flocale%3Den&private_token=&callback=http%3A%2F%2Fwww.diningcitydeals.com%2Fen%2Fdiningcity%2Fdeals%2F8531%2Freservation&payment=".match(reg);
    if (arr != null) {
      return decodeURIComponent(arr[0].substring(arr[0].search("=") + 1));
    } else {
      return "";
    }
  } catch (e) {
    console.log(e)
    return "";
  }
}

function getParentUrl() {
  let url = "";
  if (window.parent !== window) {
    try {
      url = window.parent.location.href;
    } catch (e) {
      url = document.URL;
    }
  }
  return url;
}


export function isMobile() {
  var userAgent = navigator.userAgent;
  var platform = {
    trident: userAgent.indexOf("Trident") > -1,
    presto: userAgent.indexOf("Presto") > -1,
    webKit: userAgent.indexOf("AppleWebKit") > -1,
    gecko: userAgent.indexOf("Gecko") > -1 && userAgent.indexOf("KHTML") == -1,
    mobile: !!userAgent.match(/AppleWebKit.*Mobile.*/),
    android:
      userAgent.indexOf("Android") > -1 || userAgent.indexOf("Linux") > -1, //android终端或者uc浏览器
    iPhone: userAgent.indexOf("iPhone") > -1, //是否为iPhone或者QQHD浏览器
    iPad: userAgent.indexOf("iPad") > -1, //是否iPad
    webApp: userAgent.indexOf("Safari") === -1, //是否web应该程序，没有头部与底部
  };
  return platform.mobile || platform.android || platform.iPhone || platform.iPad;
}

/** 处理deepLink链接 */

export function handleDeepLink(link) {
  const { lang, region, private_token, callback, payment, login_url, region_id } = getQuery()
  http
    .get('/public/deeplinks/webview_skip', {
      params: {
        link
      }
    }).then(({ data }) => {
      const { link, path, types, login_required } = data
      const url = link.replace('[private_token]', private_token);

      if (login_required && !private_token) {
        goLogin(link)
        return
      }
      if (types === 'load') {
        window.location.href = `${path.includes('?') ? path + '&' : path + '?'
          }lang=${lang}&region=${region}&private_token=${private_token}&callback=${callback}&payment=${payment}&login_url=${login_url}`
        // window.location.reload()
        window.top.postMessage(
          {
            type: 'other',
            params: {
              url: url,
              region,
              lang,
            },
          },
          '*'
        )
      }
      if (types === "webview") {
        window.top.postMessage(
          {
            type: 'other',
            params: {
              url: url,
              region: region_id || region
            },
          },
          '*'
        )
      }
      if (types === 'redirect') {
        window.top.location.href = url
      }
    }
    )
}

/** 跳转到登录 */

export function goLogin(return_url = null) {
  window.top.postMessage(
    {
      type: 'login',
      params: { return_url: return_url },
    },
    '*'
  );
}
