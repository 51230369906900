import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { slimImage } from "@/service/utils";
import { useTranslation } from "react-i18next";
import { useStores } from "@/store";
import "./index.less";

const Ctrip = () => {
  const { restaurantStore } = useStores();
  const [banner, setBanner] = useState({});
  const location = useLocation();
  const [restaurantItems, setRestaurantItems] = useState([]);
  const isCtrip = location.pathname.indexOf("ctrip-restaurant-collection") > 0;
  const id = 3779;
  useEffect(() => {
    restaurantStore.getBanner().then(setBanner);
    restaurantStore.getRestaurantItems(id).then(setRestaurantItems);
  }, []);
  const { banner_url } = banner;
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <section className="ctrip-restaurant-collection">
        <img src={banner_url} alt="" />
        <div>
          {restaurantItems.map((item) => (
            <Item item={item} isCtrip={isCtrip} key={item?.id} />
          ))}
        </div>
      </section>
    </React.Fragment>
  );
};

const Item = ({ item, isCtrip }) => {
  const { cover, name, tags, prices, region_name, ratings_avg, id } = item;
  const { t } = useTranslation();
  return (
    <div
      className="special-restaurant"
      onClick={() => {
        window.open(
          isCtrip
            ? item.detail_in_events_url + "?acc=ctrip-restaurant-collection-446fad1c-a327-4a55-96f"
            : item.detail_in_events_url,
          "_blank"
        );
      }}
    >
      <div className="special-restaurant-item">
        <div className="special-restaurant-item-image">
          <img src={slimImage(cover, "/w/148/h/100")} alt="" />
        </div>
        <div className="special-restaurant-item-content">
          <div className="special-restaurant-item-content-type">
            {tags.map((_, index) => (
              <span key={index}>
                {index !== 0 && <span style={{ margin: "0 3px", fontSize: "16px" }}>·</span>}
                {_.name}
              </span>
            ))}
          </div>
          <div className="special-restaurant-item-content-name">{name}</div>
          <div className="special-restaurant-item-content-price">
            {prices
              .filter((_) => _)
              .map((p, index) => (
                <span key={index}>{p}</span>
              ))}
          </div>
        </div>
        <div className="special-restaurant-item-rate">
          <strong>{Number.isInteger(ratings_avg) ? ratings_avg : ratings_avg.toFixed(1)}</strong>
          <strong>{t("RATING")}</strong>
        </div>
      </div>
      <div className="special-restaurant-region">
        <i className="icon icon-location"></i>
        <span className="special-restaurant-region-name">{region_name}</span>
      </div>
    </div>
  );
};

export default Ctrip;
