import React from "react";
import { useTranslation } from "react-i18next";
import { ListItem } from "@/components";
import moment from "moment";
import http, { getQuery } from "@/service/http";

/**
 * 退款状态
 *
 * 对于不同的退款状态，有不同的文案
 *
 * @param {*} props
 */
const Refund = ({ payment }) => {
  const { t } = useTranslation();
  let date = moment(payment.refund_at).format(
    getQuery().lang === "en" ? "dddd,MMMM DD" : "dddd, M月D日"
  );
  let time = moment(payment.refund_at).format("hh:mm A");

  return (
    <div className="book-result__body page-Refund" style={{padding:'10px'}}>
      {payment.status === "refunded" ? <Success /> : null}
      {payment.status === "refunding" ? <Pending /> : null}
      <ListItem title={t("WEB.Cancellation date")} value={date}></ListItem>
      <ListItem title={t("WEB.Cancellation time")} value={time}></ListItem>
      <ListItem
        title={t("WEB.Refund amount")}
        value={payment.format_price}
      ></ListItem>
      <ListItem
        title={t("WEB.Payment method")}
        value={payment.channel_humanize}
      ></ListItem>
    </div>
  );
};

/**退款成功 */
const Success = () => {
  const { t } = useTranslation();
  return (
    <div className="refund-success">
      <h2>{t("WEB.Refund successful")}</h2>
      <p className="tip">
        {t("WEB.We successfully transferred the funds to your account")}
      </p>
      <p className="tip">{t("WEB.Here are some additional details")}:</p>
    </div>
  );
};

/**退款中 */
const Pending = () => {
  const { t } = useTranslation();
  return (
    <div className="refund-pending">
      <h2>{t("WEB.Processing")}</h2>
      <p className="tip">{t("WEB.Refund_processing")}</p>
    </div>
  );
};

export default Refund;
