import { action, observable } from "mobx";
import http, {getQuery } from "../service/http";
import qs from "qs";

class PaymentStore {
  /**订单id */
  @observable orderId = "";
  /**支付方式 */
  @observable paymentMethods = [];
  /**支付数据 */
  @observable payment = {};

  /**
   * 验证卡号
   *
   */
  @action
  async valid(cardBin) {
    const { program } = getQuery();
    try {
      http.get(`/access_codes/wide_valid`, {
        params: {
          project: program,
          code_or_password: cardBin,
        },
      });
    } catch (error) {}
  }

  /**
   * 获取支付方式
   *
   */
  @action
  async getMethods() {
    if (this.paymentMethods.length) return;
    // 判断客户端类型
    let ua = navigator.userAgent.toLowerCase();
    let client = /Android|webOS|iPhone|iPod|iPad|BlackBerry/i.test(ua)
      ? "mobile"
      : "desktop";
    client =
      ua.match(/MicroMessenger/i) == "micromessenger" ? "weixin" : client;
    try {
      let { data } = await http.get(`/public/payments/available_channels`, {
        params: {
          from: client,
        },
      });
      this.paymentMethods = data;
    } catch (error) {}
  }

  /**获取支付凭证 */
  @action
  async prePayment({
    payment = {},
    payment_method,success_url
  }) {
    try {
      // if(payment_method!="upacp"){
      //   success_url = "";
      // }
      // success_url = success_url.stringReplaceAll("&"," %26")
      // success_url= success_url.replace(new RegExp('&','g'),"%26")
      let { data } = await http.post(`/public/payments/${payment.uuid}/pay`, {
        channel: payment_method,
        from: this.deviceDetect(),
        // 银联支付页面
        success_url: success_url,
      });
      this.orderId = payment.uuid;
      this.payment = data;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**使用ping++支付 */
  @action
  callPayment() {
    return new Promise((resolve) => {
        import("pingpp-js").then((pingpp) => {
        pingpp.setUrlReturnCallback(
          (err, url) => {
            if (err) return console.error(err);
            window.top.location.href = url;
          },
          [this.payment.channel]
        );
        pingpp.createPayment(this.payment, (result, err) => {
          alert("支付完成",result)
          alert("支付完成",err,JSON.stringify(err))
          console.log("支付完成", result, err);
          if (result === "success") {
            resolve('success')
            // this.getPayment(this.orderId)
            // 只有微信公众账号 wx_pub 支付成功的结果会在这里返回，其他的支付结果都会跳转到 extra 中对应的 URL。
          } else if (result === "fail") {
            // charge 不正确或者微信公众账号支付失败时会在此处返回
            console.error("fail:", err)
            resolve('fail')
          } else if (result === "cancel") {
            // 微信公众账号支付取消支付
            console.error("cancel:", err)
            resolve('cancel')
          }
        });
      });
    })
  }

  /**获取支付结果 */
  @action
  getPayment() {
    return http.get(`/public/payments/${this.orderId}`);
  }

  /**设备检测 */
  deviceDetect() {
    let ua = navigator.userAgent.toLowerCase();
    let device = "unknown";
    if (ua.match(/(iphone|ipod|android|ios)/i)) {
      device = "mobile";
      if (ua.indexOf("micromessenger") > -1) {
        device = "weixin";
      }
    } else {
      device = "desktop";
    }
    return device;
  }
}

export default new PaymentStore();
