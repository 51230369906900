import React from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";

/**使用条款 */
export const Terms = ({ visible, setVisible, text }) => {
  const { t } = useTranslation();
  return (
    <Modal
      visible={visible}
      wrapClassName="dc-modal voucher-modal"
      width={300}
      footer={null}
      closable={false}
      centered
      destroyOnClose
    >
      <header>{t("VOUCHERS.Terms of use")}</header>
      <div className="voucher-modal--content">
        <div dangerouslySetInnerHTML={{ __html: text }}></div>
        <footer>
          <span></span>
          <strong onClick={() => setVisible(false)}>
            {t("VOUCHERS.Close")}
          </strong>
        </footer>
      </div>
    </Modal>
  );
};
