import React, { useState, useEffect, useCallback } from 'react';
import { Button, Input, Modal, Col, Row, message } from 'antd';
import { getQuery } from '@/service/http';
import { useStores } from '@/store';
import { useTranslation } from 'react-i18next';
import { GlobalOutlined } from '@ant-design/icons';
import './index.less';

const Footer = (props) => {
  //   const [currentLanguage, setCurrentLanguage] = useState('');
  const { hasMore = true, isFixed = false,className } = props;
  const { t } = useTranslation();
  const {
    regionId,
    lang = 'zh',
    region_id,
    region,
    private_token,
  } = getQuery();
  const { CommonStore } = useStores();
  const [isShowLannCon, setIsShowLannCon] = useState(false);
  const [isShowTitles, setIsShowTitles] = useState(!hasMore);
  const [email, setEmail] = useState('');
  const [footerDeatil, setFooterDeatil] = useState(null);
  const [isShowError, setIsShowError] = useState(false);
  const [isShowMobile, setIsShowMobile] = useState(!isFixed);
  useEffect(() => {
    CommonStore.getFooterDetail(region || regionId || region_id).then((data) => {
      setFooterDeatil(data);
    });
  }, [CommonStore, region]);
  // const scrollHidden = useCallback(() => {
  //   setIsShowLannCon(false);
  //   document.removeEventListener('scroll', scrollHidden);
  //   if (document.querySelector('.home-page')) {
  //     document
  //       .querySelector('.home-page')
  //       .removeEventListener('scroll', scrollHidden);
  //   }
  // }, []);
  // useEffect(() => {
  //   if (isShowLannCon) {
  //     document.addEventListener('scroll', scrollHidden);
  //     if (document.querySelector('.home-page')) {
  //       document
  //         .querySelector('.home-page')
  //         .addEventListener('scroll', scrollHidden);
  //     }
  //   }
  // }, [scrollHidden, isShowLannCon]);
  const subscription = () => {
    if (
      /[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
        email
      )
    ) {
      CommonStore.newsletters({
        newsletter_subscription: {
          email: email,
          country_id: '9',
          region_id: regionId,
          locale: lang,
        },
      }).then(() => {
        setEmail('');
        message.success(t('Footer.SubscribeMessage'));
      });
      setIsShowError(false);
    } else {
      setIsShowError(true);
    }
  };
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  ) ? (
    <footer
      className={
        isFixed
          ? className+' dc-footer dc-footer-mobile isFixed'
          : className+' dc-footer dc-footer-mobile'
      }
    >
      <div className='dc-footer-content' hidden={!isShowMobile}>
        <div className='dc-footer-left'>
          <div className='titles'>
            <div>
              <strong>DiningCity</strong>
            </div>
            <div
              onClick={() => {
                window.location.href = `/#/dynamicPage?pageName=about_us&regionId=${regionId}&region=${region}&lang=${lang}&private_token=${private_token}`;
                window.top.postMessage(
                  {
                    type: 'other',
                    params: {
                      url: footerDeatil?.about_us,
                      region,
                      lang,
                    },
                  },
                  '*'
                );
              }}
              style={{ cursor: 'pointer' }}
            >
              {t('Footer.AboutUs')}
            </div>
            <div
              onClick={() => {
                window.top.location.href = footerDeatil?.careers;
                // window.location.href = `/#/dynamicPage?pageName=careers&regionId=${regionId}&region=${region}&lang=${lang}&private_token=${private_token}`;
                window.top.postMessage(
                  {
                    type: 'other',
                    params: {
                      url: footerDeatil?.careers,
                      region,
                      lang,
                    },
                  },
                  '*'
                );
              }}
              style={{ cursor: 'pointer' }}
            >
              {t('Footer.Careers')}
            </div>
          </div>
          <div
            onClick={() => {
              window.top.location.href = footerDeatil?.vip_privileges;
              // window.location.href = `/#/dynamicPage?pageName=benefit&regionId=${regionId}&region=${region}&lang=${lang}&private_token=${private_token}`;
              window.top.postMessage(
                {
                  type: 'other',
                  params: {
                    url: footerDeatil?.vip_privileges,
                    region,
                    lang,
                  },
                },
                '*'
              );
            }}
            style={{ cursor: 'pointer' }}
          >
            {t('Footer.VIPPrivileges')}
          </div>
        </div>
        <div className='dc-footer-right'>
          <div className='subscribe'>
            <div>
              <strong>{t('Footer.Newsletter')}</strong>
            </div>
            <div className='subscribe-btn'>
              <Input
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder={t('Footer.EmailAdress')}
              />
              <Button
                shape='round'
                onMouseDown={(e) => {
                  e.preventDefault();
                  subscription();
                }}
              >
                {t('Footer.Subscribe')}
              </Button>
            </div>
            {isShowError && (
              <div className='errorMessage'>{t('Footer.errorMessage')}</div>
            )}
            <span>{t('Footer.SubscribeDetail')}</span>
          </div>
        </div>
      </div>
      <div
        className='copyrightInfo'
        style={{
          marginTop: !isShowMobile ? '0px' : '10px',
        }}
      >
        <div className='dc-footer-left'>
          <div className='terms'>
            <div>
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  window.location.href = `/#/dynamicPage?pageName=terms_and_conditions&regionId=${regionId}&region=${region}&lang=${lang}&private_token=${private_token}`;
                  window.top.postMessage(
                    {
                      type: 'other',
                      params: {
                        url: footerDeatil?.terms_and_conditions,
                        region,
                        lang,
                      },
                    },
                    '*'
                  );
                }}
              >
                {t('Footer.Terms')}
              </span>
              <span style={{ margin: '0 5px' }}>·</span>
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  window.location.href = `/#/dynamicPage?pageName=privacy_policy&regionId=${regionId}&region=${region}&lang=${lang}&private_token=${private_token}`;
                  window.top.postMessage(
                    {
                      type: 'other',
                      params: {
                        url: footerDeatil?.privacy_policy,
                        region,
                        lang,
                      },
                    },
                    '*'
                  );
                }}
              >
                {t('Footer.Privacy')}
              </span>
            </div>
            <div
              onClick={() => {
                setIsShowLannCon(!isShowLannCon);
              }}
              className='globalChange'
            >
              <span>
                <GlobalOutlined />
              </span>
              {footerDeatil?.languages.find((_) => _.locale === lang)?.title}
            </div>
            {isFixed && (
              <div className='more'>
                <span
                  onClick={() => {
                    const node = document.querySelector('.filter-part');
                    if (node) {
                      if (isShowMobile) {
                        document
                          .querySelector('.filter-part')
                          .setAttribute('style', 'bottom:50px');
                      } else {
                        document
                          .querySelector('.filter-part')
                          .setAttribute('style', 'bottom:170px');
                      }
                    }
                    setIsShowMobile(!isShowMobile);
                  }}
                >
                  <icon
                    className={
                      isShowMobile
                        ? 'icon icon-expand_normal_grey isShowTitles'
                        : 'icon icon-expand_normal_grey'
                    }
                  />
                  {t('Footer.More')}
                </span>
              </div>
            )}
          </div>
        </div>
        {isShowMobile && (
          <div className='dc-footer-right'>
            <div className='dc-footer-right-copyright'>
              {footerDeatil?.copyright && (
                <div
                  className='col-xs-12 copyright'
                  dangerouslySetInnerHTML={{ __html: footerDeatil.copyright }}
                />
              )}
            </div>
          </div>
        )}
      </div>
      {isShowLannCon && (
        <div
          className='footer-mask'
          onClick={() => {
            setIsShowLannCon(false);
          }}
          onWheel={() => {
            setIsShowLannCon(false);
          }}
          onTouchMove={() => {
            setIsShowLannCon(false);
          }}
        ></div>
      )}
      <div className='langContainer' hidden={!isShowLannCon}>
        {/* <div>English(U.S)</div>
          <div>简体中文</div> */}
        {footerDeatil?.languages.map((item, index) => {
          return (
            <div
              key={index}
              onClick={() => {
                // window.top.location.href = `${document?.referrer}${item.locale}/${region}`;
                if (window.location.href.includes(`=${item.locale}&`)) return;
                window.location.href = window.location.href.replace(
                  /lang=[\w-]*&/,
                  'lang=' + item.locale + '&'
                );
                window.location.reload();
                window.top.postMessage(
                  {
                    type: 'language',
                    params: {
                      lang: item.locale,
                    },
                  },
                  '*'
                );
              }}
            >
              {item.title}
            </div>
          );
        })}
      </div>
    </footer>
  ) : (
    <footer className={hasMore ? className+' dc-footer' : className+' dc-footer-notMore dc-footer'}>
      <div className='dc-footer-content' hidden={!isShowTitles}>
        <div className='dc-footer-left'>
          <div className='titles'>
            <div>
              <strong>DiningCity</strong>
            </div>
            <div
              onClick={() => {
                window.location.href = `/#/dynamicPage?pageName=about_us&regionId=${regionId}&region=${region}&lang=${lang}&private_token=${private_token}`;
                window.top.postMessage(
                  {
                    type: 'other',
                    params: {
                      url: footerDeatil?.about_us,
                      region,
                      lang,
                    },
                  },
                  '*'
                );
              }}
              style={{ cursor: 'pointer' }}
            >
              {t('Footer.AboutUs')}
            </div>
            <div
              onClick={() => {
                window.top.location.href = footerDeatil?.careers;
                // window.location.href = `/#/dynamicPage?pageName=careers&regionId=${regionId}&region=${region}&lang=${lang}&private_token=${private_token}`;
                window.top.postMessage(
                  {
                    type: 'other',
                    params: {
                      url: footerDeatil?.careers,
                      region,
                      lang,
                    },
                  },
                  '*'
                );
              }}
              style={{ cursor: 'pointer' }}
            >
              {t('Footer.Careers')}
            </div>
          </div>
          <div
            onClick={() => {
              window.top.location.href = footerDeatil?.vip_privileges;
              // window.location.href = `/#/dynamicPage?pageName=benefit&regionId=${regionId}&region=${region}&lang=${lang}&private_token=${private_token}`;
              window.top.postMessage(
                {
                  type: 'other',
                  params: {
                    url: footerDeatil?.vip_privileges,
                    region,
                    lang,
                  },
                },
                '*'
              );
            }}
            style={{ cursor: 'pointer' }}
          >
            {t('Footer.VIPPrivileges')}
          </div>
        </div>
        <div className='dc-footer-right'>
          <div className='subscribe'>
            <div>
              <strong>{t('Footer.Newsletter')}</strong>
            </div>
            <div className='subscribe-btn'>
              <Input
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder={t('Footer.EmailAdress')}
              />
              <Button
                shape='round'
                onMouseDown={(e) => {
                  e.preventDefault();
                  subscription();
                }}
              >
                {t('Footer.Subscribe')}
              </Button>
            </div>
            {isShowError && (
              <div className='errorMessage'>{t('Footer.errorMessage')}</div>
            )}
            <span>{t('Footer.SubscribeDetail')}</span>
          </div>
        </div>
      </div>
      <div
        className='copyrightInfo'
        style={{
          borderTop: !isShowTitles
            ? 'none'
            : '1px solid rgba(181, 181, 181, 0.53)',
        }}
      >
        <div className='dc-footer-left'>
          <div className='terms'>
            <div>
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  window.location.href = `/#/dynamicPage?pageName=terms_and_conditions&regionId=${regionId}&region=${region}&lang=${lang}&private_token=${private_token}`;
                  window.top.postMessage(
                    {
                      type: 'other',
                      params: {
                        url: footerDeatil?.terms_and_conditions,
                        region,
                        lang,
                      },
                    },
                    '*'
                  );
                }}
              >
                {t('Footer.Terms')}
              </span>
              <span style={{ margin: '0 5px' }}>·</span>
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  window.location.href = `/#/dynamicPage?pageName=privacy_policy&regionId=${regionId}&region=${region}&lang=${lang}&private_token=${private_token}`;
                  window.top.postMessage(
                    {
                      type: 'other',
                      params: {
                        url: footerDeatil?.privacy_policy,
                        region,
                        lang,
                      },
                    },
                    '*'
                  );
                }}
              >
                {t('Footer.Privacy')}
              </span>
            </div>
            <div
              onClick={() => {
                setIsShowLannCon(!isShowLannCon);
              }}
              className='globalChange'
            >
              <span>
                <GlobalOutlined />
              </span>
              {footerDeatil?.languages.find((_) => _.locale === lang)?.title}
            </div>
          </div>
        </div>
        <div className='dc-footer-right'>
          <div className='dc-footer-right-copyright'>
            {footerDeatil?.copyright && (
              <div
                className='col-xs-12 copyright'
                dangerouslySetInnerHTML={{ __html: footerDeatil.copyright }}
              />
            )}
          </div>
          {hasMore && (
            <div className='more'>
              <span
                onClick={() => {
                  const node = document.querySelector('.filter-part');
                  if (node) {
                    if (isShowTitles) {
                      document
                        .querySelector('.filter-part')
                        .setAttribute('style', 'bottom:50px');
                    } else {
                      document
                        .querySelector('.filter-part')
                        .setAttribute('style', 'bottom:170px');
                    }
                  }
                  setIsShowTitles(!isShowTitles);
                }}
              >
                <icon
                  className={
                    isShowTitles
                      ? 'icon icon-expand_normal_grey isShowTitles'
                      : 'icon icon-expand_normal_grey'
                  }
                />
                {t('Footer.More')}
              </span>
            </div>
          )}
        </div>
      </div>
      {isShowLannCon && (
        <div
          className='footer-mask'
          onClick={() => {
            setIsShowLannCon(false);
          }}
          onWheel={() => {
            setIsShowLannCon(false);
          }}
          onTouchMove={() => {
            setIsShowLannCon(false);
          }}
        ></div>
      )}
      <div className='langContainer' hidden={!isShowLannCon}>
        {/* <div>English(U.S)</div>
            <div>简体中文</div> */}
        {footerDeatil?.languages.map((item, index) => {
          return (
            <div
              key={index}
              onClick={() => {
                // window.top.location.href = `${document?.referrer}${item.locale}/${region}`;
                if (window.location.href.includes(`=${item.locale}&`)) return;
                window.location.href = window.location.href.replace(
                  /lang=[\w-]*&/,
                  'lang=' + item.locale + '&'
                );
                window.location.reload();
                window.top.postMessage(
                  {
                    type: 'language',
                    params: {
                      lang: item.locale,
                    },
                  },
                  '*'
                );
              }}
            >
              {item.title}
            </div>
          );
        })}
      </div>
    </footer>
  );
};

export default Footer;
