import http from './http'

var _paq = (window._paq = window._paq || []);
_paq.push(["trackPageView"]);
_paq.push(["enableLinkTracking"]);

export default async function init() {
  const { data } = await http.get('/public/matomos', {
    params: {
      platform: 'web'
    }
  })
  _paq.push(["setTrackerUrl", data.tracker_url]);
  _paq.push(["setSiteId", data.site_id]);
  var d = document,
    g = d.createElement("script"),
    s = d.getElementsByTagName("script")[0];
  g.type = "text/javascript";
  g.async = true;
  g.src = data.base_url + "matomo.js";
  s.parentNode.insertBefore(g, s);
}