import React, { useEffect } from "react";
import MapPin from "@/images/map_pin.png";
import "./index.less";

/**
 * 地图
 *
 * 自动判定选择谷歌还是高德地图
 *
 * @param {any} locations
 * @returns
 */
const CollectionMap = ({ restaurants, region, tapMarkers }) => {
  let lat = restaurants[0] && restaurants[0].lat_lon.lat
  let lng = restaurants[0] && restaurants[0].lat_lon.lon
  if (!region || Object.keys(region).length === 0) {
    return null;
  }
  useEffect(() => {
    let map = null;
    let inChina = (region || {}).country_name === "china";
    console.log(inChina, region)
    // 高德地图
    let normal = L.layerGroup([
      L.tileLayer(
        "http://webrd0{s}.is.autonavi.com/appmaptile?lang=zh_en&size=1&scale=1&style=8&x={x}&y={y}&z={z}",
        {
          maxZoom: 18,
          minZoom: 5,
          subdomains: "1234",
        }
      ),
    ]);
    let googleMap = null;
    if (region && (region.country_name.toLowerCase() === "hongkong")) {
      // 谷歌地图
      googleMap = L.tileLayer.chinaProvider("Google.Normal.Map", {
        maxZoom: 18,
        minZoom: 5,
      });
    }
    else {
      googleMap = L.tileLayer(`http://{s}.google.com/vt?lyrs=m&x={x}&y={y}&z={z}&hl=${region.country_name}`, {
        maxZoom: 18,
        minZoom: 5,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
      });
    }
    let google = L.layerGroup([googleMap]);


    let markers = L.markerClusterGroup({
      spiderfyOnMaxZoom: false,
      showCoverageOnHover: false,
      zoomToBoundsOnClick: false,
      iconCreateFunction: function (cluster) {
        return L.divIcon({
          className: 'cluser-marker',
          html: '<b>' + cluster.getChildCount() + '</b>'
        });
      }
    });

    markers.on('clusterclick', function (a) {
      if (map.getZoom() < 18) {
        a.layer.zoomToBounds({ padding: [20, 20] });
      } else {
        const markers = a.layer.getAllChildMarkers()
        const markersIndex = markers.map(item => {
          return restaurants.findIndex(res => res.id === item.options.zIndexOffset)
        })
        tapMarkers(markersIndex)
      }
    });

    // 保存最大最小的经纬度
    let totalLatLng = {
      maxLat: -99999,
      minLat: 99999,
      maxLng: -99999,
      minLng: 99999
    }
    restaurants.forEach((item, index) => {
      let { lat, lon } = item.lat_lon
      lat = Number(lat)
      lon = Number(lon)
      if (lat && lon) {
        totalLatLng.maxLat = lat > totalLatLng.maxLat ? lat : totalLatLng.maxLat
        totalLatLng.minLat = lat < totalLatLng.minLat ? lat : totalLatLng.minLat
        totalLatLng.maxLng = lon > totalLatLng.maxLng ? lon : totalLatLng.maxLng
        totalLatLng.minLng = lon < totalLatLng.minLng ? lon : totalLatLng.minLng
      }
      const position = new L.LatLng(lat, lon);
      const marker = L.marker(position, {
        zIndexOffset: item.id,
        icon: L.icon({
          iconSize: [28, 32],
          iconUrl: MapPin,
        }),
      });
      marker.on('click', e => {
        tapMarkers([index])
      })
      markers.addLayer(marker)
    })

    const mapDom = document.getElementById('leaflet-map')
    console.log(mapDom, map)
    const distance = L.latLng(totalLatLng.maxLat, totalLatLng.maxLng).distanceTo(L.latLng(totalLatLng.minLat, totalLatLng.minLng))
    const nowScale = distance / Math.sqrt(Math.pow(mapDom.offsetHeight, 2) + Math.pow(mapDom.offsetWidth, 2))
    const nowZoom = 17 - Math.ceil(Math.log(nowScale) / Math.log(2))

    map = L.map("leaflet-map", {
      center: [(totalLatLng.minLat + totalLatLng.maxLat) / 2, (totalLatLng.minLng + totalLatLng.maxLng) / 2],
      zoom: nowZoom,
      layers: [inChina ? normal : google],
      zoomControl: false,
      attributionControl: false,
    });

    map.addLayer(markers)
    L.control.zoom({
      position: 'topleft'
    }).addTo(map)
    map.on('click', e => {
      tapMarkers([])
    })


    return () => {
      map && map.remove();
    };
  }, [restaurants]);

  return <div className="collection-map-view" id="leaflet-map"></div>;
};

export default CollectionMap;
