import React from "react";
import "./index.less";
import {useTranslation} from "react-i18next";
import {Drawer} from "antd";
import {Header,SpecialEvent} from "@/components";
import {Reviews} from "./Reviews";
import {Deals} from "./Deals";
import {Banner,Grid} from "../../../../pages/restaurant/components";

/**SearchDishes */
const MenuDetail = ({menu, visibleDetail, setVisibleDetail,detail,deals,events,reviews}) => {
    if (!menu) {
        return null;
    }
    const {t} = useTranslation();
    // const {restaurantStore} = useStores();
    // const [detail, setDetail] = useState([]);
    // const [deals, setMenuDeals] = useState([]);
    // const [events, setMenuEvents] = useState([]);
    // const [reviews, setMenuReviews] = useState([]);
    // if (undefined != menu.id) {
    //     if(visibleDetail){
    //         restaurantStore.getMenuDetail(menu.id).then(setDetail)
    //         restaurantStore.getMenuEvents(menu.id).then(setMenuEvents)
    //         restaurantStore.getMenuDeals(menu.id).then(setMenuDeals)
    //         restaurantStore.getMenuReviews(menu.id).then(setMenuReviews)
    //     }
    // }
    return (
        <Drawer
            className="booking-drawer deal-drawer detail-drawer"
            placement="right"
            closable={false}
            destroyOnClose
            onClose={() => setVisibleDetail(false)}
            visible={visibleDetail}
            style={{zIndex:1998}}
        >
                <Header title={t("WEB.Dish details")} onClick={() => setVisibleDetail(false)}/>
            <div className="page-main">
                <main>
                    {!detail ? null : (
                        <React.Fragment>
                            {detail.photos ? (
                                <Banner photos={detail.photos}/>
                            ) : null}
                            {detail.photos ? <Grid photos={detail.photos}/> : null}
                            <article className="page-deal--profile hairline-bottom">
                                <h2>{detail.name}</h2>
                                {/*<div className="profile-restaurant-name">{detail.desc}</div>*/}
                                <div className="profile-detail">
                                    {detail.popular_rank != null ?
                                      detail.popular_rank<11?
                                        detail.popular_rank>0&detail.popular_rank<4?
                                          <div className="imgs-top-info opacity04">
                                              Top{detail.popular_rank}
                                          </div>:<div className="imgs-top-info opacity410">
                                              Top{detail.popular_rank}
                                          </div>
                                        :null
                                      : ""}
                                    {menu.event_menu ? (
                                        <span className='profile-price'>
                                            <span className='eventExclusive'>
                                            {t('WEB.EventExclusive')}
                                            </span>
                                        </span>
                                    ) : (
                                        <span className='profile-price'>
                                            {detail.currency} {detail.price}
                                        </span>
                                    )}
                                </div>
                                <div className="profile-description" dangerouslySetInnerHTML={{
                                    __html: detail.desc
                                }}></div>
                            </article>
                        </React.Fragment>
                    )}
                    <SpecialEvent events={events} />
                    <Deals deals={deals} />
                    <Reviews menu={menu} />
                </main>
            </div>
        </Drawer>
    );
};
export default MenuDetail;
