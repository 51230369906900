import React, { useState, useEffect, useRef, useCallback } from "react";
import { Modal, Drawer, message, Affix } from "antd";
import qs from "qs";
import { useObserver } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  Header,
  Icon,
  Button,
  ListItem,
  Restaurant,
  MapView,
  WriteReview
} from "@/components";
import http from "@/service/http";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Appeal from "./appeal";
import Refund from "./refund";
import { useStores } from "@/store";
import { useHistory } from "react-router-dom";
import Item from "../../vouchers/components/Item";
import { Terms } from "../../vouchers";

const { lang = "en", private_token,region } = http.getQuery();
/**拼接url参数 */
export function urlBuilder(reservation_code, reservation_id) {
  let topUrl = http.getQuery().callback.split('?')[0];
  let payment = `payment=${reservation_code}___${reservation_id}`;
  return `${topUrl}?${payment}`;
}

/**
 * 我的预定 -- 详情页面
 *
 * @param {*} props
 */
const BookInfo = ({triggerType, openTabName}) => {
  const { t } = useTranslation();
  const { bookingsStore,restaurantStore,reviewStore } = useStores();
  const [visible, setVisible] = useState(false);
  const [event, setEvent] = useState("");
  const [program, setProgram] = useState("");
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [refundDrawerVisible, setRefundDrawerVisible] = useState(false);
  const [qrcodeVisible, setQrcodeVisible] = useState(false)
  const [scanned, setScanned] = useState(false)
  const [statuslngVisible, setStatuslngVisible] = useState(false);
  const [selectClick, setSelectClick] = useState(false);
  const [selected, setSelected] = useState("Details");
  const [kidsPolicy,setKidsPolicy] = useState({})
  const timer = useRef(null)
  useEffect(() => {
    let { project_config } = bookingsStore.currentBooking;
    if (project_config) {
      let { title, project_type } = project_config;
      if (project_type === "program") {
        setProgram(title);
      }
      if (project_type === "event") {
        setEvent(title);
      }
    }
    scrollClass();
    bookingsStore.getPrivacyPolicy(bookingsStore.currentBooking?.restaurant?.id).then(setKidsPolicy)
    return () => {
      clearInterval(timer.current)
    }
  }, [bookingsStore.currentBooking]);

  function scrollClass(){
    let $scrollClass = document.querySelector(`.scrollClass`);
    if($scrollClass){
      $scrollClass.onscroll = function () {
        if(!statuslngVisible){
          // setStatuslngVisible(true);
        }
        if(selectClick){
          return;
        }
        let $Details = document.querySelector(`.Details`);
        let $Voucher = document.querySelector(`.Voucher`);
        let $RESTAURANT = document.querySelector(`.RESTAURANT`);
        if($Details){
          if($Details.getBoundingClientRect().top<118){
            setSelected("Details");
          }
        }
        if($Voucher){
          if($Voucher.getBoundingClientRect().top<118){
            setSelected("Voucher");
          }
        }
        if($RESTAURANT){
          if($RESTAURANT.getBoundingClientRect().top<118){
            setSelected("RESTAURANT");
          }
        }
      }
    }
  }
  /**编辑预订 */
  async function editBooking() {
    const {
      reservation_code,
      id,
      project,
      restaurant,
    } = bookingsStore.currentBooking;
    const url =
      process.env.REACT_APP_MODE === "PRODUTION"
        ? "https://book.diningcity.asia"
        : "https://staging-book.diningcity.asia";
    const path = `${url}/#/restaurants/${restaurant.id}/reservation/edit?`;
    const private_token = window.private_token;
    const query = qs.stringify({
      reservation_id: id,
      reservation_code,
      project,
      lang,
      private_token,
    });
    location.href = path + query;
  }

  /**填写评论 */
  function onReview() {
    
  }

  /**取消预订,然后重新渲染？？ */
  async function cancelBooking(reservation_code, id) {
    setVisible(false);
    try {
      const result = await bookingsStore.cancelBooking(reservation_code, id);
      message.success(t("RESERVATION_CANCELLED"));
      const { payment } = bookingsStore.currentBooking
      const book = bookingsStore.bookingsOfType[openTabName].findIndex(item=>item.id === id)
      bookingsStore.bookingsOfType[openTabName].splice(book,1,result.book)
      if (payment && payment.price) {
        setRefundDrawerVisible(true);
      }
    } catch (error) {
      message.error(error.message);
    }
  }
  
  /**onAppeal */
  function onAppeal() {
    setDrawerVisible(true);
  }

  /**去支付 */
  function onPay() {
    // 如果需要支付，重定向页面到支付页面
    const domain = process.env.REACT_APP_MODE === 'PRODUTION' ? 'https://book.diningcity.asia' : 'https://staging-book.diningcity.asia'
    const { reservation_code, id, restaurant, deal, payment, project } = bookingsStore.currentBooking;
    const base = `${domain}/#/restaurants/${restaurant.id}/reservation`;
    let callback = urlBuilder(reservation_code, id);
    const query = qs.stringify({
      reservation_code: reservation_code,
      reservation_id: id,
      channel: payment?.channel,
      private_token: private_token,
      success_url: callback,
      deal_id: deal ? deal.id : null,
      lang: http.getQuery().lang,
      project,
      payment_id: payment?.id
    });
    window.top.location.href = `${base}/pay?${query}`;
  }
  
  return useObserver(() => {
    let { currentBooking, currentBookingOperational } = bookingsStore;
    let {
      status,
      status_lng,
      deal,
      group_booking,
      formated_date,
      formated_time,
      reservation_comment = "",
      loyalty_program_benefits = [],
      reservation_code,
      id,
      point,
      payment,
      genre,
      member
    } = currentBooking;
    let full_name
    if (!member.first_name && !member.last_name) {
      full_name = member.nickname
    } else {
      full_name = `${member.first_name} ${member.last_name}`
    }
    // let price = payment ? `${payment.currency}${payment.price}` : null;
    let price = payment ? payment.format_price : null;
    let debitPoint = point ? point.debit : null;
    let date = formated_date;
    let appealDate = formated_date;
    let time = formated_time;
    let remark = reservation_comment || "";
    let dealName = deal ? deal.name : "";
    let needRefund = payment && payment.price;
    let refundText =
      genre === "deposit" ? t("WEB.Refund amount") : t("WEB.Amount reimbursed");

    const { book_config, name: restaurant_name } = currentBooking.restaurant;
    const [terms, setTerms] = useState(false);
    const [current, setCurrent] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const [termsVisible, setTermsVisible] = useState(false);
    const [statuslngVisible, setStatuslngVisible] = useState(false);
    const [reviewVisible, setReviewVisible] = useState(false)
    const [checkBillVisible, setCheckBillVisible] = useState(false)
    const [checkBillUrl, setCheckBillUrl] = useState('')
    const [successVisible, setSuccessVisible] = useState({
      visible: false,
      tip: ''
    })
    const [messageApi, contextHolder] = message.useMessage();
    const policy = function () {
      try {
        return book_config.cancel.policy;
      } catch (error) {
        return null;
      }
    };
    
    // 自动触发相关操作
    useEffect(() => {
      // 通过判断列表中的按钮触发类型来自动打开对应操作
      if(triggerType === 'payNow') {
        onPay()
      }
      if(triggerType === 'modify') {
        editBooking()
      }
      if(triggerType === 'review' && !reviewVisible) {
        setReviewVisible(true)
      }
    }, [])
    // 自动触发相关操作
    useEffect(() => {
      if(triggerType === 'appeal' && currentBookingOperational.can_appeal) {
        // 需要再次判断can_appeal是否为true
        onAppeal()
      }
      if(triggerType === 'cancel' && currentBookingOperational.can_cancel) {
        // 需要再次判断can_cancel是否为true
        setVisible(true)
      }
    }, [currentBookingOperational])

    /**查看条款 */
    function showTerm(data) {
      setTerms(data.detail.desc);
      setTermsVisible(true);
    }

    function getName(name) {
      return t(`VOUCHERS.${name}`);
    }

    /**查看详情 */
    function onItemChange(group, item) {
        setCurrent({
            ...item,
            group,
        });
        setVisible2(true);
    }
    /* 中部导航栏 */
    const Navigate = ({setStatuslngVisible,currentBooking,setSelectClick}) => {
        const {t} = useTranslation();
        let navigates =[];
        if(currentBooking.vouchers&&currentBooking.vouchers.length>0){
            navigates = [
                {label: t("WEB.Details"), value: "Details"}, {
                    label: t("WEB.Voucher(s)"),
                    value: "Voucher"
                }, {label: t("RESTAURANT"), value: "RESTAURANT"}
            ].filter((i) => i !== null);
        }else{
            setSelectClick(true)
        }
        function scrollTo(name) {
            setSelected(name);
            // setStatuslngVisible(true);
            clearTimeout(timeOut)
            try {
                let $el2 = document.querySelector(`.scrollClass`);
                let $Details = document.querySelector(`.Details`);
                let $Voucher = document.querySelector(`.Voucher`);
                setSelectClick(true);
                if(name=="Details"){
                    $el2.scrollTo({
                        left: 0,
                        top:0,
                        behavior: "smooth",
                    });
                }else if(name=="Voucher"){
                    $el2.scrollTo({
                        left: 0,
                        top:  $Details.getBoundingClientRect().height+15,
                        behavior: "smooth",
                    });
                }else if(name=="RESTAURANT"){
                    $el2.scrollTo({
                        left: 0,
                        top:  $Details.getBoundingClientRect().height+ $Voucher.getBoundingClientRect().height+31,
                        behavior: "smooth",
                    });
                }
            } catch (error) {
                console.error(error);
            } finally {
                settimeOut(setTimeout(() => {
                    setSelectClick(false);
                }, 2000))
            }
        }
        scrollClass();
        return (
            <div className="page-restaurant--navigate textAlign detail-span">
                {navigates.map((item, index) => {
                    return (
                        <span
                            key={index}
                            data-selected={selected === item.value}
                            onClick={() => scrollTo(item.value)}
                        >
          {item.label}
        </span>
                    );
                })}
            </div>
        );
    };
    /* 获取二维码 */
    const showQrcode = async () => {
      if (!(currentBooking.qr_code?.qrcode_url)) {
        const qrCodeData = await bookingsStore.createBookingQrcode(currentBooking.id)
        bookingsStore.currentBooking.qr_code = Object.assign({}, qrCodeData)
      }
      setQrcodeVisible(true)
      timer.current = setInterval(() => {
        getQrcodeStatus()
      }, 3000);
    }

    /* 轮训扫码状态 */
    const getQrcodeStatus = async () => {
      if (currentBooking.qr_code.key) {
        let res = await bookingsStore.getQrcodeStatus(currentBooking.qr_code.key)
        if (res?.is_scanned) {
          setScanned(true)
          // 扫码成功后，轮询订单详情
          clearInterval(timer.current)
          timer.current = setInterval(() => {
            getBookingDetail()
          }, 3000);
        }
      }
    }

    /* 轮训核销状态 */
    const getBookingDetail = async () => {
      if (currentBooking.id && currentBooking.reservation_code) {
        let booking = await bookingsStore.getReservation(currentBooking.id, currentBooking.reservation_code)
        if (booking && booking.status === 'confirmed' && booking.checked_at) {
          let list = bookingsStore.bookings.slice();
          let index = list.findIndex((i) => i.id === currentBooking.id);
          list[index] = booking
          bookingsStore.currentBooking = booking
          bookingsStore.bookings = list;
          bookingsStore.setBookingOperational(booking.id)
          setQrcodeVisible(false)
          clearInterval(this.timer)
        }
      }
    }

    const onSubmitReview = (type, rid, options) => {
      setReviewVisible(false)
      reviewStore.submitReview(type, rid, options).then(async res => {
        if (res && res.status) {
          try {
            messageApi.open({
              type: 'success',
              content: res.message,
            });
            let booking = await bookingsStore.getReservation(currentBooking.id, currentBooking.reservation_code)
            // let list = bookingsStore.bookings.slice();
            // let index = list.findIndex((i) => i.id === currentBooking.id);
            // list[index] = booking
            bookingsStore.currentBooking = booking
            // bookingsStore.bookings = list;
            const book = bookingsStore.bookingsOfType[openTabName].findIndex(item=>item.id === currentBooking.id)
            bookingsStore.bookingsOfType[openTabName].splice(book,1,booking)
            bookingsStore.setBookingOperational(booking.id);
            setReviewVisible(false)
          } catch (err) {
            console.log(err)
          } finally {
            setSuccessVisible({
              visible: true,
              tip: res.message.split('\n').map(item => `<p>${item}</p>`).join('')
            })
            reviewStore.reviewInit()
          }
          message.success(t("REVIEW.Saved Successfully"));
        }
      })
    }
    return (
      <div className="page-booking-detail">
        {contextHolder}
        <Terms
          visible={termsVisible}
          setVisible={setTermsVisible}
          text={terms}
        />
        <h2 style={{ display: statuslngVisible ? "none" : "" }}>
          { (currentBooking.status === 'confirmed' && currentBooking.checked_at) ? t("RESERVATION_INFO.CheckedIn") : status_lng }
          {
            !!(currentBooking.status === 'confirmed' && !currentBooking.checked_at) && (
              <div className="qr-code" onClick={showQrcode}>
                <i className="icon icon-qrcode"></i>
                <div>{t("RESERVATION_INFO.QRCode")}</div>
              </div>
            )
          }
        </h2>
        {currentBooking.vouchers && currentBooking.vouchers.length > 0 ? (
          <Affix>
              <Navigate setStatuslngVisible={setStatuslngVisible} currentBooking={currentBooking} setSelectClick={setSelectClick}/>
          </Affix>
        ) : null}
        <div className="scrollClass">
          <section className="Details">
            <h3>
              {/* {currentBooking.orders[0]?.deeplink_url} */}
              {t('WEB.Details')}
              {currentBooking?.orders.length > 0 && (
                <a
                  href="javascript:void(0)"
                  onClick={()=>{
                    setCheckBillUrl(currentBooking.orders[0]?.deeplink_url)
                    setCheckBillVisible(true)
                  }}
                >
                  {t('RESERVATION_INFO.CheckBill')}
                </a>
              )}
            </h3>
            <ListItem
              title={
                <Icon
                  text={t("RESERVATION_INFO.Guest")}
                  icon="icon icon-profile"
                />
              }
              value={currentBooking.full_name}
            />
            <ListItem
              title={
                <Icon
                  text={t("RESERVATION_INFO.DATE")}
                  icon="icon icon-booking"
                />
              }
              value={date}
            />
            <ListItem
              title={
                <Icon text={t("RESERVATION_INFO.TIME")} icon="icon icon-time" />
              }
              value={time}
            />
            <ListItem
              title={
                <Icon
                  text={t("RESERVATION_INFO.PEOPLE")}
                  icon="icon icon-seat"
                />
              }
              value={currentBooking.reservation_seats}
            />
            <ListItem
              hidden={!currentBooking.children_seats}
              title={
                <Icon
                  text={t('RESERVATION_INFO.ChildrenIncluded')}
                  icon='icon icon-seat'
                />
              }
              value={`${currentBooking.adults_seats} ${t(
                'RESERVATION_INFO.Adult'
              )} + ${currentBooking.children_seats} ${t(
                'RESERVATION_INFO.Children'
              )}`}
            />
            <ListItem
              hidden={!currentBooking.reservation_code}
              title={
                <Icon text={t("RESERVATION_INFO.CODE")} icon="icon icon-info" />
              }
              value={currentBooking.reservation_code}
            />
            <ListItem
              hidden={genre === "no_pay" || currentBooking?.orders.length > 0}
              title={<Icon text={t("WEB.Amount paid")} icon="icon icon-info" />}
              value={price}
            />
            <ListItem
              hidden={!currentBookingOperational.show_refund}
              title={<Icon text={refundText} icon="icon icon-info" />}
              value={price}
            />
            {currentBooking.vouchers != null &&
            currentBooking.vouchers.length > 0 ? (
              <ListItem
                title={
                  <Icon text={t("WEB.Voucher(s)")} icon="icon icon-voucher" />
                }
                value={
                  <div>
                    <span className="vouchersSpan">
                      {currentBooking.vouchers[0].detail.title}
                    </span>
                    <span>*{currentBooking.vouchers.length}</span>
                  </div>
                }
              />
            ) : null}

            <ListItem
              hidden={!debitPoint}
              title={<Icon text={t("WEB.Points")} icon="icon icon-vip-grey" />}
              value={t("WEB.pointNumber", { points: debitPoint })}
            />
            <ListItem
              hidden={!currentBooking?.orders || currentBooking?.orders.length === 0}
              title={<Icon text={t("RESERVATION_INFO.Total Paid")} icon="icon icon-info" />}
              value={currentBooking?.format_total_amount}
            />
            {dealName && <Deal {...deal}></Deal>}
            {event && (
              <ListItem
                title={
                  <Icon
                    text={t("RESERVATION_INFO.EVENT")}
                    icon="icon icon-event"
                  />
                }
                value={event}
              />
            )}
            {program && (
              <ListItem
                title={<Icon text="Dining Program" icon="icon icon-program" />}
                value={program}
              />
            )}
            <ListItem
              hidden={!currentBooking.discount}
              title={
                <Icon text={t("RESERVATION_INFO.BS")} icon="icon icon-save" />
              }
              value={currentBooking.discount}
            />
            <ListItem
              hidden={!loyalty_program_benefits.length}
              title={
                <Icon text={t("RESERVATION_INFO.VIP")} icon="icon icon-vip" />
              }
              value={
                <span>
                  {loyalty_program_benefits.map(({ id, title }) => (
                    <div key={id}>{title}</div>
                  ))}
                </span>
              }
            />
            <React.Fragment>
              <ListItem
                hidden={!remark.length}
                title={
                  <Icon
                    text={
                      group_booking
                        ? t("GROUP_REMARK")
                        : t("RESERVATION_INFO.Requests")
                    }
                    icon="icon icon-info selected"
                  />
                }
              />
              <article className="requests-text" hidden={!remark.length}>
                {remark}
              </article>
            </React.Fragment>

            <Policy policy={policy()} />
            <Notes notes={book_config.booking_notes || []} />
            {kidsPolicy?.allow_kids && !!currentBooking.children_seats && (
              <Policy policy={kidsPolicy?.kids_policy} isKids={true} />
            )}
          </section>

          {currentBooking.vouchers && currentBooking.vouchers.length > 0 ? (
            <section className="Voucher" style={{marginTop:'10px'}}>
              <h3>{t("WEB.Voucher(s)")}</h3>
              <div className="page-vouchers--list detail-vouchers">
                {currentBooking.vouchers.map((item, index) => {
                  return (
                    <Item
                      voucher={item}
                      status={item.name}
                      key={item.id + index}
                      onSelect={() => onItemChange(getName(item.name), item)}
                      showTerm={() => showTerm(item)}
                    />
                  );
                })}
              </div>
            </section>
          ) : null}
          <section className="RESTAURANT" style={{marginTop:'10px'}}>
            {currentBooking.restaurant && (
              <BookRestaurant {...currentBooking.restaurant} />
            )}
          </section>
          {currentBooking.vouchers && currentBooking.vouchers.length > 0 ? (
            <div style={{ height: "100px" }}></div>
          ) : (
            <div style={{ height: "130px" }}></div>
          )}
        </div>
        <footer className="page-footer" align="right">
          {currentBookingOperational.can_cancel && (
            <Button onClick={() => setVisible(true)}>{t("CANCEL")}</Button>
          )}
          {currentBookingOperational.can_appeal && (
            <Button onClick={onAppeal}>{t("WEB.Appeal")}</Button>
          )}
          {currentBooking.status === "need_pay" && (
            <Button type="primary" onClick={onPay}>{t("PAYMENT.Pay now")}</Button>
          )}
          {currentBooking.can_update && (
            <Button type="primary" onClick={editBooking}>
              {t("MODIFY")}
            </Button>
          )}
          {currentBookingOperational.show_refund && (
            <Button onClick={() => setRefundDrawerVisible(true)}>
              {t("Details")}
            </Button>
          )}
          {currentBooking.can_review && (
            <Button type="primary" onClick={()=>{setReviewVisible(true)}}>
              <i className="icon icon-review"></i>
              {t("WEB.Review")}
            </Button>
          )}
        </footer>

        <Drawer
            className="booking-drawer restaurant-review-drawer"
            placement="right"
            closable={false}
            destroyOnClose
            onClose={() => setReviewVisible(false)}
            visible={reviewVisible}
          >
            <WriteReview restaurant={currentBooking.restaurant} onClose={setReviewVisible} onSubmitReview={onSubmitReview} />
          </Drawer>

          <Drawer
            className='booking-drawer booking-drawer profile-item-inner-drawer'
            placement='right'
            closable={false}
            destroyOnClose
            onClose={() => setCheckBillVisible(false)}
            visible={checkBillVisible}
          >
            <Header
              title={t('RESERVATION_INFO.Bill')}
              onClick={() => setCheckBillVisible(false)}
            />
            <iframe src={checkBillUrl} width='100%' style={{height:'calc(100% - 40px)',border:'none'}} title="checkBill"/>
          </Drawer>

        <Drawer
          className="booking-drawer appeal-drawer"
          placement="right"
          closable={false}
          destroyOnClose
          onClose={() => setDrawerVisible(false)}
          visible={drawerVisible}
        >
          <Header
            title={t("WEB.Appeal")}
            onClick={() => setDrawerVisible(false)}
          />
          <Appeal date={appealDate} id={id} />
        </Drawer>
        <Drawer
          className="booking-drawer restaurant-review-drawer"
          placement="right"
          closable={false}
          destroyOnClose
          onClose={() => setRefundDrawerVisible(false)}
          visible={refundDrawerVisible}
        >
          <Header
            title={t("WEB.CancelRefund")}
            onClick={() => setRefundDrawerVisible(false)}
          />
          <Refund payment={currentBooking.payment || {}} />
        </Drawer>
        <Drawer
          className="qrcode-drawer restaurant-review-drawer"
          placement="bottom"
          closable={false}
          destroyOnClose
          onClose={() => {setQrcodeVisible(false);clearInterval(timer.current);}}
          visible={qrcodeVisible}
        >
          <div className="qrcode-drawer-body">
            <Header
              title={t("RESERVATION_INFO.QRCodeTitle")}
              onClick={() => setQrcodeVisible(false)}
            />
            <div className="content">
              {/* <div className="res-info">
                <div className="name">
                  <i className="icon icon-rest"></i>
                  <div>{restaurant_name}</div>
                </div>
                <div className="time">
                  <i className="icon icon-time"></i>
                  {time}
                </div>
              </div> */}
              <div className="content-inner">
                <div className="res-name">
                  <i className="icon icon-rest"></i>
                  <div>{restaurant_name}</div>
                </div>
                <div className="user-info">
                  <img src={member.avatar_url} alt="" />
                  <div className="right">
                    <div className="user-name">{full_name}</div>
                    <div className="email">{member.email}</div>
                    <div className="phone">+{member.country_code} {member.mobile}</div>
                  </div>
                </div>
                <div className="info-bottom">
                  <div className="bottom-item">
                    <i className="icon icon-date"></i>
                    {currentBooking.formated_date}
                  </div>
                  <div className="bottom-item">
                    <i className="icon icon-time"></i>
                    {time}
                  </div>
                  <div className="bottom-item">
                    <i className="icon icon-seat"></i>
                    {currentBooking.reservation_seats}
                  </div>
                </div>
              </div>
              <div className="tip">{t("RESERVATION_INFO.QRCodeTip")}</div>
              <div className="success">
                {
                  scanned && (
                    <div className="success-text">
                      <i className="icon icon-success"></i>
                      {t("RESERVATION_INFO.ScanSuccessfully")}
                    </div>
                  )
                }
              </div>
              <div className="qr-code">
                <img src={currentBooking.qr_code?.qrcode_url} mode="scaleToFill" lazy-load="false" />
              </div>
            </div>
          </div>
        </Drawer>
        <CancelModal
          visible={visible}
          showRefund={currentBookingOperational.show_refund}
          onSubmit={() => cancelBooking(reservation_code, id)}
          setVisible={setVisible}
        />
      </div>
    );
  });
};
/**套餐 */
const Deal = (deal) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <React.Fragment>
      <ListItem
        title={<Icon icon="icon icon-deal" text={t("DEAL")}></Icon>}
      ></ListItem>
      <article
        className="deal-info"
        // onClick={() => window.open(`/#/deal/${deal.id}`)}
        onClick={()=>{
          // window.location.href =  `/#/deal/${deal.id}`
          window.open(`/#/deal/${deal.id}`)
          window.top.postMessage(
            {
              type: 'other',
              params: {
                url: `${document?.referrer}#/deal/${deal.id}`,
                region,
                lang,
              },
            },
            '*'
          )
        }}
      >
        <div className="deal-info--image">
          <img src={deal.picture_url} alt="" />
        </div>
        <div className="deal-info--content">
          <div className="deal-title">{deal.name}</div>
          <div className="deal-description">{deal.restaurant_name}</div>
          <div className="deal-tags">{deal.category_name}</div>
          <div className="deal-footer">
            <span className="deal-location">
              <i className="icon icon-location"></i>
              {deal.locations[0].name}
            </span>
            <span className="deal-price-desc">{deal.price_desc[0]}</span>
          </div>
        </div>
      </article>
    </React.Fragment>
  );
};

/**
 * 餐厅信息
 *
 * @returns
 */
const BookRestaurant = (restaurant = {}) => {
  const { t } = useTranslation();

  return useObserver(() => {
    const { phone, address } = restaurant;
    return (
      <section className="book-restaurant">
        {/* <ListItem
          title={<Icon text={t("WEB.Support")} icon="icon icon-phone" />}
          value={"021-3107-6100"}
        /> */}
        <h3>{t("RESTAURANT")}</h3>
        <Restaurant restaurant={restaurant} only={true} />
        <MapView {...restaurant} />
        <footer className="restaurant-address">
          <ListItem
            title={<Icon text={address} icon="icon icon-map" />}
            value={
              <CopyToClipboard text={address} onCopy={() => message.success(t("Copied address"))}>
                <span className="icon icon-copy"></span>
              </CopyToClipboard>
            }
          ></ListItem>
          <ListItem
            title={<Icon text={phone} icon="icon icon-phone" />}
            value={
              <CopyToClipboard text={phone} onCopy={() => message.success(t("Copied phone number"))}>
                <span className="icon icon-copy"></span>
              </CopyToClipboard>
            }
          ></ListItem>
        </footer>
      </section>
    );
  });
};

const Policy = ({ policy, isKids = false }) => {
  const { t } = useTranslation();
  if (!policy) return null;
  return (
    <div className="cancellation-policy">
      <ListItem
        title={
          <Icon
            text={isKids?t("RESERVATION_INFO.KidsPolicy"):t("WEB.Cancellation policy")}
            icon="icon icon-info selected"
          />
        }
      ></ListItem>
      <article>{policy}</article>
    </div>
  );
};

const Notes = ({ notes }) => {
  let list = notes || [];
  return (
    <section>
      {list.map((item, index) => {
        return (
          <div className="booking-notes" key={index}>
            <ListItem
              title={<Icon text={item.title} icon="icon icon-info" />}
            ></ListItem>
            <article>{item.content}</article>
          </div>
        );
      })}
    </section>
  );
};

/**
 * 取消预订
 *
 * @param {*} props
 * @returns
 */
export const CancelModal = (props) => {
  let { visible, setVisible, onSubmit, showRefund } = props;
  const { t } = useTranslation();
  return (
    <Modal
      visible={visible}
      wrapClassName="dc-modal"
      width={350}
      footer={null}
      closable={false}
      keyboard={false}
      maskClosable={false}
      centered
      destroyOnClose
    >
      <p
        style={{ padding: "40px 40px 0 40px", fontSize: 18 }}
        dangerouslySetInnerHTML={{
          __html: showRefund
            ? t("WEB.SURE_TO_CANCEL_WITH_PAYMENT")
            : t("WEB.SURE_TO_CANCEL"),
        }}
      ></p>
      <footer>
        <Button
          onClick={() => {
            setVisible(false);
          }}
        >
          {t("WEB.Nevermind")}
        </Button>
        <Button onClick={onSubmit} type="primary" style={{ width:300}}>
          {t("NEVERMIND_CONFIRM")}
        </Button>
      </footer>
    </Modal>
  );
};

export default BookInfo;
