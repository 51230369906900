import { action, observable, computed } from 'mobx'
import http from '../service/http'

class Store {
  /**缓存城市列表 */
  // @observable regionList = [];
  /**缓存页脚信息 */
  @observable footerDetail = {}
  @observable cityDetail = {}

  @computed
  get privacyUrl() {
    const { privacy_policy = '' } = this.footerDetail
    return privacy_policy
  }
  // 获取所有城市列表
  @action
  getAllRegions() {
    return new Promise((resolve, reject) => {
      // if (this.regionList?.length > 0) {
      //   console.log(this.regionList);
      //   return this.regionList;
      // } else {
      http
        .get('/public/regions/common', {
          headers: {
            'accept-version': '2',
          },
        })
        .then(({ data }) => {
          this.regionList = data
          resolve(data)
        })
        .catch((error) => {
          resolve(null)
        })
      // }
    })
  }
  /** 搜索地区 */
  searchRegion(keyword = '') {
    return new Promise((resolve, reject) => {
      http
        .get(`/public/regions/search`, {
          params: {
            keyword,
          },
          headers: {
            'accept-version': '2',
          },
        })
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          resolve(null)
        })
    })
  }
  /** 通过经纬度获取城市信息 */
  @action
  getCityByLocation(lat, lng) {
    return new Promise((resolve, reject) => {
      http
        .get(`/public/regions/geo`, {
          params: {
            lat,
            lng,
          },
        })
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          resolve(null)
        })
    })
  }
  /** 获取餐厅列表 */
  @action
  getBrowseRestaurants(options = {}, region) {
    return new Promise((resolve, reject) => {
      http
        .get(`/public/regions/${region}/restaurants/search`, {
          params: options,
        })
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          resolve(null)
        })
    })
  }
  /** footer详情 */
  @action
  getFooterDetail(region) {
    return new Promise((resolve, reject) => {
      if (this.footerDetail?.web_logo) {
        resolve(this.footerDetail)
      } else {
        http
          .get(`/public/regions/${region}/footer`, {
            headers: {
              'accept-version': '2',
            },
          })
          .then(({ data }) => {
            this.footerDetail = data
            resolve(data)
          })
          .catch((error) => {
            resolve(null)
          })
      }
    })
  }
  /** 订阅 */
  @action
  newsletters(options = {}) {
    return new Promise((resolve, reject) => {
      http
        .post(`/public/newsletters`, options)
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          resolve(null)
        })
    })
  }
  /** 获取页面内容 */
  getPageContent(region, pageName) {
    return new Promise((resolve, reject) => {
      http
        .get(`/public/regions/${region}/pages`, {
          params: {
            types: pageName,
          },
          headers: {
            'accept-version': '2',
          },
        })
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          resolve(null)
        })
    })
  }

  /** 通过名称获取城市信息 */
  @action
  getCityByRegion(region) {
    return new Promise((resolve, reject) => {
      if (this.cityDetail?.domain) {
        resolve(this.cityDetail)
      } else {
        http
          .get(`/public/regions/${region}`)
          .then(({ data }) => {
            this.cityDetail = data
            resolve(data)
          })
          .catch((error) => {
            resolve(null)
          })
      }
    })
  }
}

export default new Store()
