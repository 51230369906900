import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useStores } from "@/store";
import { Drawer } from "antd";
import "./index.less";
import Collections from "../collections";
import CollectionRestaurants from "../collection-res";
import { Header } from "@/components";

/**
 * 我的收藏
 *
 */
const MyCollections = () => {
  const { t } = useTranslation();
  const { memberStore } = useStores();
  const [collections, setCollections] = useState([])
  const [moreVisible, setMoreVisible] = useState(false)
  const [collectVisible, setCollectVisible] = useState(false)
  const [curCollect, setCurCollect] = useState({})

  useEffect(() => {
    memberStore.getMyCollections({
      page: 1,
      per_page: 10000
    }).then(setCollections)
  }, []);

  const tapCollectionItem = function (item) {
    setCurCollect(item)
    setCollectVisible(true)
  }

  return (
    <div className="dc-profile-collection">
      <div className="title">
        <div className="col-title">{t('Profile.collections')}</div>
        <div className="more" onClick={() => setMoreVisible(true)}>
          {t('Profile.collection-more')}
          <i className="icon icon-arrow-right"></i>
        </div>
      </div>
      <div className="col-list">
        {
          collections.map(item => (
            <div className="col-item" key={item.id} onClick={() => tapCollectionItem(item)}>
              <div className="img-box">
                <img className="col-image" src={item.cover_url} alt="" />
              </div>
              <div className="col-name">{item.name}</div>
              <div className="res-count-box">
                <div className="res-count">{item.items_count} {item.items_count > 1 ? t('Profile.collection-restaurants') : t('Profile.collection-restaurant')}</div>
              </div>
            </div>
          ))
        }
      </div>
      <Drawer
        className={`booking-drawer profile-item-drawer`}
        placement="right"
        closable={false}
        destroyOnClose
        onClose={() => setMoreVisible(false)}
        visible={moreVisible}
      >
        <Header title={t('Profile.app-name')} onClick={() => setMoreVisible(false)} />
        <Collections tapCollectionItem={tapCollectionItem}></Collections>
      </Drawer>

      <Drawer
        className={`booking-drawer profile-item-inner-drawer`}
        placement="right"
        closable={false}
        zIndex={1001}
        bodyStyle={{ paddingLeft: 0, paddingRight: 0, paddingTop: '10px' }}
        destroyOnClose
        onClose={() => setCollectVisible(false)}
        visible={collectVisible}
      >
        <Header title={curCollect.name} onClick={() => setCollectVisible(false)} />
        <CollectionRestaurants collect={curCollect}></CollectionRestaurants>
      </Drawer>
    </div>
  );
};

export default MyCollections;
