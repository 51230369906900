import React, { useState, useEffect, useMemo } from "react";
import { DeleteOutlined, PictureOutlined } from "@ant-design/icons";
import { Row, Col, message } from "antd";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components";
import { useStores } from "../../../store";

/**
 * 补充资料
 *
 * @param {*} props
 */
const Appeal = (props) => {
  const { t } = useTranslation();
  const { bookingsStore } = useStores();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [reason, setReason] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");
  const [article, setArticle] = useState({});
  const [image, setImage] = useState("");
  const maxFile = 1;

  const disabled = useMemo(() => {
    return description === null || !description.length;
  }, [description]);

  /**选择图片 */
  function onFileChange(event) {
    const { files } = event.target;
    if (!files.length) return;
    setFileList([...files]);
    event.target.value = null;
  }

  function onFileRemove(index) {
    let files = fileList.slice();
    files.splice(index, 1);
    setFileList(files);
  }

  /**onSubmit */
  async function onSubmit() {
    try {
      setLoading(true);
      await bookingsStore.createAppeal(props.id, {
        description,
        fileList,
      });
      getAppeal();
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  function getAppeal() {
    bookingsStore
      .getAppeal(props.id)
      .then((data) => {
        const { image, status, reason, description } = data || {};
        if (image && image.url) {
          setImage(image.url);
        }
        setStatus(status || "NO_SHOW");
        setReason(reason || "");
        setDescription(description || "");
      })
      .catch((error) => {});
  }

  useEffect(() => {
    let content = {};
    switch (status) {
      case "NO_SHOW":
        content = {
          title: t("WEB.Appeal no show"),
          fileTip: t("WEB.NO_SHOW_TIP"),
          titleTip: t("WEB.no_show", { date: props.date }),
          editable: true,
        };
        break;
      case "wait":
        content = {
          title: t("WEB.Reviewing"),
          fileTip: "",
          titleTip: t("WEB.appeal_wait"),
          editable: false,
        };
        break;
      case "success":
        content = {
          title: t("WEB.Approved"),
          fileTip: "",
          titleTip: t("WEB.appeal_success"),
          editable: false,
        };
        break;
      case "fail":
        content = {
          title: t("WEB.Rejected"),
          fileTip: "",
          titleTip: t("WEB.appeal_fail"),
          editable: false,
        };
        break;

      default:
        break;
    }
    setArticle(content);
  }, [status]);

  useEffect(() => {
    getAppeal();
  }, []);

  return (
    <div className="book-result__body page-appeal">
      <h2>{article.title}</h2>
      <h3 className="list-head">{t("WEB.Reason")}</h3>
      <p className="tip">{article.titleTip}</p>
      {reason && <p className="tip">{reason}</p>}
      <div className="input-wrap">
        <textarea
          value={description}
          readOnly={!article.editable}
          onChange={(event) => setDescription(event.target.value)}
        ></textarea>
      </div>
      <h3 className="list-head">{t("WEB.Attachments")}</h3>
      <p className="tip">{article.fileTip}</p>
      <Row gutter={10}>
        <Col className="gutter-row" span={6} hidden={!image}>
          <div className="upload-image">
            <img src={image} alt="" />
          </div>
        </Col>
        {fileList.map((file, index) => {
          return (
            <Col className="gutter-row" span={6} key={index}>
              <UploadImage file={file} onRemove={() => onFileRemove(index)} />
            </Col>
          );
        })}
        <Col
          className="gutter-row"
          span={6}
          hidden={image || fileList.length >= maxFile}
        >
          <div className="upload-item">
            <PictureOutlined />
            <p>{t("WEB.Upload photos")}</p>
            <input
              type="file"
              onChange={onFileChange}
              accept="image/*"
              disabled={!article.editable}
            />
          </div>
        </Col>
      </Row>
      <footer className="page-footer" align="right" hidden={!article.editable}>
        <Button
          type="primary"
          onClick={onSubmit}
          loading={loading}
          disabled={disabled}
        >
          {t("WEB.Submit")}
        </Button>
      </footer>
    </div>
  );
};

const UploadImage = (props) => {
  const [src, setSrc] = useState(props.src || "");
  useEffect(() => {
    const reader = new FileReader();
    reader.onload = function (e) {
      setSrc(e.target.result);
    };
    reader.readAsDataURL(props.file);
  }, [props.file]);
  return (
    <div className="upload-image remove">
      <img src={src} alt="" />
      <div className="remove-icon" onClick={props.onRemove}>
        <DeleteOutlined />
      </div>
    </div>
  );
};

export default Appeal;
