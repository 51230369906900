import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Icon, ListItem } from "@/components";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useStores } from "@/store";
import { Drawer, Typography, Modal,message} from "antd";
import { Link } from "react-router-dom";
import { getQuery } from "@/service/http";

const { lang } = getQuery();

/**
 * 详情
 *
 * @param {*} props
 */
const BookInfo = ({ id, group }) => {
  const { t } = useTranslation();
  const [voucher, setVoucher] = useState({});
  const [visible, setVisible] = useState(false);
  const [redeems, setRedeems] = useState([]);
  const [redeemHistory, setRedeemHistory] = useState({});
  const { voucherStore } = useStores();
  const deal = useMemo(() => {
    try {
      if (voucher.detail.deal) {
        return {
          title: t("VOUCHERS.Deal"),
          value: voucher.detail.deal.name,
          icon: "icon icon-deal",
        };
      }
      if (voucher.project_config.project_type == "event") {
        return {
          title: t("VOUCHERS.Event"),
          value: voucher.project_config.title,
          icon: "icon icon-event",
        };
      }
      return false;
    } catch (error) {
      return false;
    }
  }, [voucher]);
  const { start_at, end_at, code, points, detail = {} } = voucher;
  useEffect(() => {
    voucherStore.show(id).then((data) => {
      setVoucher(data);
    });
    voucherStore.getRedeemHistory(id).then((data) => {
      setRedeems(data);
    });
  }, [id]);

  function onClick(data) {
    setRedeemHistory({
      redeem: data,
      offer: detail.title,
    });
    setVisible(true);
  }

  return (
    <div className="page-voucher-detail">
      <HistoryDetails
        {...redeemHistory}
        visible={visible}
        onClick={setVisible}
      />
      <h2>{group}</h2>
      <h3>{t("VOUCHERS.Details")}</h3>
      <ListItem title={t("VOUCHERS.Offer")} value={detail.title} />
      <ListItem
        title={t("VOUCHERS.Valid from")}
        value={moment(start_at).format("YYYY-MM-DD HH:mm:ss")}
      />
      <ListItem
        title={t("VOUCHERS.Expires on")}
        value={moment(end_at).format("YYYY-MM-DD HH:mm:ss")}
      />
      <ListItem
        hidden={!detail.price}
        title={t("VOUCHERS.Price")}
        value={detail.currency + " " + detail.price}
      />
      <ListItem
        title={t("VOUCHERS.Code")}
        value={
          <CopyToClipboard text={code} onCopy={() => message.success(t("Copied code"))}>
            <span>
              <span
                style={{
                  textTransform: "uppercase",
                  marginRight: 10,
                }}
              >
                {code}
              </span>
              <span className="icon icon-copy"></span>
            </span>
          </CopyToClipboard>
        }
      />
      <ListItem
        title={t("VOUCHERS.Total redeems")}
        value={
          detail.points_type == "unlimited"
            ? t("VOUCHERS.Unlimited")
            : detail.points
        }
      />
      <ListItem
        title={t("VOUCHERS.Redeems left")}
        value={
          detail.points_type == "unlimited" ? t("VOUCHERS.Unlimited") : points
        }
      />
      {deal && (
        <ListItem
          hidden={false}
          title={<Icon text={deal.title} icon={deal.icon} />}
          value={deal.value}
        />
      )}

      <ListItem
        hidden={!detail.desc}
        title={
          <Icon
            text={t("VOUCHERS.Terms of use")}
            icon="icon icon-info selected"
          />
        }
      />
      <article className="requests-text" hidden={!detail.desc}>
        {detail.desc}
      </article>

      <History list={redeems} onClick={(redeem) => onClick(redeem)}></History>
    </div>
  );
};

/**兑换历史 */
export const History = ({ list, onClick }) => {
  const { t } = useTranslation();
  function showRestaurant(id) {
    return {
      pathname: `/restaurants/${id}`,
      search: `?lang=${lang}`,
    };
  }
  return (
    <div className="reedem-history" hidden={!list.length}>
      <h3>
        <span>{t("VOUCHERS.Redemption history")}</span>
        <span>{list.length}</span>
      </h3>
      {list.map((redeem, index) => {
        return (
          <section className="reedem-history-item" key={index}>
            <header>
              <Link to={showRestaurant(redeem.restaurant_id)} target="_blank">
                <ListItem
                  title={
                    <Icon
                      text={
                        <span
                          style={{
                            fontSize: 13,
                            fontWeight: "normal",
                          }}
                        >
                          {redeem.restaurant_basic_info.localized_name}
                        </span>
                      }
                      icon={"icon icon-shop"}
                    />
                  }
                  value={<Icon icon="icon icon-arrow-right" />}
                />
              </Link>
            </header>
            <div className="reedem-content">
              <ListItem
                title={
                  <span>
                    <strong>{t("VOUCHERS.Date & time")}</strong>
                    <span
                      style={{
                        fontWeight: "normal",
                        marginLeft: 40,
                        fontSize: 13,
                      }}
                    >
                      {redeem.formated_datetime}
                    </span>
                  </span>
                }
                value={
                  <Icon onClick={() => onClick(redeem)} icon="icon icon-eye" />
                }
              />
            </div>
            <footer hidden={!redeem.billing}>
              <ListItem
                title={
                  <Icon
                    text={t("VOUCHERS.Points")}
                    icon={"icon icon-vip-grey"}
                  />
                }
                value={redeem.billing ? "+" + redeem.billing.debit : ""}
              />
            </footer>
          </section>
        );
      })}
    </div>
  );
};
const Container = (props) => {
  return props?.showUseNow === 'desktop' ? (
    <Modal {...props}>{props.children}</Modal>
  ) : (
    <Drawer {...props}>{props.children}</Drawer>
  )
}
/**兑换历史详情 */
export const HistoryDetails = ({ redeem, offer, onClick, visible }) => {
  const { t } = useTranslation();
  const { paymentStore} = useStores()
  const [showUseNow, setShowUseNow] = useState('desktop')
  useEffect(() => {
    setShowUseNow(paymentStore.deviceDetect())
  }, [paymentStore])
  return (
    <Container
      placement="bottom"
      visible={visible}
      closable={false}
      onClose={() => onClick(false)}
      onCancel={() => onClick(false)}
      // height={500}
      height={showUseNow === 'desktop' ? '88%' : '87%'}
      showUseNow={showUseNow}
      footer={null}
      destroyOnClose
      className="reedem-history-drawer"
    >
      {redeem ? (
        <div className="reedem-history-details">
          <header>
            <span></span>
            <h3>{t("VOUCHERS.Redeem confirmation")}</h3>
            <span></span>
          </header>
          <div className="reedem-detail">
            <ListItem
              title={
                <Icon
                  text={t("VOUCHERS.The offer") + ":"}
                  icon={"icon icon-voucher"}
                />
              }
            />
            <div className="reedem-detail--content strong">
              <Typography.Paragraph ellipsis={{rows: 2}}>
                {offer}
              </Typography.Paragraph>
            </div>
          </div>
          <div className="reedem-detail">
            <ListItem
              title={
                <Icon
                  text={t("VOUCHERS.Date and time") + ":"}
                  icon={"icon icon-time"}
                />
              }
            />
            <div className="reedem-detail--content">
              {redeem.formated_datetime}
            </div>
          </div>
          <div className="reedem-detail">
            <ListItem
              title={
                <Icon
                  text={t("VOUCHERS.Venue") + ":"}
                  icon={"icon icon-shop"}
                />
              }
            />
            <div className="reedem-detail--content">
              {redeem.restaurant_basic_info.localized_name}
            </div>
          </div>
          <div className="reedem-detail">
            <ListItem
              title={
                <Icon
                  text={t("VOUCHERS.Items") + ":"}
                  icon={"icon icon-info"}
                />
              }
              value={
                <strong style={{ fontSize: 22, lineHeight: 1 }}>
                  x{redeem.points}
                </strong>
              }
            />
          </div>
        </div>
      ) : null}
    </Container>
  );
};

export default BookInfo;
