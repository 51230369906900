import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useStores } from "@/store";
import "./index.less";
import MyCollections from "./profile-collection";
import { Drawer } from "antd";
import { Header,Footer } from "@/components";
import AccountSettings from "./settings";
import MyBookings from "../bookings";
import MyVouchers from "../vouchers";
import MyReviews from "./my-reviews";
import MyPoints from "../points";
import { getQuery } from '@/service/http';

/**
 * 我的页面
 *
 */
const Profile = () => {
  const { t } = useTranslation();
  const { memberStore, bookingsStore,CommonStore } = useStores();
  const [curMember, setCurMember] = useState({});
  const [fullName, setFullName] = useState('');
  const [curItem, setCurItem] = useState('')
  const [itemVisible, setItemVisible] = useState(false)
  const [footerDeatil, setFooterDeatil] = useState(null);
  const {
    regionId,
    region_id,
    region,
    voucher_code,
    partner_id
  } = getQuery();
  useEffect(() => {
    CommonStore.getFooterDetail(region || regionId || region_id).then((data) => {
      setFooterDeatil(data);
    });
  }, []);
  //  检查路径是否有vouhcer_code参数 如果有直接打开优惠券弹窗
  useEffect(() => {
    if (voucher_code) {
      setItemVisible(true)
      setCurItem('vouchers')
    }
  }, [])
  // 检查路径是否有partner_id参数 如果有直接打开礼遇弹窗
  useEffect(() => {
    if (partner_id) {
      setItemVisible(true)
      setCurItem('points')
    }
  }, [])
  useEffect(() => {
    memberStore.show().then(() => {
      const member = memberStore.member
      setCurMember(member)
      if (!member.first_name && !member.last_name) {
        setFullName(member.nickname);
      } else {
        setFullName(`${member.first_name} ${member.last_name}`);
      }
    })
  }, []);

  const seeMore = () => {
    window.open(memberStore.levelData.faq_url, '_blank')
  }


  function tapInfoItem(item) {
    if(curItem === item && itemVisible)return
    if (item === 'bookings') {
      bookingsStore.bookings = []
      bookingsStore.currentBooking = {}
    }
    setCurItem(item)
    setItemVisible(true)
  }

  function updateUserInfo(params) {
    return memberStore.updateUserInfo(params).then(res => {
      if (res?.status) {
        memberStore.member = Object.assign(memberStore.member, params)
        setCurMember(memberStore.member)
      }
    })
  }

  function userDeleteSuccess() {
    setCurMember({})
    memberStore.member = {}
    let inIframe = window.top !== window.self;
    if (inIframe) {
      window.top.postMessage({
        type: 'logout',
        params: {}
      }, '*')
    }
  }

  function reLogin() {
    let inIframe = window.top !== window.self;
    if (inIframe) {
      window.top.postMessage({
        type: 'logout',
        params: {}
      }, '*')
    }
  }

  return (
    <div className="hasFooterContent">
      <div className="dc-profile">
        {
          curMember?.id ? (
            <React.Fragment>
              <div className="header-wrapper">
                <div className="header">
                  <div className="avatar">
                    <img className="avatar-img" src={curMember.avatar_url+'-thumb' || 'https://assets.diningcity.cn/profile.png-thumb'} alt="" />
                  </div>
                  <div className="name">
                    <div className="text">{fullName}</div>
                    <div className="level">
                      <i className={`icon icon-${curMember.level_text}`}></i>
                      <div className="level-text">{t(`LEVELS.${curMember.level_text}`)}</div>
                    </div>
                  </div>
                  <div className="points">
                    <div className="count">{curMember.point}</div>
                    <div className="text">{t(`Profile.points`)}</div>
                  </div>
                </div>
              </div>
              <div className="follow">
                <div className="text-item" url="/profile/userFollow?type=follow">
                  <div className="count">{curMember.followings_count}</div>
                  <div className="text">{t(`Profile.following`)}</div>
                </div>
                <div className="text-item" url="/profile/userFollow?type=follower">
                  <div className="count">{curMember.followers_count}</div>
                  <div className="text">{curMember.followers_count > 1 ? t(`Profile.followers`) : t(`Profile.follower`)}</div>
                </div>
              </div>
              <div className="space-H"></div>
              <MyCollections></MyCollections>
            </React.Fragment>
          ) : (
            <div className="login-wrapper">
              <div className="default-avatar">
                <i className="icon icon-profile-red"></i>
              </div>
              <div className="login" onClick={reLogin}>{t("Profile.login")}</div>
            </div>
          )
        }

        <div className="info-list list-inset">
          {
            curMember?.id && (
              <div className="info-list-part">
                <div className="item only" onClick={() => tapInfoItem('points')}>
                  <span className="label">{t('Profile.me-benefits')}</span>
                  <i className={"icon icon-" + curMember.level_text}></i>
                </div>
              </div>
            )
          }
          <div className="info-list-part">
            <div className="item" onClick={() => tapInfoItem('bookings')}>
              <span className="label">{t('Profile.me-bookings')}</span>
              <i className="icon icon-me-bookings"></i>
            </div>
            <div className="item" onClick={() => tapInfoItem('vouchers')}>
              <span className="label">{t('Profile.me-vouchers')}</span>
              <i className="icon icon-me-vouchers"></i>
            </div>
            <div className="item" onClick={() => tapInfoItem('reviews')}>
              <span className="label">{t('Profile.me-reviews')}</span>
              <i className="icon icon-me-reviews"></i>
            </div>
          </div>
          <div className="info-list-part">
            <div className="item" onClick={() => tapInfoItem('settings')}>
              <span className="label">{t('Profile.me-settings')}</span>
              <i className="icon icon-me-settings"></i>
            </div>
          </div>
          <div className="info-list-part">
            <div className="item" onClick={()=>{
              window.open(`${footerDeatil.privacy_policy}?embed_mode=true`, "_blank");
            }}>
              <span className="label">{t('Profile.me-privacy')}</span>
              <i className="icon icon-me-privacy"></i>
            </div>
            <div className="item" onClick={()=>{
              window.open(`${footerDeatil.terms_and_conditions}?embed_mode=true`, "_blank");
            }}>
              <span className="label">{t('Footer.Terms')}</span>
              <i className="icon icon-me-privacy"></i>
            </div>
            <div className="item" onClick={()=>{
              window.open(`${footerDeatil.about_us}?embed_mode=true`, "_blank");
            }}>
              <span className="label">{t('Profile.me-aboutus')}</span>
              <i className="icon icon-me-about"></i>
            </div>
            <div className="item" onClick={reLogin}>
              <span className="label">{t('Profile.me-logout')}</span>
              <i className="icon icon-me-logout"></i>
            </div>
          </div>
        </div>


        <Drawer
          className="booking-drawer profile-item-drawer"
          placement="right"
          closable={false}
          // bodyStyle={{ padding: 0, paddingTop: '10px' }}
          destroyOnClose
          onClose={() => setItemVisible(false)}
          visible={itemVisible}
        >
          <Header title={t(`Profile.me-${curItem}`)} onClick={() => setItemVisible(false)}>
            {curItem === 'points' ? <div style={{ fontSize: 13, cursor: 'pointer' }} onClick={seeMore}>{t("POINTS.More")}</div> : null}
          </Header>
          {
            curItem === 'points' ? <MyPoints></MyPoints> : null
          }
          {
            curItem === 'settings' ? <AccountSettings member={curMember} onUpdate={(params) => updateUserInfo(params)} userDeleteSuccess={userDeleteSuccess} toggleVisible={setItemVisible}></AccountSettings> : null
          }
          {
            curItem === 'bookings' ? <MyBookings></MyBookings> : null
          }
          {
            curItem === 'vouchers' ? <MyVouchers></MyVouchers> : null
          }
          {
            curItem === 'reviews' ? <MyReviews></MyReviews> : null
          }
        </Drawer>
      </div>
      <Footer className="profile-footer" hasMore={/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)}/>
    </div>
  );
};

export default Profile;
