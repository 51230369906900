import { action, computed, observable } from "mobx";
import http, { getQuery } from "../service/http";
import { slimImage } from "../service/utils";

class Store {
  /**获取offers信息 */
  @action
  async list(restaurantId) {
    try {
      let { data } = await http.get(
        `/public/restaurants/${restaurantId}/offers`
      );
      return data.map(item=>{
        item.picture_url = slimImage(item.picture_url, '/w/800')
        return item;
      });
    } catch (error) {
      return [];
    }
  }
}

export default new Store();
