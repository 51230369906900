import React, { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Drawer,message } from "antd";
import { RestaurantRate } from "./Rate";
import { RightOutlined } from "@ant-design/icons";
import { Header, Button, ReviewItem, ReviewDetail, WriteReview } from "@/components";
import { useStores } from "@/store";
import { useCallback } from "react";

let submiting = false;

/**评分&评论 */
export const Reviews = ({ restaurant }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [handpicReviews, setHandpickReviews]= useState([])
  const [currentReview, setCurrentReview] = useState({});
  const [page, setPage] = useState(1);
  const [showMore, setShowMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const { restaurantStore, reviewStore } = useStores();
  const [reviewVisible, setReviewVisible] = useState(false);
  const [openReply,setOpenReply] = useState(false);
  const [submitLoading,setSubmitLoading] = useState(false)
  const [reviewTotal,setreviewTotal] = useState(0)
  useEffect(() => {
    setPage(1);
    fetchHandpickReview()
    onLoadMore(20);
  }, [restaurant]);

  useEffect(() => {
    if (page > 1) {
      onLoadMore(20);
    }
  }, [page]);

  const count = useMemo(() => {
    return restaurant.reviews_count;
  }, [restaurant]);
  // 加载精选评论
  async function fetchHandpickReview () {
    const data = await restaurantStore.getHandpicReviews()
    console.log('handpick review :%o', data);
    setHandpickReviews(data)
  }
  /**加载更多 */
  async function onLoadMore(per_page) {
    setLoading(true);
    let data = await restaurantStore.getReviews(page, per_page);
    let newList = [];
    if (page > 1) {
      newList = reviews.concat(data);
    } else {
      newList = data;
    }
    setreviewTotal(data.headers.total_count)
    setShowMore(newList.length < count);
    setLoading(false);
    setReviews(newList);
  }

  /**查看详情 */
  function onShow(item) {
    setCurrentReview(item);
    setVisible2(true);
    setOpenReply(false)
  }
  function onReply(item){
    setOpenReply(true)
    setCurrentReview(item);
    setVisible2(true);
  }
  /**查看大图 */
  function onPreview() {}

  async function viewAll() {
    onLoadMore(20);
    setVisible(true);
  }

  async function showMoreReviews() {
    setPage(page + 1);
    onLoadMore(20);
    setVisible(true);
  }

  function onDelete(id) {
    let index = reviews.findIndex(item => item.id === id)
    if (index >= 0) {
      let _reviews = [...reviews]
      _reviews.splice(index, 1)
      setReviews(_reviews)
      setVisible(false)
      fetchHandpickReview()
    }
  }

  function onEdit(review) {
    setCurrentReview(review)
    setReviewVisible(true)
  }
  const ReviewItemCon = useCallback(() => <React.Fragment>
    {reviews.map((item) => {
      return (
        <ReviewItem
          key={`${item.id}-${item.timestamp}`}
          review={item}
          mobile={true}
          onPreview={onPreview}
          onDelete={onDelete}
          onEdit={onEdit}
          onClick={() => onShow(item)}
          onReply={()=>onReply(item)}
        />
      )
    })}
  </React.Fragment>, [reviews])
  const onSubmitReview = (type, rid, options, sid = '') => {
    if (submiting) return
    submiting = true
    setSubmitLoading(true)
    reviewStore.submitEditReview(type, rid, sid, options).then(res => {
      setSubmitLoading(false)
      if (res && res.status) {
        setReviewVisible(false)
        let _reviews = [...reviews]
        let listIndex = _reviews.findIndex(item => item.id === sid)
        if (listIndex >= 0) {
          res.review.timestamp = new Date().getTime()
          res.review.liked = _reviews[listIndex].liked
          _reviews.splice(listIndex, 1, res.review)
          setReviews(_reviews)
          setCurrentReview(res.review)
        }
        try {
          let draftReviews = JSON.parse(localStorage.getItem('draft-reviews'))
          let index = draftReviews.findIndex(item => item.rid == restaurant.id)
          if (index >= 0) {
            draftReviews.splice(index, 1)
            localStorage.setItem('draft-reviews', JSON.stringify(draftReviews))
          }
        } catch (err) {
          console.log(err)
        } finally {
          reviewStore.reviewInit()
        }
        message.success(t("REVIEW.Saved Successfully"));
      }
    }).finally(() => {
      submiting = false
    })
  }

  return (
    <section className="page-restaurant--reviews" hidden={reviews.length === 0}>
      <h3>{t('WEB.Reviews', { count: reviewTotal })}</h3>
      <RestaurantRate restaurant={restaurant} />
      {handpicReviews.length ? (
        <ReviewItem
          key={`${handpicReviews[0].id}-${handpicReviews[0].timestamp}`}
          review={handpicReviews[0]}
          onPreview={onPreview}
          onDelete={onDelete}
          onEdit={onEdit}
          onClick={() => viewAll()}
        />
      ) : reviews.length ? (
        <ReviewItem
          key={`${reviews[0].id}-${reviews[0].timestamp}`}
          review={reviews[0]}
          onPreview={onPreview}
          onDelete={onDelete}
          onEdit={onEdit}
          onClick={() => viewAll()}
        />
      ) : null}
      <div
        className="hairline-bottom"
        style={{ height: 10, marginBottom: 10, marginTop: -10 }}
      ></div>
      <footer className="view-all flex" onClick={() => viewAll()}>
        <span>{t('WEB.View all')}</span>
        <RightOutlined />
      </footer>

      {/* 详情 */}
      <ReviewDetail
        review={currentReview}
        onPreview={onPreview}
        visible={visible2}
        setVisible={setVisible2}
        onEdit={onEdit}
        onDelete={onDelete}
        reviews={reviews}
        setReviews={setReviews}
        openReply={openReply}
      />

      {/* 列表 */}
      <Drawer
        className="restaurant-drawer reviews-drawer"
        data-left={visible2}
        placement="right"
        style={{zIndex:9998}}
        closable={false}
        destroyOnClose
        onClose={() => setVisible(false)}
        visible={visible}
      >
        <Header
          title={t('WEB.ReviewsTitle')}
          onClick={() => setVisible(false)}
        />
        <h3>{t('WEB.Reviews', { count: reviewTotal })}</h3>
        <div style={{ marginBottom: 10 }}>
          <RestaurantRate restaurant={restaurant} />
        </div>
        {/* {reviews.map((item) => {
          return (
            <ReviewItem
              key={`${item.id}-${item.timestamp}`}
              review={item}
              mobile={true}
              onPreview={onPreview}
              onDelete={onDelete}
              onEdit={onEdit}
              onClick={() => onShow(item)}
            />
          )
        })} */}
        <ReviewItemCon/>
        <div className="show-load">
          {showMore && (
            <Button
              type="primary"
              block
              onClick={() => showMoreReviews()}
              loading={loading}
            >
              {t('WEB.Show more reviews')}
            </Button>
          )}
        </div>
      </Drawer>

      <Drawer
        className="booking-drawer restaurant-review-drawer"
        placement="right"
        closable={false}
        destroyOnClose
        onClose={() => setReviewVisible(false)}
        visible={reviewVisible}
      >
        <WriteReview
          restaurant={currentReview?.source}
          editReview={currentReview}
          onClose={setReviewVisible}
          onSubmitReview={onSubmitReview}
          submitLoading={submitLoading}
        />
      </Drawer>
    </section>
  )
};

