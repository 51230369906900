import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useStores } from "@/store";
import { getQuery } from "@/service/http";

/**
 * Booking qrcode页面
 *
 */
const Main = () => {
  const { t } = useTranslation();
  const { h5Store } = useStores();
  const [bookingDetail, setBookingDetail] = useState({})
  const { book_id, reservation_code } = getQuery();
  const [scanned, setScanned] = useState(false)
  const timer = useRef(null)
  console.log(111)
  useEffect(()=>{
    document.querySelector('.header-bar').setAttribute('style','display: none');
    document.querySelector('.page-main').setAttribute('style','padding-top:0px');

  })
  /* 轮训扫码状态 */
  const getQrcodeStatus = async () => {
    if (bookingDetail.qr_code?.key) {
      let res = await h5Store.getQrcodeStatus(bookingDetail.qr_code.key)
      if (res?.is_scanned) {
        setScanned(true)
        // 扫码成功后，轮询订单详情
        clearInterval(timer.current)
      }
    }
  }

  useEffect(() => {
    (async () => {
      let res = await h5Store.getBookingById(reservation_code, book_id)
      if (res && !res.qr_code) {
        let qrcode = await h5Store.createBookingQrcode(book_id)
        if (qrcode) {
          res.qr_code = qrcode
        }
      }
      setBookingDetail({ ...res })
    })()
  }, []);

  useEffect(() => {
    if (bookingDetail && bookingDetail.id) {
      timer.current = setInterval(() => {
        getQrcodeStatus()
      }, 3000);
    }
    return () => {
      clearInterval(timer.current)
    }
  }, [bookingDetail])

  return (
    <div className="booking-qrcode">
      <h3 className="title">{t("RESERVATION_INFO.QRCodeTitle")}</h3>
      <div className="res-info">
        <div className="name">
          <i className="icon icon-rest"></i>
          <div>{bookingDetail.restaurant && bookingDetail.restaurant.name}</div>
        </div>
        <div className="time">
          <i className="icon icon-time"></i>
          {bookingDetail.formated_time}{bookingDetail.formated_time?.split(':')[0] >= 12 ? 'PM' : 'AM'}
        </div>
      </div>
      <div className="booking-qrcode-inner">
        <div className="tip">{t("RESERVATION_INFO.QRCodeTip")}</div>
        <div className="success">
          {
            scanned && (
              <div className="success-text">
                <i className="icon icon-success"></i>
                {t("RESERVATION_INFO.ScanSuccessfully")}
              </div>
            )
          }
        </div>
        <div className="qr-code">
          <img src={bookingDetail.qr_code?.qrcode_url} mode="scaleToFill" lazy-load="false" />
        </div>
      </div>
    </div>
  );
};

export default Main;
