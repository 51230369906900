import React, { useEffect } from "react";
import { Affix } from "antd";

/**右侧栏 */
export const Aside = ({ weburl }) => {
  useEffect(() => {
    window.iframeResize.create("#web_form");
  }, [weburl]);
  return (
    <aside className="page-restaurant--aside">
      <Affix>
        <iframe id="web_form" src={weburl} frameBorder="0"></iframe>
      </Affix>
    </aside>
  );
};
