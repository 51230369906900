import React, { useEffect, useState } from 'react';
import { useStores } from '@/store';
import { getQuery } from '../../service/http';
import { Footer } from '@/components';
import './index.less';

const DynamicPage = () => {
  const { pageName, region, regionId } = getQuery();
  const { CommonStore } = useStores();
  const [pageHtml, setPageHtml] = useState('');
  useEffect(() => {
    CommonStore.getPageContent(region || regionId, pageName).then(
      ({ data }) => {
        setPageHtml(data?.content || '');
      }
    );
  },[pageName]);
  return (
    <div className="hasFooterContent">
      <div
        className='dynamicPage'
        dangerouslySetInnerHTML={{ __html: pageHtml }}
      ></div>
      <Footer  hasMore={/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)}/>
    </div>
  );
};

export default DynamicPage;
