import React, {useState} from "react";
import {Input} from 'antd';
import "./index.less";
import {useTranslation} from "react-i18next";
import {useStores} from "@/store";
import {slimImage} from "@/service/utils";
import { Icon ,MenuDetail} from "@/components"
import no_dish_picture from "@/images/no_dish_picture.svg";
const icons = [];
/**SearchDishes */
const MenusSearch = ({visible, setVisible}) => {
    const {t} = useTranslation();
    const {restaurantStore} = useStores();
    const [iconList, setIconList] = useState([]);
    const [keyWord, setKeyWord] = useState('');
    const [searchData, setSearchData] = useState([]);// Popular
    const [nodish, setNodish] = useState("none");
    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const search = e.target.value;
        setNodish('none')
        setSearchData([])
        if ("" != search) {
            restaurantStore.getSearchData(search).then(function (data) {
                icons.length = 0;
                searchData.length = 0;
                setSearchData(data)
                if (null != data && 0 < data.length) {
                    for (let i = 0; i < data.length; i++) {
                        icons.push(data[i].name)
                    }
                } else {
                    setNodish('block')
                }
            });//searchData
        } else ("" == search)
        {
            setNodish('none')
            icons.length = 0;
            searchData.length = 0;
        }

        setIconList(
            search ? (
                icons.filter(type => type.includes(search))
            ) : icons
        );
        setKeyWord(search);
        setVisible2(false);
    };
    const [visible2, setVisible2] = useState(false);
    const [currentReview, setCurrentReview] = useState({});
    const [detail, setDetail] = useState([]);
    const [deals, setMenuDeals] = useState([]);
    const [events, setMenuEvents] = useState([]);
    // const [reviews, setMenuReviews] = useState([]);
    /**查看详情 */
    function onShow(item) {
        restaurantStore.getMenuDetail(item.id).then(setDetail)
        restaurantStore.getMenuEvents(item.id).then(setMenuEvents)
        restaurantStore.getMenuDeals(item.id).then(setMenuDeals)
        // restaurantStore.getMenuReviews(item.id).then(setMenuReviews)
        setCurrentReview(item);
        setVisible2(true);
    }
    return (
        <div >
            <MenuDetail menu={currentReview} visibleDetail={visible2} setVisibleDetail={setVisible2} detail={detail} deals={deals} events={events}/>
            <header className="searchBar return">
                <Icon onClick={() => setVisible(false)} icon="icon icon-cancel searchCancel" />
                <Input.Search allowClear placeholder={t("WEB.Search dishes")} value={keyWord} onChange={handleSearch}/>
            </header>
            <div className="price-warning">
                <i className="icon icon-warning"></i>
                <span>{t("WEB.PriceWarning")}</span>
            </div>
            <div>
                {searchData.length ? (<div className="deals-list">
                    {searchData.map((menu,index) => {
                        return <a className="menu-item" id={index} onClick={() => onShow(menu)}>
                            <article >
                                <div className="menu-item--image">
                                    <img
                                        src={slimImage(menu.photos==undefined||menu.photos.length == 0||menu.photos[0]==null  ? no_dish_picture : menu.photos[0].image_url, "/w/148/h/100")}
                                        alt=""/>
                                </div>
                                <div className="menu-item--content">
                                    <div className="deal-title">{menu.name}</div>
                                </div>
                            </article>
                        </a>
                    })}
                </div>) : (<div style={{display: nodish}} className="searchNoDish">{t("WEB.No dish found")}</div>)}
            </div>
        </div>
    );
};
export default MenusSearch;
