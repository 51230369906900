import { action, observable } from "mobx";
import http from "../service/http";

class Store {
  /**当前展示的预订 */
  @observable currentBooking = {};

  /**
   * 获取预订
   *
   * @param {*} reservationCode 预订码
   * @param {*} reservationId 订单id
   * @returns
   * @memberof Service
   */
  @action
  getBookingById(reservationCode, reservationId) {
    return new Promise((resolve, reject) => {
      http
        .get(`/public/books/${reservationId}`, {
          params: {
            reservation_code: reservationCode,
          },
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          console.error("获取预订信息 失败, ", error);
          reject(error);
        });
    });
  }
  
  /**生成订单核销二维码 */
  @action
  createBookingQrcode(bookingId) {
    return new Promise((resolve, reject) => {
      http
        .post(`/public/qr_codes`, {
          qr_code: {
            args: {
              book_id: bookingId
            }
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          resolve(null);
        });
    });
  }
  
  /**获取扫码状态 */
  @action
  getQrcodeStatus(key) {
    return new Promise((resolve, reject) => {
      http
        .get(`/public/qr_codes/${key}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          resolve(null);
        });
    });
  }

  /**获取核销状态 */
  @action
  getReservation(id, code) {
    return new Promise((resolve, reject) => {
      http
        .get(`/public/books/${id}?reservation_code=${code}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          resolve(null);
        });
    });
  }
}

export default new Store();
