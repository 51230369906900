import React, { useRef } from "react";
import { DownOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useStores } from "@/store";
import { useHistory } from "react-router-dom";
import "./index.less";
import { currency } from "@/service/utils";
import { getQuery } from '@/service/http';
/**
 * 餐厅信息
 *
 * @returns
 */
const Restaurant = ({ restaurant = {}, only = false, region = '', isAd = false, tapTimeslot }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { lang = 'en', private_token, payment, callback, login_url, source, region: urlRegion } = getQuery();
  const { memberStore } = useStores();
  let {
    cover,
    tags = [],
    ratings_avg = 0,
    name,
    avg_price = 0,
    format_avg_price,
    cuisines = [],
    location = {},
    distance,
    area_range,
    current_avaliable_time_slots: timeslots = []
  } = restaurant;

  let dealDistance = distance && (distance > 1000
    ? `${(distance / 1000).toFixed(1)}km`
    : `${Math.ceil(distance)}m`);

  let dealAreaRange = area_range && (area_range > 1000
    ? `${(area_range / 1000).toFixed(1)}km`
    : `${Math.ceil(area_range)}m`);

  const price = `${t('Browse.avgPrice')}${format_avg_price}`;
  const rate = parseFloat(ratings_avg, 10).toFixed(1);

  /**查看餐厅详情 */
  function onRestaurantClick() {
    if (restaurant.website_detail_url) {
      // window.open(restaurant.website_detail_url, "_blank");
      window.location.href = `/#/restaurants/${restaurant.id}?lang=${lang}&region=${region || urlRegion}&private_token=${private_token}&callback=${callback}&payment=${payment}&login_url=${login_url}&source=${source}`
      window.top.postMessage(
        {
          type: 'other',
          params: {
            url: restaurant.website_detail_url,
            region,
            lang,
          },
        },
        '*'
      );
    } else {
      history.push(`/restaurants/${restaurant.id}`)
    }
  }

  // cover = `${cover}?imageView2/1/w/130/h/95/interlace/1`.replace('??', '?')

  const onTapTimeslot = (e, item) => {
    e.stopPropagation()
    tapTimeslot && tapTimeslot(item, restaurant)
  }

  return (
    <article className={`dc-restaurant ${only ? 'only' : ''}`} onClick={onRestaurantClick}>
      <div className="dc-restaurant-inner">
        <div className="dc-restaurant--image">
          <img src={cover.split('?')[0] + '-thumb'} width="130" height="95" />
        </div>
        <div className="dc-restaurant--content">
          <div className="dc-restaurant--content-top">
            <div style={{ flex: 1, overflow: 'hidden' }}>
              <Cuisines cuisines={cuisines} />
              <div className="dc-restaurant--name">{name}</div>
              <div className="dc-restaurant--price">{price}</div>
              <div className="dc-restaurant--address">
                {location && (
                  <span>
                    <i className='icon icon-location'></i>
                    <span>{location.name}</span>
                  </span>
                )}
                {
                  isAd && <span className="dc-restaurant--ad">
                    <span>{t('Browse.ad')}</span>
                  </span>
                }
              </div>
            </div>
            <div className="dc-restaurant--rate">
              <span className="rateTag">
                <strong>{rate}</strong>
                <span className="small">{t("RATING")}</span>
              </span>
              <span className="dc-restaurant--distance">{dealDistance || dealAreaRange}</span>
            </div>
          </div>
          {
            !!tags.length && (
              <div className="dc-restaurant--tags">
                {tags.map((item, index) => {
                  return (
                    <div className="restaurant-tag" key={index}>
                      <div className="tag-icon">
                        <img src={item.icon_url} />
                        <div>{item.name}</div>
                      </div>
                      {/* {size > 1 && index === 0 && <DownOutlined onClick={onClick} />} */}
                    </div>
                  );
                })}
              </div>
            )
          }
        </div>
      </div>
      {
        timeslots?.length ? (
          <div className="timeslots" onClick={e => e.stopPropagation()}>
            {
              timeslots.map((item, index) => (
                <div key={index} className={`time-item ${item.formated_discount ? 'discount' : ''}`} onClick={(e) => onTapTimeslot(e, item)}>
                  <div className="time">{item.formated_time}</div>
                  <div className="value">{item.formated_discount || ''}</div>
                </div>
              ))
            }
          </div>
        ) : null
      }
    </article>
  );
};

const Cuisines = ({ cuisines }) => {
  let max = cuisines.length - 1;
  return (
    <div className="dc-restaurant--cuisines small ellipsis">
      {cuisines.map((item, index) => {
        return (
          <React.Fragment key={item.name}>
            <span>{item.name}</span>
            {index < max && <i></i>}
          </React.Fragment>
        );
      })}
    </div>
  );
};

const Tags = ({ tags, onClick }) => {
  let size = tags.length;
  let tagWrapper = useRef();
  function onClick() {
    tagWrapper.current.classList.toggle("show-all");
  }
  return (
    <div className="dc-restaurant--tags" ref={tagWrapper}>
      {tags.map((item, index) => {
        return (
          <div className="restaurant-tag" key={index}>
            <div className="tag-icon">
              <img src={item.icon_url} width="12" height="12" />
              <span className="small">{item.name}</span>
            </div>
            {size > 1 && index === 0 && <DownOutlined onClick={onClick} />}
          </div>
        );
      })}
    </div>
  );
};

export default Restaurant;
