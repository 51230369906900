import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react-lite';
import { MenuItem } from '@/components';
import 'antd-mobile/lib/picker/style/css';
import { useStores } from '@/store';
import './index.less';
/**Popular */
const Popular = ({ menus, tap, timeData }) => {
  if (!menus.length) return null;
  const { t } = useTranslation();
  const { restaurantStore } = useStores();
  const [popular, setPopular] = useState([]); // Popular
  const [regionCountry, setRegionCountryData] = useState([]); //Event

  function getFront(mainStr, searchStr) {
    let foundOffset = mainStr.indexOf(searchStr);
    if (foundOffset == -1) {
      return null;
    }
    return mainStr.substring(0, foundOffset);
  }

  //提取查找字符串后面的所有字符
  function getEnd(mainStr, searchStr) {
    let foundOffset = mainStr.indexOf(searchStr);
    if (foundOffset == -1) {
      return null;
    }
    return mainStr.substring(foundOffset + searchStr.length, mainStr.length);
  }

  //在字符串 searchStr 前面插入字符串 insertStr
  function insertString(mainStr, searchStr, insertStr) {
    var front = getFront(mainStr, searchStr);
    var end = getEnd(mainStr, searchStr);
    if (front != null && end != null) {
      return front + insertStr + searchStr + end;
    }
    return null;
  }

  useEffect(() => {
    restaurantStore.getRestaurant().then((data2) => {
      restaurantStore.getPopular().then(setPopular); //Select time & discount
      restaurantStore
        .getRegionCountry(data2.region.key_word)
        .then(setRegionCountryData); //Select time & discount
    });
  }, [restaurantStore]);
  useEffect(() => {
    if(timeData.selectValue.length>0){
        let discount = timeData?.selectValue[0].split("-")[1].substring(0, timeData?.selectValue[0].split("-")[1].length - 1)
        restaurantStore.getPopulaCache(discount).then(setPopular);
    }else{
        restaurantStore.getPopular().then(setPopular);
    }
  }, [timeData]);

  return useObserver(() => {
    return (
      <section className='page-restaurant--Popular'>
        <h3>
          {t('WEB.Full menu popular')}{' '}
          <span className='title-nums'>{menus.length}</span>
        </h3>
        <div className='deals-list'>
          {popular.map((item, index) => {
            return (
              <MenuItem
                menu={item}
                key={item.id}
                odd={index % 2 === 0}
                index={index}
                mode={'popular'}
              />
            );
          })}
        </div>
        <div
          className='deal-description'
          dangerouslySetInnerHTML={{ __html: regionCountry.vat_desc }}
        ></div>
      </section>
    );
  });
};
export default Popular;
