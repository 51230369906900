import React, { useState, useEffect, useRef } from "react";
import { useObserver } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Drawer, Modal } from "antd";
import { useStores } from "@/store";
import { Header, Loading, Restaurant, Locate } from "@/components";
import InfiniteScroll from "react-infinite-scroller";
import http, { getQuery } from "@/service/http";
import { isMobile } from '@/service/utils';
import { handleDeepLink } from '@/service/utils'
import "./index.less";

let loading = false
/**
 * My points
 *
 */
const MyPoints = () => {
  const { t } = useTranslation();
  const { memberStore, HomeStore } = useStores()
  const [levelKeys, setLevelKeys] = useState([])
  const [summaryKeys, setSummaryKeys] = useState([])
  const [offerRes, setOfferRes] = useState([])
  const [partners, setPartners] = useState([])
  const [curDotIndex, setCurDotIndex] = useState(0)
  const [curLevelIndex, setCurLevelIndex] = useState(0)
  const [curPartner, setCurPartner] = useState({})
  const [hasMore, setHasMore] = useState(false);
  const [becomeVisible, setBecomeVisible] = useState(false)
  const [banner, setBanner] = useState([])
  const { region,region_id, partner_id } = getQuery()
  let startX = useRef(0)
  const [levelData, setLevelData] = useState({
    level: {},
    summary: {}
  })


  useEffect(() => {
    getLevelData()
    HomeStore.getHomeBanners({ region: region || region_id }, isMobile(), true).then(setBanner)
  }, []);

  // useEffect(() => {
  //   console.log(memberStore.position)
  // }, [memberStore.position])

  const getLevelData = async () => {
    let response = await http.get('/member/level')
    let res = response.data
    let levelKeys = Object.keys(res.level)
    levelKeys.sort(function (prev, next) {
      return res.level[prev].range[0] - res.level[next].range[0]
    })
    setLevelKeys(levelKeys)
    setSummaryKeys(Object.keys(res.summary))
    let levelIndex = levelKeys.findIndex(item => item === res.current_level)
    setCurLevelIndex(levelIndex)
    setCurDotIndex(levelIndex)
    levelKeys.forEach((item, index) => {
      let level = res.level[item]
      if (res.current_level !== 'diamond') {
        if (index > levelIndex) {
          res.level[item].progress = 0
        } else {
          res.level[item].progress = res.current_point >= level.range[1] ? '100%' : `${Math.ceil(res.current_point / level.range[1] * 100)}%`
        }
      } else {
        res.level[item].progress = '100%'
      }
    })
    // if (levelIndex === 0) {
    //   res.benefits = res.benefits.map(item => {
    //     item.tips = t('POINTS.From Gold')
    //     return item
    //   })
    // }
    memberStore.levelData = res
    setLevelData(res)
    getPartners()
    getOfferRestaurants(1, res.tags_id)
  }

  const touchStart = (e) => {
    startX.current = e.changedTouches[0].clientX
  }
  const touchEnd = (e) => {
    let endX = e.changedTouches[0].clientX
    if (startX.current - endX >= 50) {
      if (curDotIndex < levelKeys.length - 1) {
        setCurDotIndex(curDotIndex + 1)
      }
    } else if (startX.current - endX <= -50) {
      if (curDotIndex > 0) {
        setCurDotIndex(curDotIndex - 1)
      }
    }
  }
  const seeMore = () => {
    window.location.href = levelData.faq_url
  }
  const getOfferRestaurants = async (page, tags_id) => {
    if (loading) return;
    loading = true;
    try {
      let restaruant = await http.get(`/public/regions/${ region ||region_id || 'diningcity'}/restaurants/search?tags.id=${tags_id}&page=${page}&per_page=10`)
      if (restaruant.data.length >= 10) {
        setHasMore(true)
      } else {
        setHasMore(false)
      }
      let restaruants = offerRes
      restaruants = restaruants.concat(restaruant.data.map(item => {
        item.lat = item.lat_lon.lat
        item.lng = item.lat_lon.lon
        return item
      }))
      setOfferRes(restaruants)
    } catch (err) {
      console.log(err)
    } finally {
      loading = false;
    }
  }
  const getPartners = async () => {
    let res = await http.get(`/public/partners?region=${region || region_id || 'shanghai'}`)
    setPartners(res.data)
    if(partner_id && !becomeVisible){
      const item = res.data.find(_=>_.id==partner_id);
      item && tapPartner(item)
    }
  }
  function loadMore(page) {
    getOfferRestaurants(page, levelData.tags_id)
  }
  const tapPartner = item => {
    setCurPartner(item)
    setBecomeVisible(true)
  }
  const onBecomeSuccess = () => {
    setBecomeVisible(false)
    getLevelData()
  }
  const handleBannerClick = (item) => {
    handleDeepLink(item?.link)
  }
  return (
    <div className="my-benefits">
      {/* <Header title={t("POINTS.Title")}>
        <div style={{ fontSize: 13 }} onClick={seeMore}>{t("POINTS.More")}</div>
      </Header> */}
      <div className="level-banner">
        <div className="level-inner" style={{
          width: `${333 * levelKeys.length + 14 * (levelKeys.length - 1) + 21 * 2}px`,
          transform: `translateX(-${curDotIndex * (333 + 14)}px)`
        }} onTouchEnd={touchEnd} onTouchStart={touchStart}>
          {
            levelKeys.map((item, index) => (
              <div key={item} className={`level-item ${index <= curLevelIndex ? 'achieved' : ''}`}>
                <img src={`https://library.diningcity.cn/images/mini_program/${item}@3x${index <= curLevelIndex ? '' : '-gray'}.png`} alt="" />
                <h2>{t(`LEVELS.${item}`)}</h2>
                <h4>{t("POINTS.Your")}</h4>
                <div className="progress">
                  <div className="progress-inner" style={{ width: levelData.level[item]?.progress }}></div>
                  {item === 'diamond' && <i className={`icon icon-progress-star ${levelData.current_level === 'diamond' ? 'active' : ''}`}></i>}
                </div>
                <div className="bottom">
                  <div>
                    <div>{t('POINTS.Earned')}</div>
                    <div>{levelData.current_point}</div>
                  </div>
                  {
                    levelData.level[item]?.diff >= 0 ? (
                      <div>
                        <div>{t('POINTS.NextLevel')}</div>
                        <div>{levelData.level[item].diff}</div>
                      </div>
                    ) : (
                      <div>{t('POINTS.MaxLevel')}</div>
                    )
                  }
                </div>
              </div>
            ))
          }
        </div>
      </div>
      <div className="level-dots">
        {
          levelKeys.map((item, index) => (
            <div key={item} className={`dot-item ${curDotIndex === index ? 'active' : ''} ${index <= curLevelIndex ? 'achieved' : ''}`}>
              <div className="dot"></div>
              <div className="name">{t(`LEVELS.${item}`)}</div>
            </div>
          ))
        }
      </div>
      <div className="bannerList">
        {banner.map(item => (
          <div className="banner" onClick={() => handleBannerClick(item)}>
            <img src={item.image_url.replace(/\?|$/, '-thumb?')} alt="" />
          </div>
        ))}
      </div>
      {/* <div className="part">
        <div className="title">
          {t('POINTS.Summary')}
        </div>
        <div className="inner summary">
          {
            summaryKeys.map(item => (
              <div className="summary-item" key={item}>
                <i className={`icon icon-${item}`}></i>
                <div className="count">{levelData.summary[item]?.count}</div>
                <div className="label">{t(`POINTS.${item}`)}</div>
              </div>
            ))
          }
        </div>
      </div> */}
      {
        !!partners.length && (
          <div className="part no-padding no-margin">
            <div className="title">
              {t('POINTS.Partner')}
            </div>
            <div className="tip">{t('POINTS.PartnerTip')}</div>
            <div className="inner partners">
              {
                partners.map(item => (
                  <div className="partner-item" key={item.id} onClick={() => tapPartner(item)}>
                    <img src={item.thumbnail_url + '-thumb'} alt="" />
                  </div>
                ))
              }
            </div>
          </div>
        )
      }
      {/* <div className="part">
        <div className="title">{t('POINTS.Benefits')}</div>
        <div className={`inner benefits ${curLevelIndex > 0 ? 'active' : ''}`}>
          {
            levelData.benefits?.map(item => (
              <div className="benefit-item" key={item.title}>
                <img src={item.light_icon} alt="" />
                <div className="label">{item.title}</div>
                {!!item.tips && <div className={`tip ${curLevelIndex === 0 ? 'basic' : ''}`}>{item.tips}</div>}
              </div>
            ))
          }
        </div>
      </div> */}
      {/* <div className="part">
        <div className="title">{t('POINTS.OfferBenefits')}</div>
        <div className="inner benefits">
          <InfiniteScroll
            initialLoad={false}
            pageStart={1}
            loadMore={loadMore}
            hasMore={hasMore}
            useWindow={true}
          >
            {
              !offerRes.length && <div className="no-res">{t('POINTS.NoRestaurant')}</div>
            }
            {
              offerRes.map(item => (
                <Restaurant restaurant={item} key={item.id} />
              ))
            }
            <div className="load-more">
              {hasMore && <Loading red></Loading>}
            </div>
          </InfiniteScroll>
        </div>
      </div> */}
      {/* <Locate /> */}
      <Drawer
        className="points-drawer"
        placement="right"
        closable={false}
        destroyOnClose
        onClose={() => setBecomeVisible(false)}
        visible={becomeVisible}
      >
        <Header
          onClick={() => setBecomeVisible(false)}
        />
        <BecomeVip t={t} data={curPartner} success={onBecomeSuccess} />
      </Drawer>
    </div>
  )
};

const BecomeVip = ({ t, data, success }) => {
  const [code, setCode] = useState('')
  const [visible, setVisible] = useState(false)
  const [errMessage, setErrMessage] = useState('')
  const [level, setLevel] = useState('basic')
  const { vipcode } = getQuery()
  // 检查路径是否有vipcode参数 如果有进行自动填充
  useEffect(() => {
    if (vipcode) {
      setCode(vipcode)
    }
  }, [])
  const getLevel = async () => {
    // this.id, this.code, this.currentUser.private_token
    try {
      let res = await http.put(`/public/partners/${data.id}/redeem?code=${code}`, {}, {
        headers: {
          "access-token": window.private_token
        }
      })
      if (res.data && res.data.id) {
        setErrMessage('')
        setLevel(res.data.partner_batch.upgrade_level)
        setVisible(true)
      }
    } catch (err) {
      setErrMessage(err.data?.attributes[0]?.messages[0]?.message)
      setVisible(true)
    }
  }
  const tapResBtn = () => {
    if (errMessage) {
      setVisible(false)
      setErrMessage('')
    } else {
      success()
    }
  }
  return (
    <div className="become-vip">
      <div className="img-box">
        <img src={data.bg_visual_url} alt="" />
      </div>
      <div className="content">
        <div className="title">{t("POINTS.BecomeTitle")}</div>
        <div className="tip">{t("POINTS.BecomeTip")}</div>
        <input type="text" maxLength="20" value={code} onChange={e => setCode(e.target.value)} placeholder={t("POINTS.BecomePlaceholder")} />
        <button className={`get-btn ${!!code ? 'active' : ''}`} disabled={!code} onClick={getLevel}>{t("POINTS.BecomeGet")}</button>
      </div>
      {
        visible && (
          <div className="mask">
            <div className="become-modal">
              <div className="title">{!!errMessage ? t("POINTS.BecomeOops") : t("POINTS.BecomeSuccess")}</div>
              {!errMessage && <img className="level-icon" src={`https://library.diningcity.cn/images/mini_program/${level}@3x.png`} alt="" />}
              <div className="tip">{errMessage || t("POINTS.BecomeLevel", { level: t(`LEVELS.${level}`) })}</div>
              <div className="get-btn" onClick={tapResBtn}>{!!errMessage ? t("POINTS.BecomeErrorBtn") : t("POINTS.BecomeSuccessBtn")}</div>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default MyPoints;
