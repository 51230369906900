import React from "react";
import { useTranslation } from "react-i18next";
import "./index.less";
import { slimImage } from "@/service/utils";
import { Button } from "@/components";
import { getQuery } from "@/service/http";
import moment from 'moment'
import { useStores } from "@/store";

const { lang = "en",region='shanghai' } = getQuery();

/**
 * 餐厅信息
 *
 * @returns
 */
const SpecialEvent = ({ events, tap }) => {
  const { t } = useTranslation();
  const { restaurantStore } = useStores();
  const { currency } = restaurantStore;

  /**查看餐厅详情 */
  function onRestaurantClick(item) {
    if (item.days_left > 0) {
      window.open(item.detail_in_events_url, "_blank");
      window.top.postMessage(
        {
          type: 'other',
          params: {
            url: item.detail_in_events_url,
            region,
            lang,
          },
        },
        '*'
      );
    }
  }
  if (!events.length) return (<section className="page-restaurant--Event">
    <h3>{t("WEB.Event menus")} <span className="title-nums">0</span></h3>
  </section>);
  return (
    <section className="page-restaurant--Event">
      <h3>{t("WEB.Event menus")} <span className="title-nums">{events.length}</span></h3>
      {events.map((item) => {
        const { project_config } = item
        let stateText = ''
        if (moment().isAfter(moment(project_config.end_at))) { // 大于结束时间
          stateText = t("Ended")
        } else if (moment().isAfter(moment(project_config.start_at))) { // 大于开始时间
          // Booking
        } else if (!project_config.pre_booking_at) { // 当前时间小于start_at && pre_booking_at不存在
          stateText = t("Coming soon")          
        } else { // 当前时间小于start_at && pre_booking_at存在
          if (moment().isBefore(moment(project_config.pre_booking_at))) { // 当前时间小于pre_booking_at
            stateText = t("Coming soon")          
          } else {
            stateText = t("Pre booking")
          }
        }
        return (
          <article
            key={item.id+"event"}
            className="dc-special-event"
            onClick={() => onRestaurantClick(item, !stateText)}
          >
            { !stateText && <Button type="primary">{t("WEB.event get")}</Button> }
            <div className="dc-special-event--image">
              <img
                src={slimImage(item.restaurant_profile_cover)}
                width="106"
                height="80"
              />
            </div>
            <div className="dc-special-event--content">
              <div className="dc-special-event--name">{item.title}</div>
              <div className="dc-special-event--price-box">
                {
                  !!+item.price && <React.Fragment>
                    {/* {lang === "en" ? (
                      <div className="dc-special-event--price">
                        From{" "}
                        <em>
                          {currency}
                          {item.price}
                        </em>
                      </div>
                    ) : (
                      <div className="dc-special-event--price">
                        <em>
                          {currency}
                          {item.price}
                        </em>{" "}
                        起
                      </div>
                    )} */}
                    <div className="dc-special-event--price">
                        {t('WEB.Price Form',{data:currency+item.price})}
                        {/* <em>
                          {currency}
                          {item.price}
                        </em> */}
                    </div>
                  </React.Fragment>
                }
              </div>
              {
                !stateText ? (
                  // lang === "en" ? (
                  //   <div className="dc-special-event--left">
                  //     Only <span>{item.days_left}</span> days left
                  //   </div>
                  // ) : (
                  //   <div className="dc-special-event--left">
                  //     只剩 <span>{item.days_left}</span> 天
                  //   </div>
                  // )
                  <div className="dc-special-event--left">
                      {/* Only <span>{item.days_left}</span> days left */}
                      {t("WEB.only Days",{data:item.days_left})}
                  </div>
                ) : <div className="dc-special-event--other-state">{ stateText }</div>
              }
            </div>
          </article>
        );
      })}
    </section>
  )

};

export default SpecialEvent;
