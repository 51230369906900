import { action, computed, observable } from "mobx";
import http, { getQuery } from "../service/http";
import * as mock from "../service/mock";
import { message } from "antd";
import qs from "qs";
import memberStore from "./member";

const cache = {};

class Store {
  @observable deal = {};
  url =
    process.env.REACT_APP_MODE === "PRODUTION"
      ? "https://book.diningcity.asia"
      : "https://staging-book.diningcity.asia";

  /**获取webform地址 */
  @computed
  get webform() {
    const { restaurant, id ,can_buy_voucher} = this.deal;
    if (!id) return "";
    const { lang, payment, callback,source } = getQuery();
    let path = `${this.url}/#/restaurants/${restaurant.id}/reservation`;
    const private_token = memberStore.private_token;
    if((private_token==""||private_token==null||private_token==undefined)&&can_buy_voucher){
        return path + "/disabled" ;
    }
    let query = {
      lang,
      private_token,
      deal_id: id,
      top: callback || window.location.href,
      source: source,
      show_group_booking: true
    };
    if (payment && payment !== "undefined") {
      path += "/complete";
      const [reservation_code, reservation_id] = payment.split("___");
      query.reservation_code = reservation_code;
      query.reservation_id = reservation_id;
    }
    return path + "?" + qs.stringify(query);
  }

  /**获取deal信息 */
  @action
  async show(dealId) {
    return new Promise((resolve) => {
      Promise.all([
        this.getDeal(dealId),
        this.getMenus(dealId),
        this.getPhotos(dealId),
        this.getReviews(dealId),
        this.getAvailability(dealId),
      ])
        .then(([deal, menus, photos, reviews, availabilities]) => {
          resolve({
            deal,
            menus,
            photos,
            reviews,
            availabilities,
          });
        })
        .catch((error) => {
          console.error(error);
          resolve(null);
        });
    });
  }

  /**获取优惠信息 */
  @action
  async getDeal(dealId) {
    if (cache[dealId]) return cache[dealId];
    try {
      let { data } = await http.get(`/public/deals/${dealId}`);
      cache[dealId] = data;
      this.deal = data;
      return data;
    } catch (error) {
      message.error(error.message);
      return {};
    }
  }

  /**获取菜单 */
  @action
  async getMenus(dealId) {
    const cacheKey = `menu_${dealId}`;
    if (cache[cacheKey]) return cache[cacheKey];
    try {
      let { data } = await http.get(`/public/deals/${dealId}/menus`);
      cache[cacheKey] = data;
      return data;
    } catch (error) {
      return [];
    }
  }

  /**获取图片 */
  @action
  async getPhotos(dealId) {
    const cacheKey = `review_${dealId}`;
    // if (cache[cacheKey]) return cache[cacheKey];
    try {
      let { data } = await http.get(`/public/deals/${dealId}/photos`);
      cache[cacheKey] = data;
      return data;
    } catch (error) {
      message.error(error.message);
      return [];
    }
  }

  /**获取评论 */
  @action
  async getReviews(dealId, page = 1) {
    const cacheKey = `review_${dealId}`;
    if (cache[cacheKey]) return cache[cacheKey];
    try {
      let { data } = await http.get(`/public/reviews`, {
        params: {
          source_type: "deal",
          source_id: dealId,
          page,
        },
        headers: {
          "accept-version": "2",
        },
      });
      cache[cacheKey] = data;
      return data;
    } catch (error) {
      message.error(error.message);
      return [];
    }
  }

  /**获取可用日期 */
  @action
  async getAvailability(dealId) {
    const cacheKey = `availability_${dealId}`;
    if (cache[cacheKey]) return cache[cacheKey];
    try {
      let { data } = await http.get(`/public/deals/${dealId}/available_times`, {
        headers: {
          "api-key": "cgecegcegcc",
          "accept-version": "application/json; version=2",
          "access-token": window.private_token,
        },
      });
      cache[cacheKey] = data;
      return data;
    } catch (error) {
      message.error(error.message);
      return [];
    }
  }

  /**deal's voucher batch */
  @action
  async getDealsVoucherBatch(dealId) {
      const cacheKey = `dealsVoucherBatch${dealId}`;
      if (cache[cacheKey]) return cache[cacheKey];
      try {
          let {data} = await http.get(`/public/deals/${dealId}/dynamic_voucher_batch`, {
              headers: {
                  "api-key": "cgecegcegcc",
                  "accept-version": "application/json; version=2",
                  "access-token": window.private_token,
              },
          });
          cache[cacheKey] = data;
          return data;
      } catch (error) {
          message.error(error.message);
          return [];
      }
  }

  /**
   * createVoucher
   * @param {*} form
   */
  @action
  async createVoucher(data,restaurant_id) {
    try {
      return new Promise((resolve, reject) => {
        http.post(`/public/restaurants/${restaurant_id}/orders`, data,
                {
                  headers: {
                    "api-key": "cgecegcegcc",
                    "accept-version": "application/json; version=2",
                    "access-token": window.private_token,
                  },
                }
            )
            .then(({ data }) => {
              if (!data.status) {
                //message.error(error.data.full_messages, 5);
                reject(data);
              }
              resolve(data);
            })
            .catch((error) => {
             // message.error(error.data.full_messages, 5);
              reject(error);
            });
      });
    } catch (error) {
      return Promise.resolve(false);
    }
    }
    @action
    async getRecommenders(dealId) {
      const cacheKey = `recommenders_${this.restaurantId}`;
      if (cache[cacheKey]) return cache[cacheKey];
      try {
        let { data } = await http.get(`/public/deals/${dealId}/recommenders`);
        cache[cacheKey] = data;
        return data;
      } catch (error) {
        return [];
      }
    }
    // 查询兑换二维码
    @action
    async getRedeemQrcode(code) {
      return await http.post(`/member/redeems`,{
        "redeem": {
          code
        }
      }).then((data) => data.data);
    }
    // 查询兑换结果
    @action
    async queryRedeemResult(id){
      return await http.get(`/member/redeems/${id}`).then((data) => data.data);
    }
}

export default new Store();
