import axios from 'axios'
import jsonpClient from 'jsonp'
import qs from 'qs'

const http = axios.create({
  baseURL:
    process.env.REACT_APP_MODE === 'PRODUTION'
      ? 'https://api.diningcity.asia'
      : 'https://staging-api.diningcity.asia',
  headers: {
    'Content-Type': 'application/json',
    lang: getQuery()['lang'] || 'en',
  },
})

http.interceptors.request.use((config) => {
  let token = window.private_token
  if (token) {
    config.headers['access-token-member'] = token
  }
  return config
})

http.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => Promise.reject(httpErrorHandler(error))
)

/**
 * 处理通用错误信息
 *
 * @param {Error} error
 * @returns
 */
function httpErrorHandler(error) {
  let { code = '', data = {}, message = '', response } = error

  // 服务器错误
  // 如果自带了错误信息，使用服务器的错误信息，否则使用自定义的错误信息
  if (response) {
    code = response.status
    data = response.data
    message = response.data.message || response.statusText
  }

  switch (code) {
    case 400:
      // message = '参数类型错误';
      break
    case 401:
    case 403:
      break
    case 404:
      // message = '错误的请求地址';
      break
    case 405:
      // message = '错误的请求方法';
      break
    case 415:
      // message = '不支持的类型';
      break
    case 500:
    case 501:
    case 502:
    case 503:
      // message = '服务器错误';
      break
    default:
      break
  }
  return {
    code,
    message,
    data,
  }
}

/**
 * 获取域名信息
 *
 * @returns
 */
function getHostname() {
  let hostname = window.location.hostname.split('.')
  let targetHostname = 'https://www.diningcity.cn'
  if (hostname[1] && hostname[2]) {
    targetHostname = 'https://www.' + hostname[1] + '.' + hostname[2]
  }
  return targetHostname
}

http.hostname = getHostname()
http.lang = getQuery()['lang']

localStorage.setItem('lang', http.lang)

/**
 * jsonp请求
 *
 * @param {*} url
 * @param {*} [options={}]
 * @returns
 */
http.jsonp = (url, options = {}) => {
  return new Promise((resolve, reject) => {
    jsonpClient(url, options, (err, data) => {
      if (err) {
        return reject(err)
      }
      resolve(data)
    })
  })
}

/**
 * 获取url参数
 *
 * @returns
 */
http.getQuery = getQuery

export function getQuery() {
  let query = qs.parse(location.href.split('?')[1])
  if (!['en', 'zh', 'zh-HK', 'vi'].includes(query.lang)) {
    query.lang = localStorage.getItem('lang') || 'en'
  }
  return query
}

/**
 * 获取路径上的lang
 *
 * @returns
 */
http.getUrlofLang = getUrlofLang

export function getUrlofLang() {
  let query = qs.parse(location.href.split('?')[1])
  return query.lang || localStorage.getItem('lang') || 'en'
}

export default http
