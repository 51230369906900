import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useStores } from "@/store";
import qs from "qs";
import "./index.less";
import { getQuery } from "@/service/http";
import InfiniteScroll from "react-infinite-scroller";
import { Loading, Restaurant, Deal, Footer } from "@/components";
import moment from "moment";
import {isMobile} from '@/service/utils';
import { Modal ,Drawer} from "antd";
import CollectionMap from "../browse/components/collection-map";

let loading = false;
let normalAds = []
const today = moment().format('YYYY-MM-DD')
const isDark = window.matchMedia("(prefers-color-scheme: dark)").matches
const BASE_URL =
  process.env.REACT_APP_MODE === "PRODUTION"
    ? "https://book.diningcity.asia"
    : "https://staging-book.diningcity.asia";

/**
 * 餐厅集合
 *
 */
const ResCollection = () => {
  const { t } = useTranslation();
  const { restaurantStore, BrowseStore, dealStore, memberStore } = useStores();
  const [mapVisible, setMapVisible] = useState(true);
  const { gid, region, lang, payment, callback, collectionName,private_token,source } = getQuery();
  const [guide, setGuide] = useState({})
  const [hasMore, setHasMore] = useState(true);
  const [infiniteKey, setInfiniteKey] = useState(0)
  const [sortList, setSortList] = useState([])
  const [collections, setCollections] = useState([])
  const [resAds, setResAds] = useState([])
  const [showSortModal, setSortModalVisible] = useState(false)
  const [dates, setDates] = useState([])
  const [regionData, setRegionData] = useState({})
  const [curBookingRes, setCurBookingRes] = useState({})
  const [curBookingDate, setCurBookingDate] = useState({})
  const [bookingVisible, setBookingVisible] = useState(false)
  const [markerIndex, setMarkerIndex] = useState([])
  const [mapSelectedRestaurants, setMapSelectedRestaurants] = useState([])
  const [webUrl, setWebUrl] = useState('')
  const [curretnPage,setCurrentPage] = useState('1')
  const collectionScrollRef = useRef(null)
  const { CommonStore } = useStores();
  const daysLang = [
    t("Collection.weekday-sun"),
    t("Collection.weekday-mon"),
    t("Collection.weekday-tue"),
    t("Collection.weekday-wed"),
    t("Collection.weekday-thu"),
    t("Collection.weekday-fri"),
    t("Collection.weekday-sat")
  ]
  const [extraParams, setExtraParams] = useState({
    page: 1,
    lat: '',
    lng: '',
    date: moment().format('YYYY-MM-DD'),
    seats: 2,
  })

  useEffect(()=>{
    setExtraParams({
      page: 1,
      lat: '',
      lng: '',
      date: moment().format('YYYY-MM-DD'),
      seats: 2,
    })
    setCollections([])
    setCurrentPage("1")
    setSortList([])
    setHasMore(true)
    setMapSelectedRestaurants([])
    setMarkerIndex([])
  },[gid])

  const getGeoSuccess = useCallback((position) => {
    const { latitude, longitude } = position.coords
    let curExtraParams = {
      ...extraParams,
      lat: latitude,
      lng: longitude
    }
    setExtraParams(curExtraParams)
    // getGuideItems(curExtraParams)
    console.log('getGeoSuccess',extraParams)
    // BrowseStore.getCityByLocation(latitude, longitude).then(res => {
    //   setRegionData(res)
    // })
  },[extraParams,BrowseStore])

  const getGeoError = useCallback(() => {
    console.log('getGeoError',extraParams)
    // getGuideItems(extraParams)
  },[extraParams])

  const getGuideDetail = () => {
    return restaurantStore.getSmartGuideDetail(gid).then(res => {
      setGuide(res)
      setSortList(res.sort)
      setExtraParams({
        ...extraParams,
        sort: extraParams?.sort || res.sort[0].value
      })
      return res
    })
  }

  const getGuideItems = async (params,flag=false) => {
    if (!hasMore&&!flag) return false
    if (loading) return false
    loading = true

    let resAdsRes = []

    // 推广餐厅
    if (Number(params.page) === 1 && guide.item_type === 'restaurant') {
      resAdsRes = await BrowseStore.getResAds({
        region: region,
        collection_id: guide.id || '',
        type: 'restaurant',
        search_word: ''
      })
      resAdsRes = (resAdsRes || []).map(item => {
        item.lat_lon = {
          lat: item.lat,
          lon: item.lng
        }
        return item
      })
      setResAds(resAdsRes)
    }

    // 推广优惠
    if (Number(params.page) === 1 && guide.item_type === 'deal') {
      resAdsRes = await BrowseStore.getDealAds({
        collection_id: guide.id || '',
      })
      resAdsRes = (resAdsRes || []).map(item=>item?.source)
      setResAds(resAdsRes)
    }


    // 集合列表
    let {data,headers} = await restaurantStore.getGuideItems(guide.id, params)
    setCurrentPage(headers.next_page)
    let totalRes = (data || []).filter(item => {
      return !resAdsRes.find(ad => ad.id === item.id)
    }).map(item => {
      item.lat_lon = {
        lat: item.lat,
        lon: item.lng
      }
      return item
    })
    if (params.page > 1) {
      totalRes = collections.concat(totalRes)
    }
    if (data.length < 8 || params.page === headers.total_pages) {
      setHasMore(false);
    }

    // 广告
    let adsCount = Math.floor(totalRes.length / 4)
    if (adsCount > 0 && normalAds.length <= 0) {
      let normalAdsRes = await BrowseStore.getNormalAds({
        keyword: guide.id,
        region: region
      })
      if (normalAdsRes.length > 0) {
        let counter = normalAdsRes.length
        for (var i = 0; i < 4; i += counter) {
          normalAdsRes.forEach(ad => {
            normalAdsRes.push(ad)
          })
        }
      }
      normalAds = normalAdsRes
    }
    normalAds.forEach((ad, index) => {
      let pos = (index + 1) * 10
      let item = totalRes[pos - 1]
      if (item) {
        item.ad = ad
      }
    })

    setCollections(totalRes)

    loading = false
  }

  const curSortItem = useMemo(() => {
    return sortList.find(item => item.value === extraParams.sort) || {}
  }, [sortList, extraParams])

  const otherDayLang = useMemo(() => {
    let curDate = moment(extraParams.date)
    return `${curDate.date()} ${daysLang[curDate.day()] || ''}`
  }, [extraParams])

  useEffect(() => {
    if (window.screen.width <= 768) {
      setMapVisible(false)
    }

    getGuideDetail()
    let dates = []
    for (let i = 0; i < 14; i++) {
      let curDay = moment().add(i, 'days')
      dates.push({
        value: curDay.format('YYYY-MM-DD'),
        date: curDay.date(), // 日期
        day: curDay.day() // 星期
      })
    }
    setDates(dates)

    if (region) {
      BrowseStore.getCityByRegion(region).then(setRegionData)
    }
  }, [window.screen,gid]);

  useEffect(() => {
    if (guide && guide.id && extraParams.sort) {
      if (navigator.geolocation && (!extraParams.lat || !extraParams.lng)) {
        navigator.geolocation.getCurrentPosition(getGeoSuccess, getGeoError, {
          timeout: 30000,
          enableHighAccuracy:true,
          maximumAge: 75000
        })
      }
      if (collections.length === 0) {
        getGuideItems({
          ...extraParams,
          page: '1',
        })
      }
    }
  }, [guide, extraParams,gid])

  useEffect(() => {
    let ids = markerIndex.map(mIndex => collections[mIndex].restaurant_id || collections[mIndex].id)
    if (ids.length) {
      BrowseStore.getBrowseRestaurants({
        extraParams: extraParams.lang,
        region: region,
        "api-key": "cgecegcegcc",
        ids: ids.join(',')
      }).then(res => {
        setMapSelectedRestaurants(res)
      })
    } else {
      setMapSelectedRestaurants([])
    }
  }, [markerIndex,gid,extraParams])

  function loadMore(page) {
    if (!hasMore) return false
    let curExtraParams = {
      ...extraParams,
      page:curretnPage
    }
    setExtraParams(curExtraParams)
    getGuideItems(curExtraParams)
  }

  const applyFilter = (filter) => {
    setHasMore(true)
    setInfiniteKey(Math.ceil(Math.random() * 100000))
    collectionScrollRef.current.scrollTop = 0
    let curExtraParams = {
      ...extraParams,
      ...filter,
      page: 1
    }
    setExtraParams(curExtraParams)
    getGuideItems(curExtraParams,true)
  }

  function tapMarkersHandler(indexs) {
    setMarkerIndex(indexs)
  }

  const tapResTimeslot = (item, restaurant) => {
    restaurantStore.getRestaurantById(restaurant.id).then((data) => {
      setCurBookingRes(data)
      setCurBookingDate(item)
      if (restaurant?.id) {
        const { id } = restaurant;
        const path = `${BASE_URL}/#/restaurants/${id}/reservation?`;
        const private_token = memberStore.private_token;
        const query = qs.stringify({
          lang,
          private_token,
          date: item.date,
          time: item.formated_time,
          seats: extraParams.seats,
          source: source,
          show_group_booking: true
        });
        setWebUrl(path + query)
        setBookingVisible(true)
      };
    });
  }

  const tapDealTimeslot = (item, deal) => {
    dealStore.getDeal(deal.id).then(res => {
      const { restaurant, id, can_buy_voucher } = res;
      let path = `${BASE_URL}/#/restaurants/${restaurant.id}/reservation`;
      const private_token = memberStore.private_token;
      let query = {
        lang,
        private_token,
        deal_id: id,
        top: callback || window.location.href,
        show_group_booking: true,
        date: item.date,
        time: item.formated_time,
        seats: extraParams.seats,
        source: source,
      };
      if (payment && payment !== "undefined") {
        path += "/complete";
        const [reservation_code, reservation_id] = payment.split("___");
        query.reservation_code = reservation_code;
        query.reservation_id = reservation_id;
      }
      setWebUrl(path + "?" + qs.stringify(query))
      setBookingVisible(true)
    })
  }

  const clearBookingDate = () => {
    setCurBookingRes({})
    setCurBookingDate({})
  }

  const picWidth = useMemo(()=>{
    return collectionScrollRef?.current?.offsetWidth
  },[guide])
  const { domain } = CommonStore.cityDetail;
  const origin = process.env.REACT_APP_MODE === "PRODUTION" ? "https://www.diningcity.cn/" : "https://www.diningcitydeals.com/"
  return (
    <div className="hasFooterContent" key={gid}>
      <InfiniteScroll
        initialLoad={false}
        pageStart={1}
        loadMore={loadMore}
        hasMore={hasMore}
        useWindow={/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)}
        key={infiniteKey}
      >
        <div className="res-collection" ref={collectionScrollRef}>
          {<div className="navigation-title">
              <div onClick={() => {
                window.location.href = `/#/home?gid=${gid}&region=${
                  region ||'shanghai'
                }&lang=${lang}&private_token=${private_token}&callback=${callback}&payment=${payment}`;
                window.top.postMessage(
                  {
                    type: 'other',
                    params: {
                      url: `${
                        domain
                            ? 'https://www.' + domain + '/'
                            : origin
                        }${lang}/${region}`,
                      region,
                      lang,
                    },
                  },
                  '*'
                );
              }}>{t('Header.Home')}</div>
              <span>&gt;</span>
              <div className="navigation-title-selected">{guide.name}</div>
          </div>}
          <div className="collection-name">
            <h2 style={{marginBottom:'10px'}}>{guide.name}</h2>
          </div>
          <div className={`banner-box ${guide.header_style === 'image' ? 'hero-banner-box' : ''}`}>
            {
              guide.header_style !== 'image' && (
                <React.Fragment>
                  <div className="banner-text">
                    <div className="cate-name">{guide.name}</div>
                    <div className="cate-desc">{guide.description || ''}</div>
                  </div>
                  <div className="banner-cover"></div>
                </React.Fragment>
              )
            }

            <div className="map-button" onClick={() => setMapVisible(!mapVisible)}>
              <i className="icon-map-white"></i>
              {t("Collection.Map")}
            </div>
            <img className="banner" src={`${isMobile()? ( guide.app_banner_url && guide.app_banner_url.split('?')[0]+'-gallery') :( guide.banner_url && guide.banner_url.split('?')[0]+'-gallery')}`} alt="" />
          </div>
          <div className="content">
            <div className="collections-box">
                <div className="collections">
                  {
                    resAds.map(rest => {
                      return (
                        <React.Fragment key={rest.id}>
                          { guide.item_type === 'restaurant' && <Restaurant restaurant={rest} region={region} isAd={true} />}
                          { guide.item_type === 'deal' && <Deal deal={rest} isAd={true} bookNowDeals={true} showGet={false}/>}
                        </React.Fragment>
                      )
                    })
                  }
                  {
                    guide.item_type === 'restaurant'
                      ? (
                        collections.map(rest => {
                          return (
                            <React.Fragment key={rest.id}>
                              <Restaurant restaurant={rest} region={region} tapTimeslot={tapResTimeslot} />
                              {
                                rest.ad ? <AdItem ad={rest.ad} /> : null
                              }
                            </React.Fragment>
                          )
                        })
                      ) : (
                        guide.item_type === 'deal' ? (
                          collections.map((deal, index) => {
                            return (
                              <React.Fragment key={deal.id}>
                                <Deal deal={deal} odd={index % 2 === 0} bookNowDeals={true} showGet={false} tapTimeslot={tapDealTimeslot} />
                                {
                                  deal.ad ? <AdItem ad={deal.ad} /> : null
                                }
                              </React.Fragment>
                            )
                          })
                        ) : null
                      )
                  }
                </div>
                <div className="load-more">
                  {hasMore && <Loading red></Loading>}
                </div>
            </div>
            <div className={`map-box ${mapVisible ? '' : 'hide'}`}>
              <div className="map">
                <CollectionMap
                  restaurants={collections}
                  region={regionData}
                  tapMarkers={(indexs) => tapMarkersHandler(indexs)}
                />
                {
                  mapSelectedRestaurants.length ? (
                    <div className="map-restaurant">
                      {
                        mapSelectedRestaurants.map(res => (
                          <Restaurant restaurant={res} key={res.id} only={mapSelectedRestaurants.length === 1} region={region} />
                        ))
                      }
                    </div>
                  ) : null
                }
              </div>
            </div>
          </div>

          <div className="filter-part" onClick={() => setSortModalVisible(true)}>
            {
              guide.seats?.length && guide.dates?.length ? (
                <div className="date-filter">
                  {extraParams.date === today ? t('Collection.Today') : otherDayLang}
                  <div className="point"></div>
                  {extraParams.seats} {t('Collection.Pax')}
                </div>
              ) : null
            }
            <div className="sort-filter">
              <div>
                <img className="icon" src={isDark ? curSortItem.icon_white : curSortItem.icon_grey} alt="" />
                <div className="sort-text">{curSortItem.name}</div>
              </div>
            </div>
          </div>

          <ListSortModal
            visible={showSortModal}
            toggleVisible={setSortModalVisible}
            seatsList={guide.seats}
            datesList={guide.dates}
            dates={dates}
            sortList={guide.sort}
            extraParams={extraParams}
            daysLang={daysLang}
            apply={applyFilter}
          />

          {
            (bookingVisible&&!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) && <BookingForm webUrl={webUrl} onHide={setBookingVisible} />
          }
        </div >
      </InfiniteScroll>
      <Footer isFixed={true}/>
     {/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && <Drawer
        className="booking-drawer deal-drawer collection-drawer"
        placement="bottom"
        closable={false}
        destroyOnClose
        onClose={() => setBookingVisible(false)}
        visible={bookingVisible}
      >
        <div className="booking-form--collection">
          <div className="booking-form--close">
            <span>{t("WEB.Book now")}</span>
            <i className="icon icon-close" onClick={()=>setBookingVisible(false)}></i>
          </div>
          <iframe id='web_form' src={webUrl} frameBorder='0'></iframe>
        </div>
      </Drawer>}
    </div>
  );
};

const AdItem = ({ ad }) => {
  return (
    <a href={ad.href} target="_blank" className="ad-list-item" style={{ backgroundColor: ad.bg_color }}>
      <div className="cover">
        <img src={ad.image_url} alt="" />
      </div>
      <div className="desc" style={{ color: ad.color ? ad.color : '#000000' }}>
        <div>{ad.description}</div>
      </div>
      <div className="read-more" style={{ backgroundColor: ad.button?.bg_color }}>
        <div className="read-more-btn" style={{ color: ad.button?.color }}>{ad.button.text}</div>
      </div>
    </a>
  )
}

const ListSortModal = ({
  visible,
  toggleVisible,
  seatsList = [],
  datesList = [],
  dates = [],
  sortList = [],
  extraParams,
  daysLang,
  apply
}) => {
  const { t } = useTranslation();
  const [curDate, setCurDate] = useState()
  const [curSeats, setCurSeats] = useState()
  const [curSort, setCurSort] = useState()

  useEffect(() => {
    setCurDate(extraParams.date)
    setCurSeats(extraParams.seats)
    setCurSort(extraParams.sort)
  }, [extraParams])

  const showApply = useMemo(() => {
    return !!(seatsList?.length || datesList?.length)
  }, [seatsList, datesList])

  const applyFilter = (sort) => {
    apply({
      seats: curSeats,
      date: curDate,
      sort: sort || curSort
    })
    toggleVisible(false)
  }

  const showFeaturedQA = () => {
    Modal.confirm({
      icon: null,
      className: 'feature-qa-modal',
      content: t("Collection.featured-tip"),
      okText: t("VOUCHERS.Understood"),
      okType: 'default',
      cancelText: ' ',
      autoFocusButton: null,
      cancelButtonProps: {
        type: 'text'
      }
    })
  }

  const selectSort = (item) => {
    setCurSort(item.value)
    if (!showApply) {
      applyFilter(item.value)
    }
  }

  return visible && (
    <div className="sort-modal-mask" onClick={() => toggleVisible(false)}>
      <div className="inner" onClick={e => e.stopPropagation()}>
        <div className="title">
          {t("Collection.Your table")}
          <i className="icon icon-close" onClick={() => toggleVisible(false)}></i>
        </div>
        <div className="filter-content" style={{ minHeight: ((sortList.length) * 34 + 30) + 'px' }}>
          {
            seatsList?.length ? (
              <div className="section">
                <div className="section-title">{t("Collection.party-size")}</div>
                <div className="seats">
                  {
                    seatsList.map(item => (
                      <div key={item.seat} className={`seat ${curSeats === item.seat ? 'active' : ''}`} onClick={() => setCurSeats(item.seat)}>{item.seat}</div>
                    ))
                  }
                </div>
              </div>
            ) : null
          }
          {
            datesList?.length ? (
              <div className="section">
                <div className="section-title">{t("Collection.date")}</div>
                <div className="dates">
                  {
                    dates.map(item => (
                      <div key={item.value} className={`date-item ${curDate === item.value ? 'active' : ''}`} onClick={() => setCurDate(item.value)}>
                        <div className="date">{item.date}</div>
                        <div className="day">{today === item.value ? t('Collection.Today') : daysLang[item.day]}</div>
                      </div>
                    ))
                  }
                </div>
              </div>
            ) : null
          }
          <div className="section sort-section">
            <div className="section-title">{t("Collection.sort-by")}</div>
            <div>
              {
                sortList.map(item => (
                  <div key={item.value} className={`sort-item ${curSort === item.value ? 'active' : ''}`} onClick={() => selectSort(item)}>
                    <img className="icon" src={curSort === item.value ? item.icon_red : (isDark ? item.icon_white : item.icon_grey)} alt="" />
                    {item.name}
                    {
                      item.value === 'featured' && <i className="icon icon-qa" onClick={showFeaturedQA}></i>
                    }
                  </div>
                ))
              }
            </div>
          </div>
          {
            showApply && (
              <div className="footer-btns">
                <div className="apply-btn" onClick={() => applyFilter()}>{t("Footer.Confirm")}</div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  )
}

const BookingForm = ({ webUrl, onHide }) => {
  const { t } = useTranslation();
  return (
    <div className="booking-form--mask" onClick={() => onHide(false)}>
      <aside className="booking-form--aside" onClick={e => e.stopPropagation()}>
      <div className="booking-form--close" style={{margin:'15px 0 10px 0'}}>
        <span>{t("WEB.Book now")}</span>
        <i className="icon icon-close" onClick={()=>onHide(false)}></i>
      </div>
        <iframe id="web_form" src={webUrl} frameBorder="0"></iframe>
      </aside>
    </div>
  )
}

export default ResCollection;
