import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Skeleton, Drawer } from "antd";
import { slimImage } from "@/service/utils";
import DefaultImage from "@/images/no_dish_picture.svg";
import { RightOutlined } from "@ant-design/icons";
import { Header } from "@/components";
import { FullMenu } from "./FullMenu";

/**菜单 */
export const Menus = ({ menus }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [tap, setTap] = useState("ALL");
  function setDrawer(curTap) {
    setVisible(true);
    setTap(curTap);
  }
  return (
    <section className="page-restaurant--menu">
      <h3>{t("WEB.Menu")}</h3>
      <h4 hidden={menus.length === 0}>{t("WEB.Popular dishes")}</h4>
      {menus.length ? (
        <div className="menu-list">
          <div className="menu-wrapper">
            {menus.map((item) => {
              return (
                <div className="menu-list-item" key={item.id} onClick={() => setDrawer("Popular")}>
                  {item.photos.length ? (
                    <img
                      src={slimImage(item.photos[0].image_url+'-thumb', "/w/180/h/124")}
                      alt=""
                    />
                  ) : (
                    <img src={DefaultImage} />
                  )}
                  <footer>
                    <div className="small">{item.name}</div>
                  </footer>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
      <div
        className="hairline-bottom"
        style={{ height: 10, marginBottom: 10 }}
      ></div>
      <footer className="view-all flex" onClick={() => setDrawer("ALL")}>
        <span>{t("WEB.Full menu")}</span>
        <RightOutlined />
      </footer>
      {/* TODO: 查看菜单详情 */}
      <Drawer
        className="booking-drawer full-menu"
        placement="right"
        closable={false}
        destroyOnClose
        onClose={() => setVisible(false)}
        visible={visible}
      >
        <Header title={t("WEB.Menu")} onClick={() => setVisible(false)} />
        <FullMenu tap={tap} />
      </Drawer>
    </section>
  );
};
