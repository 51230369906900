import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from 'antd'
import { Button } from '@/components'

/**点评草稿 */
export const DraftReview = ({ restaurant, goReview }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false)
  const onSubmit = () => {
    try {
      let reviews = JSON.parse(localStorage.getItem('draft-reviews'))
      let index = reviews.findIndex(item => item.rid == restaurant.id)
      if (index >= 0) {
        reviews.splice(index, 1)
        localStorage.setItem('draft-reviews', JSON.stringify(reviews))
        setVisible(false)
      }
    } catch (err) {
    }
  }
  try {
    const draftReview = JSON.parse(localStorage.getItem('draft-reviews')).find(item => item.rid == restaurant.id)
    return (
      <section className="page-restaurant--draft-review">
        <h3>{t("REVIEW.Draft review")}</h3>
        <div className="date">{t("REVIEW.Last saved")}{draftReview.date}</div>
        <div className="review">
          <div className="review-left"><div className="review-text">{draftReview.content}</div></div>
          <div className="review-right">
            <div className="discard" onClick={() => setVisible(true)}>{t("REVIEW.Discard")}</div>
            <div className="submit" onClick={goReview}>{t("REVIEW.Submit")}</div>
          </div>
        </div>

        <Modal
          visible={visible}
          wrapClassName="dc-modal"
          width={300}
          footer={null}
          closable={false}
          keyboard={false}
          maskClosable={false}
          centered
          destroyOnClose
        >
          <p
            style={{ padding: "40px 40px 0 40px", fontSize: 13 }}
            dangerouslySetInnerHTML={{
              __html: t("REVIEW.Discard tip")
            }}
          ></p>
          <footer>
            <Button
              onClick={() => {
                setVisible(false);
              }}
            >
              {t("REVIEW.Nevermind")}
            </Button>
            <Button onClick={onSubmit} type="primary">
              {t("REVIEW.Discard")}
            </Button>
          </footer>
        </Modal>
      </section>
    );
  } catch (err) {
    return null
  }
};
