import React from "react";
import bookingsStore from "./bookings";
import dealStore from "./deal";
import memberStore from "./member";
import offersStore from "./offers";
import restaurantStore from "./restaurant";
import voucherStore from "./vouchers";
import paymentStore from "./payment";
import h5Store from "./h5"
import reviewStore from "./review"
import BrowseStore from "./browse"
import HomeStore from "./home"
import CommonStore from "./common"

export const StoresContext = React.createContext({
  bookingsStore,
  dealStore,
  memberStore,
  offersStore,
  voucherStore,
  restaurantStore,
  paymentStore,
  h5Store,
  reviewStore,
  BrowseStore,
  HomeStore,
  CommonStore
});

export const useStores = () => React.useContext(StoresContext);
