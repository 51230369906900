import React, { useEffect } from "react";
import { useStores } from "@/store";
import { useObserver } from "mobx-react-lite";
import { Affix } from "antd";

/**右侧栏 */
export const Aside = () => {
  const { dealStore } = useStores();
  useEffect(() => {
    window.iframeResize.create("#web_form");
  }, []);
  return useObserver(() => {
    return (
      <aside className="page-deal--aside">
        <Affix>
          <iframe id="web_form" src={dealStore.webform} frameBorder="0"></iframe>
        </Affix>
      </aside>
    );
  });
};
