import React, { useState, useEffect, useMemo, memo, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useObserver } from "mobx-react-lite";
import { Upload, Drawer, Affix, message } from "antd";
import { useStores } from "@/store";
import { Header, Button } from "@/components";
import "./index.less";


/**
 * 餐厅评论
 */
 export const Review = ({ onClose, restaurant, onSubmitReview }) => {
  const { reviewStore } = useStores();
  const { t } = useTranslation();
  const { id: rid } = restaurant;
  const [tip, setTip] = useState('')
  const [form, setForm] = useState({
    recommend: null,
    food: 0,
    service: 0,
    decor: 0,
    content: ''
  })
  const [photosVisible, setPhotosVisible] = useState(false)
  const [dishVisible, setDishVisible] = useState(false)
  console.log('-------1', form)
  const starChange = (rate) => {
    console.log(rate)
    setForm({
      ...form,
      ...rate
    })
  }
  const recommendChange = () => {
    if (form.recommend) {
      setForm({
        ...form,
        recommend: 0
      })
    } else {
      setForm({
        ...form,
        recommend: 1
      })
    }
  }
  const saveDescribe = (desc) => {
    setForm({
      ...form,
      content: desc
    })
  }
  const tapPickedPhoto = useCallback((index) => {
    reviewStore.curPhotoIndex = index
    setPhotosVisible(true)
  })
  const pickedPhotos = useCallback(() => {
    reviewStore.curPhotoIndex = 0
    setPhotosVisible(true)
  })
  const onSaveDraft = () => {
    onClose(false)
    reviewStore.saveDraft(rid, form).then(() => {
      reviewStore.reviewInit()
    })
  }
  const onSubmit = () => {
    let options = {
      rating_cuisine: form.food,
      rating_atmosphere: form.decor,
      rating_service: form.service,
      recommended: form.recommend,
      review_text: form.content
    }
    onSubmitReview('restaurant', rid, options)
  }
  const onReviewBack = () => {
    reviewStore.reviewInit()
    onClose(false)
  }
  useEffect(() => {
    reviewStore.getReviewsTips(rid).then(setTip)
    try {
      let reviews = JSON.parse(localStorage.getItem('draft-reviews'))
      let draftReview = reviews.find(item => item.rid == rid)
      setForm({
        recommend: draftReview.recommend,
        food: draftReview.food,
        service: draftReview.service,
        decor: draftReview.decor,
        content: draftReview.content,
      })
      reviewStore.photos = draftReview.photos || []
    } catch (err) {
    }
  }, [])
  return (
    <div className="page-review" data-aside={true}>
      <Header title={t("REVIEW.Create")} onClick={onReviewBack} />
      {
        !!tip && (
          <section className="tip">
            <i className="icon icon-tip"></i>
            <p>{ tip }</p>
          </section>
        )
      }
      <section>
        <h2>{t("REVIEW.Rate title")}</h2>
        <RateBox t={t} change={starChange} defaultRate={{food: form.food, service: form.service, decor: form.decor}} />
      </section>
      <section>
        <h2>{t("REVIEW.Describe title")}</h2>
        <DescribeBox t={t} onTapPhoto={tapPickedPhoto} onPicked={pickedPhotos} onDescribeBlur={saveDescribe} defaultDesc={form.content} />
      </section>
      <section>
        <h2>{t("REVIEW.Recommend title")}</h2>
        <div className="recommend-box" onClick={recommendChange}>
          <i className={`icon icon-small-heart ${form.recommend === 1 ? 'selected' : ''}`}></i>
          {t("REVIEW.Recommend")}
        </div>
      </section>
      <footer>
        <Button onClick={onSaveDraft} disabled={!(form.recommend || form.food || form.service || form.decor || reviewStore.photos.length || form.content)} style={{width: '112px'}}>
          {t("REVIEW.Save draft")}
        </Button>
        <Button type="primary" onClick={onSubmit} disabled={!(form.food && form.service && form.decor && form.content.length >= 15)} style={{width: '112px'}}>
          {t("REVIEW.Submit")}
        </Button>
      </footer>

      <Drawer
        className="tag-photos-drawer"
        placement="right"
        closable={false}
        destroyOnClose
        onClose={() => setPhotosVisible(false)}
        visible={photosVisible}
      >
        <TagPhotos t={t} onClose={() => setPhotosVisible(false)} onSelectDish={() => setDishVisible(true)} />
      </Drawer>

      <Drawer
        className="tag-photos-drawer"
        placement="right"
        closable={false}
        destroyOnClose
        onClose={() => setDishVisible(false)}
        visible={dishVisible}
      >
        <TagDish t={t} rid={rid} onClose={() => setDishVisible(false)} />
      </Drawer>
    </div>
  );
};

/**
 * 评分组件
*/
const RateBox = memo(({ t, change, defaultRate }) => {
  const [rate, setRate] = useState({
    food: 0,
    service: 0,
    decor: 0
  })
  const chooseStar = (e) => {
    if (e.target.dataset.score) {
      let newRate = {
        ...rate,
        [e.target.dataset.key]: +e.target.dataset.score
      }
      setRate(newRate)
      change && change(newRate)
    }
  }
  useEffect(() => {
    setRate(defaultRate)
  }, [defaultRate])
  return (
    <div className="rate-box">
      <div className="rate-line">
        <label htmlFor="#">{t("REVIEW.Taste")}</label>
        <div className="star-box" onClick={chooseStar}>
          <i className={`icon icon-star ${rate.food >= 2 ? 'selected' : ''}`} data-score="2" data-key="food"></i>
          <i className={`icon icon-star ${rate.food >= 4 ? 'selected' : ''}`} data-score="4" data-key="food"></i>
          <i className={`icon icon-star ${rate.food >= 6 ? 'selected' : ''}`} data-score="6" data-key="food"></i>
          <i className={`icon icon-star ${rate.food >= 8 ? 'selected' : ''}`} data-score="8" data-key="food"></i>
          <i className={`icon icon-star ${rate.food >= 10 ? 'selected' : ''}`} data-score="10" data-key="food"></i>
        </div>
      </div>
      <div className="rate-line">
        <label htmlFor="#">{t("REVIEW.Service")}</label>
        <div className="star-box" onClick={chooseStar}>
          <i className={`icon icon-star ${rate.service >= 2 ? 'selected' : ''}`} data-score="2" data-key="service"></i>
          <i className={`icon icon-star ${rate.service >= 4 ? 'selected' : ''}`} data-score="4" data-key="service"></i>
          <i className={`icon icon-star ${rate.service >= 6 ? 'selected' : ''}`} data-score="6" data-key="service"></i>
          <i className={`icon icon-star ${rate.service >= 8 ? 'selected' : ''}`} data-score="8" data-key="service"></i>
          <i className={`icon icon-star ${rate.service >= 10 ? 'selected' : ''}`} data-score="10" data-key="service"></i>
        </div>
      </div>
      <div className="rate-line">
        <label htmlFor="#">{t("REVIEW.Decoration")}</label>
        <div className="star-box" onClick={chooseStar}>
          <i className={`icon icon-star ${rate.decor >= 2 ? 'selected' : ''}`} data-score="2" data-key="decor"></i>
          <i className={`icon icon-star ${rate.decor >= 4 ? 'selected' : ''}`} data-score="4" data-key="decor"></i>
          <i className={`icon icon-star ${rate.decor >= 6 ? 'selected' : ''}`} data-score="6" data-key="decor"></i>
          <i className={`icon icon-star ${rate.decor >= 8 ? 'selected' : ''}`} data-score="8" data-key="decor"></i>
          <i className={`icon icon-star ${rate.decor >= 10 ? 'selected' : ''}`} data-score="10" data-key="decor"></i>
        </div>
      </div>
    </div>
  )
})

/**
 * 描述+选择图片
 */
const DescribeBox = memo(({ t, onPicked, onTapPhoto, onDescribeBlur, defaultDesc }) => {
  const { reviewStore } = useStores();

  return useObserver(() => {
    const [desc, setDesc] = useState('')
    const [descTip, descTipToggle] = useState(true)
    const saveDescribe = () => {
      onDescribeBlur(desc)
    }
    const pickPhotos = useMemo(() => (event) => {
      event.persist();
      let files = event.target.files
      if (files.length + reviewStore.photos.length > 9) {
        message.warning(t("REVIEW.Max images"));
      } else {
        let photos = []
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          photos.push({
            filePath: '',
            file,
            size: file.size,
            fileType: file.type
          })
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = function () {
            photos[i].filePath = reader.result
          };
        }
        setTimeout(() => {
          reviewStore.photos = reviewStore.photos.concat(photos)
          onPicked()
          event.target.value = null;
        }, 500)
      }
    })
    const descInput = (e) => {
      setDesc(e.target.value)
      if (e.target.value?.length >= 15) {
        descTipToggle(false)
      } else {
        descTipToggle(true)
      }
    }
    useEffect(() => {
      setDesc(defaultDesc)
      if (defaultDesc.length >= 15) {
        descTipToggle(false)
      } else {
        descTipToggle(true)
      }
    }, [defaultDesc])
    return (
      <>
        <div className="descibe-box">
          <textarea rows="3" value={desc} placeholder={t("REVIEW.Describe placeholder")} onChange={descInput} onBlur={saveDescribe}></textarea>
          {descTip && <div className="desc-tip">{t("REVIEW.Describe tip")}</div>}
        </div>
        <div className="upload-box">
          {
            reviewStore.photos.map((item, index) => (
              <div className="uploaded-image" key={index} onClick={() => onTapPhoto(index)} style={{backgroundImage: `url(${item.filePath})`}}>
                {
                  item.dish_id || item.comment ? (
                    <div className="small-icons">
                      {!!item.dish_id && <i className="icon icon-menu"></i>}
                      {!!item.comment && <i className="icon icon-review"></i>}
                    </div>
                  ) : null
                }
              </div>
            ))
          }
          <div className="upload">
            <input type="file" onChange={pickPhotos} multiple accept="image/gif,image/jpeg,image/jpg,image/png,image/svg" />
            <i className="icon icon-camera"></i>
            <span>{t("REVIEW.Upload photos")}</span>
          </div>
        </div>
        <div className="points">{t("REVIEW.Points")}</div>
      </>
    )
  })
})

/**
 * 选择图片后，操作图片页面
 */
const TagPhotos = memo(({ t, onClose, onSelectDish }) => {
  const { reviewStore } = useStores();
  return useObserver(() => {
    const [commentVisible, setCommentVisible] = useState(false)
    const removePhoto = () => {
      reviewStore.photos.splice(reviewStore.curPhotoIndex, 1)
      if (reviewStore.photos.length) {
        reviewStore.curPhotoIndex = 0
      } else {
        onClose()
      }
    }
    const removeDish = () => {
      reviewStore.photos[reviewStore.curPhotoIndex].dish_id = null
      reviewStore.photos[reviewStore.curPhotoIndex].dish_name = null
      reviewStore.photos[reviewStore.curPhotoIndex].dish_type = null
    }
    const showComment = () => {
      setCommentVisible(true)
    }
    const saveComment = comment => {
      reviewStore.photos[reviewStore.curPhotoIndex].comment = comment
      setCommentVisible(false)
    }
    const removeComment = () => {
      reviewStore.photos[reviewStore.curPhotoIndex].comment = ''
    }
    const pickPhotos = useMemo(() => (event) => {
      event.persist();
      let files = event.target.files
      if (files.length + reviewStore.photos.length > 9) {
        message.warning(t("REVIEW.Max images"));
      } else {
        let photos = []
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          photos.push({
            filePath: '',
            file,
            size: file.size,
            fileType: file.type
          })
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = function () {
            photos[i].filePath = reader.result
          };
        }
        setTimeout(() => {
          reviewStore.photos = reviewStore.photos.concat(photos)
          event.target.value = null;
        }, 500)
      }
    })
    const photo = reviewStore.photos[reviewStore.curPhotoIndex] || {}
    return (
      <div className="tag-photos">
        <header>
          <div className="left" onClick={onClose}><i className="icon icon-close"></i></div>
          <div className="center">{t("REVIEW.Tag photos")}</div>
          <div className="right" onClick={onClose}>
            {
              reviewStore.hasDishes ? <button>{t("REVIEW.Done")}</button> : <span>{t("REVIEW.Skip")}</span>
            }
          </div>
        </header>
        <div className="content" style={{backgroundImage: `url(${photo.filePath})`}}>
          <div className="content-bottom">
            <div>
              <div className="name" onClick={removePhoto}>{t('REVIEW.Remove')}</div>
              <i className="icon icon-trash" onClick={removePhoto}></i>
            </div>
            <div>
              <div className="value" onClick={onSelectDish}>{photo.dish_name}</div>
              {!photo.dish_id && <div className="name" onClick={onSelectDish}>{t('REVIEW.Tag dish')}</div>}
              <i className="icon icon-menu" onClick={onSelectDish}></i>
              {!!photo.dish_id && <i className="icon icon-close dark" onClick={removeDish}></i>}
            </div>
            <div>
              <div className="value">{photo.comment}</div>
              {!photo.comment && <div className="name" onClick={showComment}>{t('REVIEW.Add comment')}</div>}
              <i className="icon icon-review" onClick={showComment}></i>
              {!!photo.comment && <i className="icon icon-close dark" onClick={removeComment}></i>}
            </div>
          </div>
        </div>
        <div className="bottom">
          {
            reviewStore.photos.map((item, index) => (
              <div className={`tag-photo ${reviewStore.curPhotoIndex === index ? 'active' : ''}`} key={index} style={{backgroundImage: `url(${item.filePath})`}} onClick={() => reviewStore.curPhotoIndex = index}></div>
            ))
          }
          <div className="upload">
            <input type="file" onChange={pickPhotos} multiple accept="image/gif,image/jpeg,image/jpg,image/png,image/svg" />
            <i className="icon icon-camera"></i>
            <span>{t("REVIEW.Upload photos")}</span>
          </div>
        </div>
        { commentVisible && <PhotoComment t={t} onClose={() => setCommentVisible(false)} onDone={saveComment} />}
      </div>
    )
  })
})

/**
 * 选择图片对应菜品
 */
const TagDish = memo(({ t, rid, onClose }) => {
  const { reviewStore } = useStores();
  return useObserver(() => {
    const [categories, setCategories] = useState([])
    const [keyword, setKeyword] = useState('')
    const [searchPanelVisible, setSeachPanelVisible] = useState(false)
    const [filterDishes, setFilterDishes] = useState([])
    const [searched, setSearched] = useState(false)
    const switchDish = (index) => {
      categories[index].active = !categories[index].active
      setCategories(categories)
    }
    const selectDish = (item) => {
      let photo = Object.assign({}, reviewStore.photos[reviewStore.curPhotoIndex])
      photo.dish_type = item.type || 'menu'
      photo.dish_id = item.id
      photo.dish_name = item.name
      reviewStore.photos[reviewStore.curPhotoIndex] = photo
      onClose()
    }
    const inputKeyword = (e) => {
      setKeyword(e.target.value)
      reviewStore.filterDishes(rid, e.target.value).then(res => {
        setFilterDishes(res)
        setSearched(true)
      })
    }
    useEffect(() => {
      reviewStore.getReviewDishes(rid).then(setCategories)
    }, [])
    return (
      <div className="tag-dish">
        <header>
          <div className="left" onClick={onClose}><i className="icon icon-arrow-left"></i></div>
          <div className="center">{t("REVIEW.Tag dish")}</div>
          <div className="right"></div>
        </header>
        <div className="search-box">
          <div className="search-inner">
            <i className="icon icon-search"></i>
            <input type="text" value={keyword} placeholder={t("REVIEW.Search dishes")} onChange={inputKeyword} onFocus={() => setSeachPanelVisible(true)} />
          </div>
        </div>
        {
          searchPanelVisible && (
            <div className="search-panel" onClick={() => setSeachPanelVisible(false)}>
              {
                !filterDishes.length && searched ? (
                  <div className="no-dish">{t("REVIEW.No dish")}</div>
                ) : null
              }
              {
                filterDishes.map(item => (
                  <div className="dish-item" key={item.id} onClick={(e) => {
                    e.stopPropagation()
                    selectDish(item)
                  }}>
                    <img src={item.coverImg} alt="" />
                    <div className="dish-name">{item.name}</div>
                  </div>
                ))
              }
            </div>
          )
        }
        {
          !!reviewStore.specialDishes.length && (
            <div className="dish-part">
              {
                reviewStore.specialDishes.map(spItem => (
                  <div key={spItem.id}>
                    <div className="title">{spItem.title} - {spItem.meal_type_humanize}</div>
                    {
                      spItem.courses.map(item => (
                        <div className="dish-item" key={item.id} onClick={() => selectDish(item)}>
                          <img src={item.coverImg} alt="" />
                          <div className="dish-name">{item.name}</div>
                        </div>
                      ))
                    }
                  </div>
                ))
              }
            </div>
          )
        }
        {
          !!reviewStore.popularDishes.length && (
            <div className="dish-part">
              <div className="title">{t("REVIEW.Popular")}</div>
              {
                reviewStore.popularDishes.map(item => (
                  <div className="dish-item" key={item.id} onClick={() => selectDish(item)}>
                    <img src={item.coverImg} alt="" />
                    <div className="dish-name">{item.name}</div>
                  </div>
                ))
              }
            </div>
          )
        }
        {
          !!categories.length && (
            <div className="dish-part">
              <div className="title">{t("REVIEW.Full menu")}</div>
              {
                categories.map((item, index) => (
                  <div className={`dish-box ${item.active ? 'active' : ''}`} key={item.key}>
                    <div className="dish-title" onClick={() => switchDish(index)}>
                      <div className="category-name">{item.key}</div>
                      <i className={`icon icon-arrow-right ${item.active ? 'active' : ''}`}></i>
                    </div>
                    <div className="dish-inner">
                      {
                        reviewStore.fullDishes[item.key].map(dish => (
                          <div className="dish-item" key={dish.id} onClick={() => selectDish(dish)}>
                            <img src={dish.coverImg} alt="" />
                            <div className="dish-name">{dish.name}</div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                ))
              }
            </div>
          )
        }
      </div>
    )
  })
})

/**
 * 图片评论
 */
const PhotoComment = memo(({ t, onClose, onDone }) => {
  const { reviewStore } = useStores();
  const [comment, setComment] = useState(reviewStore.photos[reviewStore.curPhotoIndex].comment || '')
  const saveComment = () => {
    onDone(comment)
  }
  return (
    <div className="photo-comment-mask" onClick={onClose}>
      <div className="photo-comment-inner" onClick={e => e.stopPropagation()}>
        <div className="input-box">
          <input 
            type="text" 
            placeholder={t("REVIEW.Comment placeholder")}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            adjust-position="false"
            autoFocus
          />
          <div className="points">{t("REVIEW.Points")}</div>
        </div>
        <button className={`${comment ? 'active' : ''}`} onClick={saveComment}>{t("REVIEW.Done")}</button>
      </div>
    </div>
  )
})
