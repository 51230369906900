import React, { useState, useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useStores } from "@/store";
import { Drawer,message } from "antd";
import { Loading } from "@/components";
import { ReviewItem, ReviewDetail, WriteReview } from "@/components";
import InfiniteScroll from "react-infinite-scroller";

import "./index.less";

let loading = false;
let submiting = false;

/**
 * 我的评论-列表
 *
 */
const MyReviews = () => {
  const { t } = useTranslation();
  const { reviewStore, memberStore,restaurantStore,dealStore } = useStores();
  const [reviews, setReviews] = useState([])
  const [infiniteKey, setInfiniteKey] = useState(0)
  const [hasMore, setHasMore] = useState(true);
  const [visible, setVisible] = useState(false);
  const [curMember, setCurMember] = useState({});
  const [currentReview, setCurrentReview] = useState({});
  const [reviewVisible, setReviewVisible] = useState(false);
  const [restaurant,setRestaurant] = useState(null);
  const [deal,setDeal] = useState(null);
  const [submitLoading,setSubmitLoading] = useState(false)
  useEffect(() => {
    getMyReviews();
    memberStore.show().then(() => {
      setCurMember(memberStore.member)
    })
  }, []);

  /**获取列表 */
  async function getMyReviews(page) {
    if (loading) return;
    loading = true;
    try {
      let res = await reviewStore.getMyReviews(page);
      if (res) {
        if (page === 1) {
          setReviews(res)
        } else {
          setReviews(reviews.concat(res))
        }
        if (res.length < 10) {
          setHasMore(false);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading = false;
    }
  }

  function loadMore(page) {
    if (loading) return false
    getMyReviews(page);
  }

  /**查看详情 */
  function onShow(item) {
    const type = item?.source?.type;
    setRestaurant(null);
    setDeal(null);
    if(type === "restaurant"){
      restaurantStore.getRestaurantById(item.source.id).then(setRestaurant)
    }
    if(type === "deal"){
      dealStore.getDeal(item.source.id).then(setDeal)
    }
    setCurrentReview(item);
    setVisible(true);
  }

  function onPreview() {
    console.log(123)
  }

  function onDelete(id) {
    let index = reviews.findIndex(item => item.id === id)
    if (index >= 0) {
      let _reviews = [...reviews]
      _reviews.splice(index, 1)
      setReviews(_reviews)
      setVisible(false)
      setReviewVisible(false)
    }
  }

  function onEdit(review) {
    setCurrentReview(review)
    setReviewVisible(true)
    setVisible(false)
  }

  const onSubmitReview = (type, rid, options, sid = '') => {
    if (submiting) return
    submiting = true
    setSubmitLoading(true)
    reviewStore.submitEditReview(type, rid, sid, options).then(res => {
      setSubmitLoading(false)
      if (res && res.status) {
        setReviewVisible(false)
        let _reviews = [...reviews]
        let listIndex = _reviews.findIndex(item => item.id === sid)
        if (listIndex >= 0) {
          res.review.timestamp = new Date().getTime()
          res.review.liked = _reviews[listIndex].liked
          _reviews.splice(listIndex, 1, res.review)
          setReviews(_reviews)
          setCurrentReview(res.review)
        }
        try {
          let draftReviews = JSON.parse(localStorage.getItem('draft-reviews'))
          let index = draftReviews.findIndex(item => item.rid == restaurant.id)
          if (index >= 0) {
            draftReviews.splice(index, 1)
            localStorage.setItem('draft-reviews', JSON.stringify(draftReviews))
          }
        } catch (err) {
          console.log(err)
        } finally {
          reviewStore.reviewInit()
        }
        message.success(t("REVIEW.Saved Successfully"));
      }
    }).finally(() => {
      submiting = false
    })
  }

  return useObserver(() => {
    return (
      <div className="dc-my-reviews">
        {!reviews.length && <Empty />}
        <InfiniteScroll
          initialLoad={false}
          pageStart={1}
          loadMore={loadMore}
          hasMore={hasMore}
          useWindow={false}
          key={infiniteKey}
        >
          {
            reviews.map(item => {
              return (
                <ReviewItem 
                  key={`${item.id}-${item.timestamp}`} 
                  review={item}
                  onPreview={onPreview} 
                  onDelete={onDelete}
                  onEdit={onEdit}
                  onClick={() => onShow(item)} 
                />
              )
            })
          }
          <div className="load-more">
            {hasMore && <Loading red></Loading>}
          </div>
        </InfiniteScroll>

        {/* 详情 */}
        <ReviewDetail
          review={currentReview}
          onPreview={onPreview}
          visible={visible}
          setVisible={setVisible}
          onDelete={onDelete}
          onEdit={onEdit}
          member={curMember}
          restaurant={restaurant}
          deal={deal}
        />

        <Drawer
          className="booking-drawer profile-item-inner-drawer"
          placement="right"
          closable={false}
          destroyOnClose
          onClose={() => setReviewVisible(false)}
          visible={reviewVisible}
        >
          <WriteReview 
            restaurant={currentReview?.source} 
            editReview={currentReview}
            onClose={setReviewVisible} 
            onSubmitReview={onSubmitReview} 
            submitLoading={submitLoading}
          />
        </Drawer>
      </div>
    );
  });
};

/**没有评论 */
const Empty = () => {
  const { t } = useTranslation();
  return (
    <div className="no-vouchers">
      <i></i>
      <p>{t("REVIEW.Empty")}</p>
    </div>
  );
};

export default MyReviews;
