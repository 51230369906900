import React, { useState, useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Drawer } from "antd";
import { useStores } from "@/store";
import { Header, Loading, ListItem, Icon, Button, Locate } from "@/components";
import Detail from "./components/detail";
import Appeal from "./components/appeal";
import "./index.less";
import InfiniteScroll from "react-infinite-scroller";
import { getQuery } from "@/service/http";
import EmptyBooking from "@/images/empty_bookings.svg";

let titleCache = {};
let loading = false;

/**
 * 我的预定-列表
 *
 */
const MyBookings = () => {
  const { t } = useTranslation();
  const { bookingsStore, memberStore } = useStores();
  const [visible, setVisible] = useState(false);
  const [appealVisible, setAppealVisible] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [booking, setBooking] = useState({});
  const [selected, setSelected] = useState("all"); //保存book需要出发的弹窗类型
  const [triggerType, setTriggerType] = useState("");
  const bookingType = [
    {
      label: t("BOOKING.All"),
      value: "all"
    },
    {
      label: t("BOOKING.Upcoming"),
      value: "upcoming"
    },
    {
      label: t("BOOKING.Unpaid"),
      value: "need_pay"
    },
    {
      label: t("BOOKING.History"),
      value: "past"
    }
  ];
  useEffect(() => {
    getBookings(1);

    // 直接打开申诉弹窗
    const { reservationCode, reservationId, widget } = getQuery();
    if (reservationCode && reservationId && widget === "appeal") {
      bookingsStore.getBookingById(reservationCode, reservationId).then(data => {
        setBooking({
          id: data.id,
          date: data.formated_date
        });
        setAppealVisible(true);
      });
    }
  }, []);

  /**获取预定列表 */
  async function getBookings(page, type = "all", isChange = false) {
    try {
      if (loading) return;
      loading = true;
      let { data = [] } = await bookingsStore.getBookings(
        {
          type,
          page
        },
        isChange
      );
      if (data.length < 10) {
        setHasMore(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading = false;
    }
  }

  function loadMore(page) {
    getBookings(page, selected);
  }

  /**点击查看详情 */
  async function onItemChange(item, type = "") {
    setTriggerType(type);
    let booking = await bookingsStore.getReservation(item.id, item.reservation_code)
    bookingsStore.currentBooking = booking;
    bookingsStore.setBookingOperational(booking.id);
    setVisible(true);
  }
  let timer = null;
  function handleChange(item) {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      if (item.value === selected) return;
      setSelected(item.value);
      setHasMore(true);
      getBookings(1, item.value, true);
    }, 500);
  }

  return useObserver(() => {
    const { bookingsOfType } = bookingsStore;
    return (
      <div className="page-bookings">
        <Locate />
        {/* <Header title={t("WEB.My bookings")} /> */}
        <Drawer
          className="booking-drawer profile-item-inner-drawer"
          placement="right"
          closable={false}
          destroyOnClose
          onClose={() => setVisible(false)}
          visible={visible}
        >
          <Header title={t("WEB.Booking details")} onClick={() => setVisible(false)} />
          <Detail triggerType={triggerType} openTabName={selected}/>
        </Drawer>

        {/* 直接申诉 */}
        <Drawer
          className="booking-drawer appeal-drawer"
          placement="right"
          closable={false}
          destroyOnClose
          onClose={() => setAppealVisible(false)}
          visible={appealVisible}
        >
          <Header title={t("WEB.Appeal")} onClick={() => setAppealVisible(false)} />
          <Appeal date={booking.date} id={booking.id} />
        </Drawer>

        <div className="booking-list">
          <div className="booking-nav">
            {bookingType.map((item, index) => {
              return (
                <span key={index} data-selected={selected === item.value} onClick={() => {
                  handleChange(item)
                }}>
                  {item.label}
                </span>
              );
            })}
          </div>
          <div className="booking-scroll" key={selected}>
            {!bookingsOfType[selected].length ? (
              <div className="booking-empty">
                <img src={EmptyBooking} alt="" />
                <div className="txt">
                  <div>{t("BOOKING.PLACEHOLDER.TXT1")}</div>
                  <div>{t("BOOKING.PLACEHOLDER.TXT2")}</div>
                </div>
              </div>
            ) : (
              <InfiniteScroll initialLoad={false} pageStart={1} loadMore={loadMore} hasMore={hasMore} useWindow={false}>
                {bookingsOfType[selected].map((item, index) => {
                  return <BookItem {...item} key={item.id + "_" + index} onClick={type => onItemChange(item, type)} />;
                })}
                <div className="load-more">{hasMore && <Loading red></Loading>}</div>
              </InfiniteScroll>
            )}
          </div>
        </div>
      </div>
    );
  });
};

const BookItem = ({
  id,
  restaurant,
  formated_date,
  formated_time,
  reservation_seats,
  section_title,
  status_lng,
  status,
  onClick,
  can_review,
  review,
  point = {},
  can_cancel,
  can_update
}) => {
  let { t } = useTranslation();
  let date = formated_date;
  let time = formated_time;
  if (!titleCache[section_title]) {
    titleCache[section_title] = id;
  }
  return (
    <div className="book-content">
      {/* 只展示每组第一个标题 */}
      {/* {titleCache[section_title] === id && <h2>{section_title}</h2>} */}
      <div className="book-item hairline-surround" status={status}>
        <div className="book-tag">
          <i className={`tag icon icon-book-${status}`}></i>
        </div>
        {restaurant && (
          <div className="book-restaurant hairline-bottom" onClick={onClick}>
            <div className="cover">
              <img src={restaurant.cover + "-thumb"} alt="" />
            </div>
            <div className="basicInfo">
              <div className="basicInfo-restaurant">
                <div className="name">{restaurant.name}</div>
                <i className="arrow icon icon-arrow-right"></i>
              </div>
              <div className="basicInfo-time">
                <div>
                  <i className="icon icon-booking"></i>
                  <span>{date}</span>
                </div>
                <div>
                  <div>
                    <i className="icon icon-time"></i>
                    <span>{time}</span>
                  </div>
                  <div>
                    <i className="icon icon-seat"></i>
                    <span>{reservation_seats}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* <div className='book-detail' onClick={onClick}>
          <ListItem title={<Icon text={date} icon='icon icon-time' />} value={time}></ListItem>
          <ListItem
            title={<Icon text={reservation_seats} icon='icon icon-seat' />}
            value={point && point.debit ? <Icon text={t('WEB.pointNumber', { points: point.debit })} icon='icon icon-vip-grey' /> : null}
          ></ListItem>
        </div> */}
        <footer className="book-footer">
          <div className="status-button" status={status}>
            {status_lng}
            <div className="point">
              {point && point.debit ? <Icon text={t("WEB.pointNumber", { points: point.debit })} icon="icon icon-vip-grey" /> : null}
            </div>
          </div>
          <ActionButton
            restaurant={restaurant}
            status={status}
            can_cancel={can_cancel}
            can_update={can_update}
            can_review={can_review}
            review={review}
            onClick={onClick}
          />
        </footer>
      </div>
    </div>
  );
};

/**操作按钮 */
const ActionButton = ({ restaurant, status, can_cancel, can_update, review, can_review, onClick }) => {
  let { t } = useTranslation();
  const { lang = "en" } = getQuery();
  /**查看餐厅详情 */
  function onRestaurantClick() {
    const [host, url] = restaurant.website_detail_url.split("//");
    const [str1, ...str2] = url.split("/");
    window.open(`${host}//${str1}/${lang}/${str2.join("/")}`, "_blank");
  }
  if (status === "need_pay") {
    return (
      <Button type="primary" onClick={() => onClick("payNow")}>
        {t("PAYMENT.Pay now")}
      </Button>
    );
  }
  if (status === "no_show") {
    // 此处由于列表上没有can_appeal字段来判断是否可以进行Appeal操作 因此直接判断status是否为no_show来进行显示按钮
    // 在后面的detail弹窗中进行校验是否打开Appeal窗口
    return <Button onClick={() => onClick("appeal")}>{t("WEB.Appeal")}</Button>;
  }
  if (status === "cancelled") {
    return <Button onClick={onRestaurantClick}>{t("BOOKING.BookAgain")}</Button>;
  }
  // if (can_update) {
  //   return <Button onClick={() => onClick("modify")}>{t("MODIFY")}</Button>;
  // }
  // if (can_cancel) {
  //   return <Button onClick={() => onClick("cancel")}>{t("CANCEL")}</Button>;
  // }
  if (can_review) {
    return (
      <Button type="primary" onClick={() => onClick("review")}>
        {t("WEB.Review")}
      </Button>
    );
  }
  if (review) {
    return <Icon text={t("WEB.Reviewed")} icon="icon icon-reviewed" />;
  }
  return (
    <Button type="primary" onClick={onClick}>
      {t("WEB.Details")}
    </Button>
  );
};

export default MyBookings;
