import React, {useState, useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import Gallery from "react-bnb-gallery";
import "react-bnb-gallery/dist/style.css";
import moment from "moment";

function padLeft(num) {
    return num < 10 ? "0" + num : num;
}

/**横幅 */
export const Banner = ({date_end, photos}) => {
    const {t} = useTranslation();
    const [times, setTimes] = useState(["00", "00", "00"]);
    const [show, setShow] = useState(false);
    const endDate = moment(date_end).set("hour", 0).format("yyyy-MM-DD HH:mm:ss");
    const diffDays = moment(endDate).diff(new Date(), "day");

    function showPhotos() {
        setShow(true);
    }

    const photoList = photos.map((i) => {
        return {
            photo: i.image_url,
        };
    });

    function computeTime() {
        let diff = (new Date(endDate) - new Date()) / 1000;
        if (diff >= 0) {
            let newTime = [
                diffDays,
                padLeft(parseInt((diff / 60 / 60) % 24, 10)),
                padLeft(parseInt((diff / 60) % 60, 10)),
            ];
            setTimes(newTime);
        } else {
            setTimes(["00", "00", "00"]);
        }
    }

    useEffect(() => {
        computeTime();
        let timer = setInterval(() => {
            computeTime();
        }, 60000);
        return () => {
            clearInterval(timer);
        };
    }, []);
    const showFooter = useMemo(() => {
        return diffDays > 0 && diffDays < 7;
    }, []);

    const coverImg = `${photos[0].image_url}?imageView2/1/w/347/h/260/interlace/1`.replace('??', '?')
    return (
        <React.Fragment>
            <section className="page-deal--banner">
                <div className="banner-inner" onClick={showPhotos}>
                    <img src={coverImg} className="banner-bg"/>
                    <div className="banner-tag" hidden={!photos.length}>
                        <i className="icon icon-pic"></i>
                        <span>{photos.length}</span>
                    </div>
                    <footer hidden={!showFooter}>
                        <span>{t("WEB.Valid until")}:</span>
                        <span className="time-slot">{times[0]}</span>
                        <span className="time-slot">{times[1]}</span>
                        <span className="time-slot">{times[2]}</span>
                    </footer>
                </div>
            </section>
            <Gallery
                onClose={() => setShow(false)}
                photos={photoList}
                show={show}
                showThumbnails={false}
            />
        </React.Fragment>
    );
};
