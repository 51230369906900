import React, { useState, useEffect, useRef } from "react";
import { useStores } from "../../store";
import "./index.less";

/**
 * 图标文字
 *
 * @param {*} {icon, text} 图标类名 附带文字
 * @returns
 */
export const Icon = ({ icon, text, onClick }) => {
  return (
    <span className="icon-tip" onClick={onClick}>
      <i className={icon}></i>
      <span>{text}</span>
    </span>
  );
};

/**
 * 文字列表
 *
 * @param {*} {title, value, desc} 左侧，右侧的组件
 * @returns
 */
export const ListItem = ({ title, value, desc, hidden, onClick }) => {
  return (
    <div className="list-item" onClick={onClick} hidden={hidden}>
      <div className="list-item--title">
        <strong>{title}</strong>
        <div className="list-item--desc">{desc}</div>
      </div>
      <div className="list-item--value">{value}</div>
    </div>
  );
};

/**
 * 圆形复选框
 *
 * @param {*} props
 */
export const Checkbox = (props) => {
  let [checked, setChecked] = useState(props.checked || false);
  let { card, noIcon } = props;
  const onChange = () => {
    let newVal = !checked;
    setChecked(newVal);
    props.onChange && props.onChange(newVal);
  };
  return (
    <div className="checkbox" data-card={card} onClick={onChange}>
      <div
        className="checkbox-icon"
        data-checked={checked}
        hidden={noIcon}
      ></div>
      <div className="checkbox-text">{props.children}</div>
    </div>
  );
};

/**
 * 表单错误提示
 *
 * @param {*} props
 * @returns
 */
export const FormHelper = (props) => {
  return (
    <div className="form-helper" data-tip={props.tip} hidden={props.hidden}>
      <i className="icon icon-info selected"></i>
      <span>{props.children}</span>
    </div>
  );
};

/**
 * 检测浏览器运行环境
 *
 * @returns
 */
export const Platform = () => {
  var userAgent = navigator.userAgent;
  var platform = {
    trident: userAgent.indexOf("Trident") > -1,
    presto: userAgent.indexOf("Presto") > -1,
    webKit: userAgent.indexOf("AppleWebKit") > -1,
    gecko: userAgent.indexOf("Gecko") > -1 && userAgent.indexOf("KHTML") == -1,
    mobile: !!userAgent.match(/AppleWebKit.*Mobile.*/),
    android:
      userAgent.indexOf("Android") > -1 || userAgent.indexOf("Linux") > -1, //android终端或者uc浏览器
    iPhone: userAgent.indexOf("iPhone") > -1, //是否为iPhone或者QQHD浏览器
    iPad: userAgent.indexOf("iPad") > -1, //是否iPad
    webApp: userAgent.indexOf("Safari") == -1, //是否web应该程序，没有头部与底部
  };
  var isMobile =
    platform.mobile || platform.android || platform.iPhone || platform.iPad;

  document.body.classList.add(isMobile ? "is-mobile" : "is-pc");
  return null;
};

/**
 * 获取用户定位
 *
 * @returns
 */
export const Locate = () => {
  const { memberStore } = useStores();
  useEffect(() => {
    var map = L.map("locate-map").fitWorld();
    map.locate({ setView: true, maxZoom: 16 });
    map.on("locationfound", (ev) => {
      memberStore.position = ev.latlng;
      map.remove();
    });
    map.on("locationerror", (err) => {
      console.error("获取定位失败", err);
      map.remove();
    });
  }, []);
  return (
    <i
      id="locate-map"
      style={{
        visibility: "hidden",
        opacity: 1,
      }}
    ></i>
  );
};
