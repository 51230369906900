import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import "./index.less";

/**
 * 广告卡片
 *
 * @returns
 */
const Ads = ({ ad }) => {
  const { t } = useTranslation();

  return (
    <a className="dc-ads" target="_blank" href={ad.client_href.web}>
      <div className="dc-ads-inner" style={{ backgroundColor: ad.bg_color }}>
        <div className="dc-ads-cover">
          <img className="ad-cover" src={ad.image_url} alt="" />
        </div>
        <div className="dc-ads-desc" style={{ color: ad.color || '#000' }}>{ad.description}</div>
        <div className="dc-ads-read-more" style={{ backgroundColor: ad.button.bg_color }}>
          <div className="read-more-btn" style={{ color: ad.button.color }}>{ad.button.text}</div>
        </div>
      </div>
    </a>
  );
};

export default Ads;
