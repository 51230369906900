import React from "react";
import Icon from "@ant-design/icons";

/**向左箭头 */
export const BackIcon = (props) => {
  return (
    <Icon
      component={() => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
        >
          <rect fill="#fff" opacity="0" width="1em" height="1em" />
          <g transform="translate(-314.316 -552.264)">
            <path
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeWidth="1.8px"
              d="M331.032,572.948l-8.686-8.686a.1.1,0,0,1,0-.141l8.687-8.857"
            />
          </g>
        </svg>
      )}
      {...props}
    />
  );
};

/**提示 */
export const TipIcon = (props) => {
  return (
    <Icon
      component={() => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
        >
          <defs>
            <clipPath id="a">
              <rect class="a" width="1em" height="1em" />
            </clipPath>
          </defs>
          <g clipPath="fill:none">
            <rect fill="none" width="1em" height="1em" />
            <g transform="translate(-1004.382 -410.005)">
              <circle
                fill="none"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="1.5px"
                stroke="currentColor"
                cx="9.331"
                cy="9.331"
                r="9.331"
                transform="translate(1007.382 413.005)"
              />
              <path
                fill="currentColor"
                d="M1017.764,427.1c.022.573-.958,1.128-1.731,1.128a1.892,1.892,0,0,1-1.892-1.891,1.557,1.557,0,0,1,.445-1.156l.25-.342a21.936,21.936,0,0,0,1.17-2.144,4.341,4.341,0,0,0,.457-1.413c0-.692-.334-1.356-.942-1.289l-.394.046a1.8,1.8,0,0,1,1.4-.752,1.657,1.657,0,0,1,1.639,1.639,7.215,7.215,0,0,1-1.208,3.667c-.152.252-.264.4-.488.709a2.931,2.931,0,0,0-.66,1.213c.008.543.474.776,1.017.776A4.007,4.007,0,0,0,1017.764,427.1Z"
              />
              <circle
                fill="currentColor"
                cx="1.145"
                cy="1.145"
                r="1.145"
                transform="translate(1017.068 416.126)"
              />
            </g>
          </g>
        </svg>
      )}
      {...props}
    />
  );
};

/**复制 */
export const CopyIcon = (props) => {
  return (
    <Icon
      component={() => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
        >
          <rect fill="none" opacity="0" width="1em" height="1em" />
          <g transform="translate(-643.232 -410.336)">
            <rect
              fill="none"
              strokeMiterlimit="10"
              strokeWidth="1.5px"
              stroke="currentColor"
              width="15.632"
              height="15.632"
              rx="2"
              transform="translate(646.232 415.704)"
            />
            <path
              fill="none"
              strokeMiterlimit="10"
              strokeWidth="1.5px"
              stroke="currentColor"
              d="M648.328,415.7v-.711a1.655,1.655,0,0,1,1.655-1.655h12.323a1.654,1.654,0,0,1,1.654,1.655v12.323a1.653,1.653,0,0,1-1.654,1.654h-.562"
            />
            <line
              fill="none"
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeWidth="1.5px"
              stroke="currentColor"
              y2="9.5"
              transform="translate(654.048 418.77)"
            />
            <line
              fill="none"
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeWidth="1.5px"
              stroke="currentColor"
              x2="9.5"
              transform="translate(649.298 423.52)"
            />
          </g>
        </svg>
      )}
      {...props}
    />
  );
};

/**预定 */
export const BookingIcon = (props) => {
  return (
    <Icon
      component={() => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
        >
          <defs>
            <clipPath id="a">
              <rect fill="none" width="1em" height="1em" />
            </clipPath>
          </defs>
          <g clipPath="#a">
            <rect fill="#ac8d64" opacity="0" width="1em" height="1em" />
            <g transform="translate(-643.538 -350.103)">
              <rect
                fill="none"
                strokeWidth="1.5px"
                strokeMiterlimit="10"
                stroke="currentColor"
                width="17.117"
                height="17.184"
                rx="2"
                transform="translate(646.538 354.42)"
              />
              <line
                fill="none"
                strokeLinecap="round"
                strokeWidth="1.5px"
                strokeMiterlimit="10"
                stroke="currentColor"
                x1="5.434"
                y2="5.343"
                transform="translate(654.321 359.244)"
              />
              <line
                fill="none"
                strokeLinecap="round"
                strokeWidth="1.5px"
                strokeMiterlimit="10"
                stroke="currentColor"
                x2="2.74"
                y2="2.649"
                transform="translate(651.444 361.893)"
              />
              <line
                fill="none"
                strokeLinecap="round"
                strokeWidth="1.5px"
                strokeMiterlimit="10"
                stroke="currentColor"
                y2="2.495"
                transform="translate(658.155 353.103)"
              />
              <line
                fill="none"
                strokeLinecap="round"
                strokeWidth="1.5px"
                strokeMiterlimit="10"
                stroke="currentColor"
                y2="2.495"
                transform="translate(652.037 353.103)"
              />
            </g>
          </g>
        </svg>
      )}
      {...props}
    />
  );
};

/**VIP */
export const VipIcon = (props) => {
  return (
    <Icon
      component={() => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
        >
          <rect fill="none" opacity="0" width="1em" height="1em" />
          <g transform="translate(-250.004 -819.044)">
            <path
              fill="currentColor"
              d="M-355.215,328.749a1.062,1.062,0,0,1-1.05-.891l-2-8.865a1.059,1.059,0,0,1,.595-1.045,1.063,1.063,0,0,1,1.052.066l3.332,1.334,3.339-5.67a1.066,1.066,0,0,1,.788-.355,1.137,1.137,0,0,1,.794.346l.09.122,3.29,5.516,3.281-1.293a1.056,1.056,0,0,1,1.048-.066,1.06,1.06,0,0,1,.6,1.044l-.016.105-2,8.8a1.05,1.05,0,0,1-1.043.849Zm.347-1.5h11.4l1.724-7.61-3.044,1.2a1.065,1.065,0,0,1-1.3-.445l-3.064-5.139-3.032,5.175a1.06,1.06,0,0,1-1.315.451l-3.091-1.237Zm13.741-11.47a1.9,1.9,0,0,1,1.9-1.9,1.9,1.9,0,0,1,1.9,1.9,1.9,1.9,0,0,1-1.9,1.9A1.9,1.9,0,0,1-341.126,315.779Zm1.5,0a.4.4,0,0,0,.4.4.4.4,0,0,0,.4-.4.4.4,0,0,0-.4-.4A.4.4,0,0,0-339.627,315.779Zm-21.381,0a1.9,1.9,0,0,1,1.9-1.9,1.9,1.9,0,0,1,1.9,1.9,1.9,1.9,0,0,1-1.9,1.9A1.9,1.9,0,0,1-361.008,315.779Zm1.5,0a.4.4,0,0,0,.4.4.4.4,0,0,0,.4-.4.4.4,0,0,0-.4-.4A.4.4,0,0,0-359.509,315.779Zm8.441-4.9a1.9,1.9,0,0,1,1.9-1.9,1.9,1.9,0,0,1,1.9,1.9,1.9,1.9,0,0,1-1.9,1.9A1.9,1.9,0,0,1-351.068,310.879Zm1.5,0a.4.4,0,0,0,.4.4.4.4,0,0,0,.4-.4.4.4,0,0,0-.4-.4A.4.4,0,0,0-349.567,310.879Z"
              transform="translate(611.252 512.316)"
            />
          </g>
        </svg>
      )}
      {...props}
    />
  );
};

/**删除 */
export const DeleteIcon = (props) => {
  return (
    <Icon
      component={() => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
        >
          <g>
            <rect stroke="none" width="1em" height="1em" />
            <rect fill="none" x="0.5" y="0.5" width="11" height="11" />
          </g>
          <g transform="translate(1.85 1.7)">
            <path
              strokeMiterlimit="10"
              strokeWidth="0.75px"
              fill="none"
              d="M532.625,484.809v6.369a.954.954,0,0,1-.953.954h-4.259a.954.954,0,0,1-.953-.954v-6.369"
              transform="translate(-525.403 -483.531)"
            />
            <path
              strokeMiterlimit="10"
              strokeWidth="0.75px"
              fill="none"
              d="M532.612,483.552v-.8a.5.5,0,0,0-.5-.5H529.89a.5.5,0,0,0-.5.5v.727"
              transform="translate(-526.869 -482.252)"
            />
            <g transform="translate(2.697 3.497)">
              <line
                stroke="currentColor"
                strokeMiterlimit="10"
                strokeWidth="0.75px"
                strokeLinecap="round"
                fill="none"
                y2="3.102"
              />
              <line
                stroke="currentColor"
                strokeMiterlimit="10"
                strokeWidth="0.75px"
                strokeLinecap="round"
                fill="none"
                y2="3.102"
                transform="translate(1.442)"
              />
              <line
                stroke="currentColor"
                strokeMiterlimit="10"
                strokeWidth="0.75px"
                strokeLinecap="round"
                fill="none"
                y2="3.102"
                transform="translate(2.885)"
              />
            </g>
            <line
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="0.75px"
              strokeLinecap="round"
              fill="none"
              x2="8.278"
              transform="translate(0 0.988)"
            />
          </g>
        </svg>
      )}
      {...props}
    />
  );
};
