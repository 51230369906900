import React, {  useEffect } from "react";
import {useTranslation} from "react-i18next";
import {Drawer} from "antd";
import {Header,MenusSearch} from "@/components";
/**SearchDishes */
export const SearchDishes = ({visible, setVisible}) => {
    const {t} = useTranslation();
    useEffect(() => {
        if(visible){
            let x = document.getElementsByTagName("input")[1]
            if(x){
                setTimeout(function(){
                    x.focus();
                },100)
            }
        }

    });
    return (
        <Drawer
            className="booking-drawer deal-drawer1"
            placement="right"
            closable={false}
            destroyOnClose
            onClose={() => setVisible(false)}
            visible={visible}
        >
            <section >
                <Header title={t("WEB.Menu")} onClick={() => setVisible(false)}/>
                <div className="searchBar">
                    <MenusSearch visible={visible} setVisible={setVisible} />
                </div>
            </section>
        </Drawer>
    );
};
