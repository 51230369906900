import memberStore from '@/store/member'

class Resize {
  constructor() {}
  bootstrap() {
    console.warn("addEventListener resize")
    window.addEventListener("message", this.resize);
  }
  resize(ev) {
    try {
      if (ev.data.height) {
        memberStore.pageHeight = ev.data.height
        console.warn("page height", ev.data.height);
      }
    } catch (error) {}
  }
}

const resize = new Resize();

resize.bootstrap();
