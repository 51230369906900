import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";
import "./index.less";
import loadingData from "./loading_animation_white.json";
import loadingDataRed from "./loading_animation.json";

/**加载动画 */
const Loading = (props) => {
  let animation = null;
  let dom = useRef();
  useEffect(() => {
    animation = lottie.loadAnimation({
      container: dom.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: props.data || (props.red ? loadingDataRed : loadingData),
    });
  }, []);
  return (
    <span className="dc-loading">
      <i ref={dom}></i>
    </span>
  );
};

export default Loading;
