import React, { useState, useEffect, useMemo } from "react";
import { useObserver } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Drawer, Modal, Button, Form, Input } from "antd";
import { useStores } from "@/store";
import { Header, Loading } from "@/components";
import Item from "./components/Item";
import Detail, { HistoryDetails } from "./components/Detail";
import { getQuery } from '@/service/http';
import "./index.less";

const mock = [
  {
    id: 174011,
    formated_datetime: "2020年10月14日 14:34",
    restaurant_id: 2056108,
    points: 1,
    restaurant_basic_info: {
      localized_name: "Test Restaurant 5 for voucher",
      address: "东街250",
      description: " ",
    },
    billing: null,
  },
];

/**
 * 我的优惠券-列表
 *
 */
const MyVouchers = () => {
  const { t } = useTranslation();
  const { voucherStore } = useStores();
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [terms, setTerms] = useState(false);
  const [current, setCurrent] = useState(false);
  const [addModalVisible, setAddModalVIsible] = useState(false);
  const [form] = Form.useForm();
  const [str, setStr] = useState("");
  const [status, setStatue] = useState("");
  const [message, setMessage] = useState("");
  const [confirmLoading,setConfirmLoading] = useState(false)
  const { voucher_code } = getQuery();
  const okBtnDisabled = useMemo(() => !str, [str]);
  useEffect(() => {
    getList();
  }, []);

  const handleCancel = () => {
    form.resetFields();
    setStr("");
    setMessage("");
    setStatue("");
    setAddModalVIsible(false);
  };

  const handleAddVoucher = () => {
    setConfirmLoading(true)
    voucherStore
      .addVoucher(form.getFieldValue("voucherStr"))
      .then((res) => {
        getList();
        handleCancel();
      })
      .catch((err) => {
        setStatue("error");
        setMessage(err?.data?.full_messages[0]);
      }).finally(()=>{
        setConfirmLoading(false)
      })
  };
  /**获取列表 */
  async function getList() {
    try {
      setLoading(true);
      await voucherStore.list();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      if (voucher_code) {
        const { vouchers } = voucherStore;
        let group,vohucer;
        vouchers.forEach(item=>{
          const vo = item.data.find(_=>_.code === voucher_code)
          if(vo){
            group = item.name
            vohucer = vo
          }
        })
        onItemChange(getName(group), vohucer)
      }
    }
  }

  /**查看详情 */
  function onItemChange(group, item) {
    setCurrent({
      ...item,
      group,
    });
    setVisible2(true);
  }

  /**查看条款 */
  function showTerm(data) {
    setTerms(data.detail.desc);
    setVisible(true);
  }

  function getName(name) {
    return t(`VOUCHERS.${name}`);
  }

  /**
   * 关闭详情
   */
  function closeDetails() {
    setVisible2(false);
    getList();
  }

  return useObserver(() => {
    const { vouchers, total } = voucherStore;
    return (
      <div className="page-vouchers">
        <Button
          className="addVoucher"
          type="primary"
          shape="round"
          size="small"
          onClick={() => setAddModalVIsible(true)}
        >
          {t("WEB.Enter code")}
        </Button>
        {total < 1 ? <Empty /> : null}
        {/* <div style={{ textAlign: "center" }}>{loading ? <Loading /> : null}</div> */}
        <Terms visible={visible} setVisible={setVisible} text={terms} />
        <Drawer
          className="booking-drawer profile-item-inner-drawer"
          placement="right"
          closable={false}
          destroyOnClose
          push={{ distance: 0 }}
          onClose={() => closeDetails(false)}
          visible={visible2}
        >
          <Header title={t("VOUCHERS.Voucher details")} onClick={() => closeDetails()} />
          <Detail {...current} />
        </Drawer>
        <div className="page-vouchers--list">
          {vouchers.map((item) => {
            return (
              <div className="page-vouchers--group" key={item.name} hidden={!item.data.length}>
                <h2>{getName(item.name)}</h2>
                {item.data.map((voucher, index) => {
                  return (
                    <Item
                      voucher={voucher}
                      status={item.name}
                      key={item.name + index}
                      onSelect={() => onItemChange(getName(item.name), voucher)}
                      showTerm={() => showTerm(voucher)}
                    />
                  );
                })}
              </div>
            );
          })}
        </div>
        <Modal
          className="loading-modal"
          title={t("WEB.Add voucher")}
          visible={addModalVisible}
          onOk={handleAddVoucher}
          onCancel={handleCancel}
          width="420px"
          okText={t("CONFIRM")}
          cancelText={t("CANCEL")}
          okButtonProps={{ disabled: okBtnDisabled }}
          confirmLoading={confirmLoading}
        >
          <p>{t("WEB.Add voucher des")}</p>
          <Form form={form} layout="vertical" autoComplete="off">
            <Form.Item name="voucherStr" validateStatus={status} help={message}>
              <Input placeholder={t("VOUCHERS.ENTER")} onChange={(e) => setStr(e.target.value)} />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  });
};

/**使用条款 */
export const Terms = ({ visible, setVisible, text }) => {
  const { t } = useTranslation();
  return (
    <Modal
      visible={visible}
      wrapClassName="dc-modal voucher-modal"
      width={300}
      footer={null}
      closable={false}
      centered
      destroyOnClose
    >
      <header>{t("VOUCHERS.Terms of use")}</header>
      <div className="voucher-modal--content">
        <div dangerouslySetInnerHTML={{ __html: text }}></div>
        <footer>
          <span></span>
          <strong onClick={() => setVisible(false)}>{t("VOUCHERS.Close")}</strong>
        </footer>
      </div>
    </Modal>
  );
};

/**没有优惠券 */
const Empty = () => {
  const { t } = useTranslation();
  return (
    <div className="no-vouchers">
      <i></i>
      <p>{t("VOUCHERS.Your account has no vouchers")}</p>
    </div>
  );
};

export default MyVouchers;
