import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useStores } from "@/store";
import "./index.less";

/**
 * 收藏列表
 *
 */
const Collections = ({ tapCollectionItem }) => {
  const { t } = useTranslation();
  const { memberStore } = useStores();
  const [collections, setCollections] = useState([])

  useEffect(() => {
    memberStore.getMyCollections({
      page: 1,
      per_page: 10000
    }).then(setCollections)
  }, []);

  return (
    <div className="dc-collections-list">
      {
        collections.map(item => (
          <div className="col-item" key={item.id} onClick={() => tapCollectionItem(item)}>
            <div className="left">
              <img className="col-image" src={item.cover_url} alt="" />
            </div>
            <div className="right">
              <div className="col-name">{item.name}</div>
              <div className="res-count">{item.items_count} {item.items_count > 1 ? t('Profile.collection-restaurants') : t('Profile.collection-restaurant')}</div>
            </div>
          </div>
        ))
      }
    </div>
  );
};

export default Collections;
