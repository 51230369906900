import React, { useState, useEffect } from "react";
import Gallery from "react-bnb-gallery";

const width = 805;
const height = 254;
const gutter = 10;
const ImageSizes = [
  [
    {
      width: width,
      height: height,
      left: 0,
      top: 0,
    },
  ],
  [
    {
      width: width * 0.4,
      height: height,
      left: 0,
      top: 0,
    },
    {
      width: width * 0.6 - 10,
      height: height,
      left: width * 0.4 + 10,
      top: 0,
    },
  ],
  [
    {
      width: width * 0.4,
      height: height,
      left: 0
    },
    {
      width: width * 0.3 - gutter,
      height: height,
      left: width * 0.4 + gutter,
    },
    {
      width: width * 0.3 - gutter,
      height: height,
      left: width * 0.7 + gutter * 2,
    },
  ],
  [
    {
      width: width * 0.3,
      height: height,
      left: 0
    },
    {
      width: width * 0.3,
      height: height,
      left: width * 0.3 + gutter,
    },
    {
      width: width * 0.4 - gutter * 2,
      height: height * 0.5,
      left: width * 0.6 + gutter * 2,
      top: 0,
    },
    {
      width: width * 0.4 - gutter * 2,
      height: height * 0.5 - gutter,
      left: width * 0.6 + gutter * 2,
      top: height * 0.5 + gutter
    },
  ],
  [
    {
      width: width * 0.3,
      height: height,
      left: 0
    },
    {
      width: width * 0.4,
      height: height * 0.5,
      left: width * 0.3 + gutter,
    },
    {
      width: width * 0.3 - gutter * 2,
      height: height * 0.5,
      right: 0,
      top: 0,
    },
    {
      width: width * 0.3,
      height: height * 0.5 - gutter,
      left: width * 0.3 + gutter,
      top: height * 0.5 + gutter
    },
    {
      width: width * 0.4 - gutter * 2,
      height: height * 0.5 - gutter,
      right: 0,
      top: height * 0.5 + gutter
    },
  ],
];

/**横幅 */
export const Grid = ({ photos }) => {
  const [show, setShow] = useState(false);
  const [activePhotoIndex, setActivePhotoIndex] = useState(0);
  function showPhotos(index) {
    setActivePhotoIndex(index);
    setShow(true);
  }
  let size = Math.min(5, photos.length);
  let styles = ImageSizes[size - 1] || {};
  const photoItems = photos.slice(0, size).map((i, index) => {
    return {
      photo: i.image_url,
      style: styles[index] || {},
    };
  });
  const galleryPhotos = photos.map(i => i.picture_url == undefined ? i.image_url.split('?')[0] + '-gallery' : i.picture_url.split('?')[0] + '-gallery')
  return (
    <section className="page-deal--grid">
      <Gallery
        onClose={() => setShow(false)}
        photos={galleryPhotos}
        activePhotoIndex={activePhotoIndex}
        show={show}
        showThumbnails={false}
      ></Gallery>
      <div className="photo-grid">
        {photoItems.map(({ photo, style = {} }, index) => {
          photo = `${photo}`.replace(/\?|$/, '-thumb')
          return (
            <img
              src={photo}
              style={style}
              key={index}
              onClick={() => showPhotos(index)}
            />
          );
        })}
      </div>
    </section>
  );
};
