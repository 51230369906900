import React, { useEffect, useRef, useState } from "react";
import "./index.less";

/**图片预览 */
const Gallery = ({ photos, activePhotoIndex = 0, show, onClose, author, date }) => {
  const [curIndex, setCurIndex] = useState(activePhotoIndex)

  useEffect(() => {
  }, []);

  const prevPhoto = () => {
    if (curIndex <= 0) {
      setCurIndex(photos.length - 1)
    } else {
      setCurIndex(curIndex - 1)
    }
  }
  const nextPhoto = () => {
    if (curIndex >= photos.length - 1) {
      setCurIndex(0)
    } else {
      setCurIndex(curIndex + 1)
    }
  }

  return (
    <div className="dc-gallery-modal">
      <div className="dc-gallery-modal--overlay">
        <div className="dc-gallery-modal--content">
          <div className="dc-gallery-modal--close">
            <button className="dc-gallery-close" type="button" onClick={onClose}>&times;</button>
          </div>
          <div className="dc-gallery-content">
            <div className="dc-gallery">
              <div className="dc-gallery-main">
                <button type="button" className="dc-gallery-control dc-gallery-control--prev" onClick={prevPhoto}>
                  <i className="icon icon-arrow-left"></i>
                </button>
                <div className="dc-gallery-photos">
                  <div className="picture">
                    <img className="photo media-image" alt='' src={photos[curIndex].image_url.split('?')[0] + '-gallery'} />
                  </div>
                  <div className="meta">
                    {photos[curIndex].dish ? <div className="dish">{photos[curIndex]?.dish.name}</div> : null}
                    {photos[curIndex].comment ? <div className="comment">{photos[curIndex].comment}</div> : null}
                    <div className="author">{author} {date}</div>
                  </div>
                </div>
                <button type="button" className="dc-gallery-control gallery-control--next" onClick={nextPhoto}>
                  <i className="icon icon-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
