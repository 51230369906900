import React, { useState, useMemo, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Rate, Row, Col, Typography, Modal } from "antd";
import moment from "moment";
import { slimImage } from "@/service/utils";
import { Icon, Gallery } from "@/components";
import { getQuery } from "@/service/http";
import { useStores } from "@/store";
import listenForOutsideClick from "./listen-for-outside-clicks";
import { goLogin } from '@/service/utils';
import "./index.less";

function padRight(str) {
  return Number(str).toFixed(1);
}

let setLiking = false;

const ReviewItem = ({
  review,
  onPreview,
  onClick,
  onReply,
  showRateDetail,
  showAll,
  mobile,
  isShowGallery = false,
  footer = true,
  onDelete,
  onEdit,
  member,
  hideMore = false,
  replies
}) => {
  const { t } = useTranslation();
  const { reviewStore, memberStore } = useStores();
  const format = getQuery().lang == "en" ? "MMMMDD, YYYY" : "LL";
  // const date = moment(review.created_at).format(format);
  const date = review.i18n_created_at
    ? review.i18n_created_at
    : moment(review.created_at).format(format);
  const { avatar_url, level, level_icon, level_text } =
    review.member || review.owner || {};
  const {
    photos = [],
    rating_atmosphere,
    rating_cuisine,
    rating_service,
  } = review;
  const [show, setShow] = useState(false);
  const [activePhotoIndex, setActivePhotoIndex] = useState(0);
  const [curReview, setCurReview] = useState(review);
  const [isMyReview, setIsMyReview] = useState(false);
  function showPhotos(index) {
    if (isShowGallery) {
      setActivePhotoIndex(index);
      setShow(true);
    }
  }
  const span = mobile ? { span: 8 } : { span: 8, md: 4, sm: 8 };
  const avg = useMemo(() => {
    return (rating_atmosphere + rating_cuisine + rating_service) / 3 / 2;
  }, [curReview]);
  const name = useMemo(() => {
    return curReview.name_reviewer || curReview.name;
  }, [curReview]);
  const content = useMemo(() => {
    return curReview.review_text || curReview.content;
  }, [curReview]);
  const photoList = useMemo(() => {
    return showAll ? photos : photos.slice(0, 6);
  }, [curReview]);

  useEffect(() => {
    setIsMyReview((member || memberStore.member)?.id === curReview?.member?.id);
  }, [member, curReview]);

  const menuRef = useRef(null);
  const [listening, setListening] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggleActionsVisble = () => setIsOpen(!isOpen);

  useEffect(listenForOutsideClick(listening, setListening, menuRef, setIsOpen));

  useEffect(()=>{
    if(replies){
      setCurReview({
        ...curReview,
        replies_count:replies.length
      });
    }
  },[replies])

  const setLike = () => {
    const { private_token } = getQuery();
    if(private_token){
      if (setLiking) {
        return;
      }
      setLiking = true;
      reviewStore
        .setReviewLike(curReview.id)
        .then((res) => {
          console.log(res);
          if (res.status) {
            if (res.liked) {
              setCurReview({
                ...curReview,
                liked: res.liked,
                upvotes: curReview.upvotes + 1,
              });
            } else {
              setCurReview({
                ...curReview,
                liked: res.liked,
                upvotes: curReview.upvotes <= 0 ? 0 : curReview.upvotes - 1,
              });
            }
          }
        })
        .finally(() => {
          setLiking = false;
        });
    }else{
      // 没有token时跳转到登录页面
      goLogin()
    }
  };

  const deleteConfirm = () => {
    Modal.confirm({
      content: t("REVIEW.Cancel confirm"),
      onOk: () => {
        reviewStore.deleteReview(curReview.id).then((res) => {
          if (res.status) {
            onDelete && onDelete(curReview.id);
          }
        });
      },
      zIndex:9999
    });
  };

  const editConfirm = () => {
    onEdit && onEdit(curReview);
  };

  const handleReply = ()=>{
    const { private_token } = getQuery();
    if(private_token){
      if(onReply){
        onReply()
      }
    }else{
      // 没有token时跳转到登录页面
      goLogin()
    }
  }

  return (
    <div className="review-item" onClick={onClick} ref={menuRef}>
      <div className="review-avatar">
        <div className="review-avatar-pic">
          <img src={avatar_url+'-thumb'} alt="" />
        </div>
        {!!level && (
          <React.Fragment>
            {review?.owner_type !== "restaurant" && (
              <div className="review-level">
                <i className={"icon icon-" + level}></i>
              </div>
            )}
            {review?.owner_type === "restaurant" && (
              <div className="review-avatar-pic-icon">
                <img src={level_icon} alt="" />
                <p>{t("WEB.offical")}</p>
              </div>
            )}
            {review?.owner_type !== "restaurant" && (
              <div className="review-levelname small">
                {t(`LEVELS.${level}`)}
              </div>
            )}
          </React.Fragment>
        )}
      </div>
      <div className="review-info">
        <div className="review-author">{name || t("RESTAURANT")}</div>
        <time className="review-date">
          <span className="small">{date}</span>
        </time>
        <div className="review-tag" hidden={!curReview.verified}>
          <i className="icon icon-verified"></i>
          <span className="small">{t("WEB.Verified")}</span>
        </div>
        {/* 评分 */}
        <div className="review-rate" data-detail={showRateDetail}>
          {parseInt(avg) !== 0 && (
            <Rate count={5} value={avg} disabled style={{ fontSize: 12 }} />
          )}
          <div className="review-rate-detail" hidden={!showRateDetail}>
            <div className="rate-item">
              <div className="rate-item-label">{t("WEB.Food")}</div>
              <div className="rate-item-value">{padRight(rating_cuisine)}</div>
            </div>
            <div className="rate-item">
              <div className="rate-item-label">{t("WEB.Service")}</div>
              <div className="rate-item-value">{padRight(rating_service)}</div>
            </div>
            <div className="rate-item">
              <div className="rate-item-label">{t("WEB.Decor")}</div>
              <div className="rate-item-value">
                {padRight(rating_atmosphere)}
              </div>
            </div>
          </div>
        </div>

        {/* 评论 */}
        {showAll ? (
          <div className="review-desc">
            <div>{content}</div>
          </div>
        ) : (
          <div className="review-desc">
            <Typography.Paragraph ellipsis={{ rows: 2 }}>
              {content}
            </Typography.Paragraph>
            <div className="pc-text">{content}</div>
          </div>
        )}
        {show && (
          <Gallery
            onClose={() => setShow(false)}
            photos={photos}
            author={curReview.member?.nickname}
            date={date}
            activePhotoIndex={activePhotoIndex}
          ></Gallery>
        )}
        {/* 配图 */}
        <div className="review-pics">
          <Row gutter={6} justify="start">
            {photoList.map((item, index) => {
              return (
                <Col key={index} {...span}>
                  <div className="review-pic" onClick={() => showPhotos(index)}>
                    <img
                      src={slimImage(item.image_url+'-thumb', "/w/180/h/124")}
                      onClick={() => onPreview(index, photos)}
                    />
                    {item.dish || item.comment ? (
                      <div className="small-icons">
                        {!!item.dish?.id && <i className="icon icon-menu"></i>}
                        {!!item.comment && <i className="icon icon-review"></i>}
                      </div>
                    ) : null}
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
        {/* 操作 */}
        {footer && (
          <div className="review-actions" onClick={(e) => e.stopPropagation()}>
            <Icon
              icon="icon icon-review-grey"
              text={curReview.replies_count || 0}
              onClick={()=>handleReply()}
            />
            <Icon
              icon={`icon icon-small-heart ${
                curReview.liked ? "selected" : ""
              }`}
              text={curReview.upvotes || 0}
              onClick={() => setLike()}
            />
            {isMyReview && !hideMore && (
              <Icon icon="icon icon-more" onClick={toggleActionsVisble} />
            )}
            {isOpen && (
              <div className="more-actions">
                <div onClick={() => deleteConfirm()}>
                  <i className="icon icon-trash"></i> {t("REVIEW.Delete")}
                </div>
                <div onClick={() => editConfirm()}>
                  <i className="icon icon-edit"></i> {t("REVIEW.Edit")}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReviewItem;
