import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Rate, Drawer } from 'antd'
import { Button, ReviewItem, ReviewDetail, WriteReview } from '@/components'
import { useStores } from '@/store'
import { useMemo } from 'react'
import Gallery from 'react-bnb-gallery'

let submiting = false
/**评分&评论 */
export const Review = ({
  id,
  reviews,
  reviews_count,
  reviews_ratings,
  deal,
}) => {
  const { t } = useTranslation()
  const { dealStore, reviewStore } = useStores()
  const [page, setPage] = useState(1)
  const [photoList, setPhotoList] = useState([])
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [realLoad, setRealLoad] = useState(false)
  const [reviewList, setReviewList] = useState([])
  const [currentReview, setCurrentReview] = useState({})
  const [visible2, setVisible2] = useState(false)
  const [reviewVisible, setReviewVisible] = useState(false)
  const [submitLoading,setSubmitLoading] = useState(false)
  const showMore = useMemo(() => {
    return reviews_count > 1 && reviewList.length < reviews_count
  }, [reviews_count, reviewList])

  useEffect(() => {
    setReviewList([reviews[0]])
  }, [])

  async function onLoadMore() {
    setLoading(true)
    if (realLoad) {
      let list = reviewList.slice()
      let data = await dealStore.getReviews(id, page)
      list = list.concat(data)
      setPage(page++)
      setReviewList(list)
    } else {
      setRealLoad(true)
      setReviewList(reviews)
    }
    setLoading(false)
  }

  function onPreview(index, photos) {
    // setPhotoList(photos.map((i) => ({ photo: i.image_url })))
    // setShow(true)
  }
  /**查看详情 */
  function onShow(item) {
    setCurrentReview(item)
    setVisible2(true)
  }
  function onEdit(review) {
    setCurrentReview(review)
    setReviewVisible(true)
  }
  const onSubmitReview = (type, rid, options, sid = '') => {
    if (submiting) return
    submiting = true
    setSubmitLoading(true)
    reviewStore
      .submitEditReview(type, rid, sid, options)
      .then((res) => {
        setSubmitLoading(false)
        if (res && res.status) {
          setReviewVisible(false)
          let _reviews = [...reviews]
          let listIndex = _reviews.findIndex((item) => item.id === sid)
          if (listIndex >= 0) {
            res.review.timestamp = new Date().getTime()
            res.review.liked = _reviews[listIndex].liked
            _reviews.splice(listIndex, 1, res.review)
            setReviewList(_reviews)
            setCurrentReview(res.review)
          }
          try {
            let draftReviews = JSON.parse(localStorage.getItem('draft-reviews'))
            let index = draftReviews.findIndex((item) => item.rid == deal.id)
            if (index >= 0) {
              draftReviews.splice(index, 1)
              localStorage.setItem(
                'draft-reviews',
                JSON.stringify(draftReviews)
              )
            }
          } catch (err) {
            console.log(err)
          } finally {
            reviewStore.reviewInit()
          }
        }
      })
      .finally(() => {
        submiting = false
      })
  }
  return (
    <section className='page-deal--review'>
      <h3>{t('WEB.Reviews', { count: reviews_count })}</h3>
      <div className='review-rate'>
        <Rate count={5} value={reviews_ratings.ratings_avg / 2} disabled />
      </div>
      <div className='review-rate-desc'>
        <span>
          {t('WEB.Food')}: {reviews_ratings.cuisine_avg}
        </span>
        <span>
          {t('WEB.Service')}: {reviews_ratings.service_avg}
        </span>
        <span>
          {t('WEB.Decoration')}: {reviews_ratings.atmosphere_avg}
        </span>
      </div>
      {reviewList.map((item, index) => {
        return (
          <ReviewItem
            review={item}
            key={item.id + '_' + index}
            onPreview={onPreview}
            hideMore={true}
            footer={false}
            onClick={() => onShow(item)}
          />
        )
      })}
      {showMore && (
        <Button type='primary' block onClick={onLoadMore} loading={loading}>
          {t('WEB.Show more reviews')}
        </Button>
      )}
      {/* <Gallery
        onClose={() => setShow(false)}
        photos={photoList}
        show={show}
        showThumbnails={false}
      ></Gallery> */}
      <div
        className='hairline-bottom-new'
        style={{ paddingBottom: '20px' }}
      ></div>
      {/* 详情 */}
      <ReviewDetail
        review={currentReview}
        onPreview={onPreview}
        visible={visible2}
        setVisible={setVisible2}
        onEdit={onEdit}
      />
      <Drawer
        className='booking-drawer restaurant-review-drawer'
        placement='right'
        closable={false}
        destroyOnClose
        onClose={() => setReviewVisible(false)}
        visible={reviewVisible}
      >
        <WriteReview
          restaurant={currentReview?.source}
          editReview={currentReview}
          onClose={setReviewVisible}
          onSubmitReview={onSubmitReview}
          submitLoading={submitLoading}
        />
      </Drawer>
    </section>
  )
}
