import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Affix, Drawer, Input} from "antd";
import {Popular, All, Event, Icon} from "@/components";
import {Picker} from "antd-mobile";
import {useStores} from "@/store";
import { useParams} from "react-router-dom";
import 'antd-mobile/lib/picker/style/css';
import {SearchDishes} from "./SearchDishes";


/**
 * 全部菜单
 * @param menus
 * @param visible
 * @param setVisible
 * @returns {*}
 * @constructor
 */
export const FullMenu = ({tap}) => {
    const [timeOut, settimeOut] = useState();
    const {t} = useTranslation();
    const {id} = useParams();//restaurant id
    const {restaurantStore} = useStores();
    const [popular, setPopular] = useState([]);// Popular
    const [allMenus, setAllMenus] = useState({});// All
    const [events, setEvents] = useState([]);//Event
    const [visibleSearch, setVisibleSearch] = useState(false);
    const [selectClick, setSelectClick] = useState(false);

    const [selectTimeAndDiscountData, setSelectTimeAndDiscountData] = useState([]);
    const [saveNormalIconVisible, setSaveNormalIconVisible] = useState(false);
    const [saveIconVisible, setSaveIconVisible] = useState(false);
    const [canceIconVisible, setCancelIconVisible] = useState(false);// canceIconVisible
    const [timeDiscount, setTimeDiscount] = useState(false);// canceIconVisible
    const [regionCountry, setRegionCountryData] = useState([]);//Event
    const data = [];
    let nowDate = new Date();
    let year = nowDate.getFullYear();
    let month = nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1)
      : nowDate.getMonth() + 1;
    let day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate
      .getDate();
    let dateStr = year + "-" + month + "-" + day;
    const leftStyle = {
        width: '16px',
        height: '34px',
        marginLeft: '10px',
        float: 'left'
    };
    const rightStyle = {
        width: '16px',
        height: '34px',
        marginRight: '30px',
        float: 'right'
    };
    useEffect(() => {
        restaurantStore.getSelectTimeAndDiscountData().then(function (data1) {
            if (null != data1) {
                let tempData = data1.data;
                if (undefined != tempData && null != tempData) {
                    for (let i = 0; i < tempData.length; i++) {
                        let todayData = tempData[i];
                        //dateStr
                        if (dateStr == todayData.date) {
                            let times = todayData.times;
                            for (let j = 0; j < times.length; j++) {
                                let time = times[j]
                                if (time.discount) {
                                    data.push({
                                        label:
                                          (<div>
                                              <span style={{...leftStyle}}>{t("RESERVATION_TIME")}:{time.time}</span>
                                              <span style={{...rightStyle}}>-{time.discount}</span>
                                          </div>),
                                        value: j + "-" + time.discount+"-"+time.time,
                                    })
                                }
                            }
                        }
                    }
                }
                if(data.length>0){
                    setSaveNormalIconVisible(true);
                }
            }
            setSelectTimeAndDiscountData(data)
        });
        restaurantStore.getRestaurant().then((data) => {
            restaurantStore.getPopular().then(setPopular);//Popular
            restaurantStore.getAllMenus().then(function (data) {
                setAllMenus(data);
                if(tap=="ALL"){
                    setTimeout(function(){
                        scrollTo(tap);
                    },500)
                }
            });//All
            restaurantStore.getEvents().then(setEvents);//Event
        });
    }, [id, ""]);

    function scrollTo(name) {
        try {
            let $el2 = document.querySelector(`.scrollClass`);
            let $Popular = document.querySelector(`.page-restaurant--Popular`);
            let $ALL = document.querySelector(`.page-restaurant--ALL`);
            if(!$el2){
                return null;
            }
            if(!$Popular){
                return null;
            }
            if(!$ALL){
                return null;
            }
            setSelectClick(true);
            if (name == "Popular") {
                $el2.scrollTo({
                    left: 0,
                    top: 0,
                    behavior: "smooth",
                });
            } else if (name == "ALL") {
                $el2.scrollTop = ($Popular ? $Popular.getBoundingClientRect().height : 0) + 20
                // $el2.scrollTo({
                //     left: 0,
                //     top: ($Popular ? $Popular.getBoundingClientRect().height : 0) + 20,
                //     behavior: "smooth",
                // });
            } else if (name == "Event") {
                $el2.scrollTo({
                    left: 0,
                    top: ($Popular ? $Popular.getBoundingClientRect().height : 0) + ($ALL ? $ALL.getBoundingClientRect().height : 0) + 20,
                    behavior: "smooth",
                });
            }
        } catch (error) {
            console.error(error);
        } finally {
            clearTimeout(timeOut);
            settimeOut(setTimeout(() => {
                setSelectClick(false);
            }, 2000))
        }
    }
    const [selected, setSelected] = useState(tap);
    /* 中部导航栏 */
    const Navigate = ({}) => {
        const {t} = useTranslation();
        let navigates = [];
        if (popular && popular.length > 0) {
            navigates.push({label: t("WEB.Full menu popular"), value: "Popular"})
        }
        if (allMenus) {
            navigates.push({label: t("WEB.Full menu All"), value: "ALL"})
        }
        if (events && events.length > 0) {
            navigates.push({label: t("WEB.Full menu Event"), value: "Event"})
        }
        navigates.filter((i) => i !== null);
        function scrollTo(name) {
            clearTimeout(timeOut);
            setSelected(name);
            try {
                setSelectClick(true);
                let $el2 = document.querySelector(`.scrollClass`);
                if($el2){
                  //$el2.style.overflowY = "hidden";
                }
                let $Popular = document.querySelector(`.page-restaurant--Popular`);
                let $ALL = document.querySelector(`.page-restaurant--ALL`);
                if (name == "Popular") {
                    $el2.scrollTo({
                        left: 0,
                        top: 0,
                        behavior: "smooth",
                    });
                } else if (name == "ALL") {
                    $el2.scrollTo({
                        left: 0,
                        top: ($Popular ? $Popular.getBoundingClientRect().height : 0)+20,
                        behavior: "smooth",
                    });
                } else if (name == "Event") {
                    $el2.scrollTo({
                        left: 0,
                        top: ($Popular ? $Popular.getBoundingClientRect().height : 0) + ($ALL ? $ALL.getBoundingClientRect().height : 0) + 20,
                        behavior: "smooth",
                    });
                }
            } catch (error) {
                console.error(error);
            } finally {
               settimeOut(setTimeout(() => {
                 let $el2 = document.querySelector(`.scrollClass`);
                 if($el2){
                   // $el2.style.overflowY = "auto";
                 }
                 setSelectClick(false);
               }, 800))
            }
        }
        return (
          <div className="page-restaurant--navigate textAlign">
              {navigates.map((item, index) => {
                  return (
                    <span
                      key={index}
                      data-selected={selected === item.value}
                      onClick={() => scrollTo(item.value)}
                    >
            {item.label}
          </span>
                  );
              })}
          </div>
        );
    };
    let $scrollClass = document.querySelector(`.scrollClass`);
    if($scrollClass){
        $scrollClass.onscroll = function () {
            if(selectClick){
                return;
            }
            let $Popular = document.querySelector(`.page-restaurant--Popular`);
            let $ALL = document.querySelector(`.page-restaurant--ALL`);
            let $Event = document.querySelector(`.page-restaurant--Event`);
            if($Popular){
                if($Popular.getBoundingClientRect().top<160){
                    setSelected("Popular");
                }
            }
            if($ALL){
                if($ALL.getBoundingClientRect().top<151){
                  setSelected("ALL");
                }
            }
            if($Event){
                if($Event.getBoundingClientRect().top<162){
                    setSelected("Event");
                }
            }
        }
    }
    const [state, setState] = useState({
        selectValue: [],
    });
    const onChangeSelect = (selectValue) => {
        let discount = selectValue[0].split("-")[1].substring(0, selectValue[0].split("-")[1].length - 1)
        setState({
            selectValue: selectValue,
        });
        setTimeDiscount(selectValue[0].split("-")[2]+" - "+discount+"%")
        // restaurantStore.getPopulaCache(discount).then(setPopular);
        //set icon status
        setSaveNormalIconVisible(false)
        setSaveIconVisible(true)
        setCancelIconVisible(true)

    };
    function onCancel() {
        restaurantStore.getPopular().then(setPopular);
        setSaveNormalIconVisible(true)
        setSaveIconVisible(false)
        setCancelIconVisible(false)
        setState({
            selectValue: [],
        });
        return;
    }
    const FilterCom = () => (
        <div>
          <Picker
            data={selectTimeAndDiscountData}
            value={state.colorValue}
            cols={1}
            onChange={onChangeSelect}
            okText={t('CONFIRM')}
            dismissText={t('CANCEL')}
            title={t('WEB.Select time & discount')}
          >
            <div className='iconPopularDiv'>
              <div
                style={{ display: saveNormalIconVisible ? 'inline-block' : 'none' }}
              >
                <Icon icon={'icon icon-book_and_save_normal_colored'} />
              </div>
            </div>
          </Picker>
          <div className='iconPopularDiv'>
            <div
              style={{
                display: canceIconVisible ? 'inline-block' : 'none',
                marginTop: '-5px',
              }}
            >
              {t('RESERVATION_INFO.DISCOUNT')}
              {':'}
              {timeDiscount}
            </div>
            <div style={{ display: saveIconVisible ? 'inline-block' : 'none' }}>
              <Icon icon={'icon icon-save'} />
            </div>
            <div
              style={{ display: canceIconVisible ? 'inline-block' : 'none' }}
              id={'cancel'}
            >
              <Icon icon={'icon icon-cancel'} onClick={onCancel} />
            </div>
          </div>
        </div>
      );
    return (
        <div className="searchBar">
            {/*Navigate*/}
            <section >
                {/*Search*/}
               <Input.Search placeholder={t("WEB.Search dishes")} onFocus={() => setVisibleSearch(true)} />
               <SearchDishes visible={visibleSearch} setVisible={setVisibleSearch}/>
                <React.Fragment>
                    <Affix>
                        <Navigate />
                    </Affix>
                </React.Fragment>
                {/*Three page*/}
              <div className="scrollClass">
                  <FilterCom/>
                  {popular && popular.length > 0 ?<Popular menus={popular} tap={tap} timeData={state}/>: null}
                  <div style={{position:'relative'}}>
                    <FilterCom/>
                  </div>
                  {allMenus ? <All menus={allMenus} tap={tap} timeData={state}></All>: null}
                  {events && events.length > 0 ? <Event events={events} tap={tap}></Event> : null}
                  <div style={{height:"1000px"}}></div>
              </div>
            </section>
        </div>
    );
};

