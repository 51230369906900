import React, { useState, useEffect } from "react";
import { useStores } from "@/store";
import { getQuery } from "../service/http";

/**预登录 */
export default (props) => {
  const { memberStore } = useStores();
  const [hasLogin, setLogin] = useState(true);
  const [isSafe, setSafe] = useState(false)
  const { private_token } = getQuery();
  useEffect(() => {
    if (window.location.hash.indexOf('/h5/') >= 0 && !private_token) {
      setSafe(true)
      // document.body.classList.add("loaded")
    } else {
      // document.body.classList.add("loaded");
      memberStore
        .show()
        .then(() => {
          setLogin(true);
          setSafe(false)
        })
        .catch((error) => {
          setLogin(true);
          setSafe(false)
        });
    }
  }, []);
  return isSafe || hasLogin ? <React.Fragment>{props.children}</React.Fragment> : <div></div>;
};
