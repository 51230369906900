import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useStores } from "@/store";
import Logo from "@/images/logo.svg";
import "./index.less";

/**
 * offers页面
 *
 */
const Main = () => {
  const { t } = useTranslation();
  const { offersStore } = useStores();
  const [offers, setOffers] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    offersStore.list(id).then(setOffers);
  }, [id]);

  return (
    <div className="page-offers">
      <header className="offer-header">
        <img src={Logo} width="48" height="48" />
        <span>{t("OFFERS.This_restaurant_offers")}</span>
      </header>
      {offers.map((item) => {
        return (
          <a
            className="offer-card"
            key={item.id}
            href={item.link}
            target="_blank"
          >
            <img src={item.picture_url} alt="picture_url" />
          </a>
        );
      })}
    </div>
  );
};

export default Main;
