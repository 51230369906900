import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useObserver } from "mobx-react-lite";
import lottie from "lottie-web";
import moment from "moment";
import { useStores } from "../../store";
import { Modal, Row, Col } from "antd";
import "./index.less";
import loadingData from "./loading_animation_white.json";
import loadingDataRed from "./loading_animation.json";
import processingData from "./processing.json";
import successData from "./success.json";
import failedData from "./failed.json";

import http, {  getQuery } from "@/service/http";

const mockText = `
Yes, I have rYes, I have read and agreed to the Privacy Policy
aPrivacy Policy and the restaurant Terms aPrivacy Policy and the
restaurant Terms and the restaurant Terms and Conditions.`;

/**
 * 提交表单之前的弹窗等待动画
 *
 * @param {*} props
 * @returns
 */
export const LoadingModal = (props) => {
  let { term, visible, loading, setVisible, onSubmit, onComplete } = props;
  const [confirm, setConfirm] = useState(false);
  const { t } = useTranslation();
  return (
    <Modal
      visible={visible}
      wrapClassName="dc-modal"
      width={300}
      footer={null}
      closable={false}
      keyboard={false}
      maskClosable={false}
      centered
      destroyOnClose
    >
      <div className="show-loading" hidden={!loading.length}>
        {loading === "loading" ? (
          <React.Fragment>
            <Loading data={processingData}></Loading>
            <p>{t("PAYMENT.Processing payment")}</p>
          </React.Fragment>
        ) : null}
        {loading === "success" ? (
          <React.Fragment>
            <Loading data={successData} onComplete={onComplete}></Loading>
            <p>{t("PAYMENT.Payment successful!")}</p>
          </React.Fragment>
        ) : null}
        {loading === "failed" ? (
          <React.Fragment>
            <Loading data={failedData}></Loading>
            <p></p>
          </React.Fragment>
        ) : null}
      </div>
      {/* 展示预定条款 */}
      <div
        className="confirm-content"
        hidden={!!loading.length || !term.key || !term.tips}
      >
        <header>{term.title}</header>
        <div className="modal-content">
          <p>{term.tips}</p>
          <Checkbox onChange={setConfirm}>{term.checkbox}</Checkbox>
        </div>
        <footer>
          <Button
            onClick={() => {
              setVisible(false);
              setConfirm(false);
            }}
          >
            {t("CANCEL")}
          </Button>
          <Button onClick={onSubmit} type="primary" disabled={!confirm}>
            {t("CONFIRM")}
          </Button>
        </footer>
      </div>
    </Modal>
  );
};

/**
 * 支付等待动画
 *
 * @param {*} props
 * @returns
 */
export const PaymentModal = (props) => {
  let { visible, onComplete } = props;
  const { paymentStore } = useStores();
  const { t } = useTranslation();
  useEffect(() => {
    let timer = setInterval(() => {
      paymentStore.getPayment().then(({ data }) => {
        if (data.status !== "pending") {
          onComplete();
        }
      });
    }, 2000);
    () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <Modal
      visible={visible}
      wrapClassName="dc-modal"
      width={300}
      footer={null}
      closable={false}
      keyboard={false}
      maskClosable={false}
      centered
      destroyOnClose
    >
      <div className="show-loading">
        <Loading red loop></Loading>
        <p>{t("PAYMENT.Processing payment")}</p>
      </div>
    </Modal>
  );
};

/**确认弹窗 */
export const Confirm = ({ visible, setVisible, onConfirm }) => {
  const { t } = useTranslation();
  return (
    <Modal
      visible={visible}
      wrapClassName="dc-modal"
      width={300}
      footer={null}
      closable={false}
      keyboard={false}
      maskClosable={false}
      centered
      destroyOnClose
    >
      {/* 展示预定条款 */}
      <div className="confirm-content">
        <header>{t("PAYMENT.Are you sure")}</header>
        <div className="modal-content">
          <p>{t("PAYMENT.will be lost")}</p>
        </div>
        <footer>
          <Button
            onClick={() => {
              setVisible(false);
            }}
          >
            {t("CANCEL")}
          </Button>
          <Button onClick={onConfirm} type="primary">
            {t("PAYMENT.Leave")}
          </Button>
        </footer>
      </div>
    </Modal>
  );
};

/**
 * 用户资料
 *
 * @param {*} props
 * @returns
 */
export const UserProfile = (props) => {
  const { memberStore } = useStores();
  return useObserver(() => {
    const {
      avatar_url,
      name,
      email,
      mobile = "",
      country_code,
    } = memberStore.member;
    const phone = mobile.replace(/(\d{3})(\d{4})(\d{4})/, "$1 $2 $3");
    return (
      <div className="user-profile">
        <div className="user-profile--avatar">
          <img src={avatar_url} alt="user-avatar" />
        </div>
        {props.forOther ? null : (
          <React.Fragment>
            <div className="user-profile--fullname">{name}</div>
            <div className="user-profile--email">{email}</div>
            <div className="user-profile--phone">
              +{country_code} {phone}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  });
};

/**
 * 图标文字
 *
 * @param {*} {icon, text} 图标类名 附带文字
 * @returns
 */
export const Icon = ({ icon, text, children }) => {
  return (
    <span className="icon-tip">
      <i className={icon}></i>
      <span>{text}</span>
    </span>
  );
};

/**
 * 文字列表
 *
 * @param {*} {title, value, desc} 左侧，右侧的组件
 * @returns
 */
export const ListItem = ({ title, value, desc, hidden, onClick }) => {
  return (
    <div className="list-item" onClick={onClick} hidden={hidden}>
      <div className="list-item--title">
        <strong>{title}</strong>
        <div className="list-item--desc">{desc}</div>
      </div>
      <div className="list-item--value">{value}</div>
    </div>
  );
};

/**
 * 圆形复选框
 *
 * @param {*} props
 */
export const Checkbox = (props) => {
  let [checked, setChecked] = useState(props.checked || false);
  let { card, noIcon } = props;
  const onChange = () => {
    let newVal = !checked;
    setChecked(newVal);
    props.onChange && props.onChange(newVal);
  };
  return (
    <div className="checkbox" data-card={card} onClick={onChange}>
      <div
        className="checkbox-icon"
        data-checked={checked}
        hidden={noIcon}
      ></div>
      <div className="checkbox-text">{props.children}</div>
    </div>
  );
};

/**
 * 表单错误提示
 *
 * @param {*} props
 * @returns
 */
export const Help = (props) => {
  return (
    <div className="form-helper" data-tip={props.tip} hidden={props.hidden}>
      <i className="icon icon-info selected"></i>
      <span>{props.children}</span>
    </div>
  );
};
/**
 * 自定义按钮
 *
 * @param {*} props
 * @returns
 */
export const Button = (props) => {
  let { size, type, disabled, block = false, loading, hidden = false } = props;
  return (
    <button
      hidden={hidden}
      className="dc-button"
      data-size={size}
      data-block={block}
      data-loading={loading}
      type={type}
      disabled={disabled}
      onClick={props.onClick}
    >
      {loading ? <Loading></Loading> : <span>{props.children}</span>}
    </button>
  );
};
/**
 * 代码提示
 *
 * @param {*} props
 * @returns
 */
export const Code = ({ data }) => {
  let [hide, setHide] = useState(false);
  return (
    <div className="code-mask">
      <Button size="mini" onClick={() => setHide(!hide)}>
        展开/收起
      </Button>
      <pre hidden={hide}>
        <code className="language-bash">{JSON.stringify(data, null, 2)}</code>
      </pre>
    </div>
  );
};

/**
 * 用户预订的日期-时间-座位数信息
 *
 * @param {*} props
 * @returns
 */
export const Bookinfo = (props) => {
  const { restaurantStore } = useStores();
  return useObserver(() => {
    const { form } = restaurantStore;
    if (!form.date) return null;
    let date = moment(form.date).format("MMM");
    return (
      <div className="user-bookinfo">
        <div className="bookinfo-left">
          <span className="icon-tip">
            <i className="icon icon-date"></i>
            <span>
              {lang === "en"
                ? `${date}.${moment(form.date).format("D")}`
                : `${date}${moment(form.date).format("DD")}日`}
            </span>
          </span>
          <span className="icon-tip">
            <i className="icon icon-time"></i>
            <span>{form.time}</span>
          </span>
        </div>
        <div className="bookinfo-right" hidden={!form.seats}>
          <span className="icon-tip">
            <i className="icon icon-seat"></i>
            <span>{form.seats}</span>
          </span>
        </div>
      </div>
    );
  });
};

/**
 * 用户折扣信息, 可以在折扣和套餐之间切换
 *
 * @param {*} props
 * @returns
 */
export const Discount = (props) => {
  const { restaurantStore } = useStores();
  const { t } = useTranslation();
  return useObserver(() => {
    const { form, deals } = restaurantStore;
    if (!form.discount && !deals) return null;

    // 如果url带有套餐id，直接展示套餐
    if (form.deal_id) {
      return (
        <ListItem
          title={
            <Icon
              icon="icon icon-deal"
              text={
                <span
                  style={{
                    fontSize: 18,
                    lineHeight: "24px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "224px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {deals.name}
                </span>
              }
            ></Icon>
          }
          // value={
          //   <span>
          //     <div>{deals.name}</div>
          //     <div>{deals.description}</div>
          //   </span>
          // }
        ></ListItem>
      );
    }

    // todo: 如果允许选择套餐和折扣，就在新弹窗里面操作
    return (
      <div className="user-discount">
        <div className="discount-left">
          <i className="icon icon-save"></i>
          <strong>{t("RESERVATION_INFO.BS")}</strong>
        </div>
        <div className="discount-right">
          <strong>{form.discount}</strong>
          {/* {form.discount && deals ? (
            <i className="icon icon-arrow-right"></i>
          ) : null} */}
        </div>
      </div>
    );
  });
};

export const Loading = (props) => {
  let animation = null;
  let dom = useRef();
  useEffect(() => {
    animation = lottie.loadAnimation({
      container: dom.current,
      renderer: "svg",
      loop: props.loop ? props.loop : false,
      autoplay: true,
      animationData: props.data || (props.red ? loadingDataRed : loadingData),
    });
    animation.onComplete = () => {
      props.onComplete && props.onComplete();
    };
    () => {
      animation.destroy();
    };
  }, []);
  return (
    <span className="dc-loading">
      <i ref={dom}></i>
    </span>
  );
};

/**
 * 用户条款
 *
 * 普通预订，获取餐厅的 tos 和 tos_url
 *
 * @param {*} props
 * @returns
 */
export const Terms = (props) => {
  const { restaurantStore } = useStores();
  const { t } = useTranslation();
  const onShow = (ev) => {
    ev.stopPropagation();
    props.onShow();
  };
  return useObserver(() => {
    return (
      <Checkbox onChange={props.onChange} checked={props.checked}>
        <div>
          <span
            dangerouslySetInnerHTML={{
              __html: t("AGREE_PRIVACY_POLICY_AND_TERMS", {
                privacyUrl: restaurantStore.privacyUrl,
              }),
            }}
          ></span>
          <a onClick={onShow}> {t("TERMS")}</a>.
        </div>
      </Checkbox>
    );
  });
};

/**
 * 内部弹窗页头
 *
 * @param {*} props
 * @returns
 */
export const PageHeader = (props) => {
  return (
    <header>
      <div className="header-left" onClick={props.onBack}>
        <i className="icon icon-arrow-left"></i>
      </div>
      <h2>{props.children}</h2>
      <div className="header-right"></div>
    </header>
  );
};

/**
 * 选择用户福利
 *
 * @param {*} props
 * @returns
 */
export const BenefitsPopup = (props) => {
  const { memberStore } = useStores();
  const { t } = useTranslation();
  const [values, setValues] = useState(props.data || {});
  function onChange(checked, { id, is_optional }) {
    if (!is_optional) return;
    let newVal = Object.assign({}, values, {
      [id]: checked,
    });
    setValues(newVal);
  }
  return useObserver(() => {
    const { benefits } = memberStore;
    return (
      <div className="page-popup page-animate-popup">
        <PageHeader onBack={() => props.onBack(values)}>
          {t("YOUR VIP BENEFITS")}
        </PageHeader>
        <div className="page-popup--body">
          {benefits.map((item) => {
            return (
              <Checkbox
                card
                noIcon={!item.is_optional}
                key={item.title}
                checked={values[item.id]}
                onChange={(checked) => onChange(checked, item)}
              >
                <span dangerouslySetInnerHTML={{ __html: item.title }}></span>
              </Checkbox>
            );
          })}
        </div>
      </div>
    );
  });
};

/**
 * 选择套餐或折扣
 *
 * 需要同时展示折扣和套餐信息
 *
 * @param {*} props
 * @returns
 */
export const DealPopup = (props) => {
  const { restaurantStore } = useStores();
  const { t } = useTranslation();
  const [values, setValues] = useState({
    id: "", // deal_id 或 off_peak_id
    type: "", // 套餐 deal 或 折扣 discount
  });
  function onChange(checked, item) {
    if (checked) {
      setValues(item);
    }
  }
  return useObserver(() => {
    const { form, deals } = restaurantStore;
    const list = [
      {
        id: form.off_peak_id,
        type: "discount",
        desc: form.discount,
        checked: false,
      },
      {
        id: deals.id,
        type: "deal",
        desc: deals.description,
        checked: false,
      },
    ];

    return (
      <div className="page-popup">
        <PageHeader onBack={() => props.onBack(values)}>
          {t("CHOOSE_DEAL")}
        </PageHeader>
        <div className="page-popup--body">
          {list.map((item) => {
            <Checkbox card onChange={(checked) => onChange(checked, item)}>
              <span>{item.desc}</span>
            </Checkbox>;
          })}
        </div>
      </div>
    );
  });
};

/**
 * 用户条款
 *
 * 普通预订，获取餐厅的 tos 和 tos_url
 *
 * 套餐预定，展示 deals 的 term_condition
 *
 * @param {*} props
 * @returns
 */
export const TermsPopup = (props) => {
  const { t } = useTranslation();
  const { restaurantStore } = useStores();
  const query = getQuery();
  return useObserver(() => {
    const { restaurant, deals } = restaurantStore;
    return (
      <div className="page-popup page-animate-popup">
        <header>
          <div className="header-left" onClick={props.onBack}>
            <i className="icon icon-arrow-left"></i>
          </div>
          <h2>{t("TERMS")}</h2>
          <div className="header-right"></div>
        </header>
        <div className="page-popup--body">
          {props.deal_id ? (props.deal|| {}).term_condition : restaurant.tos}
        </div>
      </div>
    );
  });
};

/**
 * 选择支付方式
 *
 * @param {*} props
 * @returns
 */
export const PaymentPopup = (props) => {
  const { t } = useTranslation();
  const { paymentStore } = useStores();
  return useObserver(() => {
    let { paymentMethods } = paymentStore;
    return (
      <div className="page-popup page-animate-popup">
        <header>
          <div className="header-left" onClick={() => props.onBack(props.data)}>
            <i className="icon icon-arrow-left"></i>
          </div>
          <h2>{t("PAYMENT.Payment method")}</h2>
          <div className="header-right"></div>
        </header>
        <div className="page-popup--body">
          <div className="payments">
            <Row gutter={10} justify={"end"}>
              {paymentMethods.map((item) => {
                return (
                  <Col span={12} key={item}>
                    <div
                      onClick={() => props.onBack(item)}
                      className="payment"
                      data-method={item}
                    ></div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
      </div>
    );
  });
};

/**
 * 检测浏览器运行环境
 *
 * @returns
 */
export const Platform = () => {
  var userAgent = navigator.userAgent;
  var platform = {
    trident: userAgent.indexOf("Trident") > -1,
    presto: userAgent.indexOf("Presto") > -1,
    webKit: userAgent.indexOf("AppleWebKit") > -1,
    gecko: userAgent.indexOf("Gecko") > -1 && userAgent.indexOf("KHTML") == -1,
    mobile: !!userAgent.match(/AppleWebKit.*Mobile.*/),
    android:
      userAgent.indexOf("Android") > -1 || userAgent.indexOf("Linux") > -1, //android终端或者uc浏览器
    iPhone: userAgent.indexOf("iPhone") > -1, //是否为iPhone或者QQHD浏览器
    iPad: userAgent.indexOf("iPad") > -1, //是否iPad
    webApp: userAgent.indexOf("Safari") == -1, //是否web应该程序，没有头部与底部
  };
  var isMobile =
    platform.mobile || platform.android || platform.iPhone || platform.iPad;

  document.body.classList.add(isMobile ? "is-mobile" : "is-pc");
  var inIframe = window.top !== window.self;
  document.body.classList.add(inIframe ? "in-iframe" : "in-main");
  return null;
};
