import { action, computed, observable } from 'mobx'
import http, { getQuery } from '../service/http'
import { message } from 'antd'
import qs from 'qs'
import memberStore from './member'
import { insertString } from '@/service/utils'

const cache = {}
const base =
  process.env.REACT_APP_MODE === 'PRODUTION'
    ? 'https://api.diningcity.asia/public/restaurants/'
    : 'https://staging-api.diningcity.asia/public/restaurants/'

/**
 * 获取域名信息
 *
 * @returns
 */
function getHostname() {
  let hostname = window.location.hostname.split('.')
  let targetHostname = 'https://www.diningcity.cn'
  if (hostname[1] && hostname[2]) {
    targetHostname = 'https://www.' + hostname[1] + '.' + hostname[2]
  }
  return targetHostname
}

const hostname = getHostname()

const weburl =
  process.env.REACT_APP_MODE === 'PRODUTION'
    ? 'https://book.diningcity.asia'
    : 'https://staging-book.diningcity.asia'

class Store {
  @observable STEPS = {
    /**开始 */
    START: 0,
    /**卡号验证 */
    CARD: -1,
    /**日期 */
    DATE: 0,
    /**时间 */
    TIME: 1,
    /**座位数 */
    SEAT: 2,
    /**用户资料 */
    INFO: 3,
    /**预定资料 */
    CONFIRM: 4,
    /**预定完成 */
    COMPLETE: 5,
    /**支付二维码 */
    PAYMENT: 999,
    /**admin模式 */
    ADMIN: 1000,
  }
  @observable restaurant = {}
  @observable weburl = ''
  @observable restaurantId = ''
  @observable currency = ''
  url =
    process.env.REACT_APP_MODE === 'PRODUTION'
      ? 'https://api.diningcity.asia/public/restaurants'
      : 'https://staging-api.diningcity.asia/public/restaurants'
  /**获取webform地址 */
  @computed
  get webform() {
    const { id } = this.restaurant
    if (!id) return ''
    const { lang, source } = getQuery()
    const path = `${weburl}/#/restaurants/${id}/reservation?`
    const private_token = memberStore.private_token
    const query = qs.stringify({
      lang,
      private_token,
      source: source,
      show_group_booking: true,
    })
    return path + query
  }

  /**
   *隐私条款
   *
   * @readonly
   * @memberof Store
   */
  @computed
  get privacyUrl() {
    try {
      let restaurant = this.restaurant
      return restaurant.privacy_policy_url
    } catch (error) {
      return 'javascript:void(0);'
    }
  }
  /**设置请求的公共url */
  @action
  setUrl(restaurantId) {
    this.restaurantId = restaurantId
    this.url =
      process.env.REACT_APP_MODE === 'PRODUTION'
        ? 'https://api.diningcity.asia/public/restaurants/' + restaurantId
        : 'https://staging-api.diningcity.asia/public/restaurants/' +
        restaurantId
  }

  /**获取餐厅信息 */
  @action
  async getRestaurant() {
    if (cache[this.restaurantId]) return cache[this.restaurantId]
    try {
      let { data } = await http.get(`${this.url}`)
      cache[this.restaurantId] = data
      this.restaurant = data
      try {
        this.currency = data.region.currency
      } catch (error) { }
      return data
    } catch (error) {
      return {}
    }
  }

  /**获取餐厅信息 */
  @action
  async getRestaurantById(id) {
    if (cache[id]) return cache[id]
    try {
      let { data } = await http.get(`${this.url}/${id}`)
      cache[id] = data
      return data
    } catch (error) {
      return {}
    }
  }

  /**获取套餐信息 */
  @action
  async getDeals() {
    const cacheKey = `deal_${this.restaurantId}`
    if (cache[cacheKey]) return cache[cacheKey]
    try {
      let { data } = await http.get(
        `${this.url}/deals?new_deal=true&per_page=20`
      )
      cache[cacheKey] = data
      return data
    } catch (error) {
      return []
    }
  }

  /**获取菜单 */
  @action
  async getMenus() {
    const cacheKey = `menu_${this.restaurantId}`
    if (cache[cacheKey]) return cache[cacheKey]
    try {
      let { data } = await http.get(`${this.url}/menus/popular`)
      cache[cacheKey] = data
      return data
    } catch (error) {
      return []
    }
  }

  /**Popular */
  @action
  async getPopular() {
    const cacheKey = `menu_popular_${this.restaurantId}`
    if (cache[cacheKey]) return cache[cacheKey]
    try {
      let { data } = await http.get(`${this.url}/menus/popular`)
      cache[cacheKey] = data
      return data
    } catch (error) {
      return []
    }
  }

  @action
  async getPopulaCache(discount) {
    try {
      let { data } = await http.get(`${this.url}/menus/popular`)
      for (let i = 0; i < data.length; i++) {
        data[i].menu_category_name = 'discount'
        data[i].discountPrice = data[i].price
        data[i].price = ((data[i].price * (100 - discount)) / 100).toFixed(2)
      }
      return data
    } catch (error) {
      return []
    }
  }

  @action
  async getAllMenusCache(discount) {
    try {
      let { data } = await http.get(`${this.url}/menus`)
      for (let key in data) {
        let menu = data[key]
        for (let i = 0; i < menu.length; i++) {
          menu[i].menu_category_name = 'discount'
          menu[i].discountPrice = menu[i].price
          menu[i].price = ((menu[i].price * (100 - discount)) / 100).toFixed(2)
        }
      }
      return data
    } catch (error) {
      return []
    }
  }

  /**AllMenus */
  @action
  async getAllMenus() {
    const cacheKey = `menu_all_${this.restaurantId}`
    if (cache[cacheKey]) return cache[cacheKey]
    try {
      let { data } = await http.get(`${this.url}/menus`)
      cache[cacheKey] = data
      return data
    } catch (error) {
      return []
    }
  }

  /**extras_events */
  @action
  async getEvents() {
    const cacheKey = `restaurant_events_${this.restaurantId}`
    if (cache[cacheKey]) return cache[cacheKey]
    try {
      let { data } = await http.get(`${this.url}/extras_events`)
      cache[cacheKey] = data
      return data
    } catch (error) {
      return []
    }
  }

  /**Search */
  @action
  async getSearchData(param) {
    try {
      if ('' == param.trim() || null == param.trim()) {
        return []
      }
      let { data } = await http.get(`${this.url}/menus/search?q=` + param)
      return data
    } catch (error) {
      return []
    }
  }

  /**getMenuPhotos */
  @action
  async getMenuPhotos(menuId) {
    const cacheKey = `menu_photos_${this.restaurantId}_${menuId}`
    if (cache[cacheKey]) return cache[cacheKey]
    try {
      let { data } = await http.get(`${this.url}/menus/${menuId}/photos`)
      cache[cacheKey] = data
      return data
    } catch (error) {
      return []
    }
  }

  /**getMenuDetail */
  @action
  async getMenuDetail(menuId) {
    const cacheKey = `menu_detail_${this.restaurantId}_${menuId}`
    if (cache[cacheKey]) return cache[cacheKey]
    try {
      let { data } = await http.get(`${this.url}/menus/${menuId}`)
      cache[cacheKey] = data
      return data
    } catch (error) {
      return []
    }
  }

  /**Select time & discount */
  @action
  async getSelectTimeAndDiscountData() {
    const cacheKey = `menu_Select_time_discount_${this.restaurantId}`
    if (cache[cacheKey]) return cache[cacheKey]
    try {
      let { data } = await http.get(`${this.url}/available_2018`)
      cache[cacheKey] = data
      return data
    } catch (error) {
      return []
    }
  }

  /**getRegionCountry */
  @action
  async getRegionCountry(id) {
    const cacheKey = `menu_regionCountry_${this.restaurantId}`
    if (cache[cacheKey]) return cache[cacheKey]
    try {
      let { data } = await http.get(`/public/regions/${id}/country`)
      const { lang } = getQuery()
      if (lang == 'en') {
        data.vat_desc = insertString(data.vat_desc, '.', '<br/>')
      } else {
        data.vat_desc = insertString(data.vat_desc, '。', '<br/>')
      }
      cache[cacheKey] = data
      return data
    } catch (error) {
      return []
    }
  }

  /**MenuEvents */
  @action
  async getMenuEvents(menuId) {
    const cacheKey = `menu_events_${this.restaurantId}_${menuId}`
    if (cache[cacheKey]) return cache[cacheKey]
    try {
      let { data } = await http.get(`${this.url}/menus/${menuId}/extras_events`)
      cache[cacheKey] = data
      return data
    } catch (error) {
      return []
    }
  }
  /**MenuReviews */
  @action
  async getMenuReviews(menuId, page = 1) {
    const cacheKey = `menu_reviews_${this.restaurantId}_${menuId}_${page}`
    if (cache[cacheKey]) return cache[cacheKey]
    try {
      let { data, headers } = await http.get(
        `${this.url}/menus/${menuId}/reviews`,
        {
          params: {
            page,
            per_page: 10,
          },
        }
      )
      const result = { data, info: headers }
      cache[cacheKey] = result
      return result
    } catch (error) {
      return []
    }
  }

  /**MenuDeals */
  @action
  async getMenuDeals(menuId) {
    const cacheKey = `menu_deals_${this.restaurantId}_${menuId}`
    if (cache[cacheKey]) return cache[cacheKey]
    try {
      let { data } = await http.get(`${this.url}/menus/${menuId}/deals`)
      cache[cacheKey] = data
      return data
    } catch (error) {
      return []
    }
  }

  /**获取特殊活动 */
  @action
  async getExtrasEvents() {
    const cacheKey = `event_${this.restaurantId}`
    if (cache[cacheKey]) return cache[cacheKey]
    try {
      let { data } = await http.get(`${this.url}/extras_events?status=opening`)
      cache[cacheKey] = data
      return data
    } catch (error) {
      return []
    }
  }

  /**获取VIP待遇 */
  @action
  async getBenfits() {
    const cacheKey = `benfit_${this.restaurantId}`
    if (cache[cacheKey]) return cache[cacheKey]
    try {
      let { data } = await http.get(`${this.url}/loyalty_program_benefits`, {
        platform: 'web',
      })
      cache[cacheKey] = data
      return data
    } catch (error) {
      return []
    }
  }

  /**获取标签 */
  @action
  async getTags() {
    const cacheKey = `tag_${this.restaurantId}`
    if (cache[cacheKey]) return cache[cacheKey]
    try {
      let { data } = await http.get(`${this.url}/profile_tags`, {
        platform: 'web',
      })
      cache[cacheKey] = data
      return data
    } catch (error) {
      return []
    }
  }

  /**获取图片 */
  @action
  async getPhotos() {
    const cacheKey = `photo_${this.restaurantId}`
    if (cache[cacheKey]) return cache[cacheKey]
    try {
      let { data } = await http.get(`${this.url}/pictures`, {
        params: {
          per_page: 9999,
        },
      })
      cache[cacheKey] = data
      return data
    } catch (error) {
      return []
    }
  }

  /**获取评论 */
  @action
  async getReviews(page = 1, per_page = 20) {
    // const cacheKey = `review_${this.restaurantId}_${page}_${per_page}`;
    // if (cache[cacheKey]) return cache[cacheKey];
    try {
      let { data, headers } = await http.get(`/public/reviews`, {
        params: {
          source_type: 'restaurant',
          source_id: this.restaurantId,
          page,
          per_page: per_page,
        },
        headers: {
          'accept-version': '2',
        },
      })
      data.headers = headers
      // cache[cacheKey] = Array.isArray(data) ? data : [];
      // return cache[cacheKey];
      return data
    } catch (error) {
      return []
    }
  }

  /** 获取精选评论 */
  @action
  async getHandpicReviews() {
    try {
      const { data } = await http.get('/public/reviews/handpick', {
        params: {
          source_type: 'restaurant',
          source_id: this.restaurantId,
        },
        headers: {
          'accept-version': '2',
        },
      })
      return data
    } catch (err) {
      return []
    }
  }

  /**获取评论回复 */
  @action
  async getReplies(reviewId, page = 1) {
    // const cacheKey = `replie_${reviewId}_${page}`
    // if (cache[cacheKey]) return cache[cacheKey]
    try {
      let { data, headers } = await http.get(`/public/replies`, {
        params: {
          platform: 'desktop',
          review_id: reviewId,
          page,
          per_page: 200,
        },
        headers: {
          'accept-version': '2',
        },
      })
      data.headers = headers
      // cache[cacheKey] = data
      return data
    } catch (error) {
      return []
    }
  }

  /**获取可用日期 */
  @action
  async getAvailability() {
    const cacheKey = `availability_${this.restaurantId}`
    if (cache[cacheKey]) return cache[cacheKey]
    try {
      let { data } = await http.get(
        `${this.url}/book_now_available_time_slots`,
        {
          headers: {
            'api-key': 'cgecegcegcc',
            'accept-version': 'application/json; version=2',
            'access-token': window.private_token,
          },
        }
      )
      cache[cacheKey] = data
      return data
    } catch (error) {
      return {}
    }
  }

  @action
  async getblockJustifications() {
    const cacheKey = `block_justifications_${this.restaurantId}`
    if (cache[cacheKey]) return cache[cacheKey]
    try {
      let { data } = await http.get(
        `${this.url}/block_justifications?project=diningcity`
      )
      cache[cacheKey] = data
      return data
    } catch (error) {
      return []
    }
  }

  @action
  async getSmartGuideDetail(gid) {
    try {
      let { data } = await http.get(`/public/guides/${gid}/detail`, {
        params: {
          'api-key': 'cgecegcegcc',
        },
        headers: {
          'accept-version': '2',
        },
      })
      return data
    } catch (error) {
      return {}
    }
  }
  /** 获取优惠餐厅 */
  // @action
  // getRestaurantItems(type = "rwcn_summer_2018_riesling", id = "1278") {
  //   const { lang } = getQuery();
  //   return new Promise((resolve) => {
  //     http
  //       .get(
  //         `/public/project/${type}/regions/Shenzhen/collections/${id}/items`,
  //         {
  //           params: {
  //             lang
  //           },
  //           headers: {
  //             "accept-version": "2"
  //           }
  //         }
  //       )
  //       .then(({ data }) => {
  //         resolve(data);
  //       })
  //       .catch((error) => {
  //         resolve(null);
  //       });
  //   });
  // }
  /** 获取海报 */
  @action
  getBanner() {
    const type = process.env.REACT_APP_MODE === "PRODUTION" ? 'rwcn_spring_2024' : 'rwcn_summer_2018_riesling';
    const id = process.env.REACT_APP_MODE === "PRODUTION" ? '3779' : '1278';
    const lang = (navigator.language || navigator.userLanguage).toLowerCase().indexOf('zh') === -1 ? 'en' : 'zh'
    return new Promise((resolve, reject) => {
      http
        .get(`/public/project/${type}/regions/diningcity/collections/${id}`, {
          params: {
            lang
          },
          headers: {
            "accept-version": "2"
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          resolve(null);
        });
    });
  }
  /** 获取优惠餐厅 */
  @action
  getRestaurantItems(itemId) {
    const type = process.env.REACT_APP_MODE === "PRODUTION" ? 'rwcn_spring_2024' : 'rwcn_summer_2018_riesling';
    const id = process.env.REACT_APP_MODE === "PRODUTION" ? itemId : '1278';
    const city = process.env.REACT_APP_MODE === "PRODUTION" ? 'diningcity' : 'Shenzhen';
    const lang = (navigator.language || navigator.userLanguage).toLowerCase().indexOf('zh') === -1 ? 'en' : 'zh'
    return new Promise((resolve) => {
      http
        .get(
          `/public/project/${type}/regions/${city}/collections/${id}/items`,
          {
            params: {
              lang
            },
            headers: {
              "accept-version": "2"
            }
          }
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          resolve(null);
        });
    });
  }

  @action
  async getRecommenders() {
    const cacheKey = `recommenders_${this.restaurantId}`;
    if (cache[cacheKey]) return cache[cacheKey];
    try {
      let { data } = await http.get(`${this.url}/recommenders`);
      cache[cacheKey] = data;
      return data;
    } catch (error) {
      return [];
    }
  }

  @action
  async getGuideItems(gid, options) {
    try {
      let { data, headers } = await http.get(`/public/guides/${gid}/items`, {
        params: options,
        headers: {
          "accept-version": "2",
        },
      });
      return { data, headers }
    } catch (error) {
      return [];
    }
  }
  /** 获取海报 */
  @action
  getBanner() {
    const type = process.env.REACT_APP_MODE === "PRODUTION" ? 'rwcn_spring_2024' : 'rwcn_summer_2018_riesling';
    const id = process.env.REACT_APP_MODE === "PRODUTION" ? '3779' : '1278';
    const lang = (navigator.language || navigator.userLanguage).toLowerCase().indexOf('zh') === -1 ? 'en' : 'zh'
    return new Promise((resolve, reject) => {
      http
        .get(`/public/project/${type}/regions/diningcity/collections/${id}`, {
          params: {
            lang
          },
          headers: {
            "accept-version": "2"
          }
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          resolve(null);
        });
    });
  }
  /** 获取优惠餐厅 */
  @action
  getRestaurantItems(itemId) {
    const type = process.env.REACT_APP_MODE === "PRODUTION" ? 'rwcn_spring_2024' : 'rwcn_summer_2018_riesling';
    const id = process.env.REACT_APP_MODE === "PRODUTION" ? itemId : '1278';
    const city = process.env.REACT_APP_MODE === "PRODUTION" ? 'diningcity' : 'Shenzhen';
    const lang = (navigator.language || navigator.userLanguage).toLowerCase().indexOf('zh') === -1 ? 'en' : 'zh'
    return new Promise((resolve) => {
      http
        .get(
          `/public/project/${type}/regions/${city}/collections/${id}/items`,
          {
            params: {
              lang
            },
            headers: {
              "accept-version": "2"
            }
          }
        )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          resolve(null);
        });
    });
  }

  @action
  async getRecommenders(project) {
    const cacheKey = `recommenders_${this.restaurantId}`;
    if (cache[cacheKey]) return cache[cacheKey];
    try {
      let { data } = await http.get(`${this.url}/recommenders${project ? `?project=${project}` : ''}`);
      cache[cacheKey] = data;
      return data;
    } catch (error) {
      return [];
    }
  }

  /** 提交评论回复 */
  @action
  async saveReply(review_id, content) {
    let { data } = await http.post(
      `/public/replies`,
      {
        review_id,
        content
      },
      {
        headers: {
          'accept-version': '2',
        },
      }
    )
    return data
  }
}

export default new Store()
