import React, { useState } from 'react'
import { Col, Row } from 'antd'
import { UpOutlined, DownOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { getQuery } from '@/service/http'

export const Similar = ({ recommenders, type = 'restaurant' }) => {
  const { t } = useTranslation()
  const { region = 'shanghai', lang = 'en' } = getQuery()
  const [shows, setShows] = useState(
    Array(recommenders?.length || 0).fill(false)
  )
  const handleToggle = (ri) => {
    const arr = JSON.parse(JSON.stringify(shows))
    arr.splice(ri, 1, !arr[ri])
    setShows(arr)
  }
  return (
    <div className='page-restaurant--similar'>
      <h3>{t('WEB.Similar')}</h3>
      <div className='similarList'>
        {recommenders.map((item, ri) => {
          return (
            <div className='similarContainer'>
              <div
                className='similarList-item'
                onClick={() => {
                  window.open(item.website_detail_url, '_blank')
                  window.top.postMessage(
                    {
                      type: 'other',
                      params: {
                        url: item.website_detail_url,
                        region,
                        lang,
                      },
                    },
                    '*'
                  )
                }}
                key={ri}
              >
                <div>
                  {type === 'deal' ? null : item.ratings_avg ? (
                    <div className='rating-score'>
                      {item.ratings_avg}
                      <span>{t('RATING')}</span>
                    </div>
                  ) : (
                    <div className='rating-score'>
                      <span>{t('WEB.No rating')}</span>
                    </div>
                  )}
                  {/* {(item.ratings_avg || item.ratings_avg === 0) && (
                    <div className="rating-score">
                      {item.ratings_avg}
                      <span>{t("RATING")}</span>
                    </div>
                  )} */}
                  <div className='img-fixer'>
                    <img
                      src={
                        item.cover
                          ? `${
                              item.cover.split('?')[0]
                            }-thumb?imageView2/1/w/158/h/120/q/75|imageslim`
                          : `${
                              item.picture_url.split('?')[0]
                            }-thumb?imageView2/1/w/158/h/120/q/75|imageslim`
                      }
                      alt=''
                    />
                  </div>
                </div>
                <div className='content'>
                  {type === 'deal' ? (
                    <div className='deal-name'>{item.name}</div>
                  ) : (
                    <div
                      className='cuisines'
                      style={{ color: '#cfb495', minHeight: '22px' }}
                    >
                      {item.cuisines.map((_, index) => (
                        <span key={index}>
                          {index !== 0 && (
                            <span style={{ margin: '0 3px', fontSize: '12px' }}>
                              ·
                            </span>
                          )}
                          {_.name}
                        </span>
                      ))}
                    </div>
                  )}
                  {type === 'deal' ? (
                    <div className='deal-res-name'>{item.restaurant_name}</div>
                  ) : (
                    <div className='deal-name'>{item.name}</div>
                  )}
                  {type === 'deal' ? (
                    <div className='other'>
                      <div className='deal-type'>
                        <div className='deal-type-inner'>
                          {item.category_name}
                        </div>
                      </div>
                      {/* <div className='deal-price'>{item.format_deal_price}</div> */}
                      {!!item.price_desc?.length && (
                        <div className='deal-price'>
                          {item.price_desc[0]}
                          {item.price_desc.length > 1 && (
                            <div className='origin-price'>
                              {item.price_desc[1]}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className='other'>
                      <div className='avg-price'>
                        {item.format_avg_price
                          ? item.format_avg_price
                          : `${item.currency} ${item.deal_price}`}
                        /p.p
                      </div>
                      <div className='location'>
                        <i className='icon icon-location'></i>
                        {item?.location?.name || item?.locations[0]?.name}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* {item?.tags && (
                <div className="tag-toggle">
                  <div
                    style={{
                      display: shows[ri] ? "block" : "flex",
                      width: "100%"
                    }}
                  >
                    {item.tags.map((tag, index) => (
                      <div className="tag" key={index}>
                        <img src={tag.icon_url} alt="" />
                        {shows[ri] && <span>{tag.name}</span>}
                      </div>
                    ))}
                    {shows[ri] && (
                      <div className="tag-toggle-up">
                        <UpOutlined
                          onClick={() => {
                            handleToggle(ri);
                          }}
                        />
                      </div>
                    )}
                  </div>
                  {!shows[ri] && (
                    <DownOutlined
                      onClick={() => {
                        handleToggle(ri);
                      }}
                    />
                  )}
                </div>
              )} */}
            </div>
          )
        })}
      </div>
    </div>
  )
}
