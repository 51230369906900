import { action, computed, observable } from "mobx";
import zhCN from "antd/es/locale/zh_CN";
import zhTW from "antd/es/locale/zh_TW";
import enUS from "antd/es/locale/en_US";
import viVN from "antd/es/locale/vi_VN";
import moment from "moment";
import "moment/locale/zh-cn";
import "moment/locale/en-au";
import "moment/locale/zh-tw";
import "moment/locale/vi";
import http, { getQuery } from "../service/http";
import "../locales";
import { message } from "antd";

class Store {
  /**用户信息 */
  @observable member = null;
  /**定位坐标 */
  @observable position = null;
  /**定位token */
  @observable private_token = undefined;
  /**页面高度 */
  @observable pageHeight = '';
  /**用户等级数据 */
  @observable levelData = {}

  url =
    process.env.REACT_APP_MODE === "PRODUTION"
      ? "https://api.diningcity.asia"
      : "https://staging-api.diningcity.asia";
  /**
   * 默认从 URL 中获取 private_token
   * 如果URL没有 从当前的域名的主域名获取 private_token
   *
   * 如果当前域名是 website.diningcity.cn/xxx
   * www.diningcity.cn/api/v2/members/profile?callback=JSONP_CALLBACK
   *
   * website.diningcity.hk/xxx
   * www.diningcity.hk/api/v2/members/profile?callback=JSONP_CALLBACK
   *
   * website.diningcity.sg/xxx
   * www.diningcity.sg/api/v2/members/profile?callback=JSONP_CALLBACK
   *
   * @memberof Service
   */
  get jsonCallback() {
    let host = "";
    switch (document.domain) {
      case "website.diningcity.cn":
        host = "https://dcwebsiteapi.diningcity.cn";
        break;
      case "website.diningcity.hk":
        host = "https://dcwebsiteapi.diningcity.hk";
        break;
      case "website.diningcity.sg":
        host = "https://dcwebsiteapi.diningcity.sg";
        break;

      default:
        host = "https://dcwebsiteapi.diningcity.cn";
        break;
    }
    return host + "/api/v2/members/profile?callback=JSONP_CALLBACK";
  }
  /**
   * 通过token获取用户信息
   *
   * @param {string} private_token
   * @returns
   * @memberof Service
   */
  getUserByToken(private_token) {
    return new Promise((resolve, reject) => {
      http
        .get(`${this.url}/member`, {
          headers: {
            "access-token-member": private_token,
          },
        })
        .then(({ data }) => {
          this.member = data;
          resolve(data);
        })
        .catch((error) => {
          console.error("获取用户信息失败, ", error);
          reject(error);
        });
    });
  }

  /**
   * 获取用户信息
   *
   * @returns
   * @memberof Service
   */
  @action
  async show() {
    let member = {};
    if (this.member) return this.member;
    try {
      let { private_token } = getQuery();
      if (private_token) {
        window.private_token = private_token;
        this.private_token = private_token;
        member = await this.getUserByToken(private_token);
      } else {
        let data = await http.jsonp(this.jsonCallback, { timeout: 3000 });
        member = data.member;
      }
      this.member = member;
      window._paq.push(['setUserId', member.id])
      window.private_token = member.private_token;
      this.private_token = member.private_token;
      return member;
    } catch (error) {
      console.error("获取用户信息失败, ", error);
      return Promise.reject(error);
    }
  }
  /**获取距离 */
  @action getDistance(lat2, lng2) {
    if (this.position) {
      const { lat, lng } = this.position;
      let distance = L.latLng(lat, lng).distanceTo(L.latLng(lat2, lng2));
      return distance > 1000
        ? `${(distance / 1000).toFixed(1)}km`
        : `${Math.ceil(distance)}m`;
    }
  }

  @action getLocale() {
    let query = http.getQuery();
    let { lang = "en" } = query;
    const language = {
      en: {
        moment: "en-au",
        antd: enUS,
      },
      zh: {
        moment: "zh-cn",
        antd: zhCN,
      },
      "zh-HK": {
        moment: "zh-tw",
        antd: zhTW,
      },
      vi: {
        moment: "vi",
        antd: viVN,
      },
    }[lang];
    moment.locale(language.moment);
    return language.antd;
  }

  /** 我的收藏 */
  @action
  getMyCollections(options) {
    return new Promise((resolve, reject) => {
      http
        .get(`/member/collections`, {
          params: options,
          headers: {
            'accept-version': '2',
          },
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  /** 我的收藏下的餐厅列表 */
  @action
  getCollectionRestaurants(collectId, options) {
    return new Promise((resolve, reject) => {
      http
        .get(`/member/collections/${collectId}/items`, {
          params: options,
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /** 更新用户信息 */
  @action
  updateUserInfo(params) {
    return new Promise((resolve, reject) => {
      http
        .put(`/member`, params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          message.error(error.message);
          reject(error);
        });
    });
  }

  /** 获取删除用户信息 */
  @action
  getDestorySummery(options) {
    return new Promise((resolve, reject) => {
      http
        .get(`/member/destroy_member_summary`, {
          params: options
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /** 获取城市代码 */
  @action
  getCountryCodes(options) {
    return new Promise((resolve, reject) => {
      http
        .get(`/public/countries/country_codes`, {
          params: options
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /** 发送验证码 */
  @action
  sendSmsCode(options) {
    return new Promise((resolve, reject) => {
      http
        .post(`/public/mobile_tokens`, options)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          message.error(error.message);
          reject(error);
        });
    });
  }

  /** 发送帮助 */
  @action
  sendHelp(options) {
    return new Promise((resolve, reject) => {
      http
        .post(`${process.env.REACT_APP_MODE === "PRODUTION" ? "https://dcwebsiteapi.diningcity.asia" : "https://www.diningcitydeals.com"}/api/v2/members/sign_up_help`, options)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          message.error(error.message);
          reject(error);
        });
    });
  }

  /** 删除账号 */
  @action
  detoryMember(options) {
    return new Promise((resolve, reject) => {
      http
        .delete(`/member/destroy_member`, {
          params: options
        })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          message.error(error.data?.full_messages?.length ? error.data.full_messages[0] : error.message);
          reject(error);
        });
    });
  }
  
}

export default new Store();
