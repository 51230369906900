import { action, observable } from 'mobx'
import http from '../service/http'

class Store {
  /**当天的预订 */
  @observable todayBookings = {}
  @observable shortCuts = null
  /**
   * 获取首页banner
   *
   */
  @action
  getHomeBanners({ region = 'shanghai' }, isMobile, genre=false) {
    return new Promise((resolve, reject) => {
      http
        .get(`/public/regions/${region}/app_banners`, {
          params:{
            platform: isMobile ? '' : 'desktop',
            genre: genre ? 'vip_benefits' : '',
          }
        })
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  /**
   * 获取首页shortcuts
   *
   */
  @action
  getHomeShortCuts({ region = 'shanghai', project }) {
    return new Promise((resolve, reject) => {
      if(this.shortCuts){
        resolve(this.shortCuts)
      } else  {
        http
        .get(`/public/shortcuts`, {
          params: {
            region,
            project: project || 'diningcity',
          },
          headers: {
            'accept-version': '2',
          },
        })
        .then(({ data }) => {
          // data = data.concat(data.concat(data))
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
      }
    })
  }

  /**
   * 获取Adv pop up
   *
   */
  @action
  getHomeAdvPopup({ region }) {
    return new Promise((resolve, reject) => {
      http
        .get(`/public/advertisement_pops`, {
          params: {
            region,
          },
        })
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  /**
   * 获取today bookings
   *
   */
  @action
  getTodayBookings() {
    return new Promise((resolve, reject) => {
      http
        .get(`/member/bookings/today`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  /**
   * 获取Collections
   *
   */
  @action
  getHomeCollections(params) {
    return new Promise((resolve, reject) => {
      http
        .get(`/public/regions/${params.region}/groups/web_home`, {
          params,
          headers: {
            'accept-version': '2',
          },
        })
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  /**
   * 获取shortLink详情
   *
   */
  @action
  getWebviewSkip(link) {
    return new Promise((resolve, reject) => {
      http
        .get('/public/deeplinks/webview_skip', {
          params: {
            link,
          },
        })
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

export default new Store()
