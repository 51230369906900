import React from "react";
import { Deal } from "@/components";
import { useTranslation } from "react-i18next";

/**优惠预定 */
export const Deals = ({ deals }) => {
  const { t } = useTranslation();
  if (deals==""||deals==null||deals==undefined) {
    return null;
  }
  return (
    <section className="page-restaurant--deals">
      <h3>{t("WEB.In deal(s)")}</h3>
      <div className="deals-list">
        {deals.map((item, index) => {
          return <Deal deal={item} key={item.id} odd={index % 2 === 0} />;
        })}
      </div>
    </section>
  );
};
