import React from "react";
import Loading from "../dc-loading";
import "./index.less";

/**
 * 按钮
 *
 * @param {*} {size, type, disabled, block = false, loading}
 * @returns
 */
const Button = (props) => {
  let { size, type, disabled, block = false, loading, style = {} } = props;
  return (
    <button
      className="dc-button"
      data-size={size}
      data-block={block}
      data-loading={loading}
      type={type}
      disabled={disabled}
      onClick={props.onClick}
      style={style}
    >
      {loading ? <Loading></Loading> : props.children}
    </button>
  );
};

export default Button;
