import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Drawer, Input } from 'antd'
import { Header, ReviewItem, Restaurant, Deal, Button } from '@/components'
import { useStores } from '@/store'
import { getQuery } from '@/service/http'
import './index.less'

/**评论详情 */
const ReviewDetail = ({
  review,
  onPreview,
  visible,
  setVisible,
  onDelete = () => {},
  onEdit = () => {},
  restaurant,
  deal,
  reviews,
  setReviews,
  openReply=false,
}) => {
  const { t } = useTranslation()
  const { restaurantStore } = useStores()
  const [replies, setReplies] = useState([])
  const [isReply, setIsReply] = useState(false)
  const [replyDes, setReplyDes] = useState('')
  const { region } = getQuery()
  useEffect(()=>{
    setIsReply(openReply)
  },[openReply])
  useEffect(() => {
    if (!review.id) return
    restaurantStore.getReplies(review.id).then(setReplies)
  }, [review.id])
  const onReply = () => {
    setIsReply(true)
  }
  const submitReply = () => {
    restaurantStore.saveReply(review.id, replyDes).then((res) => {
      restaurantStore.getReplies(review.id).then((data) => {
        setReplies(data)
        setIsReply(false)
        if (setReviews) {
          const list = JSON.parse(JSON.stringify(reviews))
          const index = list.findIndex((_) => _.id === review.id)
          list[index].replies_count = data.length
          setReviews(list)
        }
      })
    })
  }
  return (
    <Drawer
      className='booking-drawer reviews-drawer profile-item-inner-drawer reviews-drawer-detail'
      placement='right'
      closable={false}
      style={{zIndex:9998}}
      destroyOnClose
      onClose={() => {
        setVisible(false)
        // setIsReply(false)
      }}
      visible={visible}
    >
      <Header title={t('Details')} onClick={() => setVisible(false)} />
      <ReviewItem
        key={`${review.id}-${review.timestamp}`}
        review={review}
        onPreview={onPreview}
        showRateDetail={true}
        mobile={true}
        showAll={true}
        onDelete={onDelete}
        onEdit={onEdit}
        isShowGallery={true}
        onReply={onReply}
        replies={replies}
      />
      <div hidden={!replies.length}>
        <h3>{t('WEB.comments', { count: replies.length })}</h3>
        <div className='review-comments'>
          {replies.map((item) => {
            return (
              <ReviewItem
                mobile={true}
                key={item.id}
                review={item}
                showAll={true}
              />
            )
          })}
        </div>
      </div>
      {restaurant && (
        <div className='review-restaurant'>
          <h2>{t('WEB.The restaurant')}</h2>
          <Restaurant restaurant={restaurant} region={region} />
        </div>
      )}
      {deal && (
        <div className='review-restaurant'>
          <h2>{t('WEB.The deal')}</h2>
          <Deal deal={deal} odd={false} bookNowDeals={true} showGet={false} />
        </div>
      )}
      {isReply && (
        <div className='review-reply'>
          <Input
            size='large'
            onChange={(e) => {
              setReplyDes(e.target.value)
            }}
            autoFocus
            placeholder={t('REVIEW.Describe placeholder')}
          />
          <Button size='small' type='primary' onClick={submitReply}>
            {t('REVIEW.Submit')}
          </Button>
        </div>
      )}
    </Drawer>
  )
}

export default ReviewDetail
